import { ShareOutlined } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import student from 'assets/students/av6.png';
import { useEffect } from 'react';
import Header from './Header';
//import { Margin } from 'mdi-material-ui';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/store';
import { getUserQR } from 'store/Thunk/user';
function TopicsEngamement({
  onClick,
  parent,
  studentData,
  studentQR,
}: {
  onClick?: any;
  parent: boolean;
  studentData?: any;
  studentQR?: any;
}) {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  useEffect(() => {
    studentData?.id && dispatch(getUserQR(studentData?.id || ''));
    // setLoadingState(openState);
  }, [studentData]);
const {currentSelectedUser } = useAppSelector(
    (state: any) => state.ParentEducatorReducer,
  );
  const { userQR } = useAppSelector((state) => state.userReducer);

  const handleDownlaodQR = () => {
    const downloadLink = document.createElement('a');
    downloadLink.href = userQR?.qr || '';
    downloadLink.download = `QR-${studentData?.id}.jpg`;
    downloadLink.click();
  };
  const handleQRPress = () => {
    const userData: any = localStorage.getItem('selectedStudentId');
    const downloadLink = document.createElement('a');
    downloadLink.href = userQR?.qr || '';
    downloadLink.download = `QR-${userData}.jpg`;
    downloadLink.click();
  };

  return (
    <>
      {parent ? (
        <Box
          className={
            'lg:w-[56%] border border-[#EAECF0] rounded-lg p-2 mr-1 mt-2'
          }
        >
          <div className="mt-2 ml-2">
            <Header text={'Engagement by Topics/Subjects'} />
            <div className="flex items-center justify-between">
              <div className="mt-6 ">
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: 12,
                    textAlign: 'center',
                  }}
                >
                  Download Our Mobile App
                </Typography>
                <div
                  className={
                    studentData
                      ? 'flex justify-center mt-4'
                      : 'flex justify-center mt-4'
                  }
                >
                  <div
                    role="button"
                    onClick={() =>
                      window.open(
                        'https://apps.apple.com/us/app/g3ms/id1625313492',
                        '_blank',
                      )
                    }
                    className="w-[40%]"
                  >
                    <div className="flex items-center justify-center">
                      <img
                        alt=""
                        src={require('../../../../assets/Parent/app-store.png')}
                        className="h-8 w-8"
                      />
                    </div>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: 10,
                        textAlign: 'center',
                      }}
                    >
                      Download on the App Store
                    </Typography>
                  </div>
                  <div
                    role="button"
                    onClick={() =>
                      window.open(
                        'https://play.google.com/store/apps/details?id=com.g3ms',
                        '_blank',
                      )
                    }
                    className="w-[40%]"
                  >
                    <div className="flex items-center justify-center">
                      <img
                        alt=""
                        src={require('../../../../assets/Parent/play-store.png')}
                        className="h-8 w-8"
                      />
                    </div>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: 10,
                        textAlign: 'center',
                      }}
                    >
                      Download on the Play Store
                    </Typography>
                  </div>
                </div>
              </div>
              {studentData ? (
                <Box className="border lg:w-[42%] w-[50%] border-[#EAECF0] mt-3 rounded-lg p-2">
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: 12,
                      // textAlign: 'center',
                    }}
                  >
                    Student Detail
                  </Typography>
                  <div className="flex items-center justify-between mt-3">
                    <div className="border-[1.5px] rounded-full border-[#20A58A] p-1 lg:ml-3">
                      <img
                        alt=""
                        src={studentData?.profileImage || student}
                        className="h-10 w-10 "
                      />
                    </div>

                    <img alt="" src={studentQR} className="h-12 w-12" />
                  </div>
                  <div className="w-[40%]">
                    <Typography
                      color={'#20A58A'}
                      sx={{
                        fontSize: 13,
                        fontWeight: 400,
                        marginTop: 1,
                        textAlign: 'center',
                      }}
                    >
                      {studentData?.name}
                    </Typography>
                    <div className="flex items-center justify-between">
                      <Typography
                        color={'#131515'}
                        sx={{ fontSize: 12, fontWeight: 400, marginRight: 2 }}
                      >
                        {studentData?.username}
                      </Typography>
                      <Typography
                        color={'#131515'}
                        sx={{ fontSize: 12, fontWeight: 400 }}
                      >
                        {studentData?.grade_?.name}
                      </Typography>
                    </div>
                  </div>
                  <div className="w-full mt-3">
                    <Button
                      onClick={handleDownlaodQR}
                      sx={{ width: '100%' }}
                      variant="contained"
                      startIcon={<ShareOutlined />}
                    >
                      Download
                    </Button>
                  </div>
                </Box>
              ) : null}
            </div>
          </div>
        </Box>
      ) : (
        <Box className={' border border-[#EAECF0] lg:mx-1 rounded-lg p-2 mt-2'}>
          <div className="mt-2 ml-2">
            <Header text={'Engagement by Topics/Subjects'} />
            <div className={
              currentSelectedUser==="student"?"flex":
              ""}>
              <div className="mt-6 w-[60%]">
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: 12,
                    textAlign: 'center',
                  }}
                >
                  Download Our Mobile App
                </Typography>
                <div className="flex justify-around mt-4 ">
                  <div
                    role="button"
                    onClick={() =>
                      window.open(
                        'https://apps.apple.com/us/app/g3ms/id1625313492',
                        '_blank',
                      )
                    }
                    className="w-[40%] "
                  >
                    <div className="flex justify-center items-center mb-2">
                      <img
                        alt=""
                        src={require('../../../../assets/Parent/app-store.png')}
                        className="h-8 w-8"
                      />
                    </div>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: 10,
                        textAlign: 'center',
                      }}
                    >
                      Download on the App Store
                    </Typography>
                  </div>
                  <div
                    role="button"
                    onClick={() =>
                      window.open(
                        'https://play.google.com/store/apps/details?id=com.g3ms',
                        '_blank',
                      )
                    }
                    className="w-[40%]"
                  >
                    <div className="flex justify-center items-center mb-2">
                      <img
                        alt=""
                        src={require('../../../../assets/Parent/play-store.png')}
                        className="h-8 w-8"
                      />
                    </div>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: 10,
                        textAlign: 'center',
                      }}
                    >
                      Download on the Play Store
                    </Typography>
                  </div>
                </div>
              </div>
              {currentSelectedUser==="student"?
               <Box className="border  border-[#EAECF0] mt-6 rounded-lg p-4 ml-4 ">
               <div className="flex items-center justify-center ">
                 <img
                   alt=""
                   src={studentQR}
                   className="h-14 w-14"
                 />
               </div>
               <Typography
                 sx={{ fontSize: 13, fontWeight: 600, marginTop: 3, textAlign:"center" }}
               >
                 Join Me
               </Typography>
               <div className="w-full mt-3">
            <Button
              onClick={handleQRPress}
              sx={{ width: '100%' }}
              variant="contained"
              startIcon={<ShareOutlined />}
            >
              Download
            </Button>
          </div>
             </Box>
             :
             null
              }
             
            </div>
          </div>
         
        </Box>
      )}
    </>
  );
}
export default TopicsEngamement;
