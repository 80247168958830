
import { createAsyncThunk } from '@reduxjs/toolkit';
import API from 'store/api';

import {
  setbreadcrumbs,
  setClassRoomList,
  setClassroomStudentList,
  setCurrentSelectedUser,
  setDepartmentList,
  setEngagementList,
  setReactionList,
  setRoute,
  setSchools,
  setTotals
  } from 'store/Slice/ParentEducatorDashboardSlice';
  import { stopLoading, startLoading } from 'store/Slice/loadingSlice';
  import {
    notificationFail,
    notificationSuccess,
  } from 'store/Slice/notificationSlice';
import { setStudentList } from 'store/Slice/userSlice';
  
  export const getClassList = createAsyncThunk(
    'getClassList',
    
    async (_, { dispatch }) => {
//         const userData: any = localStorage.getItem('userData');
//         const id :any =JSON.parse(userData).id
// console.log("id%%%%%%%%%%", id)
     let url = `/api/v1/dashboard/parent/educator/classes`;
      try {
        const response: any = await API.get(url);
        dispatch(setClassRoomList(response.data.data));
     console.log("classdata%%%%%%%",response.data.data )
        return response;
      }  catch (err) {
        return err;
      }
    },
  );
  export const getClassListDistrictLeader = createAsyncThunk(
    'getClassListDistrict',
    
    async (_id:any, { dispatch }) => {
//         const userData: any = localStorage.getItem('userData');
//         const id :any =JSON.parse(userData).id
// console.log("id%%%%%%%%%%", id)
     let url = `api/v1/dashboard/educator/district/classes/${_id}`;
      try {
        const response: any = await API.get(url);
        dispatch(setClassRoomList(response.data.data));
     console.log("classdata%%%%%%%",response.data.data )
        return response;
      }  catch (err) {
        return err;
      }
    },
  );
  export const getAllStudent = createAsyncThunk(
    'getAllStudentList',
    
    async (_, { dispatch }) => {
     let url = `/api/v1/dashboard/parent/educator/classes/all/students`;
      try {
        const response: any = await API.get(url);
        dispatch(setClassroomStudentList(response.data.data.classStudents));
     console.log("ClassroomStudentList", response.data)
        return response;
      }  catch (err) {
        return err;
      }
    },
  );
  export const getAllStudentSchoolLeader = createAsyncThunk(
    'getAllStudentListSchool',
    
    async (_, { dispatch }) => {
     let url = `/api/v1/dashboard/school/leader/school/all/students`;
      try {
        const response: any = await API.get(url);
        dispatch(setClassroomStudentList(response.data.data.classStudents));
        return response;
      }  catch (err) {
        return err;
      }
    },
  );
  export const getAllStudentDistrictLeader = createAsyncThunk(
    'getAllStudentListSchool',
    
    async (_id:any, { dispatch }) => {
     let url = `/api/v1/dashboard/educator/district/students/${_id}`;
      try {
        const response: any = await API.get(url);
        dispatch(setClassroomStudentList(response.data.data.classStudents));
        return response;
      }  catch (err) {
        return err;
      }
    },
  );
  export const getStudentOfClassList = createAsyncThunk(
    'getStudentList',
    
    async (_id:any, { dispatch }) => {
     let url = `/api/v1/dashboard/parent/educator/students/${_id}`;
      try {
        const response: any = await API.get(url);
        dispatch(setClassroomStudentList(response.data.data.classStudents));
     console.log("ClassroomStudentList", response.data)
        return response;
      }  catch (err) {
        return err;
      }
    },
  );
  export const getEngagements = createAsyncThunk(
    'getEngagements',
    
    async (_data:any, { dispatch }) => {
      console.log("in engagements API", _data)
      let url;
      if(_data.type==="DEPARTMENT"){
        console.log("DEPARTMENT@@@@")
         url = `/api/v1/dashboard/educator/engagements?topicId=${_data?.id}&type=${_data.type}`;
      }else{
        console.log("OUTDEPARTMENT")
        url = `/api/v1/dashboard/educator/engagements?id=${_data?.id}&type=${_data.type}`;
      }
    
      try {
        const response: any = await API.get(url);
        dispatch(setEngagementList(response.data.data));
     console.log("engagements dataaaa",response.data.data)
        return response;
      }  catch (err) {
        return err;
      }
    },
  );
  export const getReactions = createAsyncThunk(
    'getReactions',
    
    async (_data:any, { dispatch }) => {
      console.log("in engagements API", _data)
      let url;
      if(_data.type==="DEPARTMENT"){
         url = `/api/v1/dashboard/educator/reactions?topicId=${_data?.id}&type=${_data.type}`;
      }else{
        url = `/api/v1/dashboard/educator/reactions?id=${_data?.id}&type=${_data.type}`;
      }

      try {
        const response: any = await API.get(url);
        dispatch(setReactionList(response.data.data))
        console.log("engagements API", response.data.data)
        return response;
      }  catch (err) {
        return err;
      }
    },
  );
  export const getTotals = createAsyncThunk(
    'getTotals',
    
    async (_data:any, { dispatch }) => {
      console.log("inside totals", _data)
      let url;
      if(_data.type==="DEPARTMENT"){
         url = `/api/v1/dashboard/educator/totals?topicId=${_data?.id}&type=${_data.type}`;
      }else{
        url = `/api/v1/dashboard/educator/totals?id=${_data?.id}&type=${_data.type}&topicId=MATH01`;
      }

      try {
        const response: any = await API.get(url);
        dispatch(setTotals(response.data.data))
        console.log("totals", response.data.data)
        return response;
      }  catch (err) {
        return err;
      }
    },
  );
  export const getDepartments = createAsyncThunk(
    'getDepartmentList',
    
    async (_, { dispatch }) => {
     let url = `/api/v1/dashboard/parent/educator/school/Departments`;
      try {
        const response: any = await API.get(url);
        dispatch(setDepartmentList(response.data.data.allDepartments))
        console.log("DepartmentList", response.data.data)
        return response;
      }  catch (err) {
        return err;
      }
    },
  );
  export const getDepartmentsSchoolLeader = createAsyncThunk(
    'getDepartmentListSchool',
    
    async (_, { dispatch }) => {
     let url = `/api/v1/dashboard/school/leader/school/departments`;
      try {
        const response: any = await API.get(url);
        dispatch(setDepartmentList(response.data.data.allDepartments))
        console.log("DepartmentList", response.data.data)
        return response;
      }  catch (err) {
        return err;
      }
    },
  );
  export const getDepartmentClasses = createAsyncThunk(
    'getDepartmentList',
    
    async (_id:any, { dispatch }) => {
     let url = `/api/v1/dashboard/educator/department/classrooms/${_id}`;
      try {
        const response: any = await API.get(url);
        dispatch(setClassRoomList(response.data.data))
        console.log("classrooms%%%%%", response.data.data)
        return response;
      }  catch (err) {
        return err;
      }
    },
  );
  export const getDepartmentClassesSchoolLeader = createAsyncThunk(
    'getDepartmentListSchoolLeader',
    
    async (_id:any, { dispatch }) => {
      console.log("In departments API ######## %%%%%")
     let url = `/api/v1/dashboard/school/leader/department/classrooms/${_id}`;
      try {
        const response: any = await API.get(url);
        dispatch(setClassRoomList(response.data.data))
        console.log("classrooms of departments %%%%%", response.data.data)
        return response;
      }  catch (err) {
        return err;
      }
    },
  );
  export const getAllSchools = createAsyncThunk(
    'getDepartmentListSchoolLeader',
    
    async (_id:any, { dispatch }) => {
     let url = `/api/v1/dashboard/educator/district/schools/${_id}`;
     console.log("url%%%%%", url)
      try {
        const response: any = await API.get(url);
        dispatch(setSchools(response.data.data.schools))
console.log("inSChopol***", response.data.data)
        return response;
      }  catch (err) {
        return err;
      }
    },
  );
  export const deleteStudent = createAsyncThunk(
    'deleteClass',
  
    async (id: any, { dispatch }) => {
      // console.log("delete student",  id)
      let url = `/api/v1/classroom/${id}`;
      dispatch(startLoading());
  
      try {
        const response: any = await API.delete(url);
        dispatch(notificationSuccess('Classroom Deleted Successfully!!'));
        dispatch(stopLoading());
        dispatch(getClassList());
        let tempArr;
  
        return response;
      } catch (err: any) {
        dispatch(notificationFail(err.response.data.message));
        dispatch(stopLoading());
        return err;
      }
    },
  );
  export const getClassesOfSchool = createAsyncThunk(
    'getClassesOfSchool',
    
    async (_id:any, { dispatch }) => {
     let url = `/api/v1/school/${_id}/classroom`;
      try {
        const response: any = await API.get(url);
        dispatch(setClassRoomList(response.data.data.items))
        return response;
      }  catch (err) {
        return err;
      }
    },
  );
  export const getStudentsOfSchool = createAsyncThunk(
    'getStudentsOfSchool',
    
    async (_id:any, { dispatch }) => {
     let url = `/api/v1/schools/${_id}/students`;
      try {
        const response: any = await API.get(url);
        dispatch(setClassroomStudentList(response.data.data))
        console.log("students",response.data.data)
        return response;
      }  catch (err) {
        return err;
      }
    },
  )
  export const getStudentsOfDepartment = createAsyncThunk(
    'getStudentsOfSchool',
    
    async (_id:any, { dispatch }) => {
     let url = `/api/v1/dashboard/educator/department/students/${_id}`;
      try {
        const response: any = await API.get(url);
        dispatch(setClassroomStudentList(response.data.data.classStudents))
        console.log("students********** ",response.data.data.classStudents)
        return response;
      }  catch (err) {
        return err;
      }
    },
  )
  export const getCurrentSelectedUser = createAsyncThunk(
    'getSelectedUser',
    
    async (_data:any, { dispatch }) => {
    
      try {
        dispatch(setCurrentSelectedUser(_data))
      }  catch (err) {
        return err;
      }
    },
  );
  export const setBreadCrumbsData = createAsyncThunk(
    'setbreadcrumbs',
    
    async (_data:any, { dispatch }) => {
    
      try {
        dispatch(setbreadcrumbs(_data))
      }  catch (err) {
        return err;
      }
    },
  );
  export const setSelectedRoute = createAsyncThunk(
    'setRoute',
    
    async (_data:any, { dispatch }) => {
    
      try {
        dispatch(setRoute(_data))
      }  catch (err) {
        return err;
      }
    },
  );

