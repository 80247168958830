import { Button, Modal, rem } from '@mantine/core';
import useModal from 'components/Modal/hook/useModal';
import { useGetMyProfileQuery } from 'store/apis/profile';
import cn from 'utils/cn';

export default function EducatorUpgradeSuccess() {
  const { closeModal, isOpen, modal, openModal } = useModal<{
    onContinue?: () => void;
  }>();

  const { data, isLoading } = useGetMyProfileQuery();

  return (
    // <Modal isOpen={isOpen('AskAGrownupModal')} onClose={closeModal}>
    //   <Modal.Content className="[--max-width:_45rem] [--top:_2rem]  md:[--top:_10%]">
    //     <Modal.CloseButton />
    <Modal
      opened={isOpen('EducatorUpgradeSuccess')}
      onClose={closeModal}
      closeOnClickOutside={false}
      closeOnEscape={false}
      size={'lg'}
      centered
      shadow="sm"
      radius={'lg'}
      classNames={{
        title:
          '!text-lg !font-semibold lg:!text-xl 2xl:!text-2xl !text-[#2C3E50]',
        close:
          '!text-[#2C3E50] !text-lg !font-semibold lg:!text-xl 2xl:!text-2xl',
      }}
      padding={rem(24)}
      zIndex={999999999}
      withCloseButton={false}
    >
      <div className="flex flex-col gap-4 xl:gap-8">
        <div className="grid gap-2 lg:gap-4 justify-center align-top items-center place-items-center">
          <h1
            className={cn(
              '!font-semibold !text-lg xl:!text-xl 2xl:!text-2xl text-[#131515] text-center',
            )}
          >
            🎉 Upgrade Successful!
          </h1>

          <p
            className={cn(
              '!text-sm lg:!text-base xl:!text-lg 2xl:!text-xl text-black text-center',
            )}
          >
            You've unlocked more learning opportunities for your students! Now,
            personalize G3MS as an educator to maximize their success.
          </p>
        </div>

        <p
          className={cn(
            '!text-sm lg:!text-base xl:!text-lg 2xl:!text-xl text-[#131515] font-bold',
          )}
        >
          🎉 The Student was added to classroom
        </p>
        <p
          className={cn(
            '!text-sm lg:!text-base xl:!text-lg 2xl:!text-xl text-[#131515]',
          )}
        >
          Would you like to add another student?
        </p>

        <div className="grid gap-4 lg:grid-cols-2">
          <Button
            onClick={() => {
              if (!data?.data?.accountSetup?.isAcademicContentsSelected) {
                openModal('ParentSelectAcademic', {
                  educatorPlan: 'premium',
                  cache: true,
                });
                return;
              }
              if (!data?.data?.accountSetup?.isSocialContentsSelected) {
                openModal('ParentSelectSocialEmotion', {
                  educatorPlan: 'premium',
                  cache: true,
                });
                return;
              }

              openModal('EducatorPremiumPlan', {
                educatorPlan: 'premium',
                cache: true,
              });
            }}
            className="max-w-full !rounded-xl"
            color="#999999"
            size="xl"
            fw={500}
          >
            No
          </Button>
          <Button
            onClick={() => {
              if (data?.data?.isUserUpgradedPlan) {
                openModal('AddAnotherUserModal', { cache: true });
              } else {
                openModal('UpgradeToAddMoreStudentModal', { cache: true });
              }
            }}
            className="max-w-full !rounded-xl"
            color="#20A58A"
            size="xl"
            fw={500}
          >
            Yes
          </Button>
        </div>
      </div>
    </Modal>

    // </Modal.Content>
    // </Modal>
  );
}
