import * as React from 'react';
import CustomizedSelectComponent from './CustomizedSelectComponent';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAppSelector } from 'store/store';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { getGradeList } from 'store/Thunk/videoThunk';
import { getCities, getStates } from 'store/Thunk/districtThunk';
import { getSchoolByCity } from 'store/Thunk/schoolThunk';
import Input from 'components/Input';
import TextFieldCustomized from './TextFieldCustomized';
import useModal from 'components/Modal/hook/useModal';
import { addStudent, getChildList } from 'store/Thunk/ParentDashboardThunk';
import { useGetMyProfileQuery } from 'store/apis/profile';
import ButtonMain from '../ButtonMain';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  day: Yup.number().required('Required'),
  month: Yup.number().required('Required'),
  year: Yup.number().required('Required'),
  email: Yup.string().email(),
  username: Yup.string().required('Username is required'),
  state: Yup.object().shape({
    value: Yup.string().required('State is required'),
  }),
  grade: Yup.object().shape({
    value: Yup.string().required('Grade is required'),
  }),
  city: Yup.object().shape({
    value: Yup.string().required('City is required'),
  }),
  school: Yup.object().shape({
    value: Yup.string().required('school is required'),
  }),
});
function AddStudentComponent({ type }: { type: string }) {
  const { data } = useGetMyProfileQuery();
  const { openModal, closeModal } = useModal();
  const { gradeList } = useAppSelector((state: any) => state.videoReducer);
  const { schoolByCity } = useAppSelector((state: any) => state.schoolReducer);
   const nextInputRef = React.useRef<HTMLInputElement | null>(null);
  const { stateList, cityList } = useAppSelector(
    (state: any) => state.districtReducer,
  );
  const { screenLoading } = useAppSelector(
    (state: any) => state.loadingReducer,
  );
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  React.useEffect(() => {
    document.addEventListener("keydown", function (event: any) {
      if (event.keyCode === 13 && event.target.nodeName === "INPUT") {
        var form = event.target.form;
        var index = Array.prototype.indexOf.call(form, event.target);
        form.elements[index + 1].focus();
        event.preventDefault();
      }
    });
  }, []);
  React.useEffect(() => {
    dispatch(getGradeList());
    dispatch(getStates({}));
  }, []);

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      username: '',
      email: '',
      day: '',
      month: '',
      year: '',
      grade: {
        label: '',
        value: '',
      },
      dob: '',
      state: {
        label: '',
        value: '',
      },
      city: {
        label: '',
        value: '',
      },
      school: {
        label: '',
        value: '',
      },
    },
    onSubmit: (values, { setFieldError }) => {
    
      console.log('hello', values);
      const day = Number(values?.day);
      const month = Number(values?.month);
      const year = Number(values?.year);
      try {
        const monthWith31Days = [1, 3, 5, 7, 8, 10, 12];
        if (day > 31) {
          setFieldError('day', 'Invalid Date, Please enter a valid date');
          // Toast.show({
          //   type: 'error',
          //   text1: 'Invalid Date',
          //   text2: 'Please enter a valid date',
          // });
          return;
        }

        if (day > 30 && !monthWith31Days.includes(month)) {
          setFieldError('day', 'Invalid Date, Please enter a valid date');
          // Toast.show({
          //   type: 'error',
          //   text1: 'Invalid Date',
          //   text2: 'Please enter a valid date',
          // });
          return;
        }
        if (month === 2 && day > 28) {
          const isLeapYear =
            Number(year) % 4 === 0 && Number(year) % 100 !== 0 ? true : false;

          if (Number(month) === 2 && Number(day) > 29 && isLeapYear) {
            setFieldError('day', 'Invalid Date, Please enter a valid date');
            // Toast.show({
            //   type: 'error',
            //   text1: 'Invalid Date',
            //   text2: 'Please enter a valid date',
            // });
            return;
          }

          if (Number(day) > 28 && !isLeapYear) {
            setFieldError('day', 'Invalid Date, Please enter a valid date');
            // Toast.show({
            //   type: 'error',
            //   text1: 'Invalid Date',
            //   text2: 'Please enter a valid date',
            // });
            return;
          }
        }

        const date = dayjs(new Date(year, month - 1, day));
        console.log(date.format('YYYY-MM-DD'));

        if (!values?.firstName) {
          // openModal('WarningModal', {
          //   title: 'Failed to add student',
          //   description: 'FirstName is required',
          // });
          setFieldError('firstName', 'FirstName is required');
          return;
        }
        if (!values?.city?.label) {
          // openModal('WarningModal', {
          //   title: 'Failed to add student',
          //   description: 'City is required',
          // });
          setFieldError('city', 'City is required');
          return;
        }
        if (!values?.grade?.label) {
          // openModal('WarningModal', {
          //   title: 'Failed to add student',
          //   description: 'Grade is required',
          // });
          setFieldError('grade', 'Grade is required');
          return;
        }
        if (!values?.username) {
          // openModal('WarningModal', {
          //   title: 'Failed to add student',
          //   description: 'Username is required',
          // });
          setFieldError('username', 'Username is required');
          return;
        }
        if (!values?.school?.value) {
          // openModal('WarningModal', {
          //   title: 'Failed to add student',
          //   description: 'School is required',
          // });
          setFieldError('school', 'School is required');
          return;
        }
        if (!values?.state?.value) {
          // openModal('WarningModal', {
          //   title: 'Failed to add student',
          //   description: 'School is required',
          // });
          setFieldError('state', 'School is required');
          return;
        }
        if (!values?.city?.value) {
          // openModal('WarningModal', {
          //   title: 'Failed to add student',
          //   description: 'School is required',
          // });
          setFieldError('city', 'School is required');
          return;
        }

        const body = {
          type: 'student',
          name: values.firstName,
          lastname: values.lastName,
          username: values.username,
          dateOfBirth: date,
          gradeId: values.grade.value,
          schoolId: values.school?.value,
          cityId: values.city.value,
          parentId: [data?.data?.id],
        };
        const response: any = dispatch(addStudent(body)).unwrap();

        closeModal();
      } catch (error) {
        if (error) {
          console.log(error);
        }
      }
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    // validate(values) {
    //   const errors = {
    //     firstName: '',
    //     lastName: '',
    //     username: '',
    //     grade: '',
    //     state: '',
    //     city: '',
    //     school: '',
    //     dob: '',
    //   };
    //   if (!values.firstName) {
    //     errors.firstName = 'FirstName is required';
    //   }
    //   if (!values.lastName) {
    //     errors.state = 'LastName is required';
    //   }
    //   if (!values.state?.value) {
    //     errors.state = 'State is required';
    //   }
    //   if (!values.city?.value) {
    //     errors.city = 'City is required';
    //   }
    //   if (!values.school?.value) {
    //     errors.school = 'School is required';
    //   }
    //   if (!values.username) {
    //     errors.username = 'Username is required';
    //   }
    //   if (!containsOnlyAllowedCharacters(values.username)) {
    //     errors.username =
    //       'Username can only contain letters, numbers, spaces, hyphens, and underscores';
    //   }

    //   if (!values.grade?.value) {
    //     errors.grade = 'Grade is required';
    //   }
    //   if (!values.dob) {
    //     errors.grade = 'DOB is required';
    //   }

    //   return errors;
    // },
  });
  React.useEffect(() => {
    if (formik?.values?.state?.value === '') return;
    dispatch(getCities(formik?.values?.state?.value));
  }, [formik?.values?.state]);

  React.useEffect(() => {
    if (formik?.values?.city?.value === '') return;
    dispatch(getSchoolByCity({ id: formik?.values?.city?.value }));
  }, [formik.values?.city?.value]);
  const selectRef = React.useRef<HTMLDivElement | null>(null);
  const gradeListData = React.useMemo(() => {
    if (!gradeList) return [];

    return gradeList?.map((grade: any) => ({
      value: grade.id,
      label: grade.name,
    }));
  }, [gradeList]);
  const allStates = React.useMemo(() => {
    if (!stateList) return []; 

    return stateList?.map((state: any) => ({
      value: state.id,
      label: state.name,
    }));
  }, [stateList]);
  const allCities = React.useMemo(() => {
    if (!cityList) return [];

    return cityList?.map((city: any) => ({
      value: city.id,
      label: city.name,
    }));
  }, [cityList]);

  const allSchools = React.useMemo(() => {
    if (!schoolByCity) return [];

    return schoolByCity?.map((school: any) => ({
      value: school.id,
      label: school.name,
    }));
  }, [schoolByCity]);
  const [dropdownOpened, setDropdownOpened] = React.useState(false);
  return (
    <div className="w-[100%]">
      <form
        onSubmit={formik.handleSubmit}
        className="grid gap-4 content-center w-[100%]"
      >
        <div
          className={
            type == 'student'
              ? ' grid  grid-cols-1 lg:grid-cols-2 space-x-2 space-y-3'
              : ' grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 space-x-2 space-y-3'
          }
        >
          <TextFieldCustomized
            label={'First Name'}
            placeholder={'Enter first name'}
    error={formik.touched.firstName && formik.errors.firstName}
            {...formik.getFieldProps('firstName')}
          />

          <TextFieldCustomized
            label={'Last Name'}
            placeholder={'Enter last name'}
     error={formik.touched.lastName && formik.errors.lastName}
            {...formik.getFieldProps('lastName')}
          />
          <div>
            <div className="block !text-[14px] !text-[#484848] font-semibold">
              Date of birth
            </div>
            <div className="grid grid-cols-3 space-x-1">
              <TextFieldCustomized
                label={''}
                placeholder={'Day'}
           error={formik.touched.day && formik.errors.day}
                {...formik.getFieldProps('day')}
              />
              <TextFieldCustomized
                label={''}
                placeholder={'Month'}
               error={formik.touched.month && formik.errors.month}
                {...formik.getFieldProps('month')}
              />
              <TextFieldCustomized
                label={''}
                placeholder={'Year'}
              error={formik.touched.year && formik.errors.year}
                {...formik.getFieldProps('year')}
              />
            </div>
          </div>
          {gradeListData?.length && (
            <CustomizedSelectComponent
            ref={selectRef} 
            dropdownOpened={dropdownOpened}
            onDropdownOpen={() => setDropdownOpened(true)}
            onDropdownClose={() => setDropdownOpened(false)}
              gradeLoading={screenLoading}
              placeholder={'Select Grade'}
              onkeydown={(event:React.KeyboardEvent<HTMLDivElement>) => {
                if (event.key === "Enter") {
                  setDropdownOpened(false);
                  event.preventDefault();
                  event.stopPropagation();
      console.log("incondition")
                  // Close dropdown manually
                  setDropdownOpened(false);
                  nextInputRef.current?.focus()
                }
              }}
              error={
                formik.touched.grade?.value &&
                formik.errors.grade?.value &&
                'Grade is required'
              }
              heading="Grade/Subject"
              value={formik.values?.grade?.value}
              onChange={(value: any) => {
                console.log('value', value);
                const option = gradeListData?.find(
                  (grade: any) => grade.value === value,
                );
                formik.setFieldValue('grade', option);
              }}
              data={gradeListData}
            />
          )}

          <TextFieldCustomized
          ref={nextInputRef}
            label={'Email Address *optional'}
            placeholder={'Email address'}
           error={formik.touched.email && formik.errors.email}
            {...formik.getFieldProps('email')}
          />
          <TextFieldCustomized
            label={'Unique Username'}
            placeholder={'Unique Username'}
            error={formik.touched.username && formik.errors.username}
            {...formik.getFieldProps('username')}
          />
          {allStates?.length > 0 && (
            <CustomizedSelectComponent
              gradeLoading={screenLoading}
              heading="State"
              placeholder={'Select state'}
           error={formik.touched.state?.value && formik.errors.state?.value && 'State is required'}
              value={formik.values?.state?.value}
              data={allStates}
              onChange={(value: any) => {
                const option = allStates.find(
                  (state: any) => state.value === value,
                );
                console.log('option', option);
                formik.setFieldValue('state', option);
              }}
            />
          )}
          {allCities?.length > 0 && (
            <CustomizedSelectComponent
              gradeLoading={screenLoading}
              heading="City"
              placeholder={'Select city'}
             error={formik.touched.city?.value && formik.errors.city?.value && 'City is required'}
              value={formik.values?.city?.value}
              data={allCities}
              onChange={(value: any,) => {

                const option = allCities.find(
                  (state: any) => state.value === value,
                );
                console.log('city', option);
                formik.setFieldValue('city', option);
              }}
            />
          )}
        </div>
        <div className="flex px-1">
          {allSchools?.length > 0 && (
            <CustomizedSelectComponent
              fullWidth={true}
              gradeLoading={screenLoading}
              heading="Select School"
              placeholder={'Select city'}
            error={formik.touched.school?.value && formik.errors.school?.value && 'School is required'}
              value={formik.values?.school?.value}
              data={allSchools}
              onChange={(value: any) => {
                const option = allSchools.find(
                  (state: any) => state.value === value,
                );
                console.log('value', value);
                formik.setFieldValue('school', option);
              }}
            />
          )}
        </div>
        <ButtonMain
       // disabled={}
          mb={15}
          loader={screenLoading}
          text={'Add Student'}
          font={'700'}
          color={''}
          type={'submit'}
        />
      </form>
    </div>
  );
}

export default AddStudentComponent;
