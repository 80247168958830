// ** Icon imports
import { VerticalNavItemsType } from '@core/layouts/types';
import HomeIcon from 'icons/homeIcon';
import JoinLiveTutoringIcon from 'icons/joinLiveTutoring';
import DailyChallengeIcon from 'icons/dailyChallenge';
import PlayToEarnIcon from 'icons/playToEarn';
import ShareToEarnIcon from 'icons/shareToEarn';
import ShopWithGMSIcon from 'icons/shopWithGMS';

//admin
import SchoolManagement from 'icons/schoolManagement';
import SettingsIcon from 'icons/settings';
import DistrictManagement from 'icons/districtManagement';
import UserManagement from 'icons/userManagement';
import UserRole from 'icons/userRole';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import ContentManagementIcon from 'icons/contentManagement';
import { route_info } from 'navigation/route';
import { Roles } from '../../constants/consts';
import { getUser } from 'helper/functions';
import SchoolManagementNew from 'icons/schoolManagementNew';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/store';
import { getNavItems } from 'store/Thunk/navThunk';
import { useNavigate } from 'react-router-dom';
import { NotificationBing } from 'iconsax-react';
import { useGetMyProfileQuery } from 'store/apis/profile';

// ** Type import

const Navigation = (admin: any, schoolId = '90'): VerticalNavItemsType => {
  const teacherSchool: any = localStorage.getItem('teacherSchool');
  const user = getUser();
  const role = user?.type || '';

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getNavItems(`/api/v1/school/${teacherSchool}/grades-topics`));
  }, []);
  const { navItems } = useAppSelector((state: any) => state.navItemReducer);
  useEffect(() => {
    setNavmenus(navItems);
  }, [navItems]);
  const [navMenus, setNavmenus] = useState([]);
 const {data}= useGetMyProfileQuery()
console.log("Data^^^",data?.data?.school?.ncesDistrictId)
const ncesDistrictId:any= data?.data?.school?.ncesDistrictId
  const convertedData = navMenus?.map((item: any) => {
    const gradeTitle = item?.grade.name;
    const gradeId = item?.grade.id;
    const gradePath = route_info.content_management.daily_challenge_school;

    const topicChildren = item.topic.map((topic: any) => {
      const topicTitle = topic.name;
      const topicId = topic.id;
      const topicPath = topic.name;

      return {
        title: topicTitle,
        topicId: topicId,
        path: `${route_info?.school_management.school_manage.replace(
          ':id',
          teacherSchool,
        )}/${gradeId}/${topicId}`,
      };
    });

    return {
      title: gradeTitle,
      path: `?topic=${gradeId}`,
      gradeId: gradeId,
      children: topicChildren,
    };
  });

  const navigate: any = useNavigate();

  const routeInfo = {
    content_management: {
      video_school: '/video-school',
      quiz_template_school: '/quiz-template-school',
      daily_challenge_school: '/daily-challenge-school',
      integrations_school: '/integrations-school',
    },
    school_management: {
      school_manage: '/school-manage/:id',
    },
  };
  const children = navMenus?.map((item: any) => {
    const gradeTitle = `Grade ${item.grade.name}`;
    const gradePath = routeInfo.content_management.quiz_template_school;

    const topics = item?.topic?.map((topic: any) => {
      const title = topic.name.split(', ')[0];
      const path = routeInfo.content_management.daily_challenge_school;

      return {
        title,
        path,
      };
    });

    return {
      title: gradeTitle,
      path: gradePath,
      children: topics,
    };
  });

  const finalStructure = [
    {
      title: 'All grades',
      path: routeInfo.content_management.video_school,
      children: children,
    },
  ];

  // console.log('finalStructure', finalStructure);

  if (role === Roles.ADMIN) {
    return [
      {
        title: 'Dashboard',
        icon: HomeIcon,
        path: '/super-admin/dashboard',
      },
      {
        title: 'G3MS Learning Hub',
       icon: HomeIcon,
        path: '/admin/gemslearninghub',
      },
      {
        title: 'Video Content Management',
        icon: ContentManagementIcon,
        children: [
          {
            title: 'Academic Videos',
            path: route_info.content_management.video,
          },
          {
            title: 'Social Fun Videos',
            path: route_info.content_management.video_fun,
          },
        
          
        ],
      },
      {
        title: 'Assessments & Questions',
        icon: SchoolManagement,
        path: route_info.content_management.quiz_template,
      },
      {
        title: 'Daily Challenges',
        icon: SchoolManagement,
        path: route_info.content_management.daily_challenge,
      },
      {
        title: 'District Management',
        icon: SchoolManagement,
        path: route_info.district_management,
      },
      {
        title: 'Assignment Management',
        icon: SchoolManagementNew,
        path: "/"
      },
      {
        title: 'User Management',
        icon: UserManagement,
        path: route_info.user_management,
      },
      // {
      //   title: 'Notifications',
      //   icon: NotificationBing,
      //   path: route_info.notification_management.create,
      // },
      // {
      //   title: 'User Role',
      //   icon: UserRole,
      //   path: route_info.user_role,
      // },
      // {
      //   title: 'User Session',
      //   icon: ManageHistoryIcon,
      //   path: route_info.user_session,
      // },
      // {
      //   title: 'Settings',
      //   icon: SettingsIcon,
      //   path: route_info.user_settings,
      // },
    ];
  } else if (role === Roles.SCHOOL_LEADER || role === Roles.LEARNING_COACH) {
    return [
      {
        title: 'Home Dashboard',
       icon: HomeIcon,
        path: '/school-leader/dashboard',
      },
      {
        title: 'G3MS Learning Hub',
       icon: HomeIcon,
        path: '/admin/gemslearninghub',
      },
      {
        title: 'Video Content Management',
        icon: ContentManagementIcon,
        children: [
          {
            title: 'Academic Videos',
            path: route_info.content_management.video_school,
          },
          {
            title: 'Social Emotional Learning Videos',
            path: route_info.content_management.video_school_fun,
          },
          // {
          //   title: 'Assessments & Questions',
          //   path: route_info.content_management.quiz_template_school,
          // },
          // {
          //   title: 'Daily Challenges',
          //   path: route_info.content_management.daily_challenge_school,
          // },
        ],
      },
      {
        title: 'Assessment Content Management',
       icon: HomeIcon,
       path: route_info.content_management.quiz_template_school,
      },
      {
        title: 'School Management',
        icon: SchoolManagementNew,
        path: route_info?.school_management.school_manage.replace(
          ':id',
          teacherSchool,
        ),
      },
      {
        title: 'Assignment Management',
        icon: SchoolManagementNew,
        path: "/"
      },
      // {
      //   title: 'Classroom Management',
      //   icon: SchoolManagementNew,
      //   path: route_info?.school_management.school_manage.replace(
      //     ':id',
      //     teacherSchool,
      //   ),
      //   children: [
      //     {
      //       title: 'All Classes',
      //       icon: SchoolManagementNew,
      //       path: route_info?.school_management.school_manage.replace(
      //         ':id',
      //         teacherSchool,
      //       ),
      //     },
      //     {
      //       title: 'All grades',

      //       path: route_info.content_management.video_school,
      //       children: convertedData,
      //     },
      //   ],
      // },
      {
        title: 'User Management',
        icon: UserManagement,
        path: route_info.user_manage,
      },
    ];
  } else if (role === Roles.PARENT) {
    return [
      {
        title: 'Dashboard',
        icon: HomeIcon,
        path: '/parent/dashboard',
      },
      // {
      //   title: 'Reporting & Insighs',
      //   icon: ContentManagementIcon,
      //   path: '/parent/insight',
      // },
    ];
  } else if (
    role === Roles.TEACHER ||
    role === Roles.TUTOR ||
    role === Roles.HOME_SCHOOL_PARENT
  ) {
    return [
      {
        title: 'Dashboard',
        icon: HomeIcon,
        path: '/teacher/dashboard',
      },
      {
        title: 'G3MS Learning Hub',
       icon: HomeIcon,
        path: '/admin/gemslearninghub',
      },
      {
        title: 'Classroom Management',
        icon: SchoolManagement,
        path: route_info?.school_management.school_manage.replace(
          ':id',
          teacherSchool,
        ),
       
      },
      {
        title: 'Assignment Management',
        icon: SchoolManagementNew,
        path: "/"
      },
    ];
  } else if(role === Roles.DISTRICT_LEADER || role === Roles.DIRECTOR_TECHNOLOGY || role === Roles.TUTOR_COMPANY || role === Roles.OTHER)
    return[
      {
        title: 'Home Dashboard',
        icon: HomeIcon,
        path: '/district-leader/dashboard',
      },
      {
        title: 'G3MS Learning Hub',
       icon: HomeIcon,
        path: '/admin/gemslearninghub',
      },
      {
        title: 'Video Content Management',
        icon: ContentManagementIcon,
        children: [
          {
            title: 'Academic Videos',
            path: route_info.content_management.video_school,
          },
          {
            title: 'Social Emotional Learning Videos',
            path: route_info.content_management.video_school_fun,
          },
          // {
          //   title: 'Assessments & Questions',
          //   path: route_info.content_management.quiz_template_school,
          // },
          // {
          //   title: 'Daily Challenges',
          //   path: route_info.content_management.daily_challenge_school,
          // },
        ],
      },
      {
        title: 'Assessment Content Management',
       icon: HomeIcon,
       path: route_info.content_management.quiz_template_school,
      },
      {
        title:"District Management",
        icon:HomeIcon,
        path:route_info?.district_management_school.replace(
          ':id',
          ncesDistrictId
        ),
      },
      {
        title:"Assignment Management",
        icon:HomeIcon,
        path:"/"
      },
      {
        title: 'User Management',
        icon: UserManagement,
        path: route_info.user_manage,
      },
    ]
  
  
  return [];
};

export default Navigation;
