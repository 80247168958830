// *** React Imports
// @ts-nocheck

import React, { useEffect, useState } from 'react';

// *** Material UI Imports
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputLabel,
  Popover,
  TextField
} from '@mui/material';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import MDDialog from '@core/components/MDDialog';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { DateRangePicker as DateSelectionRange } from 'react-date-range';

/* mui-color-input' */
import Validations from 'helper/validations';
import { useAppDispatch, useAppSelector } from 'store/store';

import AddSkill from 'components/dialog/skill/addSkill';
import AddSubTopic from 'components/dialog/subtopic/addSubTopic';

//calendar
import useSchoolClassroomInfo from 'hooks/useSchoolClassroomInfo';
import moment from 'moment';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useNavigate, useParams } from 'react-router-dom';
import { getGroupListByClassId } from 'store/Thunk/groupThunk';
import { getStudentListByClassroom } from 'store/Thunk/studentThunk';


const AssigToList = [
  { title: 'Group' },
  { title: 'Individual' },
  { title: 'Whole class' },
];

interface FormattedInfo {
  assignmentType: string;
  assignTo: string | undefined;
  assignClassroomId: string | undefined;
  userId: string;
  groupId: string;
  startDate: string;
  endDate: string;
  toName?: string;
}

function Index({ open, onClose, editContentInfo }: any) {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch: any = useAppDispatch();

  const { groupListByClassId } = useAppSelector((state) => state.groupReducer);
  const { studentListByClassroom } = useAppSelector(
    (state) => state.studentReducer,
  );

  const [formData, setFormData] = useState<any>({
    assignToType: '',
  });
  const [formError, setFormError] = useState<any>({});
  const [screenLoader, setScreenLoader] = React.useState(false);
  const [openAssignToType, setOpenAssignToType] = React.useState(false);
  const [openSubTopicDialog, setOpenSubTopicDialog] = React.useState(false);
  const [newSubTopicValue, setNewSubTopicValue] = React.useState({});
  const [openSkillDialog, setOpenSkillDialog] = React.useState(false);
  const [newSkillValue, setNewSkillValue] = React.useState({});
  const [loadingGrade, setLoadingGrade] = React.useState(false);
  const [openAssignTo, setOpenAssignTo] = React.useState(false);
  const [calendar, setCalendar] = React.useState<boolean>(false);
  const { schoolId, classroomId } = useSchoolClassroomInfo();
  const [dateRange, setDateRange] = useState<any>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);


  useEffect(() => {
    if (editContentInfo) {
      setFormData({
        assignTo: {
          title: 'Individual'
        },
        type: 'homeWork',
        assignToType: studentListByClassroom?.items?.find((student:any) => student?.user?.id === editContentInfo.userId)
      })
      setDateRange([{
        startDate: new Date(moment(editContentInfo?.startDate, "M/D/YYYY").format("M-DD-YYYY")),
        endDate: new Date(moment(editContentInfo?.endDate, "M/D/YYYY").format("M-DD-YYYY")),
        key: 'selection',
      }])
    }
  }, [editContentInfo])




  useEffect(() => {
    if (formData?.assignTo?.title === 'Group') {
      dispatch(getGroupListByClassId({ id }));
    }
  }, [formData?.assignTo]);

  useEffect(() => {
    if (formData?.assignTo?.title === 'Individual') {
      dispatch(getStudentListByClassroom({ id }));
    }
  }, [formData?.assignTo]);



  const [deadline, setDeadline] = useState<any>(null);


  useEffect(() => {
    setDeadline(
      `${moment(dateRange[0]?.startDate).format('DD-MM-YYYY')} - ${moment(
        dateRange[0]?.endDate,
      ).format('DD-MM-YYYY')}`,
    );
  }, [dateRange]);

  const handleChangeInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleChangeAutocomplete =
    (name: string) =>
      (
        event: React.SyntheticEvent<Element, Event>,
        newValue: string | null | any,
      ) => {
        let param = {
          [name]: newValue,
        };
        setFormData({ ...formData, ...param });
      };

  const handleSkillDialog = (name: string = '') => {
    setNewSkillValue({
      ...(!openSkillDialog
        ? {
          topic: formData.topic,
          name: name,
          subTopic: formData.sub_topic,
        }
        : {}),
    });
    setOpenSkillDialog(!openSkillDialog);
  };

  const handleAssigmentType = (event: any) => {
    setFormData({
      ...formData,
      type: event.target.value,
    });
  };

  const handleSubTopicDialog = (name: string = '') => {
    setNewSubTopicValue({
      ...(!openSubTopicDialog
        ? {
          topic: formData.topic,
          name: name,
        }
        : {}),
    });
    setOpenSubTopicDialog(!openSubTopicDialog);
  };

  const onSave = (event: any) => {
    event.preventDefault();

    const info = { ...formData, deadline };
    let allError = Validations.ValidationsForAssignContent(info);

    if (
      Object.entries(allError).length === 0 &&
      allError.constructor === Object
    ) {
      const formattedInfo: FormattedInfo = {
        assignmentType: formData.type,
        assignTo: formData.assignTo?.title,
        assignClassroomId: id,
        userId:
          formData.assignTo?.title === 'Individual'
            ? formData.assignToType?.userId
            : '',
        groupId:
          formData.assignTo?.title === 'Group' ? formData.assignToType?.id : '',
        startDate: moment(dateRange[0]?.startDate).format(
          'YYYY-MM-DDTHH:mm:ss',
        ),
        endDate: moment(dateRange[0]?.endDate).format('YYYY-MM-DDTHH:mm:ss'),
        toName:
          formData?.assignToType?.user?.name ||
          formData?.assignToType?.name ||
          'Whole Class',
      };

      localStorage.setItem('selectiveInfo', JSON.stringify(formattedInfo));
      if (classroomId && schoolId) {
        localStorage.setItem('current_classroomId', classroomId);
        localStorage.setItem('current_schoolId', schoolId);
      }


      if (editContentInfo) {
        navigate(
          `/assign-content/${editContentInfo.assignmentId}?content=${editContentInfo.contentTypeId}&contentType=${editContentInfo.type}`,
        );

      }
      else {
        navigate('/assign-content');
      }


      onClose();
    } else {
      setFormError(allError);
    }
  };

  const dialogProps = {
    open,
    onClose,
    onSave,
    title: 'Assign Content',
    size: 'xs',
    saveTbtText: 'Next',
    closeBtnText: 'Cancel',
    screenLoader,
  };

  return (
    <MDDialog {...dialogProps}>
      <Box sx={{ p: 3 }}>
        <Grid container>
          <Grid item md={12} sm={12} xs={12}>
            <FormControl>
              <FormLabel
                error={formError?.type}
                id="demo-row-radio-buttons-group-label"
              >
                Assignment Type
              </FormLabel>
              <RadioGroup
                row
                defaultValue={formData?.type}
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="type"
                onChange={(e) => {
                  handleAssigmentType(e);
                }}
              >
                <FormControlLabel
                  value="homeWork"
                  control={<Radio />}
                  label="Homework"
                />
                <FormControlLabel
                  value="classWork"
                  control={<Radio />}
                  label="Classwork"
                />
                <FormControlLabel
                  value="both"
                  control={<Radio />}
                  label="Both"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid container spacing={2}>
            <Grid
              item
              md={
                formData?.assignTo &&
                  formData?.assignTo?.title !== 'Whole class'
                  ? 4
                  : 12
              }
              sm={12}
              xs={12}
            >
              <>
                <InputLabel
                  error={formError && formError?.assignTo}
                  sx={{ color: '#484848', pb: 2 }}
                >
                  Assign to
                </InputLabel>
                <Autocomplete
                  id="assignTo"
                  open={openAssignTo}
                  onOpen={() => setOpenAssignTo(true)}
                  onClose={() => setOpenAssignTo(false)}
                  isOptionEqualToValue={(option, value) =>
                    option.title === value.title
                  }
                  getOptionLabel={(option) => option?.title || ''}
                  options={AssigToList}
                  filterSelectedOptions
                  disabled={editContentInfo && true}
                  value={
                    formData?.assignTo?.length > 0
                      ? formData?.assignTo[0]
                      : formData?.assignTo || {}
                  }
                  onChange={handleChangeAutocomplete('assignTo')}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select type"
                      error={formError && formError?.assignTo}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loadingGrade ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </>
            </Grid>
            <Grid item md={formData?.assignTo ? 8 : 12} sm={12} xs={12}>
              {formData?.assignTo !== null &&
                formData?.assignTo?.title !== 'Whole class' &&
                formData?.assignTo ? (
                <>
                  <InputLabel
                    error={formError && formError?.assignToType}
                    sx={{ color: '#484848', pb: 2 }}
                  >
                    Assign to
                  </InputLabel>
                  <Autocomplete
                    id="assignTo"
                    open={openAssignToType}
                    onOpen={() => setOpenAssignToType(true)}
                    onClose={() => setOpenAssignToType(false)}
                    disabled={editContentInfo && true}
                    isOptionEqualToValue={(option, value) =>
                      option.name === value.name
                    }
                    getOptionLabel={(option) =>
                      option?.name || option?.user?.name || ''
                    }
                    options={
                      (formData?.assignTo?.title === 'Group'
                        ? groupListByClassId?.items
                        : studentListByClassroom?.items) || []
                    }
                    // filterSelectedOptions
                    value={
                      formData?.assignToType?.length > 0
                        ? formData?.assignToType[0]
                        : formData?.assignToType || {}
                    }
                    onChange={handleChangeAutocomplete('assignToType')}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select type"
                        error={formError && formError?.assignToType}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loadingGrade ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </>
              ) : null}
            </Grid>
          </Grid>
          <Grid item md={12} sm={12} xs={12} sx={{ display: 'flex', mt: 3 }}>
            <FormControl fullWidth>
              <TextField value={deadline} variant="outlined" />
              <Popover
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'center',
                }}
                // onClose={handleClose}
                open={calendar}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <DateSelectionRange
                    editableDateInputs={true}
                    onChange={(item: any) => {
                      setDateRange([item.selection]);
                    }}
                    moveRangeOnFirstSelection={false}
                    ranges={dateRange}
                    rangeColors={['#C73E9D']}
                  />
                  <Box>
                    <Button onClick={() => setCalendar(false)}>Cancel</Button>
                    <Button onClick={() => setCalendar(false)}>Save</Button>
                  </Box>
                </Box>
              </Popover>
            </FormControl>
            <IconButton
              onClick={() => {
                setCalendar(true);
              }}
            >
              <CalendarTodayIcon />
            </IconButton>
          </Grid>
        </Grid>
        {openSkillDialog && (
          <AddSkill
            open={openSkillDialog}
            onClose={handleSkillDialog}
            skill={newSkillValue}
          />
        )}
        {openSubTopicDialog && (
          <AddSubTopic
            open={openSubTopicDialog}
            onClose={handleSubTopicDialog}
            subTopic={newSubTopicValue}
          />
        )}
      </Box>
    </MDDialog>
  );
}

export default Index;
