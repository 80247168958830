import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Header from './Header';
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Line,
  Label,
  Legend,
} from 'recharts';
import { ReactI18NextChild } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
// const data1 = [
//   { title: 'Exceeding', averageMarks: 91.5 },
//   { title: 'Meeting', averageMarks: 71.54 },
//   { title: 'Approaching', averageMarks: 50 },
//   { title: 'Emerging', averageMarks: 30 },
// ];
const data1 = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 },
  { name: 'Group D', value: 200 },
];
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
const style = {
top: '40%',
  right: 0,
  left:"60%",
  transform: 'translate(-10%, -50%)',
  lineHeight: '16px',
  color: 'black',
  fontSize: '11px',
};

const renderLegend = (props: any) => {
  const { payload } = props;

  return (
    <ul>
      {payload.map((entry: { value: any }, index: any) => (
        <li key={`item-${index}`}>{entry.value}</li>
      ))}
    </ul>
  );
};
const renderColorfulLegendText = (value: string, entry: any) => {
  const { color } = { color: 'black' };

  return <span style={{ color, width:"20%" }}>{value}</span>;
};


// function CustomLabel(value1: any, value2: any) {
//   return (
//     <text
//       x="40%"
//       y="40%"
//       fill="#3d405c"
//       className="recharts-text recharts-label"
//       textAnchor="middle"
//       dominantBaseline="central"
//     >
//       <tspan alignmentBaseline="middle" fontSize="26">
//         {value1}
//       </tspan>
//       <tspan fontSize="14">{value2}</tspan>
//     </text>
//   );
// }


const ITEM_HEIGHT = 48;
function ProficiencyLevels({dataKey, options}:{dataKey?:any; options?:any}) {
  const [selectedValue, setSelectedValue] = React.useState(options?.[0]);
  const [avg, setAvg] = React.useState(0);
    const [finalData, setFinaldata] = useState<any>([]);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [isData, setIsData]= useState(false)
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = (option: any) => {
      setSelectedValue(option);
      setAnchorEl(null);
    };
  console.log("dataKey", dataKey)
 const[data, setData]= useState<any>([])
  useEffect(()=>{
setData(dataKey)
  },[dataKey])
  useEffect(()=>{
  selectTopiic()
  },[data])

const selectTopiic = () => {
  
   if ( data && data?.length>0){
    let obj = [
      { title: 'Exceeding', averageMarks: Math.round(data[0]?.exceedings) || 0 },
      { title: 'Meeting', averageMarks:  Math.round(data[0]?.meetings) || 0  },
      { title: 'Approaching', averageMarks:  Math.round(data[0]?.approchings) || 0  },
      { title: 'Emerging', averageMarks:  Math.round(data[0]?.emergings) || 0 },
    ];
    let avrg=(Math.round(data[0]?.exceedings || 0 )+  Math.round(data[0]?.meetings) || 0 +Math.round(data[0]?.approchings) || 0 +Math.round(data[0]?.emergings) || 0 )/4
    setFinaldata(obj)
    setAvg(avrg)
   }
   

   }
  
 

 
  return (
   
    <Box className="border border-[#EAECF0] rounded-lg p-2 mx-1">
       <div className="flex items-center justify-between">
        <div >
        <Header text="Proficiency Levels " />
          
        </div>
        
        {/* {options?.length > 0 ? (
          <div>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <img
                alt=""
                src={require('../../../../assets/Parent/dots-vertical.png')}
                className="h-4 w-4"
              />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              slotProps={{
                paper: {
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: '30ch',
                  },
                },
              }}
            >
              {options?.map((option: any) => (
                <MenuItem
                  key={option}
                  selected={option === 'Engagement by Topics'}
                  onClick={() => handleClose(option)}
                >
                  {option}
                </MenuItem>
              ))}
            </Menu>
          </div>
        ) : null} */}
      </div>
    {avg===0 ?
     <div className='text-sm font-medium text-center mt-3'>No data yet</div>:
     <div className=" flex items-center justify-center mt-2 mb-2">

     <PieChart width={300} 
     height={130}
     >
       <Pie
         data={finalData}
         dataKey="averageMarks"
         nameKey="title"
         innerRadius={40}
         outerRadius={60}
         cx="40%"
         cy="50%"
         fill="#8884d8"
         paddingAngle={-15}
         blendStroke={true}
       >
          {finalData?.map((entry:any, index:any) => (
         <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
       ))}

         <Label
           value={`${Math.round(avg)}%`}
           fontSize={'14px'}
           fontWeight={500}
           color="#000000 !important"
           position="center"
           className="label"
         />
         
       </Pie>
       <Legend
         verticalAlign="middle"
         iconType="circle"
         align="right"
         layout={'vertical'}
         height={12}
         iconSize={10}
         wrapperStyle={style}
         formatter={renderColorfulLegendText}
       />
       <Line
         type="monotone"
         dataKey="averageMarks"
         stroke="#8884d8"
         activeDot={{ r: 8 }}
       />
       <Line type="monotone" dataKey="averageMarks" stroke="#8884d8" />
       <Line type="monotone" dataKey="averageMarks" stroke="#8884d8" />
       <Line type="monotone" dataKey="averageMarks" stroke="#8884d8" />
     </PieChart>
   </div>
  
    }
     
    </Box>
  );
}

export default ProficiencyLevels;
