import { useFormik } from 'formik';

import { Button, LoadingOverlay, Modal, rem, Title } from '@mantine/core';
import useModal from 'components/Modal/hook/useModal';
import cn from 'utils/cn';

import NumberInputField from 'components/NumberInputField';
import { useUpdateParentSchoolSettingsMutation } from 'store/apis/onboarding';
import { useGetMyProfileQuery } from 'store/apis/profile';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';

const nonNegativeStringNumber = z
  .string({ message: 'Required' })
  .refine((val) => !isNaN(Number(val)) && Number(val) >= 0, {
    message: 'Must be a non-negative number',
  });

const validationSchema = z.object({
  meeting: z.object({
    start: nonNegativeStringNumber,
    end: nonNegativeStringNumber,
  }),
  approaching: z.object({
    start: nonNegativeStringNumber,
    end: nonNegativeStringNumber,
  }),
  emerging: z.object({
    start: nonNegativeStringNumber,
    end: nonNegativeStringNumber,
  }),
});

export type ParentSchoolSettingsModalProps = z.infer<typeof validationSchema>;

export default function ParentSchoolSettingsModal() {
  const { closeModal, isOpen, modal, openModal } = useModal<{
    onContinue?: () => void;
    isFirstStudent?: boolean;
    withUpgradeSuccess?: boolean;
    withAddons?: boolean;
    educatorPlan?: 'free' | 'premium';
  }>();

  const [updateSchoolSettings, updateSchoolSettingsResponse] =
    useUpdateParentSchoolSettingsMutation();

  const { data: userData, isLoading } = useGetMyProfileQuery();

  const formik = useFormik<ParentSchoolSettingsModalProps>({
    initialValues: {
      meeting: { start: '100', end: '' },
      approaching: { start: '', end: '' },
      emerging: { start: '', end: '0' },
    },
    validationSchema: toFormikValidationSchema(validationSchema),
    onSubmit: async (values, { setFieldError }) => {
      try {
        await updateSchoolSettings({
          proficienyRangeStart: Number(values.meeting.start || '0'),
          proficienyRangeEnd: Number(values.meeting.end || '0'),
          emergingRangeStart: Number(values.approaching.start || '0'),
          emergingRangeEnd: Number(values.approaching.end || '0'),
          notProficientRangeStart: Number(values.emerging.start || '0'),
          notProficientRangeEnd: Number(values.emerging.end || '0'),
          schoolId: userData?.data?.schoolId!,
        }).unwrap();

        const withAddOn = userData?.data?.activeSubscriptions?.some(
          (sub) =>
            sub?.status === 'active' && sub?.product?.name?.includes('Add-On'),
        );

        if (
          !userData?.data?.isUserUpgradedPlan ||
          userData?.data?.type !== 'parent'
        ) {
          openModal('ParentTrial', { cache: true });
          return;
        }

        if (
          (userData?.data.type !== 'parent' &&
            userData?.data?.isUserUpgradedPlan) ||
          withAddOn
        ) {
          if (!userData?.data?.accountSetup?.isAcademicContentsSelected) {
            openModal('ParentSelectAcademic', {
              ...formik.values,
              withUpgradeSuccess: modal?.props?.withUpgradeSuccess,
              withAddons: withAddOn,
              cache: true,
            });
            return;
          }
          if (!userData?.data?.accountSetup?.isSocialContentsSelected) {
            openModal('ParentSelectSocialEmotion', {
              ...formik.values,
              withUpgradeSuccess: modal?.props?.withUpgradeSuccess,
              withAddons: withAddOn,
              cache: true,
            });
            return;
          }

          if (
            userData?.data?.isUserUpgradedPlan &&
            userData?.data?.type === 'parent'
          ) {
            openModal('ParentPremiumPlan', { cache: true });
          }

          if (
            userData?.data?.isUserUpgradedPlan &&
            userData?.data?.type !== 'parent'
          ) {
            openModal('EducatorPremiumPlan', { cache: true });
          }

          openModal('ParentTrial', { cache: true });

          return;
        }

        openModal('AddAnotherStudentWarningModal', {
          ...formik.values,
          cache: true,
        });
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <Modal
      opened={isOpen('ParentSchoolSettingsModal')}
      onClose={closeModal}
      closeOnClickOutside={false}
      closeOnEscape={false}
      size={'2xl'}
      centered
      shadow="sm"
      radius={'lg'}
      classNames={{
        title:
          '!text-lg !font-semibold lg:!text-xl 2xl:!text-2xl !text-[#2C3E50]',
        close:
          '!text-[#2C3E50] !text-lg !font-semibold lg:!text-xl 2xl:!text-2xl',
      }}
      padding={rem(24)}
      zIndex={999999999}
      withCloseButton={false}
    >
      <div className="grid gap-4 lg:gap-6">
        <div className="space-y-2">
          <h1
            className={cn(
              '!font-semibold !text-lg lg:!text-xl 2xl:!text-2xl text-[#131515]',
            )}
          >
            School Settings
          </h1>
        </div>

        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-6">
          <div className="flex flex-col gap-4">
            <Title order={4} className="text-black">
              Provide starting and ending percentages for student proficiency
              levels
            </Title>
            <div className="grid gap-4 lg:gap-6 grid-cols-2">
              <NumberInputField
                label="Meeting:"
                placeholder="Start (%)"
                onChange={(value: string | number) =>
                  formik.setFieldValue('meeting.start', value.toString())
                }
                value={
                  formik.values.meeting.start
                    ? (Number(formik.values.meeting.start) as number | string)
                    : ''
                }
                error={
                  formik.touched.meeting?.start && formik.errors.meeting?.start
                }
                onBlur={() => formik.setFieldTouched('meeting.start', true)}
                readOnly
                rightSection={<div className="text-lg text-[#B0B0B0]">%</div>}
              />
              <NumberInputField
                label=" "
                placeholder="End (%)"
                onChange={(value: string | number) => {
                  formik.setFieldValue('meeting.end', value.toString());
                  formik.setFieldValue(
                    'approaching.start',
                    (typeof value === 'string'
                      ? parseInt(value) - 1
                      : value - 1
                    ).toString(),
                  );
                }}
                value={
                  formik.values.meeting.end
                    ? (Number(formik.values.meeting.end) as number | string)
                    : ''
                }
                error={
                  formik.touched.meeting?.end && formik.errors.meeting?.end
                }
                onBlur={() => formik.setFieldTouched('meeting.end', true)}
                max={99}
                rightSection={<div className="text-lg text-[#B0B0B0]">%</div>}
                min={0}
              />
            </div>
            {formik.values.meeting.end && (
              <div className="grid gap-4 lg:gap-6 grid-cols-2">
                <NumberInputField
                  label="Approaching:"
                  placeholder="Start (%)"
                  onChange={(value: string | number) =>
                    formik.setFieldValue('approaching.start', value.toString())
                  }
                  value={
                    formik.values.approaching.start
                      ? (Number(formik.values.approaching.start) as
                          | number
                          | string)
                      : ''
                  }
                  error={
                    formik.touched.approaching?.start &&
                    formik.errors.approaching?.start
                  }
                  readOnly
                  onBlur={() =>
                    formik.setFieldTouched('approaching.start', true)
                  }
                  rightSection={<div className="text-lg text-[#B0B0B0]">%</div>}
                />
                <NumberInputField
                  label=" "
                  placeholder="End (%)"
                  onChange={(value: string | number) => {
                    formik.setFieldValue('approaching.end', value.toString());
                    formik.setFieldValue(
                      'emerging.start',
                      (typeof value === 'string'
                        ? parseInt(value) - 1
                        : value - 1
                      ).toString(),
                    );
                  }}
                  value={
                    formik.values.approaching.end
                      ? (Number(formik.values.approaching.end) as
                          | number
                          | string)
                      : ''
                  }
                  error={
                    formik.touched.approaching?.end &&
                    formik.errors.approaching?.end
                  }
                  onBlur={() => formik.setFieldTouched('approaching.end', true)}
                  rightSection={<div className="text-lg text-[#B0B0B0]">%</div>}
                  max={
                    formik.values.meeting.end
                      ? Number(formik.values.meeting.end) - 2
                      : 99
                  }
                />
              </div>
            )}
            {formik.values.approaching.end && (
              <div className="grid gap-4 lg:gap-6 grid-cols-2">
                <NumberInputField
                  label="Emerging:"
                  placeholder="Start (%)"
                  onChange={(value: string | number) =>
                    formik.setFieldValue('emerging.start', value.toString())
                  }
                  value={
                    formik.values.emerging.start
                      ? (Number(formik.values.emerging.start) as
                          | number
                          | string)
                      : ''
                  }
                  error={
                    formik.touched.emerging?.start &&
                    formik.errors.emerging?.start
                  }
                  onBlur={() => formik.setFieldTouched('emerging.start', true)}
                  rightSection={<div className="text-lg text-[#B0B0B0]">%</div>}
                  readOnly
                />
                <NumberInputField
                  label=" "
                  placeholder="End (%)"
                  onChange={(value: string | number) =>
                    formik.setFieldValue('emerging.end', value.toString())
                  }
                  value={
                    formik.values.emerging.end
                      ? (Number(formik.values.emerging.end) as number | string)
                      : ''
                  }
                  error={
                    formik.touched.emerging?.end && formik.errors.emerging?.end
                  }
                  onBlur={() => formik.setFieldTouched('emerging.end', true)}
                  rightSection={<div className="text-lg text-[#B0B0B0]">%</div>}
                  readOnly
                />
              </div>
            )}
          </div>

          <div className="w-full max-w-full grid">
            <Button
              className="!rounded-xl col-span-2 !w-[clamp(5rem,25rem,100%)] mx-auto"
              color="#20A58A"
              size="xl"
              fw={500}
              type="submit"
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
      <LoadingOverlay visible={updateSchoolSettingsResponse.isLoading} />
    </Modal>
  );
}
