import { useFormik } from 'formik';

import { Button, LoadingOverlay, Modal, rem } from '@mantine/core';
import useModal from 'components/Modal/hook/useModal';
import cn from 'utils/cn';

import SelectInputField from 'components/SelectInputField';
import TextInputField from 'components/TextInputField';
import React from 'react';
import { useCreateHomeSchoolMutation } from 'store/apis/account-setup';
import { useGetGradeListQuery } from 'store/apis/hometab';
import {
  useGetCitiesQuery,
  useGetDistrictsQuery,
  useGetStatesQuery,
  useGetTopicsQuery,
} from 'store/apis/states-cities';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { useGetMyProfileQuery } from 'store/apis/profile';

const validationSchema = z.object({
  name: z.string().min(2, 'Too Short!'),
  grade: z.string({ message: 'Required' }),
  period: z.string({ message: 'Required' }),
  homeSchoolName: z.string().min(2, 'Too Short!'),
  state: z.string({ message: 'Required' }),
  city: z.string({ message: 'Required' }),
  topic: z.string({ message: 'Required' }),
  district: z.string({ message: 'Required' }).optional().nullable(),
});

export type AddHomeSchoolClassRoomModalProps = z.infer<typeof validationSchema>;

export default function AddHomeSchoolClassRoomModal() {
  const { closeModal, isOpen, modal, openModal } = useModal<{
    onContinue?: () => void;
    isFirstClassRoom?: boolean;
    withUpgradeSuccess?: boolean;
    withAddons?: boolean;
    educatorPlan?: 'free' | 'premium';
  }>();

  const { data: gradeList, isLoading } = useGetGradeListQuery();
  const [createHomeSchoolClassRoom, createHomeSchoolClassRoomResponse] =
    useCreateHomeSchoolMutation();

  const { data, refetch } = useGetMyProfileQuery();

  const formik = useFormik<AddHomeSchoolClassRoomModalProps>({
    initialValues: {
      name: '',
      grade: '',
      period: '',
      homeSchoolName: '',
      state: '',
      city: '',
      topic: '',
      district: '',
    },
    validationSchema: toFormikValidationSchema(validationSchema),
    onSubmit: async (values, { setFieldError }) => {
      try {
        await createHomeSchoolClassRoom({
          schoolName: values.homeSchoolName,
          cityId: values.city,
          classroomGradeId: values.grade,
          classroomName: values.name,
          classroomPeriod: values.period,
          classroomTopicId: values.topic,
          stateId: values.state,
        }).unwrap();
        await refetch();

        /*   if (modal?.props?.withUpgradeSuccess) {
          openModal('ParentSelectAcademic', formik.values);
          return;
        } */

        if (data?.data?.type === 'homeschool_parent_esa' || data?.data?.type === 'parent') {
          if (
            data?.data?.accountSetup?.students &&
            data?.data?.accountSetup?.students?.length <= 0
          ) {
            openModal('AddStudentModal', {
              educatorPlan: data?.data?.isUserUpgradedPlan ? 'premium' : 'free',
              cache: true,
            });
            return;
          }

          if (modal?.props?.withUpgradeSuccess) {
            openModal('ParentSchoolSettingsModal', {
              ...formik.values,
              cache: true,
            });
            return;

            /*   if (!data?.data?.accountSetup?.isAcademicContentsSelected) {
              openModal('ParentSelectAcademic', {
                ...formik.values,
                cache: true,
              });
            }
            if (!data?.data?.accountSetup?.isSocialContentsSelected) {
              openModal('ParentSelectSocialEmotion', {
                ...formik.values,
                cache: true,
              });
            }
            return; */
          }

          openModal('ParentUpgradeToPlanWarning', {
            cache: true,
            educatorPlan: data?.data?.isUserUpgradedPlan ? 'premium' : 'free',
          });
          return;
        }

        openModal('AddAnotherClassRoomWarningModal', {
          ...formik.values,
          withAddons: data?.data?.accountSetup?.isEducatorAddOnIncluded,
          educatorPlan: modal?.props?.educatorPlan,
          cache: true,
        });
      } catch (error) {
        console.log(error);
      }
    },
  });

  console.log(formik.errors);

  const {
    data: statesData,
    isLoading: statesLoading,
    error: statesError,
    isFetching: statesFetching,
  } = useGetStatesQuery({
    search: '',
  });

  const gradedata = React.useMemo(() => {
    if (!gradeList) return [];
    return gradeList.data.map((grade) => ({
      value: grade.id,
      label: grade.name,
    }));
  }, [gradeList]);

  const {
    data: citiesData,
    isLoading: citiesLoading,
    error: citiesError,
    isFetching: citiesFetching,
  } = useGetCitiesQuery(
    {
      search: '',
      stateId: formik.values.state,
    },
    {
      skip: formik.values.state === '' || formik.values.state === undefined,
    },
  );

  const allCities = React.useMemo(() => {
    if (!citiesData) return [];

    return citiesData.data.map((city) => ({
      value: city.id,
      label: city.name,
    }));
  }, [citiesData]);

  const allStates = React.useMemo(() => {
    if (!statesData) return [];

    return statesData.data.map((state) => ({
      value: state.id,
      label: state.name,
    }));
  }, [statesData]);

  /*   const {
    data: schoolsData,
    isLoading: schoolsLoading,
    isFetching: schoolsFetching,
    error: schoolsError,
  } = useGetSchoolsQuery(
    {
      search: '',
      stateId: formik.values.state,
      cityId: formik.values.city,
    },
    {
      skip: formik.values.city === '' || formik.values.city === undefined,
    },
  );

  const allSchools = React.useMemo(() => {
    if (!schoolsData) return [];

    return schoolsData.data?.items?.map((school) => ({
      value: school.id,
      label: school.name,
    }));
  }, [schoolsData]); */

  const { data: districtsData, isLoading: districtsLoading } =
    useGetDistrictsQuery();

  const allDistricts = React.useMemo(() => {
    if (!districtsData) return [];

    return districtsData.data.map((district) => ({
      value: district.id,
      label: district.name,
    }));
  }, [districtsData]);

  const { data: topicsData, isLoading: topicsLoading } = useGetTopicsQuery();

  const allTopics = React.useMemo(() => {
    if (!topicsData) return [];

    const topicsToReturn = topicsData.data.map((topic) => ({
      value: topic.id,
      label: topic.name,
    }));
    if (!data?.data?.isUserUpgradedPlan) {
      return topicsToReturn.filter((topic) => topic.value === 'MATH01');
    }
    return topicsToReturn;
  }, [topicsData, data?.data?.isUserUpgradedPlan]);

  console.log(formik.values.state, 'state');

  return (
    // <Modal isOpen={isOpen('AskAGrownupModal')} onClose={closeModal}>
    //   <Modal.Content className="[--max-width:_45rem] [--top:_2rem]  md:[--top:_10%]">
    //     <Modal.CloseButton />
    <Modal
      opened={isOpen('AddHomeSchoolClassRoomModal')}
      onClose={closeModal}
      closeOnClickOutside={false}
      closeOnEscape={false}
      size={'xl'}
      centered
      shadow="sm"
      radius={'lg'}
      classNames={{
        title:
          '!text-lg !font-semibold lg:!text-xl 2xl:!text-2xl !text-[#2C3E50]',
        close:
          '!text-[#2C3E50] !text-lg !font-semibold lg:!text-xl 2xl:!text-2xl',
      }}
      padding={rem(24)}
      zIndex={999999999}
      withCloseButton={false}
    >
      <div className="grid gap-4 lg:gap-6">
        {modal?.props?.withUpgradeSuccess && (
          <div className="space-y-4 mb-6">
            <h1
              className={cn(
                '!font-semibold text-center !text-lg lg:!text-xl 2xl:!text-2xl text-[#131515]',
              )}
            >
              🎉 Upgrade Successful!
            </h1>
            <p
              className={cn(
                '!text-sm lg:!text-base xl:!text-lg 2xl:!text-xl text-[#131515] text-center',
              )}
            >
              You've unlocked more learning opportunities for as an Homeschool
              Parent! Now, personalize G3MS as an educator to maximize their
              success.
            </p>
          </div>
        )}
        <div className="space-y-2">
          <h1
            className={cn(
              '!font-semibold text-center !text-lg lg:!text-xl 2xl:!text-2xl text-[#131515]',
            )}
          >
            Add Homeschool Classroom
          </h1>
        </div>

        <form
          onSubmit={formik.handleSubmit}
          className="flex flex-col lg:grid gap-4 lg:!grid-cols-2"
        >
          <div className="col-span-2">
            <TextInputField
              label="Homeschool Name"
              placeholder="Enter Homeschool Name"
              {...formik.getFieldProps('homeSchoolName')}
              error={
                formik.touched.homeSchoolName && formik.errors.homeSchoolName
              }
            />
          </div>
          <div className="col-span-2">
            <TextInputField
              label="Classroom Name"
              placeholder="Enter Classroom Name"
              {...formik.getFieldProps('name')}
              error={formik.touched.name && formik.errors.name}
            />
          </div>

          <SelectInputField
            label="Grade/Subject"
            placeholder="Select Grade/Subject"
            data={gradedata}
            disabled={isLoading}
            onChange={(value) => formik.setFieldValue('grade', value)}
            value={formik.values.grade}
            error={formik.touched.grade && formik.errors.grade}
            onBlur={() => formik.setFieldTouched('grade', true)}
          />

          <TextInputField
            label="Classroom Period"
            placeholder="Enter Classroom Period"
            {...formik.getFieldProps('period')}
            error={formik.touched.period && formik.errors.period}
          />

          <div className="col-span-2">
            <SelectInputField
              label="Department"
              placeholder="Select Department"
              data={allTopics}
              disabled={topicsLoading}
              onChange={(value) => formik.setFieldValue('topic', value)}
              value={formik.values.topic}
              error={formik.touched.topic && formik.errors.topic}
              onBlur={() => formik.setFieldTouched('topic', true)}
            />
          </div>

          {/* district */}

          <SelectInputField
            label="State"
            placeholder="Select State"
            data={allStates}
            disabled={statesLoading}
            onChange={(value) => {
              formik.setFieldValue('state', value);
              formik.setFieldValue('city', '');
              formik.setFieldValue('school', '');
            }}
            value={formik.values.state}
            error={formik.touched.state && formik.errors.state}
            onBlur={() => formik.setFieldTouched('state', true)}
          />

          {formik.values.state && (
            <SelectInputField
              label="City"
              placeholder="Select City"
              data={allCities}
              disabled={citiesLoading}
              onChange={(value) => {
                formik.setFieldValue('city', value);
                formik.setFieldValue('school', '');
              }}
              value={formik.values.city}
              error={formik.touched.city && formik.errors.city}
              onBlur={() => formik.setFieldTouched('city', true)}
            />
          )}

          {/*           {formik.values.city && (
            <SelectInputField
              label="School"
              placeholder="Select School"
              data={allSchools}
              disabled={schoolsLoading}
              onChange={(value) => formik.setFieldValue('school', value)}
              value={formik.values.school}
              error={formik.touched.school && formik.errors.school}
              onBlur={() => formik.setFieldTouched('school', true)}
            />
          )} */}

        {/*   {formik.values.city && (
            <SelectInputField
              label="District"
              placeholder="Select District"
              data={allDistricts}
              disabled={districtsLoading}
              onChange={(value) => formik.setFieldValue('district', value)}
              value={formik.values.district}
              error={formik.touched.district && formik.errors.district}
              onBlur={() => formik.setFieldTouched('district', true)}
            />
          )} */}

          <Button
            className="max-w-full !rounded-xl col-span-2"
            color="#20A58A"
            size="xl"
            fw={500}
            type="submit"
          >
            {modal?.props?.isFirstClassRoom
              ? 'Add Your First Classroom'
              : 'Add Classroom'}
          </Button>
        </form>
      </div>
      <LoadingOverlay visible={createHomeSchoolClassRoomResponse.isLoading} />
    </Modal>

    // </Modal.Content>
    // </Modal>
  );
}
