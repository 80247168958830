import { Roles } from 'constants/consts';
import { getUser } from 'helper/functions';
import React, { useEffect } from 'react';
import { useAppDispatch } from 'store/store';
import {
  getSchoolLeaderDashboardSkillsMastered,
  getSuperadminDashboardSkillsMastered,
} from 'store/Thunk/dashboardThunk';
import { ReactComponent as DropDownIcon } from './assets/drop-down-svg.svg';
import GroupedBarChart from './components/bar-chart';
import CustomDateRangePicker from './components/date-range-Picker';
import Nodata from './components/noData';
import { getUniqueSkills, SkillsMasteredBarChartData } from './helpers/helpers';

interface Props {
  data: SkillsMasteredBarChartData[];
  tabs: string[];
}

const SkillsMasteredChart = ({ data, tabs }: Props) => {
  const user = getUser();
  const role = user?.type || '';
  const header: any = localStorage.getItem('userData');
  const id: string = JSON.parse(header)?.id || '';
  const dispatch: any = useAppDispatch();

  useEffect(() => {}, []);
  const [selectTab, setSelectTab] = React.useState(data[0]?.tableTitle || '');
  const [isOpen, setIsOpen] = React.useState(false);
  const onChangeHandler = (item: any) => {
    if (role === Roles.ADMIN) {
      dispatch(getSuperadminDashboardSkillsMastered({ date: item.startDate }));
    } else if (role === Roles.SCHOOL_LEADER) {
      dispatch(
        getSchoolLeaderDashboardSkillsMastered({ id, date: item.startDate }),
      );
    }
  };

  return (
    <div style={Styles.mainContainer}>
      <div className="section-header" style={Styles.sectionHeader}>
        <div className="right-aligned" style={Styles.rightAligned}>
          {tabs.map((value, index) => {
            const selectedStyles = {
              lineHeight: '20px',
              fontWeight: 600,
              borderBottom: '4px solid #C73E9D',
            };
            return (
              <div
                className="tab-item"
                onClick={() => setSelectTab(value)}
                style={{
                  ...Styles.tabItem,
                  color: selectTab === value ? '#484848' : 'inherit',
                  ...(selectTab === value ? selectedStyles : {}),
                }}
              >
                {value}
              </div>
            );
          })}
        </div>
        <div className="left-aligned" style={{ position: 'relative' }}>
          <div
            style={{
              zIndex: 1000,
              position: 'absolute',
              top: '3rem',
              right: '1rem',
            }}
          >
            {isOpen && (
              <CustomDateRangePicker onChangeHandler={onChangeHandler} />
            )}
          </div>

          <div
            style={Styles.rangePickerContainer}
            onClick={() => setIsOpen(!isOpen)}
          >
            <p style={{ marginRight: '7px' }}>Select Range</p>
            <div
              style={{
                transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            >
              <DropDownIcon />
            </div>
          </div>
        </div>
      </div>
      <div className="section-body" style={Styles.sectionBody}>
        {selectTab === 'Skills mastered' && data[0]?.tableData?.length ? (
          <GroupedBarChart data={data} selectTab={selectTab} />
        ) : selectTab === 'Watched hours' && data[1]?.tableData?.length ? (
          <GroupedBarChart data={data} selectTab={selectTab} />
        ) : (
          <Nodata type={1} />
        )}
        <div className="group-list" style={Styles.groupList}>
          {getUniqueSkills(data, selectTab).map((item, index) => {
            return (
              <div
                className="item"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '1rem',
                }}
              >
                <div
                  style={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    backgroundColor: item.color,
                    marginRight: '5px',
                  }}
                ></div>
                <div
                  style={{
                    lineHeight: '20px',
                    fontWeight: 400,
                    fontSize: '12px',
                  }}
                >
                  {item.title}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SkillsMasteredChart;

const Styles = {
  mainContainer: {
    width: '100%',
    height: 'auto',
    backgroundColor: 'white',
    color: '#484848',
  },
  sectionHeader: {
    height: '65px',
    borderBottom: '1px solid #E5E5E5',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 2rem',
  },
  rightAligned: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
  },
  tabItem: {
    cursor: 'pointer',
    height: '100%',
    marginRight: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 1rem',
    fontFamily: 'Inter',
    fontSize: '14px',
  },
  rangePickerContainer: {
    cursor: 'pointer',
    fontFamily: 'Inter',
    fontSize: '14px',
    color: '#484848',
    fontWeight: 500,
    border: '1px solid #E0E2E7',
    padding: '10px 14px',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
  },
  sectionBody: {
    padding: '1.5rem 2rem 1rem 2rem',
  },
  groupList: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '0.5rem',
  },
};
