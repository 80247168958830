import { useFormik } from 'formik';

import { Button, Modal, rem, Title } from '@mantine/core';
import useModal from 'components/Modal/hook/useModal';
import cn from 'utils/cn';

import NumberInputField from 'components/NumberInputField';
import SelectInputField from 'components/SelectInputField';
import TextInputField from 'components/TextInputField';
import { Trash } from 'iconsax-react';
import React, { useEffect } from 'react';
import { useGetGradeListQuery } from 'store/apis/hometab';
import { useGetMyProfileQuery } from 'store/apis/profile';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';

const validationSchema = z.object({
  // grade: z.string({ message: 'Required' }),
  // scholeType: z.string({ message: 'Required' }),
  leaders: z
    .array(
      z.object({
        firstName: z.string({ message: 'Required' }),
        lastName: z.string({ message: 'Required' }),
        email: z.string({ message: 'Required' }),
        phoneNumber: z.string({ message: 'Required' }),
      }),
    )
    .min(1, 'Min 1 Leader')
    .max(3, 'Max 3 Leaders'),
  numberOfTeachers: z.string({ message: 'Required' }),
  schoolPerformanceGrade: z.string({ message: 'Required' }),
  meeting: z.object({
    start: z.string({ message: 'Required' }),
    end: z.string({ message: 'Required ' }),
  }),
  approacing: z.object({
    start: z.string({ message: 'Required' }),
    end: z.string({ message: 'Required ' }),
  }),
  emerging: z.object({
    start: z.string({ message: 'Required' }),
    end: z.string({ message: 'Required ' }),
  }),
});

export type SchoolSettingsModalProps = z.infer<typeof validationSchema>;

export default function SchoolSettingsModal() {
  const { closeModal, isOpen, modal, openModal } = useModal<{
    onContinue?: () => void;
    isFirstStudent?: boolean;
    withUpgradeSuccess?: boolean;
    withAddons?: boolean;
    educatorPlan?: 'free' | 'premium';
  }>();

  const { data: gradeList, isLoading } = useGetGradeListQuery();

  const { data, isLoading: myProfileIsLoading } = useGetMyProfileQuery();

  const formik = useFormik<SchoolSettingsModalProps>({
    initialValues: {
      leaders: [],
      numberOfTeachers: '',
      schoolPerformanceGrade: '',
      meeting: { start: '', end: '' },
      approacing: { start: '', end: '' },
      emerging: { start: '', end: '' },
    },
    validationSchema: toFormikValidationSchema(validationSchema),
    onSubmit: async (values, { setFieldError }) => {
      try {
        console.log(values);

        if (modal?.props?.withUpgradeSuccess || data?.data?.accountSetup?.isEducatorAddOnIncluded) {
          openModal('ParentSelectAcademic', {
            ...formik.values,
            withUpgradeSuccess: modal?.props?.withUpgradeSuccess,
            withAddons: data?.data?.accountSetup?.isEducatorAddOnIncluded,
            cache: true,
          });
          return;
        }

        if (!data?.data?.isUserUpgradedPlan) {
          openModal('ParentTrial', { cache: true });
          return;
        }

        openModal('ParentTrial', {
          ...formik.values,
          cache: true,
        });
      } catch (error) {
        console.log(error);
      }
    },
  });

  const gradedata = React.useMemo(() => {
    if (!gradeList) return [];
    return gradeList.data.map((grade) => ({
      value: grade.id,
      label: grade.name,
    }));
  }, [gradeList]);

  useEffect(() => {
    if (data?.data) {
      formik.setFieldValue('leaders', [
        {
          firstName: data.data?.name || '',
          lastName: data.data?.lastname || '',
          email: data.data?.email || '',
          phoneNumber: data.data?.phone || '',
        },
      ]);
    }
  }, [data]);

  return (
    <Modal
      opened={isOpen('SchoolSettingsModal')}
      onClose={closeModal}
      closeOnClickOutside={false}
      closeOnEscape={false}
      size={'2xl'}
      centered
      shadow="sm"
      radius={'lg'}
      classNames={{
        title:
          '!text-lg !font-semibold lg:!text-xl 2xl:!text-2xl !text-[#2C3E50]',
        close:
          '!text-[#2C3E50] !text-lg !font-semibold lg:!text-xl 2xl:!text-2xl',
      }}
      padding={rem(24)}
      zIndex={999999999}
      withCloseButton={false}
    >
      <div className="grid gap-4 lg:gap-6">
        <div className="space-y-2">
          <h1
            className={cn(
              '!font-semibold !text-lg lg:!text-xl 2xl:!text-2xl text-[#131515]',
            )}
          >
            School Settings
          </h1>
        </div>

        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-6">
        {/*   <div className="flex flex-col gap-4">
            <Title order={4} className="text-black">
              Let’s get your School Settings configured to streamline
              personalized learning and performance tracking.
            </Title>
            <div className="grid gap-4 lg:gap-6 lg:grid-cols-2">
              <SelectInputField
                label="School Type"
                placeholder="Select School Type"
                data={gradedata}
                disabled={isLoading}
                onChange={(value) => formik.setFieldValue('scholeType', value)}
                value={formik.values.scholeType}
                error={formik.touched.scholeType && formik.errors.scholeType}
                onBlur={() => formik.setFieldTouched('scholeType', true)}
              />
              <SelectInputField
                label="Grade/Subject"
                placeholder="Select Grade/Subject"
                data={gradedata}
                disabled={isLoading}
                onChange={(value) => formik.setFieldValue('grade', value)}
                value={formik.values.grade}
                error={formik.touched.grade && formik.errors.grade}
                onBlur={() => formik.setFieldTouched('grade', true)}
              />
            </div>
          </div> */}
         {/*  <div className="flex flex-col gap-4">
            <div className="flex gap-4 justify-between">
              <Title order={4} className="text-black max-w-[75%]">
                Leadership Information School Leader (Add up to 3 additional
                leaders)
              </Title>
              <button
                type="button"
                className="text-[#4CBFA7] !font-semibold !text-sm whitespace-nowrap"
                onClick={() => {
                  if (formik.values.leaders.length >= 3) return;
                  formik.setFieldValue('leaders', [
                    ...formik.values.leaders,
                    { firstName: '', lastName: '' },
                  ]);
                }}
              >
                +Add more
              </button>
            </div>
            <div className="grid gap-4">
              {formik.values.leaders &&
                formik.values.leaders.map((leader, index) => (
                  <div className="relative">
                    {index !== 0 && (
                      <button
                        className="!absolute right-0 top-0 p-2 -translate-y-2  flex gap-2 items-center text-[#C73E9D] text-sm"
                        onClick={() => {
                          const leaders = formik.values.leaders.filter(
                            (_, i) => i !== index,
                          );
                          formik.setFieldValue('leaders', leaders);
                        }}
                      >
                        <Trash
                          className="text-[#C73E9D] text-sm"
                          size={20}
                          variant="Bold"
                        />
                        <span>Remove</span>
                      </button>
                    )}
                    <div
                      key={index}
                      className="grid gap-4 lg:gap-6 sm:grid-cols-2 lg:!grid-cols-[1fr,1fr,1fr,1fr]"
                    >
                      <TextInputField
                        label="First Name"
                        placeholder="Enter First Name"
                        {...formik.getFieldProps(`leaders.${index}.firstName`)}
                        error={
                          formik.touched.leaders?.[index]?.firstName &&
                          // @ts-ignore
                          formik.errors.leaders?.[index]?.firstName
                            ? 'Please enter a valid first name'
                            : undefined
                        }
                        onBlur={() =>
                          formik.setFieldTouched(
                            `leaders.${index}.firstName`,
                            true,
                          )
                        }
                      />
                      <TextInputField
                        label="Last Name"
                        placeholder="Enter Last Name"
                        error={
                          formik.touched.leaders?.[index]?.lastName &&
                          // @ts-ignore
                          formik.errors.leaders?.[index]?.lastName
                            ? 'Please enter a valid first name'
                            : undefined
                        }
                        {...formik.getFieldProps(`leaders.${index}.lastName`)}
                        onBlur={() =>
                          formik.setFieldTouched(
                            `leaders.${index}.lastName`,
                            true,
                          )
                        }
                      />

                      <TextInputField
                        label="Email"
                        placeholder="Enter Email"
                        {...formik.getFieldProps(`leaders.${index}.email`)}
                        error={
                          formik.touched.leaders?.[index]?.email &&
                          // @ts-ignore
                          formik.errors.leaders?.[index]?.email
                            ? 'Please enter a valid email'
                            : undefined
                        }
                        onBlur={() =>
                          formik.setFieldTouched(`leaders.${index}.email`, true)
                        }
                      />
                      <TextInputField
                        label="Phone Number"
                        placeholder="Enter Phone Number"
                        {...formik.getFieldProps(
                          `leaders.${index}.phoneNumber`,
                        )}
                        error={
                          formik.touched.leaders?.[index]?.phoneNumber &&
                          // @ts-ignore
                          formik.errors.leaders?.[index]?.phoneNumber
                            ? 'Please enter a valid phone number'
                            : undefined
                        }
                        onBlur={() =>
                          formik.setFieldTouched(
                            `leaders.${index}.phoneNumber`,
                            true,
                          )
                        }
                      />
                    </div>
                  </div>
                ))}
            </div>
          </div> */}

          <div className="flex flex-col gap-4">
            <Title order={4} className="text-black">
              Teacher & Classroom Information
            </Title>
            <div className="grid gap-4 lg:gap-6 lg:grid-cols-2">
              <NumberInputField
                label="Number of teachers"
                placeholder="Add number of teachers..."
                disabled={isLoading}
                onChange={(value: string | number) =>
                  formik.setFieldValue('numberOfTeachers', value.toString())
                }
                value={
                  formik.values.numberOfTeachers
                    ? (Number(formik.values.numberOfTeachers) as
                        | number
                        | string)
                    : ''
                }
                error={
                  formik.touched.numberOfTeachers &&
                  formik.errors.numberOfTeachers
                }
                onBlur={() => formik.setFieldTouched('numberOfTeachers', true)}
              />
              <SelectInputField
                label="School Performance Grade"
                placeholder="Select school Performance Grade"
                data={['A', 'B', 'C', 'D', 'F']}
                disabled={isLoading}
                onChange={(value) =>
                  formik.setFieldValue('schoolPerformanceGrade', value)
                }
                value={formik.values.schoolPerformanceGrade}
                error={
                  formik.touched.schoolPerformanceGrade &&
                  formik.errors.schoolPerformanceGrade
                }
                onBlur={() =>
                  formik.setFieldTouched('schoolPerformanceGrade', true)
                }
              />
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <Title order={4} className="text-black">
              Provide starting and ending percentages for student proficiency
              levels
            </Title>
            <div className="grid gap-4 lg:gap-6 grid-cols-2">
              <NumberInputField
                label="Meeting:"
                placeholder="Start (%)"
                disabled={isLoading}
                onChange={(value: string | number) =>
                  formik.setFieldValue('meeting.start', value.toString())
                }
                value={
                  formik.values.meeting.start
                    ? (Number(formik.values.meeting.start) as number | string)
                    : ''
                }
                error={
                  formik.touched.meeting?.start && formik.errors.meeting?.start
                }
                onBlur={() => formik.setFieldTouched('meeting.start', true)}
              />
              <NumberInputField
                label=" "
                placeholder="End (%)"
                disabled={isLoading}
                onChange={(value: string | number) =>
                  formik.setFieldValue('meeting.end', value.toString())
                }
                value={
                  formik.values.meeting.end
                    ? (Number(formik.values.meeting.end) as number | string)
                    : ''
                }
                error={
                  formik.touched.meeting?.end && formik.errors.meeting?.end
                }
                onBlur={() => formik.setFieldTouched('meeting.end', true)}
              />
            </div>
            <div className="grid gap-4 lg:gap-6 grid-cols-2">
              <NumberInputField
                label="Approaching:"
                placeholder="Start (%)"
                disabled={isLoading}
                onChange={(value: string | number) =>
                  formik.setFieldValue('approacing.start', value.toString())
                }
                value={
                  formik.values.approacing.start
                    ? (Number(formik.values.approacing.start) as
                        | number
                        | string)
                    : ''
                }
                error={
                  formik.touched.approacing?.start &&
                  formik.errors.approacing?.start
                }
                onBlur={() => formik.setFieldTouched('approacing.start', true)}
              />
              <NumberInputField
                label=" "
                placeholder="End (%)"
                disabled={isLoading}
                onChange={(value: string | number) =>
                  formik.setFieldValue('approacing.end', value.toString())
                }
                value={
                  formik.values.approacing.end
                    ? (Number(formik.values.approacing.end) as number | string)
                    : ''
                }
                error={
                  formik.touched.approacing?.end &&
                  formik.errors.approacing?.end
                }
                onBlur={() => formik.setFieldTouched('approacing.end', true)}
              />
            </div>
            <div className="grid gap-4 lg:gap-6 grid-cols-2">
              <NumberInputField
                label="Emerging:"
                placeholder="Start (%)"
                disabled={isLoading}
                onChange={(value: string | number) =>
                  formik.setFieldValue('emerging.start', value.toString())
                }
                value={
                  formik.values.emerging.start
                    ? (Number(formik.values.emerging.start) as number | string)
                    : ''
                }
                error={
                  formik.touched.emerging?.start &&
                  formik.errors.emerging?.start
                }
                onBlur={() => formik.setFieldTouched('emerging.start', true)}
              />
              <NumberInputField
                label=" "
                placeholder="End (%)"
                disabled={isLoading}
                onChange={(value: string | number) =>
                  formik.setFieldValue('emerging.end', value.toString())
                }
                value={
                  formik.values.emerging.end
                    ? (Number(formik.values.emerging.end) as number | string)
                    : ''
                }
                error={
                  formik.touched.emerging?.end && formik.errors.emerging?.end
                }
                onBlur={() => formik.setFieldTouched('emerging.end', true)}
              />
            </div>
          </div>

          <div className="w-full max-w-full grid">
            <Button
              className="!rounded-xl col-span-2 !w-[clamp(5rem,25rem,100%)] mx-auto"
              color="#20A58A"
              size="xl"
              fw={500}
              type="submit"
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
}
