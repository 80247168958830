import { Card } from '@mui/material';
import Header from 'pages/parent/components/Header/Header';
import EducatorDashboard from 'pages/parent/Dashboards/EducatorDashboard';
import React from 'react';

function Dashboard() {
 
  return (
    <Card>
      <Header role={'EduOnboarded'}  />
      <EducatorDashboard role={'EduOnboarded'} />
    </Card>
  );
} 

export default Dashboard;
