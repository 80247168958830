import { CircularProgress } from '@mui/material';
import useAuth from 'hooks/useAuth';

import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export default function TokenLogin() {
  const [searchParams] = useSearchParams();

  const { loginUser } = useAuth();

  const otherQueryParams = Object.fromEntries(searchParams.entries());

  useEffect(() => {
    loginUser(otherQueryParams);
  }, [otherQueryParams]);

  return (
    <div className="absolute inset-0 grid place-items-center">
      <div className="grid place-items-center gap-4">
        <CircularProgress color="secondary" />
        <h1 className="text-black text-center mt-4 font-bold text-xl ">
          Loading
        </h1>
      </div>
    </div>
  );
}
