import { Card } from '@mui/material';
import Header from 'pages/parent/components/Header/Header';
import EducatorDashboardSchoolLeader from 'pages/parent/Dashboards/EducatorDashboardSchoolLeader';
import React from 'react';

function index() {
  
    return (
        <Card>
        <Header role={'EduOnboarded'} />
        <EducatorDashboardSchoolLeader role={'EduOnboarded'} />
      </Card>
    );
}

export default index;