import { apiSlice } from '..';
import { AddUserBody, IAddUserResponse, TGetMyUserApiResponse } from './types';

export const userApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addUser: builder.mutation<IAddUserResponse, AddUserBody>({
      query: (body) => ({
        url: 'users',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['MyProfile', 'UserStudents'],
    }),
    addUserToClass: builder.mutation<
      void,
      { userIds: string[]; classId: string }
    >({
      query: ({ userIds, classId }) => ({
        url: `/classroom/${classId}/student`,
        method: 'POST',
        body: { studentIds: userIds },
      }),
      invalidatesTags: ['MyProfile', 'UserStudents'],
    }),
    getUserStudents: builder.query<TGetMyUserApiResponse, string>({
      query: (id) => `parents/${id}/children`,
      providesTags: ['UserStudents'],
    }),
  }),
});

export const {
  useAddUserMutation,
  useAddUserToClassMutation,
  useGetUserStudentsQuery,
} = userApi;
