import { combineReducers, configureStore } from '@reduxjs/toolkit';
import userReducer from './Slice/userSlice';
import videoReducer from './Slice/videoSlice';
import navItemReducer from './Slice/navSlice';
import notificationReducer from './Slice/notificationSlice';
import authReducer from './Slice/authSlice';
import loadingReducer from './Slice/loadingSlice';
import templateReducer from './Slice/templateSlice';
import QuestionSlice from './Slice/QuestionSlice';
import dailyChallengesReducer from './Slice/dailyChallengesSlice';
import StudentReplyReducer from './Slice/studentReplySlice';
import districtReducer from './Slice/districtSlice';
import schoolReducer from './Slice/schoolSlice';
import userSessionReducer from './Slice/userSessionSlice';
import schoolLeaderReducer from './Slice/schoolLeaderSlice';
import classroomReducer from './Slice/classroomSlice';
import assignContentSlice from './Slice/assignContentSlice';
import studentReducer from './Slice/studentSlice';
import groupReducer from './Slice/groupSlice';
import dashboardReducer from './Slice/dashboardSlice';
import teacherDashboardReducer from './Slice/teacherDashboardSlice';
import teacherSlice from './Slice/teacherSlice';
import SchoolLeaderDashboardReducer from './Slice/schoolLeaderDashboardSlice';
import parentSlice from './Slice/parentSlice';
import schoolAssignmentSlice from './Slice/schoolAssignmentSlice';
import ParentDashboardSlice from './Slice/ParentDashboardSlice';
import ParentEducatorDashboardSlice from './Slice/ParentEducatorDashboardSlice';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import apiSlice from './apis';

const combinedReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  userReducer,
  notificationReducer,
  videoReducer,
  navItemReducer,
  authReducer,
  loadingReducer,
  templateReducer,
  QuestionSlice,
  dailyChallengesReducer,
  StudentReplyReducer,
  districtReducer,
  schoolReducer,
  userSessionReducer,
  schoolLeaderReducer,
  classroomReducer,
  studentReducer,
  groupReducer,
  dashboardReducer,
  teacherDashboardReducer,
  assignContentSlice,
  teacherReducer: teacherSlice,
  SchoolLeaderDashboardReducer,
  parentReducer: parentSlice,
  schoolAssignmentSlice,
  parentDashboardReducer: ParentDashboardSlice,
  ParentEducatorReducer: ParentEducatorDashboardSlice,
});

export const store = configureStore({
  reducer: combinedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;
