import { Button, Modal, rem } from '@mantine/core';
import useModal from 'components/Modal/hook/useModal';
import useAuth from 'hooks/useAuth';
import { useGetMyProfileQuery } from 'store/apis/profile';
import cn from 'utils/cn';

export default function ParentUpgradeToPlanWarning() {
  const { closeModal, isOpen, modal, openModal } = useModal<{
    onContinue?: () => void;
  }>();

  const { data } = useGetMyProfileQuery();

  const { loginUser, redirectToLogin } = useAuth();

  return (
    // <Modal isOpen={isOpen('AskAGrownupModal')} onClose={closeModal}>
    //   <Modal.Content className="[--max-width:_45rem] [--top:_2rem]  md:[--top:_10%]">
    //     <Modal.CloseButton />
    <Modal
      opened={isOpen('ParentUpgradeToPlanWarning')}
      onClose={closeModal}
      closeOnClickOutside={false}
      closeOnEscape={false}
      size={'xl'}
      centered
      shadow="sm"
      radius={'lg'}
      classNames={{
        title:
          '!text-lg !font-semibold lg:!text-xl 2xl:!text-2xl !text-[#2C3E50]',
        close:
          '!text-[#2C3E50] !text-lg !font-semibold lg:!text-xl 2xl:!text-2xl',
      }}
      padding={rem(24)}
      zIndex={999999999}
      withCloseButton={false}
    >
      <div className="space-y-4 xl:space-y-8">
        <div className="grid gap-2 items-start justify-start align-top">
          <h1
            className={cn(
              '!font-semibold !text-lg lg:!text-xl 2xl:!text-2xl text-[#131515] text-center mt-2 max-sm:text-center',
            )}
          >
            Upgrade to Educator Dashboard
          </h1>
          <p
            className={cn(
              '!text-sm lg:!text-base xl:!text-lg 2xl:!text-xl text-[#131515]',
            )}
          >
            Take G3MS learning experience to the next level with the
            Educator Dashboard!
          </p>
          <p
            className={cn(
              '!text-sm lg:!text-base xl:!text-lg 2xl:!text-xl text-black font-bold',
            )}
          >
            With the upgraded dashboard, you’ll have powerful tools to:
          </p>
        </div>

        <ul
          className={cn(
            '!text-sm lg:!text-base xl:!text-lg 2xl:!text-xl text-[#131515] space-y-1',
          )}
        >
          <li>
            📊 <span className="text-black font-bold">Track Progress:</span>
            Track students performance and activity.
          </li>
          <li>
            🔍 <span className="text-black font-bold">Spot Gaps:</span> Identify
            strengths and areas for improvement.
          </li>
          <li>
            🎉 <span className="text-black font-bold">Celebrate Wins:</span>{' '}
            Track milestones and reward success.
          </li>
        </ul>

        <p
          className={cn(
            '!text-sm lg:!text-base xl:!text-lg 2xl:!text-xl text-black font-bold my-4',
          )}
        >
          Upgrade now to stay involved and support their growth!
        </p>

        <div className="grid gap-4 lg:grid-cols-2 col-span-2 mt-4">
          <Button
            onClick={() => {
              const token = localStorage.getItem('token');
              if (token && data?.data) {
                loginUser({
                  token,
                  redirectTo: 'plans',
                });
              } else {
                redirectToLogin();
              }
            }}
            className="max-w-full !rounded-xl"
            color="#20A58A"
            size="xl"
            fw={500}
          >
            Upgrade to Educator
          </Button>
          <Button
            onClick={() => {
              openModal('ParentTrial', { cache: true });
            }}
            className="max-w-full !rounded-xl"
            color="#999999"
            size="xl"
            fw={500}
          >
            Keep the Parent Plan
          </Button>
        </div>
      </div>
    </Modal>

    // </Modal.Content>
    // </Modal>
  );
}
