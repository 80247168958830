import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { ITeacher } from '../../components/Dashboard/table';


interface IntialState {
    skillMastered: any[];
    totalWatchHours: any[];
}


const intialState: IntialState = {
  skillMastered: [],
  totalWatchHours: [],
}

const SchoolLeaderDashboardSlice = createSlice({
  name: 'school_leader_dashboard_slice',
  initialState: intialState,
  reducers: {
    setSchoolLeaderDashboardInfo: (
      state: Draft<IntialState>,
      action: PayloadAction<{ skillMastered?: any; totalWatchHours?: any }>
    ) => {
      if (action.payload.skillMastered) {
        state.skillMastered = action.payload.skillMastered;
      }
  
      if (action.payload.totalWatchHours) {
        state.totalWatchHours = action.payload.totalWatchHours;
      }
    },
  },
});

export const { setSchoolLeaderDashboardInfo } = SchoolLeaderDashboardSlice.actions;

export default SchoolLeaderDashboardSlice.reducer; 