import { useState } from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import '../styles/style.css';

const DateRangePicker = ({
  onChangeHandler,
}: {
  onChangeHandler: Function;
}) => {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection',
    },
  ]);

  const handleOnChange = (item: any) => {
    setState([item.selection]);
    console.log('state', item);

    onChangeHandler({
      startDate: new Date(item.selection.startDate).toISOString(),
      endDate: new Date(item.selection.endDate).toISOString(),
    });
  };
  console.log('state', state);

  return (
    // @ts-ignore
    <DateRange
      onChange={(item: any) => handleOnChange(item)}
      ranges={state as any}
    />
  );
};

export default DateRangePicker;
