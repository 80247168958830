import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Card,
  Typography,
  Breadcrumbs,
  Link,
  Grid,
  CardContent,
  Button,
  Tooltip,
} from '@mui/material';
import CustomTable from 'components/customTable';
import DefaultCell from 'components/DefaultCell';
import { EditIcon, DeleteIcon, ViewIcon } from 'icons';
import Profile from 'assets/actionIcons/profile.png';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

//*** Icons
import CheckCircle from 'icons/checkCircle';
// import PlusCircle from 'icons/PlusCircle';
// import LineIndicationOne from 'icons/lineIndicationOne';
// import File from 'icons/File';

// *** Third Party Imports
import { useNavigate } from 'react-router-dom';

// *** Custom Components
import { route_info } from 'navigation/route';
import { useAppDispatch, useAppSelector } from 'store/store';
import { getDistrictList } from 'store/Thunk/districtThunk';
import AddUpdateDistrict from 'components/dialog/district/AddUpdate';
import DeleteDistrict from 'components/dialog/district/Delete';
import AddUpdateSchool from 'components/dialog/school/AddUpdate';
import { startLoading, stopLoading } from 'store/Slice/loadingSlice';
import AddFirstDistrict from './AddFirstDistrict';
import {
  lineIndicationFour,
  lineIndicationOne,
  lineIndicationThree,
  lineIndicationTwo,
} from 'assets';
import EditBorderIcon from 'icons/editBorderIcon';
import DeleteBorderIcon from 'icons/deleteBorderIcon';
import ViewBorderIcon from 'icons/viewBorderIcon';

// const borderStyleObj = {
//   width: '100%',
//   height: '1px',
//   backgroundImage: 'linear-gradient(to right, #c73e9d4f 50%, #fff 50%)',
//   backgroundPosition: 'top',
//   backgroundSize: '11px 2px',
//   backgroundRepeat: 'repeat-x',
//   marginLeft: '12px',
// };

const steps = [
  {
    title: 'Manage Districs ',
    content: 'Create an District to get started.',
    icon: lineIndicationOne,
  },
  {
    title: 'Manage Schools',
    content: 'Create an school to get started.',
    icon: lineIndicationTwo,
  },
  {
    title: 'Manage Classroom',
    content: 'Create classroom to get started.',
    icon: lineIndicationThree,
  },
  {
    title: 'Manage all content',
    content: 'Create an District to get started.',
    icon: lineIndicationFour,
  },
];

function handleBreadCrumbClick(
  event: React.MouseEvent<HTMLDivElement, MouseEvent>,
) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

function Index() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { districtList } = useAppSelector(
    (state: any) => state.districtReducer,
  );

  const { screenLoading } = useAppSelector(
    (state: any) => state.loadingReducer,
  );

  const [isOpen, setIsOpen] = useState(false);
  const [template, setTemplate] = useState<any[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [perPage, setPageSize] = useState(10);
  const [search, setSearch] = useState<any>(null);
  const [totalCount, setTotalCount] = useState(0);
  const [editId, setEditId] = useState('');
  const [openDelete, setOpenDelete] = React.useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [isSchoolOpen, setSchoolIsOpen] = useState(false);

  const fetchIdRef = useRef(0);
  // useEffect(() => {
  //   dispatch(startLoading());
  //   dispatch(getDistrictList({pageCount, perPage})).then(()=> dispatch(stopLoading()));
  // }, []);

  useEffect(() => {
    dispatch(getDistrictList({ pageCount: 1, perPage: 10 }));
  }, []);

  useEffect(() => {
    if (districtList && districtList?.items) {
      setTemplate(districtList?.items);
      setPageCount(districtList?.meta?.lastPage);
      setTotalCount(districtList?.meta?.total);
    }
  }, [districtList]);

  const handleAddUpdateDistrict = () => {
    setEditId('');
    setIsOpen(!isOpen);
  };

  const handleEditClick = (id: any) => {
    navigate(route_info?.district_management_edit.replace(':id', id));
  };

  const handleDeleteDialog = (id: string) => {
    setDeleteId(id);
    setOpenDelete(!openDelete);
  };

  const handleAddUpdateSchool = () => {
    setSchoolIsOpen(!isSchoolOpen);
  };

  const columns = [
    {
      Header: 'NCES District ID',
      accessor: 'ncesDistrictId',
      width: '480px',
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: 'District Name',
      accessor: 'district_name',
      Cell: ({ value }: any) => (
        // <Tooltip title={value?.id}>
        //   <DefaultCell value={value?.name} />,
        // </Tooltip>
        <Tooltip title={value?.id} arrow>
          <Typography minWidth="50px">{value?.name}</Typography>
        </Tooltip>
      ),
    },
    {
      Header: ' District Type',
      accessor: 'districtType',
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: 'Superintendent',
      accessor: 'superintendent',
      Cell: ({ value }: any) => (
        <Stack direction="row" spacing={2} alignItems="center">
          {Object.keys(value).length ? (
            <>
              <Box component="img" src={value?.profileImage || Profile} />
              <Box>{value?.name || value?.email}</Box>
            </>
          ) : (
            <DefaultCell value={''} />
          )}
        </Stack>
      ),
    },
    {
      Header: 'School',
      accessor: 'school',
      Cell: ({ value }: any) => (
        <Stack direction="row" spacing={2} alignItems="center">
          {value.school.length === 0 ? (
            <Box>
              <Button
                variant="text"
                sx={{
                  textDecoration: 'underline',
                  textTransform: 'none !important',
                }}
                onClick={handleAddUpdateSchool}
              >
                Add School
              </Button>
            </Box>
          ) : (
            <Box sx={{ borderBottom: '1px solid #979C9E', px: 2 }}>
              <Link
                underline="none"
                key="1"
                color="inherit"
                onClick={() =>
                  navigate(
                    route_info?.district_details.replace(':id', value.id),
                  )
                }
              >
                {value.school.length}
              </Link>
            </Box>
          )}
        </Stack>
      ),
    },
    {
      Header: 'City',
      accessor: 'city',
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: 'State / Country',
      accessor: 'state',
      Cell: ({ value }: any) => (
        <DefaultCell value={`${value?.state}/ ${'US'}`} />
      ),
    },
    // {
    //   Header: 'Country',
    //   accessor: 'county',
    //   Cell: ({ value }: any) => <DefaultCell value={value} />,
    // },
    {
      Header: 'Zip Code',
      accessor: 'zipCode',
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: ({ value }: any) => (
        <Box>
          <IconButton onClick={() => handleEditClick(value?.id)}>
            <EditBorderIcon />
          </IconButton>
          <IconButton onClick={() => handleDeleteDialog(value?.id)}>
            <DeleteBorderIcon />
          </IconButton>
          <IconButton
            onClick={() =>
              navigate(route_info?.district_details.replace(':id', value.id))
            }
          >
            <ViewBorderIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const getRows = () => {
    const data: any = template?.map((obj: any) => ({
      id: obj?.id,
      district_name: obj,
      ncesDistrictId: obj?.ncesDistrictId,
      districtType: obj?.districtType,
      superintendent: obj?.superintendent || null,
      school: obj || null,
      city: obj?.city || null,
      state: obj || null,
      county: obj?.county || null,
      zipCode: obj?.zipCode || null,
      action: obj,
    }));
    return data;
  };

  const tableData = {
    columns: columns,
    rows: getRows(),
  };

  const fetchData = useCallback(({ pageSize, pageIndex, search }: any) => {
    const fetchId = ++fetchIdRef.current;

    // We'll even set a delay to simulate a server here
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        setPageNo(pageIndex);
        setPageSize(pageSize);
        setSearch(search);
        dispatch(startLoading());
        dispatch(getDistrictList({ pageSize, pageIndex, search })).then(() =>
          dispatch(stopLoading()),
        );
      }
    }, 1000);
  }, []);

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href={route_info?.school_management?.districtCrud}
      onClick={() => handleBreadCrumbClick}
    >
      School Management
    </Link>,
    <Typography key="2" color="#C73E9D">
      District
    </Typography>,
  ];

  return (
    <>
      {/* <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        style={{ marginBottom: 20 }}
      >
        {breadcrumbs}
      </Breadcrumbs> */}
      {districtList?.items?.length <= 0 && !search && <AddFirstDistrict />}
      <Grid container spacing={4} sx={{ mt: 3, mb: 4 }}>
        {steps?.map((item) => {
          return (
            <Grid item md={3} sm={6} xs={12}>
              <Card sx={{ minHeight: '130px' }}>
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={item?.icon}
                      alt="line indication"
                      width={'100%'}
                    />
                  </Box>
                  <Typography
                    variant="body1"
                    sx={{
                      color: '#18191F',
                      fontSize: '16px',
                      lineHeight: '30px',
                      fontWeight: '600',
                      mt: 3,
                    }}
                  >
                    {item?.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: '13px',
                      fontWeight: '400',
                      lineHeight: '26px',
                      color: '#18191F',
                    }}
                  >
                    {item?.content}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      <Card sx={{ p: '24px' }}>
        <CustomTable
          table={tableData}
          fetchData={fetchData}
          canSearch
          title="Districts"
          buttonTitle="Add District"
          searchText="Search..."
          pageCount={pageCount}
          pageNo={pageNo}
          totalCount={totalCount}
          handleOnChange={handleAddUpdateDistrict}
          backgroundColor={'#fff'}
          color={'#979C9E'}
          isSorted={false}
        />
      </Card>

      {isOpen && (
        <AddUpdateDistrict
          open={isOpen}
          onClose={handleAddUpdateDistrict}
          editId={editId}
        />
      )}

      {openDelete && (
        <DeleteDistrict
          open={openDelete}
          deleteId={deleteId}
          onClose={handleDeleteDialog}
        />
      )}
      {screenLoading ? (
        <CircularProgress size={'20px'} sx={{ color: 'white' }} />
      ) : null}

      {isSchoolOpen && (
        <AddUpdateSchool open={isSchoolOpen} onClose={handleAddUpdateSchool} />
      )}
    </>
  );
}

export default Index;
