import { Button, Modal, rem } from '@mantine/core';
import useModal from 'components/Modal/hook/useModal';
import { useGetMyProfileQuery } from 'store/apis/profile';
import cn from 'utils/cn';

export default function ParentFreePlanStep1() {
  const { closeModal, isOpen, modal, openModal } = useModal<{
    onContinue?: () => void;
  }>();

  const { data } = useGetMyProfileQuery();

  return (
    // <Modal isOpen={isOpen('AskAGrownupModal')} onClose={closeModal}>
    //   <Modal.Content className="[--max-width:_45rem] [--top:_2rem]  md:[--top:_10%]">
    //     <Modal.CloseButton />
    <Modal
      opened={isOpen('ParentFreePlanStep1')}
      onClose={closeModal}
      closeOnClickOutside={false}
      closeOnEscape={false}
      size={'xl'}
      centered
      shadow="sm"
      radius={'lg'}
      classNames={{
        title:
          '!text-lg !font-semibold lg:!text-xl 2xl:!text-2xl !text-[#2C3E50]',
        close:
          '!text-[#2C3E50] !text-lg !font-semibold lg:!text-xl 2xl:!text-2xl',
      }}
      padding={rem(24)}
      zIndex={999999999}
      withCloseButton={false}
    >
      <div className="grid gap-4 lg:gap-6">
        <div className="grid gap-2 items-start justify-start align-top ">
          <h1
            className={cn(
              '!font-semibold max-sm:text-center !text-lg lg:!text-xl 2xl:!text-2xl text-[#131515]',
            )}
          >
            🎉 Welcome to G3MS, {data?.data?.name} {data?.data?.lastname}!
          </h1>
          <p
            className={cn(
              '!text-sm lg:!text-base xl:!text-lg 2xl:!text-xl text-[#131515]',
            )}
          >
            Thank you for joining G3MS to support child’s learning journey.
            Here’s what they can do with the Free Plan:
          </p>
        </div>

        <ul
          className={cn(
            '!text-sm lg:!text-base xl:!text-lg 2xl:!text-xl text-[#131515] space-y-1',
          )}
        >
          <li>✅ Access state-aligned Math lessons.</li>
          <li>✅ Earn tokens to customize their avatar.</li>
          <li>✅ Use the camera for interactive challenges.</li>
        </ul>

        <Button
          onClick={() => {
            openModal('AddStudentModal', {
              isFirstStudent: true,
              cache: true,
            });
          }}
          className="max-w-full !rounded-xl"
          color="#20A58A"
          size="xl"
          fw={500}
        >
          Add Your First Student
        </Button>
      </div>
    </Modal>

    // </Modal.Content>
    // </Modal>
  );
}
