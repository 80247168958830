import { useFormik } from 'formik';

import { Button, Modal, rem } from '@mantine/core';
import useModal from 'components/Modal/hook/useModal';
import cn from 'utils/cn';

import DateInputField from 'components/DateInputField';
import SelectInputField from 'components/SelectInputField';
import TextInputField from 'components/TextInputField';
import { Calendar } from 'iconsax-react';
import React from 'react';
import { toast } from 'react-toastify';
import { useGetGradeListQuery } from 'store/apis/hometab';
import { useGetMyProfileQuery } from 'store/apis/profile';
import { useGetSchoolsQuery } from 'store/apis/school';
import { useGetCitiesQuery, useGetStatesQuery } from 'store/apis/states-cities';
import { useAddUserMutation } from 'store/apis/user';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { useDispatch } from 'react-redux';
import { getChildList } from 'store/Thunk/ParentDashboardThunk';
import { useAppDispatch } from 'store/store';

const validationSchema = z.object({
  fistName: z.string().min(2, 'Too Short!'),
  lastName: z.string().min(2, 'Too Short!'),
  dob: z.date({ message: 'Invalid Date' }),
  grade: z.string({ message: 'Required' }),
  username: z.string({ message: 'Required' }),
  email: z.string().email('Invalid Email').optional(),
});

const validationSchemaWithSchool = validationSchema.extend({
  state: z.string({ message: 'Required' }),
  city: z.string({ message: 'Required' }),
  school: z.string({ message: 'Required' }),
});

export type AddStudentModalProps = z.infer<typeof validationSchema>;

export default function AddStudentModal() {
  const { closeModal, isOpen, modal, openModal } = useModal<{
    onContinue?: () => void;
    isFirstStudent?: boolean;
    withUpgradeSuccess?: boolean;
    withAddons?: boolean;
  }>();

  const { data: gradeList, isLoading } = useGetGradeListQuery();
  const { data } = useGetMyProfileQuery();

  const [addUserRequest, addUserResponse] = useAddUserMutation();

  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      fistName: '',
      lastName: '',
      dob: new Date(),
      grade: '',
      username: '',
      state: '',
      city: '',
      school: '',
      email: '',
    },
    validationSchema: data?.data?.schoolId
      ? toFormikValidationSchema(validationSchema)
      : toFormikValidationSchema(validationSchemaWithSchool),
    onSubmit: async (values, { setFieldError }) => {
      try {
        dispatch(getChildList());
        if (!data?.data?.id) {
          openModal('WarningModal', {
            title: 'Error',
            description: "Couldn't add student",
          });
          return;
        }

        console.log({
          type: 'student',
          name: values.fistName,
          lastname: values.lastName,
          username: values.username,
          dateOfBirth: values.dob,
          gradeId: values.grade,
          schoolId: values.school || data?.data?.schoolId,
          cityId: values.city || undefined,
          parentId: [data?.data?.id],
          email: values?.email || undefined,
          stateId: values.state || undefined,
        });

        await addUserRequest({
          type: 'student',
          name: values.fistName,
          lastname: values.lastName,
          username: values.username,
          dateOfBirth: values.dob,
          gradeId: values.grade,
          schoolId: values.school || data?.data?.schoolId,
          // cityId: values.city,
          parentId: [data?.data?.id],
          email: values.email,
          // stateId: values.state,
        }).unwrap();

        if (modal?.props?.onContinue) {
          modal.props.onContinue();
          return;
        }
        /* 
        if (data?.data?.accountSetup?.isEducatorAddOnIncluded) {
          openModal('AddAnotherClassRoomWarningModal', {
            ...formik.values,
            withAddons: data?.data?.accountSetup?.isEducatorAddOnIncluded,
            cache: true,
            withUpgradeSuccess: modal?.props?.withUpgradeSuccess,
          });
          return;
        } */

        /*         if (modal?.props?.withUpgradeSuccess) {
          openModal('ParentSchoolSettingsModal', {
            ...formik.values,
            cache: true,
            withUpgradeSuccess: modal?.props?.withUpgradeSuccess,
          });
          return;
        } */

        openModal('AddAnotherStudentWarningModal', {
          ...formik.values,
          withAddons: data?.data?.accountSetup?.isEducatorAddOnIncluded,
          cache: true,
          withUpgradeSuccess: modal?.props?.withUpgradeSuccess,
        });
      } catch (error: any) {
        toast.error(error?.data?.message || 'Something went wrong');

        console.log(error);
      }
    },
  });

  const gradedata = React.useMemo(() => {
    if (!gradeList) return [];
    return gradeList.data.map((grade) => ({
      value: grade.id,
      label: grade.name,
    }));
  }, [gradeList]);

  const {
    data: statesData,
    isLoading: statesLoading,
    error: statesError,
    isFetching: statesFetching,
  } = useGetStatesQuery({
    search: '',
  });

  const {
    data: citiesData,
    isLoading: citiesLoading,
    error: citiesError,
    isFetching: citiesFetching,
  } = useGetCitiesQuery(
    {
      search: '',
      stateId: formik.values.state,
    },
    {
      skip: formik.values.state === '' || formik.values.state === undefined,
    },
  );

  const {
    data: schoolsData,
    isLoading: schoolsLoading,
    isFetching: schoolsFetching,
    error: schoolsError,
  } = useGetSchoolsQuery(
    {
      search: '',
      stateId: formik.values.state,
      cityId: formik.values.city,
    },
    {
      skip: formik.values.city === '' || formik.values.city === undefined,
    },
  );

  const allCities = React.useMemo(() => {
    if (!citiesData) return [];

    return citiesData.data.map((city) => ({
      value: city.id,
      label: city.name,
    }));
  }, [citiesData]);

  const allStates = React.useMemo(() => {
    if (!statesData) return [];

    return statesData.data.map((state) => ({
      value: state.id,
      label: state.name,
    }));
  }, [statesData]);

  const allSchools = React.useMemo(() => {
    if (!schoolsData) return [];

    return schoolsData.data?.items?.map((school) => ({
      value: school.id,
      label: school.name,
    }));
  }, [schoolsData]);

  return (
    // <Modal isOpen={isOpen('AskAGrownupModal')} onClose={closeModal}>
    //   <Modal.Content className="[--max-width:_45rem] [--top:_2rem]  md:[--top:_10%]">
    //     <Modal.CloseButton />
    <Modal
      opened={isOpen('AddStudentModal')}
      onClose={closeModal}
      closeOnClickOutside={false}
      closeOnEscape={false}
      size={'xl'}
      centered
      shadow="sm"
      radius={'lg'}
      classNames={{
        title:
          '!text-lg !font-semibold lg:!text-xl 2xl:!text-2xl !text-[#2C3E50]',
        close:
          '!text-[#2C3E50] !text-lg !font-semibold lg:!text-xl 2xl:!text-2xl',
      }}
      padding={rem(24)}
      zIndex={999999999}
      withCloseButton={false}
    >
      <div className="grid gap-4 lg:gap-6">
        {data?.data?.type === 'parent' && modal?.props?.withUpgradeSuccess && (
          <div className="space-y-4 mb-6">
            <h1
              className={cn(
                '!font-semibold text-center !text-lg lg:!text-xl 2xl:!text-2xl text-[#131515]',
              )}
            >
              🎉 Upgrade Successful!
            </h1>
            <p
              className={cn(
                '!text-sm lg:!text-base xl:!text-lg 2xl:!text-xl text-[#131515] text-center',
              )}
            >
              You've unlocked more learning opportunities for your child! Now,
              personalize their learning journey to maximize their success.
            </p>
          </div>
        )}
        <div className="space-y-2">
          <h1
            className={cn(
              '!font-semibold text-center !text-lg lg:!text-xl 2xl:!text-2xl text-[#131515]',
            )}
          >
            Add a student
          </h1>
        </div>

        <form
          onSubmit={formik.handleSubmit}
          className="flex flex-col lg:grid gap-4 lg:!grid-cols-2"
        >
          <TextInputField
            label="First Name"
            placeholder="Enter First Name"
            {...formik.getFieldProps('fistName')}
            error={formik.touched.fistName && formik.errors.fistName}
          />
          <TextInputField
            label="Last Name"
            placeholder="Enter Last Name"
            {...formik.getFieldProps('lastName')}
            error={formik.touched.lastName && formik.errors.lastName}
          />

          <DateInputField
            label="Date of Birth"
            placeholder="Enter Date of Birth"
            value={formik.values.dob as Date}
            onChange={(value: any) =>
              formik.setFieldValue('dob', value as Date)
            }
            onBlur={() => formik.setFieldTouched('dob', true)}
            error={
              formik.touched.dob && typeof formik.errors.dob === 'string'
                ? formik.errors.dob
                : undefined
            }
            rightSection={<Calendar />}
          />
          <SelectInputField
            label="Grade/Subject"
            placeholder="Select Grade/Subject"
            data={gradedata}
            disabled={isLoading}
            onChange={(value) => formik.setFieldValue('grade', value)}
            value={formik.values.grade}
            error={formik.touched.grade && formik.errors.grade}
            onBlur={() => formik.setFieldTouched('grade', true)}
          />

          {/*  <TextInputField
            label={
              <div className="flex items-center justify-between w-full space-x-8">
                <span className="block !text-sm lg:!text-base !text-[#484848] ">
                  School/Company Code
                </span>
                <span className="text-[#0038AB] font-medium underline cursor-pointer">
                  Change School
                </span>
              </div>
            }
            placeholder="Enter school/company code"
            {...formik.getFieldProps('schoolCode')}
            error={formik.errors.schoolCode}
          /> */}

          <div className="col-span-2 flex flex-col gap-4">
            <TextInputField
              label="Username"
              placeholder="Enter Username"
              {...formik.getFieldProps('username')}
              error={formik.touched.username && formik.errors.username}
            />
            <TextInputField
              label="Email (Optional)"
              placeholder="Enter Email"
              {...formik.getFieldProps('email')}
              error={formik.touched.email && formik.errors.email}
            />
          </div>

          {!data?.data?.schoolId && (
            <>
              <SelectInputField
                label="State"
                placeholder="Select State"
                data={allStates}
                disabled={statesLoading}
                onChange={(value) => {
                  formik.setFieldValue('state', value);
                  formik.setFieldValue('city', '');
                  formik.setFieldValue('school', '');
                }}
                value={formik.values.state}
                error={formik.touched.state && formik.errors.state}
                onBlur={() => formik.setFieldTouched('state', true)}
              />

              {formik.values.state && (
                <SelectInputField
                  label="City"
                  placeholder="Select City"
                  data={allCities}
                  disabled={citiesLoading}
                  onChange={(value) => {
                    formik.setFieldValue('city', value);
                    formik.setFieldValue('school', '');
                  }}
                  value={formik.values.city}
                  error={formik.touched.city && formik.errors.city}
                  onBlur={() => formik.setFieldTouched('city', true)}
                />
              )}

              {formik.values.city && (
                <SelectInputField
                  label="School"
                  placeholder="Select School"
                  data={allSchools}
                  disabled={schoolsLoading}
                  onChange={(value) => formik.setFieldValue('school', value)}
                  value={formik.values.school}
                  error={formik.touched.school && formik.errors.school}
                  onBlur={() => formik.setFieldTouched('school', true)}
                />
              )}
            </>
          )}

          <Button
            className="max-w-full !rounded-xl col-span-2"
            color="#20A58A"
            size="xl"
            fw={500}
            type="submit"
            loading={addUserResponse.isLoading}
          >
            {'Add Student'}
          </Button>
        </form>
      </div>
    </Modal>

    // </Modal.Content>
    // </Modal>
  );
}
