import { Button, Modal, rem } from '@mantine/core';
import useModal from 'components/Modal/hook/useModal';
import localforage from 'localforage';
import { useFinishAccountSetupMutation } from 'store/apis/account-setup';
import cn from 'utils/cn';

export default function ParentPremiumPlan() {
  const { closeModal, isOpen, modal, openModal } = useModal<{
    onContinue?: () => void;
  }>();

  const [finishAccountSetupRequest, finishAccountSetupResponse] =
    useFinishAccountSetupMutation();

  return (
    // <Modal isOpen={isOpen('AskAGrownupModal')} onClose={closeModal}>
    //   <Modal.Content className="[--max-width:_45rem] [--top:_2rem]  md:[--top:_10%]">
    //     <Modal.CloseButton />
    <Modal
      opened={isOpen('ParentPremiumPlan')}
      onClose={closeModal}
      closeOnClickOutside={false}
      closeOnEscape={false}
      size={'lg'}
      centered
      shadow="sm"
      radius={'lg'}
      classNames={{
        title:
          '!text-lg !font-semibold lg:!text-xl 2xl:!text-2xl !text-[#2C3E50]',
        close:
          '!text-[#2C3E50] !text-lg !font-semibold lg:!text-xl 2xl:!text-2xl',
      }}
      padding={rem(24)}
      zIndex={999999999}
      withCloseButton={false}
    >
      <div className="flex flex-col gap-4 xl:gap-8">
        <div className="grid gap-2 lg:gap-4 justify-start align-top items-center max-sm:place-items-center">
          <h1
            className={cn(
              '!font-semibold !text-lg md:!text-xl 2xl:!text-2xl text-[#131515] max-sm:text-center',
            )}
          >
            🎉 Welcome to G3MS Premium!
          </h1>
        </div>

        <p
          className={cn(
            '!text-sm lg:!text-base xl:!text-lg 2xl:!text-xl text-black font-bold',
          )}
        >
          Thank you for choosing G3MS to empower learning journeys! With your
          Premium subscription, learners get access to:
        </p>

        <ul
          className={cn(
            '!text-sm lg:!text-base xl:!text-lg 2xl:!text-xl text-[#131515] space-y-1 list-disc pl-8',
          )}
        >
          <li>
            <span className="text-black font-bold">🧠 AI Tutor:</span>{' '}
            Personalized recommendations to close learning gaps and build
            future-ready skills.
          </li>
          <li>
            <span className="text-black font-bold">🎁 Unlimited Rewards:</span>{' '}
            Continue redeeming tokens for gift cards and fun prizes.
          </li>
          <li>
            <span className="text-black font-bold">
              📚 Access All Subjects:
            </span>{' '}
            ELA, Science, Social Studies, Math, Test Prep, and more
          </li>
        </ul>

        <p
          className={cn(
            '!text-sm lg:!text-base xl:!text-lg 2xl:!text-xl text-black font-bold ',
          )}
        >
          Your investment in G3MS ensures your child is learning what matters
          and earning what counts!
        </p>

        <div className="w-full grid">
          <Button
            onClick={async () => {
              await finishAccountSetupRequest();
              await localforage.removeItem('modal');
              closeModal();
            }}
            className="max-w-full !rounded-xl w-full"
            color="#20A58A"
            size="xl"
            fw={500}
          >
            Get Started
          </Button>
        </div>
      </div>
    </Modal>

    // </Modal.Content>
    // </Modal>
  );
}
