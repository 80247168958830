import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import useModal from 'components/Modal/hook/useModal';
import AddStudentComponent from 'pages/parent/components/AddStudent/AddStudentComponent';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};
function AddStudent() {
  const { closeModal, openModal, isOpen } = useModal();

  return (
    <div>
      <Modal
        open={isOpen('AddStudent')}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="innerboxShadow lg:w-[40%] w-[70%] max-h-[90%] overflow-y-scroll"
          style={{
            position: 'relative',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: 3,
            backgroundColor: '#fff',
            boxShadow: 'shadow-inner-2xl',
            alignItems: 'center',
            borderRadius: 10,
          }}
        >
          <div
            role="button"
            onClick={closeModal}
            className=" flex md:flex lg:flex justify-end pr-4 pt-4"
          >
            <img
              alt=""
              src={require('../../assets/Parent/cross.png')}
              style={{ height: 8, width: 8 }}
            />
          </div>

          <div className="mx-4">
            <Typography
              sx={{
                fontSize: 17,
                fontWeight: 700,
                //textAlign: 'center',
              }}
              color={'#2C3E50'}
            >
              Add a student
            </Typography>
          </div> 

          <div className="px-4">
            <AddStudentComponent type="student" />
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default AddStudent;
