import useModal from 'components/Modal/hook/useModal';
import config from 'config/config';
import localforage from 'localforage';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLazyVerifyTokenQuery } from 'store/apis/auth';
import { useLazyGetMyProfileWithTokenQuery } from 'store/apis/profile';
import { AccountSetup } from 'store/apis/profile/types';
import { setLoginUser } from 'store/Slice/authSlice';

type TQueryParams =
  | {
      redirectTo?: string;
      token?: string | undefined;
    }
  | {
      showWelcomeModal: string;
      modalType?:
        | 'parent-free'
        | 'parent-no-addon'
        | 'parent-addon'
        | 'educator-free'
        | 'educator-premium';
      token?: string;
    };

// remove last / from the url
const STUDENT_BASE_URL = config.STUDENT_BASE_URL?.replace(/\/$/, '');

export default function useAuth() {
  const [verifyToken, veryFyTokenRes] = useLazyVerifyTokenQuery();
  const dispatch = useDispatch();
  const { openModal } = useModal();

  const [getMyProfileWithToken, getMyProfileWithTokenRes] =
    useLazyGetMyProfileWithTokenQuery();

  const navigate = useNavigate();

  const handleShowWelcomeModal = async (
    modalType: string,
    accountSetup?: AccountSetup,
  ) => {
    const localModal = await localforage.getItem('modal');
    //
    if (modalType && !localModal) {
      switch (modalType) {
        case 'parent-free':
          console.log('parent-free');
          if (accountSetup?.isAccountSetupCompleted) {
            return;
          }
          openModal('ParentFreePlanStep1', {
            cache: true,
          });
          break;
        case 'parent-no-addon':
          if (!accountSetup?.students?.length) {
            openModal('AddStudentModal', {
              withUpgradeSuccess: true,
              isFirstStudent: true,
              cache: true,
            });
            return;
          }

          if (accountSetup?.parents?.length < 2) {
            openModal('AddParentModal', {
              withUpgradeSuccess: true,
              cache: true,
            });
            return;
          }

          if (!accountSetup?.isAcademicContentsSelected) {
            openModal('ParentSelectAcademic', {
              withUpgradeSuccess: true,
              cache: true,
            });
            return;
          }

          if (!accountSetup?.isSocialContentsSelected) {
            openModal('ParentSelectSocialEmotion', {
              withUpgradeSuccess: true,
              cache: true,
            });
            return;
          }

          openModal('ParentPremiumPlan', {
            withUpgradeSuccess: true,
            cache: true,
          });

          break;
        case 'parent-addon':
          if (!accountSetup?.educatorClassrooms?.length) {
            openModal('AddHomeSchoolClassRoomModal', {
              withUpgradeSuccess: true,
              withAddons: true,
              cache: true,
            });
            return;
          }

          if (!accountSetup?.students?.length) {
            openModal('AddStudentModal', {
              withUpgradeSuccess: true,
              isFirstStudent: true,
              cache: true,
            });
            return;
          }

          if (accountSetup?.parents?.length < 2) {
            openModal('AddParentModal', {
              withUpgradeSuccess: true,
              cache: true,
            });
            return;
          }

          if (!accountSetup?.isAcademicContentsSelected) {
            openModal('ParentSelectAcademic', {
              withUpgradeSuccess: true,
              cache: true,
            });
            return;
          }

          if (!accountSetup?.isSocialContentsSelected) {
            openModal('ParentSelectSocialEmotion', {
              withUpgradeSuccess: true,
              cache: true,
            });
            return;
          }

          if (accountSetup?.schoolDetails?.school_settings?.length === 0) {
            openModal('ParentSchoolSettingsModal', {
              withUpgradeSuccess: true,
              cache: true,
            });
            return;
          }

          openModal('ParentPremiumPlan', {
            withUpgradeSuccess: true,
            cache: true,
          });

          break;
        case 'educator-free':
          if (accountSetup?.isAccountSetupCompleted) {
            return;
          }
          openModal('EducatorWelcome', {
            cache: true,
          });
          break;
        case 'educator-premium':
          openModal('EducatorWelcome', {
            cache: true,
            withUpgradeSuccess: true,
          });
          break;
        default:
          break;
      }
    }
  };

  const loginUser = async (
    query: TQueryParams & { token?: string },
    preservePath?: boolean,
  ) => {
    const tokenData = query.token || undefined;

    try {
      let token = tokenData || localStorage.getItem('token') || undefined;

      if (!token) {
        navigate('/login');
        return;
      }

      const response = await verifyToken(token).unwrap();
      const user = response.data;
      localStorage.setItem('teacherSchool', user?.school?.id || '');

      if (!user) {
        localStorage.clear();
        navigate('/login');
        return;
      }

      const profileResponse = await getMyProfileWithToken(user.token).unwrap();
      const profileData = profileResponse.data;

      dispatch(setLoginUser(user));
      localStorage.setItem('userData', JSON.stringify(user));
      localStorage.setItem('token', token);

      if (
        preservePath &&
        window.location.pathname !== '/login' &&
        window.location.pathname !== '/'
      ) {
        const href = window.location.href;
        const path = href.replace(window.location.origin, '');
        navigate(path);
        return;
      }

      const queryParams = queryString.stringify({
        ...query,
        token: undefined,
        modalType: undefined,
        showWelcomeModal: undefined,
      });

      if (user.type !== 'student' && user.type !== 'parent') {
        if (
          (query && 'redirectTo' in query && query.redirectTo === 'plans') ||
          (query && 'redirectTo' in query && query.redirectTo === '/plans')
        ) {
          navigate(`/plans`);
          return;
        }
      }

      switch (user.type) {
        case 'student':
          window.location.href = `${STUDENT_BASE_URL}/auth/token-login?${queryParams}`;
          break;
        case 'teacher':
        case 'private_tutor':
        case 'tutor':
        case 'homeschool_parent_esa':
          console.log(`/teacher/dashboard?${queryParams}`);
          navigate(`/teacher/dashboard?${queryParams}`);
          // window.location.href = `${ADMIN_URL}/school/${user.school.id}?token=${token}`;
          break;
        case 'parent':
          console.log(STUDENT_BASE_URL);
          if (
            (query && 'redirectTo' in query && query.redirectTo === 'plans') ||
            (query && 'redirectTo' in query && query.redirectTo === '/plans')
          ) {
            window.location.href = `${STUDENT_BASE_URL}/auth/token-login?${queryParams}&token=${token}`;
            return;
          }
          navigate(`/parent/dashboard?${queryParams}`);
          break;
        case 'school_leader':
        case 'learning_coach':
          console.log(`/school-leader/dashboard?${queryParams}`);
          navigate(`/school-leader/dashboard?${queryParams}`);
          break;
        case 'super_admin':
          // window.location.href = `${ADMIN_URL}/super-admin/dashboard?${queryParams}`;
          navigate(`/super-admin/dashboard?${queryParams}`);
          break;
        default:
          // window.location.href = `${ADMIN_URL}/super-admin/dashboard?${queryParams}`;
          navigate(`/district-leader/dashboard?${queryParams}`);

          break;
      }
      if (query && 'showWelcomeModal' in query) {
        const localModalType = await localforage.getItem('modalType');
        if (localModalType !== query.modalType) {
          await localforage.clear();
        } else {
          await localforage.setItem('modalType', query.modalType);
        }

        if (profileData?.type === 'super_admin') {
          return;
        }

        handleShowWelcomeModal(
          query.modalType || '',
          profileData?.accountSetup,
        );
      }
    } catch (error) {
      localStorage.clear();
      navigate('/login');
    }
  };

  const redirectToLogin = () => {
    window.location.href = `${STUDENT_BASE_URL}/login`;
  };

  return { loginUser, redirectToLogin, isLoading: veryFyTokenRes.isLoading };
}
