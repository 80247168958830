import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Header from './Header';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
const renderColorfulLegendText = (value: string, entry: any) => {
  const { color } = { color: 'black' };

  return <span style={{ color }}>{value}</span>;
};

const style = {
  lineHeight: '16px',
  color: 'black',
  fontSize: '10px',
};
const ITEM_HEIGHT = 48;
function TierSupport({ tierData, options }: { tierData?: any; options?: any }) {
  const [finalData, setFinaldata] = useState<any>([]);
  const [isTierData, setIsTierData] = useState(false);
  const [selectedValue, setSelectedValue] = React.useState(options?.[0]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (option: any) => {
    setSelectedValue(option);
    setAnchorEl(null);
  };
  console.log('tierData', tierData);
  useEffect(() => {
    dataManupulateByTopic(tierData);
    // setSelectedValue(options?.[0])
  }, [tierData, selectedValue]);

  const dataManupulate = (tierData: any) => {
    // teir 1 data
    let Tier1Exceeding = 0;
    let Tier1Meeting = 0;
    let Tier1Approaching = 0;
    let Tier1Emerging = 0;

    let Tier2Exceeding = 0;
    let Tier2Meeting = 0;
    let Tier2Approaching = 0;
    let Tier2Emerging = 0;

    let Tier3Exceeding = 0;
    let Tier3Meeting = 0;
    let Tier3Approaching = 0;
    let Tier3Emerging = 0;
    tierData?.forEach((item: any) => {
      if (item.tier1 < 60) {
        Tier1Emerging = Tier1Emerging + item.tier1;
      } else if (item.tier1 < 75) {
        Tier1Approaching = Tier1Approaching + item.tier1;
      } else if (item.tier1 < 90) {
        Tier1Meeting = Tier1Meeting + item.tier1;
      } else if (item.tier1 < 101) {
        Tier1Exceeding = Tier1Exceeding + item.tier1;
      }

      // teir 2 data
      if (item.tier2 < 60) {
        Tier2Emerging = Tier2Emerging + item.tier2;
      } else if (item.tier2 < 75) {
        Tier2Approaching = Tier2Approaching + item.tier2;
      } else if (item.tier2 < 90) {
        Tier2Meeting = Tier2Meeting + item.tier2;
      } else if (item.tier2 < 101) {
        Tier2Exceeding = Tier2Exceeding + item.tier2;
      }
      // teir 3 data

      if (item.tier3 < 60) {
        Tier3Emerging = Tier3Emerging + item.tier3;
      } else if (item.tier3 < 75) {
        Tier3Approaching = Tier3Approaching + item.tier3;
      } else if (item.tier3 < 90) {
        Tier3Meeting = Tier3Meeting + item.tier3;
      } else if (item.tier3 < 101) {
        Tier3Exceeding = Tier3Exceeding + item.tier3;
      }
    });
    const data = [
      {
        name: 'Tier 1',
        Exceeding: Tier1Exceeding,
        Meeting: Tier1Meeting,
        Approaching: Tier1Approaching,
        Emerging: Tier1Emerging,
      },
      {
        name: 'Tier 2',
        Exceeding: Tier2Exceeding,
        Meeting: Tier2Meeting,
        Approaching: Tier2Approaching,
        Emerging: Tier2Emerging,
      },
      {
        name: 'Tier 3',
        Exceeding: Tier3Exceeding,
        Meeting: Tier3Meeting,
        Approaching: Tier3Approaching,
        Emerging: Tier3Emerging,
      },
    ];
    setFinaldata(data);
  };
  const dataManupulateByTopic = (tierData: any) => {
    // teir 1 data
    // let Tier1Exceeding = 0;
    // let Tier1Meeting = 0;
    // let Tier1Approaching = 0;
    // let Tier1Emerging = 0;

    // let Tier2Exceeding = 0;
    // let Tier2Meeting = 0;
    // let Tier2Approaching = 0;
    // let Tier2Emerging = 0;

    // let Tier3Exceeding = 0;
    // let Tier3Meeting = 0;
    // let Tier3Approaching = 0;
    // let Tier3Emerging = 0;
    // let isData=false;
    // tierData?.forEach((item: any) => {
    //   if(item.title===selectedValue){

    //   if (item.tier1 >0 && item.tier1 < 60) {

    //     isData=true;
    //     Tier1Emerging = Tier1Emerging + item.tier1;
    //   } else if (item.tier1 >59 &&item.tier1 < 75) {
    //     isData=true;
    //     Tier1Approaching = Tier1Approaching + item.tier1;
    //   } else if (item.tier1 >74 &&item.tier1 < 90) {
    //     isData=true;
    //     Tier1Meeting = Tier1Meeting + item.tier1;
    //   } else if (item.tier1 >89 &&item.tier1 < 101) {
    //     isData=true;
    //     Tier1Exceeding = Tier1Exceeding + item.tier1;
    //   }

    //   // teir 2 data
    //   if (item.tier2 >0 && item.tier2 < 60) {
    //     isData=true;
    //     Tier2Emerging = Tier2Emerging + item.tier2;
    //   } else if (item.tier2 >59 &&item.tier2 < 75) {
    //     isData=true;
    //     Tier2Approaching = Tier2Approaching + item.tier2;
    //   } else if (item.tier2 >74 &&item.tier2 < 90) {
    //     isData=true;
    //     Tier2Meeting = Tier2Meeting + item.tier2;
    //   } else if (item.tier2 >89 &&item.tier2 < 101) {
    //     isData=true;
    //     Tier2Exceeding = Tier2Exceeding + item.tier2;
    //   }
    //   // teir 3 data

    //   if (item.tier3 >0 && item.tier3 < 60) {
    //     isData=true;
    //     Tier3Emerging = Tier3Emerging + item.tier3;
    //   } else if (item.tier3 >59 &&item.tier3 < 75) {
    //     isData=true;
    //     Tier3Approaching = Tier3Approaching + item.tier3;
    //   } else if (item.tier3 >74 &&item.tier3 < 90) {
    //     isData=true;
    //     Tier3Meeting = Tier3Meeting + item.tier3;
    //   } else if (item.tier3 >89 &&item.tier3 < 101) {
    //     isData=true;
    //     Tier3Exceeding = Tier3Exceeding + item.tier3;
    //   }
    // }
    // });
let isData= false
    if (
      tierData?.teir1?.exceedings===null &&
      tierData?.teir1?.meetings===null &&
      tierData?.teir1?.approchings===null &&
      tierData?.teir1?.emergings===null &&
      tierData?.teir2?.exceedings===null &&
      tierData?.teir2?.meetings===null &&
      tierData?.teir2?.approchings===null &&
      tierData?.teir2?.emergings===null &&
      tierData?.teir3?.exceedings===null &&
      tierData?.teir3?.meetings===null &&
      tierData?.teir3?.approchings===null &&
      tierData?.teir3?.emergings===null
    ) {
      isData=false
    } else {
     isData=true
    }
    const data = [
      {
        name: 'Tier 1',
        Exceeding: tierData?.teir1?.exceedings,
        Meeting: tierData?.teir1?.meetings,
        Approaching: tierData?.teir1?.approchings,
        Emerging: tierData?.teir1?.emergings,
      },
      {
        name: 'Tier 2',
        Exceeding: tierData?.teir2?.exceedings,
        Meeting: tierData?.teir2?.meetings,
        Approaching: tierData?.teir2?.approchings,
        Emerging: tierData?.teir2?.emergings,
      },
      {
        name: 'Tier 3',
        Exceeding: tierData?.teir3?.exceedings,
        Meeting: tierData?.teir3?.meetings,
        Approaching: tierData?.teir3?.approchings,
        Emerging: tierData?.teir3?.emergings,
      },
    ];
setIsTierData(isData)
    // setIsTierData(isData);
    setFinaldata(data);
  };

  return (
    <Box className="border border-[#EAECF0] rounded-lg p-2 mt-2 mx-1">
      <div className="flex items-center justify-between">
        <div>
          <Header text="Proficiency vs RTI Tier Support" />
        </div>
      </div>
      {isTierData ? (
        <div className="mt-2 mb-2">
          <BarChart
            width={300}
            height={150}
            data={finalData}
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <XAxis
              axisLine={false}
              tickLine={false}
              label={{ offset: 0 }}
              dataKey="name"
              style={{
                fontSize: '12px',
                color: 'black',
              }}
            />

            <Tooltip />
            <Legend
              verticalAlign="bottom"
              iconType="circle"
              align="center"
              layout={'horizontal'}
              height={12}
              iconSize={10}
              wrapperStyle={style}
              formatter={renderColorfulLegendText}
            />
            <Bar dataKey="Exceeding" stackId="a" fill="#3498DB" />
            <Bar dataKey="Meeting" stackId="a" fill="#20A58A" />
            <Bar dataKey="Approaching" stackId="a" fill="#FFD700" />
            <Bar dataKey="Emerging" stackId="a" fill="#FF6B6B" />
          </BarChart>
        </div>
      ) : (
        <div>
          <div className="text-[16px] font-semibold mt-2">📊 No Data Yet</div>
          <div className="text-[13px] font-normal mt-1">
            This chart will show proficiency levels as students complete
            assignments, quizzes, and tests.
          </div>
        </div>
      )}
    </Box>
  );
}

export default TierSupport;
