import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  Typography,
  Divider,
  Card,
  Avatar,
  IconButton,
  Stack,
  Link,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { getClassroomsOfStudent } from 'store/Thunk/classroomThunk';
import { useAppDispatch, useAppSelector } from 'store/store';

import style from './view.module.scss';
import SimpleTable from 'components/simpleTable/simpleTable';
import { DeleteBorderIcon, EditBorderIcon, EditIcon } from 'icons';
import { ClassItem, TypoItem } from './macros';
import { getUserData } from 'store/Thunk/user';
import ViewLoaderBox from './loader';
import { getLinkPartFromRole, getUser } from 'helper/functions';
import { setUser } from 'store/Slice/userSlice';
import DeleteUserDialog from 'components/dialog/users/Delete/deleteUser';

import { getUserListAccordingToType } from 'store/Thunk/userThunk';
import { deleteStudent } from 'store/Thunk/studentThunk';
import { Roles } from 'constants/consts';
import { route_info } from 'navigation/route';
import { useGetMyProfileQuery } from 'store/apis/profile';
import { setBreadCrumbsData } from 'store/Thunk/ParentEducatorDashboardThunk';

function Student() {
  const dispatch = useAppDispatch();
  const { user, loading } = useAppSelector((state) => state.userReducer);
  console.log('user', user);
  const [classList, setClassList] = useState([]);
   useEffect(() => {
      if (user) {
        dispatch(setBreadCrumbsData(breadcrumbs));
      }
    }, [user]);
 const {data} =useGetMyProfileQuery()
  const navigate = useNavigate();
  const initial = getLinkPartFromRole();
 const { selectedRoute } = useAppSelector(
    (state: any) => state.ParentEducatorReducer,
  );
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [studentId] = useState(queryParams.get('id'));
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedUserToDelete, setSelectedUserToDelete] = useState({
    id: user.id,
    name: user.name,
  });
  useEffect(() => {
    if (studentId) {
      dispatch(getUserData(studentId));
    }
    return () => {
      dispatch(setUser([]));
    };
  }, []);
   function handleBreadCrumbClick(
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) {
      event.preventDefault();
      console.info('You clicked a breadcrumb.');
    }
  const { type } = getUser();
  const breadcrumbs =
    type === Roles.DISTRICT_LEADER && selectedRoute === 'User Management'
      ? [
          <Link
            underline="hover"
            key="1"
            color="inherit"
            href={route_info.user_manage}
            onClick={() => handleBreadCrumbClick}
          >
            User Management
          </Link>,
          <Typography key="3" color="black">
            Students
          </Typography>,
          // <Link  underline="hover"
          // key="1"
          // color="inherit"
          // href={route_info?.district_management_school.replace(
          //   ':id',
          //   data?.data?.school?.ncesDistrictId || ""
          // )}
          // onClick={() => handleBreadCrumbClick}>
          //   {districtID.length > 0
          //     ? districtID[0]?.name || districtID[0]?.ncesDistrictId
          //     : 'District'}
          // </Link>,
          <Box
           className="actions-left"
           sx={{
             ml: 2,
             alignItems: 'center',
             background: '#4CBFA71A',
             justifyContent: 'center',
             paddingX: 3,
             paddingY: 1,
             borderRadius: 0.6,
           }}
         >
           <Typography
             className="title_text"
             style={{ fontSize: '0.8rem', fontWeight: '600' }}
             color={'#20A58A'}
           >
          {user?.name}
           </Typography>
         </Box>
        
        ]
      : type === Roles.DISTRICT_LEADER
      ? [
          <Link
            underline="hover"
            key="1"
            color="inherit"
            href={route_info?.district_management_school.replace(
              ':id',
              data?.data?.school?.ncesDistrictId || '',
            )}
            onClick={() => handleBreadCrumbClick}
          >
            District Management
          </Link>,
          <Link  underline="hover"
          key="1"
          color="inherit"
          href={route_info?.district_management_school.replace(
            ':id',
            data?.data?.school?.ncesDistrictId || ""
          )}
         >
            {user?.school?.state}
          </Link>,
          <Link
            underline="hover"
            key="1"
            color="inherit"
            //href={`district/${data?.data?.school?.ncesDistrictId}/school/${user?.school?.ncesSchoolId}`}
            onClick={() => 
              navigate(route_info?.school_management.school_details.replace(
                                                ':id',
                                                user?.school?.ncesSchoolId,                                 
                                              ))
            }
          >
            {user?.school?.name}
          </Link>,
          <Link
            underline="hover"
            key="1"
            color="inherit"
            onClick={() => navigate(-1)}
          >
            {user?.classrooms?.length > 0 &&
              user?.classrooms[0]?.classroom?.name}
          </Link>,
           <Box
           className="actions-left"
           sx={{
             ml: 2,
             alignItems: 'center',
             background: '#4CBFA71A',
             justifyContent: 'center',
             paddingX: 3,
             paddingY: 1,
             borderRadius: 0.6,
           }}
         >
           <Typography
             className="title_text"
             style={{ fontSize: '0.8rem', fontWeight: '600' }}
             color={'#20A58A'}
           >
          {user?.name}
           </Typography>
         </Box>
        ]
      :
      type === Roles.SCHOOL_LEADER && selectedRoute === 'User Management'
      ? [
          <Link
            underline="hover"
            key="1"
            color="inherit"
            href={route_info.user_manage}
            onClick={() => handleBreadCrumbClick}
          >
            User Management
          </Link>,
          <Typography key="2" color="black">
            Students
          </Typography>,
          // <Link  underline="hover"
          // key="1"
          // color="inherit"
          // href={route_info?.district_management_school.replace(
          //   ':id',
          //   data?.data?.school?.ncesDistrictId || ""
          // )}
          // onClick={() => handleBreadCrumbClick}>
          //   {districtID.length > 0
          //     ? districtID[0]?.name || districtID[0]?.ncesDistrictId
          //     : 'District'}
          // </Link>,
          <Box
          className="actions-left"
          sx={{
            ml: 2,
            alignItems: 'center',
            background: '#4CBFA71A',
            justifyContent: 'center',
            paddingX: 3,
            paddingY: 1,
            borderRadius: 0.6,
          }}
        >
          <Typography
            className="title_text"
            style={{ fontSize: '0.8rem', fontWeight: '600' }}
            color={'#20A58A'}
          >
          {user?.name}
          </Typography>
        </Box>
          
        ]
      : type === Roles.SCHOOL_LEADER
      ? [
          <Link
            underline="hover"
            key="1"
            color="inherit"
            //href={`district/${data?.data?.school?.ncesDistrictId}/school/${user?.school?.ncesSchoolId}`}
            onClick={() => 
              navigate(route_info?.school_management.school_details.replace(
                                                ':id',
                                                user?.school?.ncesSchoolId,                                 
                                              ))
            }
          >
            {user?.school?.name}
          </Link>,
          <Link
            underline="hover"
            key="1"
            color="inherit"
            onClick={() => navigate(-1)}
          >
            {user?.classrooms?.length > 0 &&
              user?.classrooms[0]?.classroom?.name}
          </Link>,
          <Box
                  className="actions-left"
                  sx={{
                    ml: 2,
                    alignItems: 'center',
                    background: '#4CBFA71A',
                    justifyContent: 'center',
                    paddingX: 3,
                    paddingY: 1,
                    borderRadius: 0.6,
                  }}
                >
                  <Typography
                    className="title_text"
                    style={{ fontSize: '0.8rem', fontWeight: '600' }}
                    color={'#20A58A'}
                  >
                  {user?.name}
                  </Typography>
                </Box>
         
        ]:
        [
          <Link
            underline="hover"
            key="1"
            color="inherit"
            onClick={() => navigate(-1)}
          >
            All Classes
            {/* {user?.classroom.length > 0 &&
              user?.classroom[0]?.classroom?.name} */}
          </Link>,
          <Box
          className="actions-left"
          sx={{
            ml: 2,
            alignItems: 'center',
            background: '#4CBFA71A',
            justifyContent: 'center',
            paddingX: 3,
            paddingY: 1,
            borderRadius: 0.6,
          }}
        >
          <Typography
            className="title_text"
            style={{ fontSize: '0.8rem', fontWeight: '600' }}
            color={'#20A58A'}
          >
          {user?.classroom?.length > 0 && user?.name}
          </Typography>
        </Box>
        ];
  const getRows = () => {
    const data = user?.classroom?.map((classroom: any) => {
      const classroomName = classroom?.classroom?.name || '-';

      const teachers =
        classroom?.classroom?.ClassroomTeacher?.map((teacher: any) => ({
          Name: teacher.user.name || '-',
          Phone: teacher.user.phone || '-',
          Subject: teacher.user.subject || '-',
          Class: classroomName,
        })) || [];

      return teachers;
    });

    const flattenData = data ? data.flat() : [];
    return flattenData;
  };

  const handleEditClick = () => {
    const url = `/school-leader/user-management/edit?mode=student&id=${studentId}`;
    navigate(url);
  };

  if (loading) {
    return <ViewLoaderBox allowShadow />;
  }
  const fetchTableData = () => {
    dispatch(
      getUserListAccordingToType({
        pageSize: 10,
        pageIndex: 0,
        search: '',
        userType: 'student',
      }),
    );
  };
  const handleDeleteUser = () => {
    setDeleteLoading(true);
    dispatch(deleteStudent(selectedUserToDelete.id))
      .then(() => {
        fetchTableData();
        navigate(`/school-leader/user-management?tab=2`);
      })
      .finally(() => {
        setOpenDeleteModal(false);
        setDeleteLoading(false);
        setSelectedUserToDelete({
          id: '',
          name: '',
        });
      });
  };
  return (
    <Stack sx={{ p: 4, position: 'relative' }} spacing={2}>
      <Grid sx={{ position: 'relative' }} container spacing={2}>
        <Card
          sx={{
            p: 7,
            gap: '10px',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box className={style.content_wrapper}>
            {user?.profileImage ? (
              <img
                className={style.profile_img}
                alt={user?.name}
                src={user?.profileImage}
              />
            ) : (
              <Avatar sx={{ width: 120, height: 120, alignitems: 'center' }} />
            )}
            <div className={style.profile_detail_wrapper}>
              <Box className={style.profile_detail}>
                <Typography
                  sx={{ color: '#20A58A', fontWeight: 700, fontSize: '23px' }}
                >
                  {user?.name}
                </Typography>
                <Typography sx={{ display: '13.48px' }}>
                  (ID: {user?.id})
                </Typography>
              </Box>
              <Box className={style.info}>
                <TypoItem title="Email" detail={user?.email} />
                <TypoItem title="Grade" detail={user?.grade_?.name} />
                <TypoItem title="Parent" detail={user?.parent?.name} />
                <TypoItem
                  title="School Name"
                  detail={user?.school?.name || '-'}
                />
              </Box>
            </div>
            <Box
              sx={{ display: 'flex', position: 'absolute', top: 30, right: 30 }}
            >
              <IconButton onClick={() => handleEditClick()}>
                <EditBorderIcon />
              </IconButton>

              <IconButton
                onClick={() => {
                  setOpenDeleteModal(true);
                  setSelectedUserToDelete({
                    id: user.id,
                    name: user.name,
                  });
                }}
              >
                <DeleteBorderIcon />
              </IconButton>
            </Box>
          </Box>
          <Divider />
          <Box>
            <Typography sx={{ color: '#929292' }}>Classrooms</Typography>
            <Box className={style.classroom_list}>
              {user.classroom?.length > 0 &&
                user.classroom?.map((item: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <ClassItem
                        title={
                          item?.classroom?.name?.includes(':')
                            ? item?.classroom?.name?.split(':')[1]
                            : item?.classroom?.name
                        }
                      />
                    </React.Fragment>
                  );
                })}
            </Box>
          </Box>
        </Card>
      </Grid>
      <Card sx={{ p: 7 }}>
        <SimpleTable
          title="Teachers"
          rows={getRows()}
          columns={['Name', 'Phone', 'Subject', 'Class']}
        />
      </Card>
      <DeleteUserDialog
        loading={deleteLoading}
        open={openDeleteModal}
        title={`Are you sure you want to delete the user "${selectedUserToDelete?.name}" from G3MS?`}
        onOk={handleDeleteUser}
        onClose={() => {
          setOpenDeleteModal(false);
          setSelectedUserToDelete({
            id: '',
            name: '',
          });
        }}
      />
    </Stack>
  );
}

export default Student;
