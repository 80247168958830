// ** MUI Imports
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
// ** Icons Imports
import MenuIcon from 'mdi-material-ui/Menu';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// ** Type Import

// ** Components
// import Autocomplete from '../../../layouts/components/Autocomplete'
import { Settings } from '@core/context/settingsContext';
import { Theme } from '@mui/material/styles';
import {
  Avatar,
  Breadcrumbs,
  InputAdornment,
  Link,
  Menu,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';

import { useLocation, Link as RouterLink } from 'react-router-dom';
import UserDropdown from '@core/layouts/components/shared-components/UserDropdown';
import { useAppSelector } from 'store/store';
import { useDispatch } from 'react-redux';
import { setSelectedRoute } from 'store/Thunk/ParentEducatorDashboardThunk';
import { ThunkDispatch } from '@reduxjs/toolkit';

interface Props {
  item: any;
  hidden: boolean;
  admin: boolean;
  settings: Settings;
  toggleNavVisibility: () => void;
  saveSettings: (values: Settings) => void;
}

const AppBarContent = (props: Props) => {
  // ** Props
  function toTitleCase(str: any) {
    return str.replace(/\b\w+/g, function (s: any) {
      return s.charAt(0).toUpperCase() + s.substr(1).toLowerCase();
    });
  }
  const { breadcrumbs } = useAppSelector((state: any) => state.ParentEducatorReducer);
  console.log("breadcrumbs??????", breadcrumbs)
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);
const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  //   let index=pathnames.indexOf("school")
  //   if(index>-1){
  // pathnames.splice(index, 1)
  // }
  //   let index2=pathnames.indexOf("admin")
  //   if(index2>-1){
  // pathnames.splice(index2, 1)
  //   }
  console.log("pathnames", pathnames)
  const { item, hidden, toggleNavVisibility, settings } = props;

  function handleBreadCrumbClick(
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
  }

  const breadcrumbView = (link1: any, link2: any) => {
    console.log('link1', link1, link1);
    return (
      <>
        {!hidden ? (
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            //style={{ marginBottom: 20 }}
          >
            <Link
              underline="hover"
              key="1"
              color="inherit"
              // href={'/'}
              onClick={() => handleBreadCrumbClick}
            >
              {link1}
            </Link>
            ,
            <Box
              className="actions-left"
              sx={{
                ml: 2,
                alignItems: 'center',
                background: '#4CBFA71A',
                justifyContent: 'center',
                paddingX: 3,
                paddingY: 1,
                borderRadius: 0.6,
              }}
            >
              <Typography
                className="title_text"
                style={{ fontSize: '0.8rem', fontWeight: '600' }}
                color={'#20A58A'}
              >
                {link2}
              </Typography>
            </Box>
            ,
          </Breadcrumbs>
        ) : null}
      </>
    );
  };
  const simpleView = (link1: any) => {
    console.log("link1", link1)
    if(link1){
      dispatch(setSelectedRoute(link1))
    }
    return (
      <>
        {!hidden ? (
          link1 ? (
            <Box
              className="actions-left"
              sx={{
                ml: 2,
                alignItems: 'center',
                background: '#4CBFA71A',
                justifyContent: 'center',
                paddingX: 3,
                paddingY: 1,
                borderRadius: 0.6,
              }}
            >
              <Typography
                className="title_text"
                style={{ fontSize: '0.8rem', fontWeight: '600' }}
                color={'#20A58A'}
              >
                {link1}
              </Typography>
            </Box>
          ) : (
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="Breadcrumb"
            >
              {/* {pathnames.map((value, index) => {
                const last = index === pathnames.length - 1;
                const to = `/${pathnames.slice(0, index + 1).join('/')}`;
    
                  if(value==="school"|| value==="classroom"){
                    return;
                  }  else{
                    return last ? (
                      <Box
                        className="actions-left"
                        sx={{
                          ml: 2,
                          alignItems: 'center',
                          background: '#4CBFA71A',
                          justifyContent: 'center',
                          paddingX: 3,
                          paddingY: 1,
                          borderRadius: 0.6,
                        }}
                      >
                        <Typography
                          className="title_text"
                          style={{ fontSize: '0.8rem', fontWeight: '600' }}
                          color={'#20A58A'}
                        >
                          {toTitleCase(value)}
                        </Typography>
                      </Box>
                    ) : (
                      <RouterLink color="inherit" to={to}>
                        {toTitleCase(value)}
                      </RouterLink>
                    );
                  }           
                             
              })} */}
              {breadcrumbs}
            </Breadcrumbs>
          )
        ) : null}
      </>
    );
  };

  const getTitle = () => {
    let title: any = '';
    let heading: any = '';
    let isChildren: any = false;
    console.log('mainPath***', location.pathname);
    item?.forEach((item: any) => {
      if (item?.children) {
        item?.children?.map((val: any) => {
          if (val?.path === location.pathname) {
            console.log('val?.path+++++', val?.path);
            isChildren = true;
            title = val?.title || '';
            heading = item.title || '';
          }
        });
      } else {
        if (item?.path === location.pathname) {
          isChildren = false;
          console.log('item?.path+++++', item?.path);
          title = item?.title || '';
        }
      }
    });
    // const itemObj = item.filter((obj: any) => obj.path === location.pathname);
    // console.log("itemObj)))))))))))",itemObj)
    // console.log("itemObj*******",item)
    return isChildren
      ? hidden
        ? title
        : breadcrumbView(heading, title)
      : hidden
      ? title
      : simpleView(title);
  };

  return (
    <div className="flex px-6  w-full justify-between items-center">
      {hidden ? (
        <>
          <div
            //s role='button'
            ///onClick={toggleNavVisibility}
            className="-ml-3"
          >
            <img
              alt=""
              src={require('../../../assets/Parent/Logo.png')}
              className="w-14 h-8"
            />
          </div>
          <div className="actions-left  ml-2 flex items-center">
            {getTitle() && (
              <Typography style={{ fontSize: 15 }}>{getTitle()}</Typography>
            )}
          </div>

          <UserDropdown
            onClick={toggleNavVisibility}
            hidden={hidden}
            settings={settings}
          />
        </>
      ) : (
        <>
          <div className="flex items-center">
            <div>
              <img
                src={require('../../../assets/_Home.png')}
                alt="Home"
                className=" w-7 "
              />
            </div>
            <div>
              <img
                src={require('../../../assets/chevron-right.png')}
                alt="Home"
                className=" w-4"
              />
            </div>
            <Box className="actions-left" sx={{ ml: 2 }}>
              {getTitle()}
            </Box>
          </div>

          <Box
            className="actions-right"
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <UserDropdown settings={settings} />
          </Box>
        </>
      )}
    </div>
  );
};

export default AppBarContent;
