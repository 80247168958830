import { TextInput } from '@mantine/core';
import { nanoid } from '@reduxjs/toolkit';
import React, { useId } from 'react';
import cn from 'utils/cn';

function TextInputField({
  label,
  type = 'text',
  placeholder,
  value,
  onChange,
  required = false,
  disabled = false,
  className = '',
  error,
  ...props
}: {
  label?: string | JSX.Element;
  type?: string;
  placeholder?: string;
  value?: any;
  onChange?: any;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  error?: any;
} & React.ComponentProps<typeof TextInput>) {
  const id = useId();
  return (
    <TextInput
      id={id}
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      required={required}
      disabled={disabled}
      error={error}
      classNames={{
        input: cn(
          'block w-full  text-[7px] !border focus:!border  !border-[#DCDCDC] !rounded-lg !shadow-sm   justify-center',
          'focus:!border-[#DCDCDC] focus:!ring-1 focus:!ring-[#DCDCDC] transition-all duration-200 ease-in-out',
          { 'border-[#F41F52] focus:border-[#F41F52]': !!error },
          className,
        ),
        label: 'block !text-sm lg:!text-base !text-[#484848] ',
        error: '!text-[#F41F52] !text-sm xl:!text-sm',
        root: 'space-y-[0.25rem]',
      }}
      size="lg"
      name={nanoid()}
      {...props}
      style={{ marginTop: 2 }}
    />
  );
}

export default TextInputField;
