import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';

import AddFirstContent from './AddFirstContent';
import EditBorderIcon from 'icons/editBorderIcon';
import DeleteBorderIcon from 'icons/deleteBorderIcon';
import DefaultCell from 'components/DefaultCell';
import CustomTable from 'components/customTable';

import AddUpdateContent from 'components/dialog/assigment/AddUpdate';
import TableTeacherProgress from 'components/student/progress/teacherTable';
import StudentProgressTable from 'components/student/progress/table';
import {
  getAllAssignments,
  getAssignments,
} from 'store/Thunk/assignContentThunk';
import { useAppDispatch, useAppSelector } from 'store/store';
import { useParams } from 'react-router-dom';
import useSchoolClassroomInfo from 'hooks/useSchoolClassroomInfo';
import AssignmentList from './AssignmentList';
import TableHeader from 'components/tableHeader';
import AssignContent from 'components/student/assign';
import AssignmentView from './AssignmentView';
import DeleteConfirmation from './DeleteConfirmation';
import { startLoading } from 'store/Slice/loadingSlice';

function Index() {
  const [isOpenFilter, setIsOpenFilter] = React.useState<boolean>(false);
  const [isOpenAssign, setIsOpenAssign] = React.useState<boolean>(false);
  const [isOpenDelete, setIsOpenDelete] = React.useState<boolean>(false);
  const [isOpenAssignmentView, setIsOpenAssignmentView] =
    React.useState<boolean>(false);
  const [selectedAssignement, setSelectedAssignment] = React.useState({
    id: '',
  });
  const urlParams = new URLSearchParams(window.location.search);
  const classroomId = urlParams.get('classroomId');
  const studentDetail: any = urlParams.get('studentDetail');
  const studentsCount: any = urlParams.get('studentsCount');
  const studentId: any = urlParams.get('studentId');
  const [search, setSearch] = useState<string>('');
  const [pageNo, setPageNo] = useState(0);

  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [assignmentsList, setAssignmentsList] = useState([]);
  const [assignmentsListMeta, setAssignmentsListMeta] = useState({});
  const singleStudent = studentDetail
    ? assignmentsList?.filter((item: any) => item?.id === studentId)
    : [];
    console.log('assignmentsList@@@@@@@@@@@@', assignmentsList);
  console.log('assignmentsList', assignmentsList);
  console.log('assignmentsList', classroomId);
  const fetchIdRef = useRef(0);
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const handleClose = () => {
    setIsOpenAssign(false);
  };
  const handleCloseView = () => {
    setIsOpenAssignmentView(false);
    setIsOpenDelete(false);
  };
  useEffect(() => {
    dispatch(getAllAssignments({ id: classroomId }))
      .unwrap()
      .then((res) => {
        setAssignmentsList(res?.items);
        setAssignmentsListMeta(res);
        console.log('assignmentsList', res);
      });
  }, []);
  const fetchData = useCallback(
    ({ pageSize, pageIndex }: any) => {
      const fetchId = ++fetchIdRef.current;
      // We'll even set a delay to simulate a server here
      if (fetchId === fetchIdRef.current) {
        dispatch(startLoading());
        setPageNo(0);
        dispatch(
          getAllAssignments({
            id: classroomId,
            pageSize: 10,
            pageIndex: 0,
            search,
          }),
        )
          .unwrap()
          .then((res) => {
            setAssignmentsList(res.items);
            setAssignmentsListMeta(res);
            console.log('assignmentsList', res);
          });
      }
      // setTimeout(() => {}, 1000);
    },
    [search],
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleAddUpdateContent = () => {
    setIsOpen(!isOpen);
  };
  const header = [
    { label: 'Student name', width: 3, value: 'name', id },
    { label: 'Total Assignments', width: 3, value: 'totalCountOfAssignments' },
    { label: 'Completed', width: 3, value: 'totalCompletedAssignments' },
    { label: 'Not Started', width: 2.5, value: 'totalNotStartedAssignments' },
  ];
  const color = '#E9F6F3';
  // Search input state handle
  const { studentListByClassroom } = useAppSelector(
    (state) => state.studentReducer,
  );
  console.log('studentListByClassroom', studentListByClassroom);
  return (
    <Box>
      <Box className="px-10">
        <TableHeader
          item={{
            search,
            setSearch,
            fetchData: fetchData,
            canSearch: true,
            openFilter: isOpenFilter,
            openAssign: isOpenAssign,
            onCloseFilter: setIsOpenFilter,
            onCloseAssign: setIsOpenAssign,
            action: true,
            button: true,
            disabledButton: !(
              parseInt(studentListByClassroom?.items?.length) > 0
            ),
          }}
        />
        {assignmentsList?.length > 0 ? (
          <Typography
            style={{ color: '#1A846E' }}
            my={4}
            className="!text-sm !font-bold"
          >
            Click “Assign Content” to have G3MS automatically provide classwork
            to address skills selected below.
          </Typography>
        ) : (
          <></>
        )}
      </Box>
      {assignmentsList?.length ? (
        <Box m={4}>
          <AssignmentList
            color={color}
            search={search}
            pageNo={pageNo}
            setPageNo={setPageNo}
            assignmentsListMeta={assignmentsListMeta}
            setAssignmentsListMeta={setAssignmentsListMeta}
            assignmentsList={assignmentsList}
            setAssignmentsList={setAssignmentsList}
            classes={studentDetail ? singleStudent : assignmentsList}
            setIsOpenDelete={setIsOpenDelete}
            setSelectedAssignment={setSelectedAssignment}
            setIsOpenAssignmentView={setIsOpenAssignmentView}
            header={header}
          />
        </Box>
      ) : (
        <AddFirstContent handleAddUpdateContent={handleAddUpdateContent} />
      )}
      {isOpen && (
        <AddUpdateContent open={isOpen} onClose={handleAddUpdateContent} />
      )}
      <AssignContent open={isOpenAssign} onClose={handleClose} />
      <AssignmentView
        open={isOpenAssignmentView}
        onClose={handleCloseView}
        selectedAssignement={selectedAssignement}
      />
      <DeleteConfirmation
        setAssignmentsList={setAssignmentsList}
        open={isOpenDelete}
        deleteId={selectedAssignement}
        onClose={handleCloseView}
      />
    </Box>
  );
}

export default Index;
