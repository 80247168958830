import React from "react";
import { Typography, Breadcrumbs, Link, Card, Box } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import EditDetailDistrict from "components/district/editDetail";
import { route_info } from "navigation/route";
// *** Third Party Imports
import { useNavigate } from "react-router-dom";

function handleBreadCrumbClick(
  event: React.MouseEvent<HTMLDivElement, MouseEvent>
) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

const breadcrumbs = [
  <Link
    underline="hover"
    key="1"
    color="inherit"
    href={route_info.content_management.video}
    onClick={() => handleBreadCrumbClick}
  >
    District Management
  </Link>,
  <Typography key="2" color="inherit">
    District
  </Typography>,
  <Typography key="2" color="#C73E9D">
    Edit
  </Typography>,
];

function Index() {
  const navigate: any = useNavigate();
  const handleCancel = ({ add, edit, detail }: any) => {
    if (edit || detail) {
      navigate(route_info.content_management.video);
    }
  }
  return (
    <Box>
      {/* <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        style={{ marginBottom: 20 }}
      >
        {breadcrumbs}
      </Breadcrumbs> */}
      <Card
        sx={{
          p: "25px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <EditDetailDistrict saveTitle="Update" edit handleCancel={handleCancel}/>
      </Card>
    </Box>
  );
}

export default Index;
