import { apiSlice } from '..';
import {
  AccountContentResponse,
  ICreateHomeSchoolRequest,
  TAddClassRoomRequest,
  TUpdateAccountContentRequest,
} from './types';

export const authApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAcademicContent: builder.query<AccountContentResponse, void>({
      query: () => '/account/setup/fetch-account-contents?isFunTopic=false',
    }),
    getFunContent: builder.query<AccountContentResponse, void>({
      query: () => '/account/setup/fetch-account-contents?isFunTopic=true',
    }),
    updateAccountContent: builder.mutation<
      AccountContentResponse,
      TUpdateAccountContentRequest
    >({
      query: (body) => ({
        url: '/account/setup/update-account-contents',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['MyProfile'],
    }),
    createHomeSchool: builder.mutation<any, ICreateHomeSchoolRequest>({
      query: (body) => ({
        url: '/account/setup/create-homeschool-classroom',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['MyProfile', 'UserStudents'],
    }),
    addClassRoom: builder.mutation<any, TAddClassRoomRequest>({
      query: (body) => ({
        url: `/classroom`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['MyProfile', 'UserStudents'],
    }),
    finishAccountSetup: builder.mutation<any, void>({
      query: () => ({
        url: 'account/setup/update-account-setup-completion-status',
        method: 'POST',
      }),
      invalidatesTags: ['MyProfile'],
    }),
  }),
});

export const {
  useGetAcademicContentQuery,
  useGetFunContentQuery,
  useUpdateAccountContentMutation,
  useCreateHomeSchoolMutation,
  useAddClassRoomMutation,
  useFinishAccountSetupMutation,
} = authApi;
