import React from 'react';
import Header from './Header';
import SocialMatrics from './SocialMatrics';

function SocialMatricsList({ role, data }: { role?: any, data? :any  }) {

  return (
    <div className="mt-2">
      <Header text={'Social Emotional Metrics:'} />
      <div className="lg:flex grid md:grid-cols-3 gird-cols-2 lg:items-center lg:space-x-1  lg:space-y-0 space-y-1 mt-2">
  
        <SocialMatrics
          values={data?.mood} 
          role={role}
          name={'Mood'}
          follow={false}
          mood={true}
          others={false}
        //  src={require('../../../../assets/Parent/smiley.png')}
        />
        <SocialMatrics
          values={data?.following}
          role={role}
          name={'Following'}
          follow={true}
          mood={false}
          others={false}
          src={null}
        />
        <SocialMatrics
          values={data?.socialFunVideo}
          role={role}
          name={'Social Fun Post'}
          follow={false}
          mood={false}
          others={true}
          src={require('../../../../assets/Parent/fun.png')}
        />
        <SocialMatrics
          values={data?.dailyChallenges}
          role={role}
          name="Academic Challenges"
          follow={false}
          mood={false}
          others={true}
          src={require('../../../../assets/Parent/challenge.png')}
        />
        <SocialMatrics
          values={data?.WatchTimeSubject?data?.WatchTimeSubject:data?.userWatchTime?.WatchTimeSubject}
          role={role}
          name="Top topic watching"
          follow={false}
          mood={false}
          others={true}
          src={require('../../../../assets/Parent/topic.png')}
        />
      </div>
    </div>
  );
}

export default SocialMatricsList;