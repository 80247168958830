import React, { useEffect } from 'react';
import {
  Box,
  IconButton,
  Breadcrumbs,
  Link,
  Typography,
  Paper,
  Card,
  Button,
  CardActions,
  CardContent,
  Grid,
  Avatar,
  AvatarGroup,
  Modal,
  Fade,
  Backdrop,
  MenuItem,
  Menu,
} from '@mui/material';
import CustomTable from 'components/customTable';
import DefaultCell from 'components/DefaultCell';
import { EditIcon, DeleteIcon, ViewIcon } from 'icons';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Profile from 'assets/actionIcons/profile.png';
import Classroom from 'icons/ManageClassroom';
import ManageStudents from 'icons/manageStudent';
import ManageTeachers from 'icons/manageTeacher';
import AddIcon from '@mui/icons-material/Add';
import ClassroomTable from './classroom';
import AddUpdate from 'components/dialog/school/AddUpdate';
import AddUpdateClass from 'components/dialog/classroom/AddUpdate';
import ThreeDotVertical from 'icons/threeDotVerticalIcon';
import ViewDetails from 'components/ViewPages/viewDetails';
import ClassroomCrud from './classroom';
import StudentList from './student';
import TeacherList from './teacherList';
import { useAppDispatch, useAppSelector } from 'store/store';
import { useNavigate, useParams } from 'react-router-dom';
import { getSchoolByID } from 'store/Thunk/schoolThunk';
import SchoolDetails from 'components/dialog/school/SchoolDetails';
import { profile } from 'assets';
import UsersIcon from 'icons/users';
import AddUpdateSchool from 'components/dialog/school/AddUpdate';
import EditBorderIcon from 'icons/editBorderIcon';
import DeleteBorderIcon from 'icons/deleteBorderIcon';
import CustomDropdown from 'components/CustomDropdownButton';
import { getUser } from 'helper/functions';
import { Roles } from 'constants/consts';
import StudentListBySchool from '../studentList';
import useSchoolClassroomInfo from 'hooks/useSchoolClassroomInfo';
import TeacherListBySchool from '../teacherList';
import ParentListBySchool from '../parentList';
import { route_info } from 'navigation/route';
import { useGetMyProfileQuery } from 'store/apis/profile';
import { setBreadCrumbsData } from 'store/Thunk/ParentEducatorDashboardThunk';
import { getDistrictByID } from 'store/Thunk/districtThunk';

function handleBreadCrumbClick(
  event: React.MouseEvent<HTMLDivElement, MouseEvent>,
) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

const cardData = [
  { title: 'Manage Class', icons: <Classroom />, tabValue: 'class' },
  { title: 'Manage Students', icons: <ManageStudents />, tabValue: 'student' },
  { title: 'Manage Teachers', icons: <ManageTeachers />, tabValue: 'teacher' },
  { title: 'Manage Parents', icons: <ManageTeachers />, tabValue: 'parent' },
];

function Index() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
const {data}=useGetMyProfileQuery()
const { districtID } = useAppSelector((state: any) => state.districtReducer);
  const { schoolByID } = useAppSelector((state) => state.schoolReducer);
  const [templete, setTemplete] = React.useState<any>({});
  const [isOpenAdd, setIsOpenAdd] = React.useState<boolean>(false);
  const [schoolDetails, setSchoolDetails] = React.useState<any>([]);
  const [isOpenAddClass, setIsOpenAddClass] = React.useState<boolean>(false);
  const [tabValue, setTabValue] = React.useState<string>('class');
  const [isOpen, setIsOpen] = React.useState(false);
  const [editId, setEditId] = React.useState('');

  const [selectedAvatars, setSelectedAvatars] = React.useState<any>([]);
  const [isSchoolOpen, setSchoolIsOpen] = React.useState<boolean>(false);
  const { type } = getUser();
  console.log('schoolId', id);
  useEffect(() => {
    dispatch(getSchoolByID({ id }));
    
  }, []);
  useEffect(() => {
   

  }, [id]);
  useEffect(() => {
    setTemplete(schoolByID[0]);
  }, [schoolByID]);
  const allall = templete?.user?.map((user: any) => user?.name)?.join(', ');
  console.log('allall', allall);
  useEffect(() => {
    if (schoolByID?.length > 0) {
      const avatarProfiles = [
        'Ashley24',
        'Travis Howard',
        'Cindy Baker',
        'Cindy Baker',
        'Cindy Baker',
        'Cindy Baker',
        'Cindy Baker',
        'Cindy Baker',
      ];

      const avatarsToShow = avatarProfiles.slice(0, 3);

      const avatarGroup = (
        <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
          <AvatarGroup>
            {avatarsToShow.map((profileName, index) => (
              <Avatar key={index} alt={profileName} src={''} />
            ))}
          </AvatarGroup>
          {avatarProfiles.length > 3 && (
            <>
              {/* <Button
                variant="contained"
                size="small"
                onClick={() => {
                  setSelectedAvatars(avatarProfiles.slice(3));
                  setIsOpen(!isOpen);
                }}
                sx={{
                  //backgroundColor: '#20A58A',
                  height: '32px',
                  width: '32px',
                  borderRadius: '24px',
                }}
              >
                View
              </Button> */}
              <Box sx={{ mt: 1, ml: 2 }}>
                <CustomDropdown title={'View'} />
              </Box>
            </>
          )}
        </Box>
      );

      setSchoolDetails([
        { title: 'District ID', content: templete?.ncesDistrictId || '-' },
        { title: 'School Type', content: templete?.schoolType || '-' },
        {
          title: 'Specific School',
          content: templete?.specificSchoolType || '-',
        },
        { title: 'School Grades', content: templete?.schoolGrades || '-' },
        { title: 'County', content: templete?.county || '-' },
        { title: 'State', content: templete?.state?.name || '-' },
        { title: 'City', content: templete?.city?.name || '-' },
        { title: 'Address', content: templete?.streetAddress || '-' },
        { title: 'Phone', content: templete?.phone || '-' },
        {
          title: 'School Leader',
          content:
            templete?.user?.map((user: any) => user?.name)?.join(', ') || '-',
          avatar: avatarGroup,
        },
      ]);
    }
  }, [templete]);

  const breadcrumbs =
    type === Roles.ADMIN
      ? [
          <Link
            underline="hover"
            key="1"
            color="inherit"
            href={route_info?.school_management?.districtCrud}
            onClick={() => handleBreadCrumbClick}
          >
            School Management
          </Link>,
          <Box
            key="2"
            color="#C73E9D"
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              navigate(-1);
            }}
          >
            Schools
          </Box>,
          <Typography key="3" color="#C73E9D">
            {templete?.name}
          </Typography>,
        ]
      :
      type===Roles.DISTRICT_LEADER?
      [
        <Link
              underline="hover"
              key="1"
              color="inherit"
              href={route_info?.district_management_school.replace(
                ':id',
                data?.data?.school?.ncesDistrictId || ""
              )}
              onClick={() => handleBreadCrumbClick}
            >
              District Management
            </Link>,
            <Typography key="2" color="black">
              {districtID.length > 0
                ? districtID[0]?.name || districtID[0]?.ncesDistrictId
                : 'District'}
            </Typography>,
             <Box
                        className="actions-left"
                        sx={{
                          ml: 2,
                          alignItems: 'center',
                          background: '#4CBFA71A',
                          justifyContent: 'center',
                          paddingX: 3,
                          paddingY: 1,
                          borderRadius: 0.6,
                        }}
                      >
                        <Typography
                          className="title_text"
                          style={{ fontSize: '0.8rem', fontWeight: '600' }}
                          color={'#20A58A'}
                        >
                          {templete?.name}
                        </Typography>
                      </Box>
            
      ]:
      [
        <Typography key="3" color="#C73E9D">
              School
            </Typography>,
          <Typography key="3" color="#C73E9D">
            {templete?.name}
          </Typography>,
        ];

         useEffect(()=>{
            dispatch(setBreadCrumbsData(breadcrumbs))
          },[breadcrumbs, templete, districtID])

  const handleSchoolDetails = () => {
    setIsOpen(!isOpen);
    setSelectedAvatars([]);
  };

  const handleOnChangeSchool = () => {
    setIsOpenAdd(!isOpenAdd);
  };

  const handleOnChangeClassRoom = () => {
    setIsOpenAddClass(!isOpenAddClass);
  };

  const handleAddUpdateSchool = (id: string) => {
    setEditId(id);

    setSchoolIsOpen(!isSchoolOpen);
  };

  return (
    <>
      {/* <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        style={{ marginBottom: 20 }}
      >
        {breadcrumbs}
      </Breadcrumbs> */}
      <Card className="datatable_card" sx={{ p: '24px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              variant="body1"
              sx={{
                color: '#20A58A',
                fontSize: '22.98px',
                fontWeight: '700',
                mr: 2,
                textTransform: 'capitalize',
              }}
            >
              {templete?.name}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: '#929292',
                fontSize: '12px',
                fontFamily: 'Inter',
                fontWeight: '400',
                wordWrap: 'break-word',
                mt: 2,
              }}
            >
              {`(ID: ${templete?.ncesSchoolId || templete?.id})`}
            </Typography>
          </Box>
          <Box>
            {/* <ThreeDotVertical /> */}
            <IconButton
              onClick={() =>
                handleAddUpdateSchool(templete?.ncesSchoolId || templete?.id)
              }
            >
              <EditBorderIcon />
            </IconButton>
            <IconButton>
              <DeleteBorderIcon />
            </IconButton>
          </Box>
        </Box>
        <Box>
          <ViewDetails details={schoolDetails} gridSize={1.7} />
        </Box>
        {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            className="primary_bg_btn"
            sx={{ ml: 3 }}
            onClick={() => {
              setIsOpenAdd(!isOpenAdd);
            }}
            endIcon={<AddIcon />}
          >
            {'Add School'}
          </Button>
        </Box> */}
        {type !== Roles.TEACHER && type !== Roles.TUTOR && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Grid container spacing={5}>
              {cardData.map((item: any) => {
                return (
                  <Grid item xs={12} sm={6} md={3}>
                    <Paper>
                      <Card
                        onClick={() => {
                          setTabValue(item?.tabValue);
                        }}
                        sx={
                          tabValue === item?.tabValue
                            ? { borderBottom: '2px #C73E9D solid' }
                            : null
                        }
                      >
                        <CardContent
                          sx={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <Box>{item?.icons}</Box>
                          <Typography
                            variant="body1"
                            sx={{
                              mb: 1,
                              color: '#484848',
                              fontSize: '16px',
                              fontWeight: '500',
                            }}
                          >
                            {item?.title}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        )}
        {tabValue === 'class' && <ClassroomCrud setTabValue={setTabValue} />}
        {tabValue === 'student' && (
          <StudentListBySchool schoolId={templete?.id || ''} />
        )}
        {tabValue === 'teacher' && (
          <TeacherListBySchool schoolId={templete?.id || ''} />
        )}
        {tabValue === 'parent' && (
          <ParentListBySchool schoolId={templete?.id || ''} />
        )}
      </Card>
      {/* {isOpen && (
            <SchoolDetails open={isOpen} selectedAvatars={selectedAvatars} imagePath = {Profile}  onClose={handleSchoolDetails} />
      )} */}

      {isSchoolOpen && (
        <AddUpdateSchool
          editId={editId}
          open={isSchoolOpen}
          title={'Update School'}
          saveTbtText={'Update'}
          onClose={handleAddUpdateSchool}
        />
      )}
    </>
  );
}

export default Index;
