import { Loader } from '@mantine/core';
import Button from '@mui/material/Button';
function ButtonMain({
  onPress,
  text,
  font,
  color,
  type,
  loader,
  mb,
  disabled
}: {
  onPress?: any;
  text: string;
  font: string;
  color: any;
  type?: any;
  loader?:boolean;
  mb?:any;
  disabled?:boolean
}) {
  return (
    <div className="w-full px-3 py-2 "
    style={{marginBottom:mb}}
    >
      <Button
      type={type}
        sx={{ fontWeight: font, backgroundColor: color ? color : '#4CBFA7' }}
        onClick={onPress}
        className="w-full"
        variant="contained"
        disabled={disabled}
      >
        {loader? <Loader size="xs" color="blue" className="ml-2" />:
        <div className='text-[15px] font-medium text-[white]'>
        {text}
        </div>}
      
      </Button>
    </div>
  );
}

export default ButtonMain;
