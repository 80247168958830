import { update } from '@intercom/messenger-js-sdk';
import { Portal } from '@mantine/core';
import { useEffect, useState } from 'react';
import cn from 'utils/cn';
import { getDeviceType } from 'utils/lib';

export default function FloatingActionBar() {
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState({
    x: window.innerWidth - 100,
    y: window.innerHeight - 100,
  });
  const [dragging, setDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMove = (e: MouseEvent | TouchEvent) => {
      if (!dragging) return;

      let clientX: number, clientY: number;
      if (e instanceof MouseEvent) {
        clientX = e.clientX;
        clientY = e.clientY;
      } else {
        clientX = e.touches[0].clientX;
        clientY = e.touches[0].clientY;
      }

      setPosition((prev) => ({
        x: Math.max(0, Math.min(window.innerWidth - 64, clientX - offset.x)),
        y: Math.max(0, Math.min(window.innerHeight - 64, clientY - offset.y)),
      }));
    };

    const handleEnd = () => setDragging(false);

    if (dragging) {
      document.addEventListener('mousemove', handleMove);
      document.addEventListener('mouseup', handleEnd);
      document.addEventListener('touchmove', handleMove);
      document.addEventListener('touchend', handleEnd);
    }

    return () => {
      document.removeEventListener('mousemove', handleMove);
      document.removeEventListener('mouseup', handleEnd);
      document.removeEventListener('touchmove', handleMove);
      document.removeEventListener('touchend', handleEnd);
    };
  }, [dragging, offset]);

  useEffect(() => {
    const setInitialPosition = () => {
      setPosition({
        x: window.innerWidth - 100,
        y: window.innerHeight - 100,
      });
    };

    setInitialPosition();

    window.addEventListener('resize', setInitialPosition);
  }, []);

  const handleStart = (
    e:
      | React.MouseEvent<HTMLButtonElement>
      | React.TouchEvent<HTMLButtonElement>,
  ) => {
    setDragging(true);

    let clientX: number, clientY: number;
    if ('touches' in e) {
      clientX = e.touches[0].clientX;
      clientY = e.touches[0].clientY;
    } else {
      clientX = e.clientX;
      clientY = e.clientY;
    }

    setOffset({
      x: clientX - position.x,
      y: clientY - position.y,
    });
  };

  return (
    <Portal>
      <button
        onClick={() => {
          if (dragging) return;
          if (isOpen) {
            setIsOpen(false);
            window.Intercom && window.Intercom('hide');
          } else {
            update({
              customAttributes: {
                platform: 'admin-web',
                deviceType: getDeviceType(),
              },
            });
            setIsOpen(true);
            window.Intercom && window.Intercom('show');
          }
        }}
        className={cn(
          'fixed bottom-0 right-0  text-white p-2 rounded-full size-[5rem] overflow-hidden bg-cover z-[9999] bg-slate-500',
        )}
        style={{
          backgroundImage: "url('/chatbot.svg')",
          left: `${position.x}px`,
          top: `${position.y}px`,
        }}
        onMouseDown={handleStart}
        onTouchStart={handleStart}
      />
    </Portal>
  );
}
