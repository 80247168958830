// ** React Import
import { useEffect, useRef } from 'react';

// ** Type Import
import { LayoutProps } from '../../@core/layouts/types';

// ** Layout Components
import VerticalLayout from './VerticalLayout';
import HorizontalLayout from './HorizontalLayout';
import { Box } from '@mui/material';
let role = '';

const Layout = (props: LayoutProps) => {
  // ** Props
  const { admin, mobileSize, hidden, children, settings, saveSettings } = props;

  // ** Ref
  const isCollapsed = useRef(settings.navCollapsed);
  useEffect(() => {
    const user: any = localStorage.getItem('userData');
    if (user) {
      role = JSON.parse(user).type;
      console.log('role', role);
    }
  }, []);
  useEffect(() => {
    if (hidden) {
      if (settings.navCollapsed) {
        saveSettings({ ...settings, navCollapsed: false, layout: 'vertical' });
        isCollapsed.current = true;
      } else {
        saveSettings({ ...settings, layout: 'vertical' });
      }
    } else {
      if (isCollapsed.current) {
        saveSettings({
          ...settings,
          navCollapsed: true,
          layout: settings.lastLayout,
        });
        isCollapsed.current = false;
      } else {
        saveSettings({ ...settings, layout: settings.lastLayout });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hidden]);

  if (settings.layout === 'horizontal') {
    return <HorizontalLayout {...props}>{children}</HorizontalLayout>;
  }

  return !mobileSize || admin || role == 'parent' || role=="teacher" || role=="school_leader" || role=="learning_coach" || role=="tutor" || role=="homeschool_parent_esa" || role=="district_leader" ? (
    <VerticalLayout {...props}>{children}</VerticalLayout>
  ) : (
    <Box>{children}</Box>
  );
};

export default Layout;
