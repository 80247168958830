import { Typography } from '@mui/material';
import { ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react'; 
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/store';
import { getParentSubTopicList } from 'store/Thunk/ParentDashboardThunk';
import DefaultCell from 'components/DefaultCell';
import Modal from '@mui/material/Modal';
import CustomTable from 'components/customTable';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
function AcademicUnlock({onClick}:{onClick?:any}) {
  const {
      parentSubtopicList,

    } = useAppSelector((state: any) => state.parentDashboardReducer);
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
     const [open, setOpen] = React.useState(false);
     const handleOpen = () => setOpen(true);
     const handleClose = () => setOpen(false);
       const [totalCount, setTotalCount] = React.useState(10);
       const [pageCount, setPageCount] = React.useState(10);
       const [pageNo, setPageNo] = React.useState(1);
    
       React.useEffect(() => {
        
          dispatch(
            getParentSubTopicList({
              limit: pageCount,
              page: pageNo,
              topicId: 'MATH01',
            }),
          );
        }, []);
        React.useEffect(() => {
          dispatch(
            getParentSubTopicList({
              limit: pageCount,
              page: pageNo,
              topicId: 'MATH01',
            }),
          );
        }, [totalCount, pageNo]);
        const defaultCellStyle = {};
        const columns = [
          {
            Header: 'Id',
            accessor: 'id',
            Cell: ({ value }: any) => (
              <DefaultCell sx={defaultCellStyle} value={value} />
            ),
          },
          {
            Header: 'Name',
            accessor: 'name',
            Cell: ({ value }: any) => (
              <DefaultCell sx={defaultCellStyle} value={value} />
            ),
          },
          {
            Header: 'Skill',
            accessor: 'skill',
            Cell: ({ value }: any) => (
              <DefaultCell sx={defaultCellStyle} value={value} />
            ),
          },
          {
            Header: 'Topic',
            accessor: 'topic',
            Cell: ({ value }: any) => (
              <DefaultCell sx={defaultCellStyle} value={value} />
            ),
          },
        ];
        const fetchData = () => {
          setPageNo(0);
          setPageCount(10);
        };
         const rows = React.useMemo(() => {
            if (parentSubtopicList?.length > 0) {
              const data = parentSubtopicList?.map((item: any) => ({
                id: item?.id,
                name: item?.name, 
                skill: item?.skill[0]?.skill?.name,
                topic: item?.topic[0]?.topic?.name,
              }));
              return data;
            } else return [];
          }, [parentSubtopicList]);
  return (
    <div className='flex items-center justify-center mt-3'>
    <div className="mt-2 ">
      <Typography color={'#072D20'} sx={{ fontSize: 12, fontWeight: 700 }}>
        📚 Unlock State-Aligned Standards by Topic!
      </Typography>
      <Typography color={'#072D20'} sx={{ fontSize: 12, fontWeight: 400 }}>
        Free accounts have access to Math Standards—start exploring today!
      </Typography>
      <Typography color={'#072D20'} sx={{ fontSize: 12, fontWeight: 400 }}>
        ✅ Ensure full alignment with state requirements.
      </Typography>
      <Typography color={'#072D20'} sx={{ fontSize: 12, fontWeight: 400 }}>
        ✅ Plan comprehensive learning pathways with confidence.
      </Typography>
      <div role='button'
      onClick={handleOpen}
      >
      <Typography
        color={'#20A58A'}
        // display="inline"
        sx={{
          fontSize: 14,
          fontWeight: 700,
          textAlign: 'center',
          textDecoration: 'underline',
          marginTop: 3,
          marginBottom: 2,
        }}
      >
        🎯 View Math Standards Now
      </Typography>
      </div>
      <div>
            <Modal
              className="fixed bg-[white] left-0 top-0 z-[1055]  h-full w-full overflow-y-auto overflow-x-hidden  justify-center "
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div className=" bg-[white] w-full ">
                <div className="ml-3 mt-3" role="button" onClick={handleClose}>
                  <ArrowBackIcon fontSize="small" />
                </div>

                <div className=" bg-[white] w-full flex justify-center ">
                  <div className="w-[90%]">
                    <CustomTable
                      fetchData={fetchData}
                      pageCount={pageCount}
                      pageNo={pageNo}
                      totalCount={totalCount}
                      table={{
                        columns: columns,
                        rows: rows,
                      }}
                    />
                  </div>
                </div>
              </div>
            </Modal>
          </div>
    </div>
    </div>
  );
}

export default AcademicUnlock;
