import { Box, Typography } from '@mui/material';
import config from 'config/config';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useGetMyProfileQuery } from 'store/apis/profile';
import ButtonMain from '../ButtonMain';
import BenifitsText from '../FreePlan/BenifitsText';
import Progresss from '../Progress/Progresss';
import useAuth from 'hooks/useAuth';

function UpgradeCard({
  from,
  stroke,
  styl,
  fw,
  onPress,
}: {
  stroke: string;
  styl: any;
  fw: string;
  from: string;
  onPress: any;
}) {
  const { data, isLoading } = useGetMyProfileQuery();
  const navigate = useNavigate();

  const { loginUser } = useAuth();

  const navigateToPlan = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    loginUser({
      token,
      redirectTo: 'plans',
    });
  };
  if (data?.data?.isUserUpgradedPlan || isLoading) {
    return null;
  }

  console.log('data rr', dayjs(data?.data?.freeTrialEnd).diff(dayjs(), 'day'));

  return (
    <Box
      className={
        from == 'modal'
          ? ' mr-3 rounded-lg pb-2 '
          : stroke == '#20A58A'
          ? ' border border-[#EAECF0] mr-3 rounded-lg'
          : ' bg-[#F9FAFB] mr-3 rounded-lg pb-2 pt-2'
      }
    >
      <div className="flex items-center   mt-2">
        <div className="ml-3">
          <Progresss
            value={66}
            style={false}
            text={
              data?.data?.isFreeTrialActive && data?.data?.freeTrialEnd
                ? `${dayjs(data?.data?.freeTrialEnd).diff(dayjs(), 'day')} days`
                : '0 days'
            }
            stroke={'#20A58A'}
          />
        </div>
        <Typography sx={styl} fontFamily="Inter" color="#000000">
          {data?.data?.isFreeTrialActive
            ? `⏳ Your Free Trial Is Ending Soon! ⏳`
            : 'Time to Upgrade!'}
        </Typography>
      </div>
      <div className={stroke == '#20A58A' ? 'mt-4' : 'mt-2'}>
        <BenifitsText
          fw={fw}
          classNameM={stroke == '#20A58A' ? 'mt-2' : 'mt-1'}
          classNameD={stroke == '#20A58A' ? 'text-[#475467]' : 'text-[#000000]'}
          color={stroke == '#20A58A' ? '#475467' : '#000000'}
          text={'Keep redeeming tokens for gift cards and rewards.'}
        />
        <BenifitsText
          fw={'400'}
          classNameM={stroke == '#20A58A' ? 'mt-2' : 'mt-1'}
          classNameD={stroke == '#20A58A' ? 'text-[#475467]' : 'text-[#000000]'}
          color={stroke == '#20A58A' ? '#475467' : '#000000'}
          text={
            'Continue using the AI Tutor for homework help and learning support.'
          }
        />
        <BenifitsText
          fw={'400'}
          classNameM={stroke == '#20A58A' ? 'mt-2' : 'mt-1'}
          classNameD={stroke == '#20A58A' ? 'text-[#475467]' : 'text-[#000000]'}
          color={stroke == '#20A58A' ? '#475467' : '#000000'}
          text={
            'Stay ahead with full access to ELA, Science, Social Studies, and test prep.'
          }
        />
      </div>
      {from === 'modal' ? null : stroke == '#20A58A' ? (
        <ButtonMain
          color={false}
          font={'bold'}
          onPress={onPress}
          text={'Upgrade Plan'}
        />
      ) : (
        <button
          onClick={navigateToPlan}
          className="h-[45px] w-full text-[#4CBFA7] text-xs text-left font-semibold mt-2 ml-2"
        >
          Upgrade Plan
        </button>
      )}
    </Box>
  );
}

export default UpgradeCard;
