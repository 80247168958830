import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';

import StudentInformation from './StudentInformation';
import student from 'assets/students/av6.png';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import useModal from 'components/Modal/hook/useModal';
import {
  getEngagements,
  getStudentOfClassList,
  getReactions,
  getTotals,
  getCurrentSelectedUser,
  getDepartmentClasses,
  getAllStudentSchoolLeader,
  getDepartmentClassesSchoolLeader,
  getStudentsOfDepartment,
} from 'store/Thunk/ParentEducatorDashboardThunk';
import {
  getChildTotals,
  getChildReactions,
  getChildEngagements,
  getChildQR,
  getStudentData,
  deleteStudent,
} from 'store/Thunk/ParentDashboardThunk';
import {
  getAllStudent,
  getClassList,
  getDepartments,
} from 'store/Thunk/ParentEducatorDashboardThunk';
import { useAppSelector } from 'store/store';
import { deleteClassroom } from 'store/Thunk/classroomThunk';
import { useGetMyProfileQuery } from 'store/apis/profile';
function ClassRoomComponentSchoolLeader({
  role,
  classRooms,
}: { 
  role: string;
  classRooms?: any;
}) {
  console.log('classRooms in components', classRooms);
  const [selected, setSelected] = React.useState(1);
  const [depSelect, setDepSelect] = React.useState(false);
  const [classRoom, setClassRoom] = React.useState<any>([]);
  const [selection, setSelection] = React.useState('ghefcdyegdyw');
  const [allHeading, setAllHeading] = React.useState(false);
  const [allHeadingText, setAllHeadingText] = React.useState('');
   const [students, setStudent] = React.useState<any>([]);
  const [ids, setIds] = React.useState('');
  const [newElement, setNewElement] = React.useState([
    {
      classroom: {
        id: 'ghefcdyegdyw',
        name: 'Departments',
      },
    },
    {
      classroom: {
        id: 'jhgkedbj',
        name: 'Classrooms',
      },
    },
    {
      classroom: {
        id: 'lkfjilerf',
        name: 'Students',
      },
    },
  ]);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const { openModal } = useModal();
  const { classroomStudentsList, departmentList } = useAppSelector(
    (state: any) => state.ParentEducatorReducer,
  );
  const { data } = useGetMyProfileQuery();
  useEffect(() => {
    allDepartment();
  }, [data]);
  const onClickALL = () => {
    setDepSelect(false);
    dispatch(getClassList());
    setSelected(1);
    dispatch(getCurrentSelectedUser('all'));
    dispatch(getEngagements({ id: data?.data?.school?.id, type: 'SCHOOL' }));
    dispatch(getReactions({ id: data?.data?.school?.id, type: 'SCHOOL' }));
    dispatch(getTotals({ id: data?.data?.school?.id, type: 'SCHOOL' }));
  };
 useEffect(() => {
    const uniqueUsers = Array.from(new Map(classroomStudentsList?.map((user:any) => [user?.user?.id, user])).values());
    console.log(uniqueUsers);
    setStudent(uniqueUsers)
    console.log("uniqueUsers", uniqueUsers)
  }, [classroomStudentsList]);
  useEffect(() => {
    setClassRoom(classRooms);
  }, [classRooms]);
  const handleDeleteStudent = (id: any) => {
    dispatch(deleteStudent(id));
  };
  const handleDeleteClass = (id: any) => {
    dispatch(deleteClassroom(id));
  };
  //========get classroom data =========
  const handleClassroomClick = (classroomId: any) => {
    setSelected(classroomId);
    dispatch(getCurrentSelectedUser('class'));
    dispatch(getStudentOfClassList(classroomId));
    dispatch(getEngagements({ id: classroomId, type: 'CLASS' }));
    dispatch(getReactions({ id: classroomId, type: 'CLASS' }));
    dispatch(getTotals({ id: classroomId, type: 'CLASS' }));
  };
  //========get classroom students data =========
  const handleTabClick = (classroomId: any) => {
    console.log('id', classroomId);
    setSelection(classroomId);
    setAllHeading(false)
    if (classroomId === 'ghefcdyegdyw') {
      allDepartment();
      setAllHeading(false)
    } else if (classroomId === 'jhgkedbj') {
      onClickALL();
      setAllHeading(false)
    } else if (classroomId === 'lkfjilerf') {
      allStudents();
      setAllHeading(false)
    }
  };
  const handleStudentClick = (id: any) => {
    console.log('student id @@@@@', id);
    setSelected(id);
    dispatch(getCurrentSelectedUser('student'));
    localStorage.setItem('selectedStudentId', id);
    dispatch(getChildTotals('MATH01'));
    dispatch(getChildEngagements());
    dispatch(getChildReactions());
    dispatch(getChildQR());
  };
  const handleDepClick = (id: any) => {
    setDepSelect(true);
    setSelected(id);
    dispatch(getCurrentSelectedUser('dep'));
    dispatch(getDepartmentClassesSchoolLeader(id));
    dispatch(getEngagements({ id: id, type: 'DEPARTMENT' }));
    dispatch(getReactions({ id: id, type: 'DEPARTMENT' }));
    dispatch(getTotals({ id: id, type: 'DEPARTMENT' }));
  };
  const allDepartment = () => {
    setSelected(7);
    setDepSelect(false);
    dispatch(getAllStudentSchoolLeader());
    dispatch(getCurrentSelectedUser('all'));
    dispatch(getEngagements({ id: data?.data?.school?.id, type: 'SCHOOL' }));
    dispatch(getReactions({ id: data?.data?.school?.id, type: 'SCHOOL' }));
    dispatch(getTotals({ id: data?.data?.school?.id, type: 'SCHOOL' }));
  };
  const allStudents = () => {
    setSelected(2);
    dispatch(getAllStudentSchoolLeader());
    dispatch(getCurrentSelectedUser('all'));
    dispatch(getEngagements({ id: data?.data?.school?.id, type: 'SCHOOL' }));
    dispatch(getReactions({ id: data?.data?.school?.id, type: 'SCHOOL' }));
    dispatch(getTotals({ id: data?.data?.school?.id, type: 'SCHOOL' }));
  };
  const openModals = () => {
    if (selection === 'lkfjilerf') {
      openModal('AddStudent');
    } else if (selection === 'jhgkedbj') {
      openModal('AddClassRoomModal');
    }
  };
  return (
    <Box className="border border-[#EAECF0] lg:ml-3 lg:mr-2 rounded-lg">
      <div className=" items-center justify-between mt-3 mx-2 mb-2">
        <div className="flex items-center justify-between w-[100%]">
          {/* <div className="w-[40%] py-[5px] flex items-center border border-[#DCDCDC] rounded-lg mr-2 ">
            <img
              alt=""
              src={require('../../../../assets/Parent/search.png')}
              className="h-3 w-3 ml-2"
            />
            <input className="text-[12px] ml-2" placeholder="Search"></input>
          </div> */}
          <div className=" lg:w-[50%] w-[100%] p-[1px] flex border rounded-md border-['#E9EAEB'] items-center overflow-x-scroll  ">
            {newElement?.map((item: any) => (
              <div
                role="button"
                style={{
                  backgroundColor:
                    selection === item?.classroom?.id
                      ? '#20A58A'
                      : 'transparent',
                }}
                onClick={() => handleTabClick(item?.classroom?.id)}
                className={`h-6 lg:w-[50%] w-[80%] rounded-md flex items-center justify-center`}
              >
                <Typography
                  sx={{
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 1,
                    fontWeight: 600,
                    fontSize: 10,
                    textAlign: 'center',
                    color:
                      selection === item?.classroom?.id ? 'white' : '#717680',
                  }}
                >
                  {item?.classroom?.name}
                </Typography>
              </div>
            ))}
          </div>
          {selection === 'ghefcdyegdyw' || selection === 'tuyeuk' ? null : (
            <div
              role="button"
              className="bg-[#20A58A] h-[32px] w-[32px] rounded-lg "
              onClick={openModals}
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  color: 'white',
                  textAlign: 'center',
                  marginTop: 0.5,
                }}
              >
                +
              </Typography>
            </div>
          )}
        </div>
      </div>
      {selection === 'jhgkedbj' ? (
        <div className="flex items-center mt-2 ml-2">
         {!allHeading?
                   <div>
                   <div
                     style={{ backgroundColor: selected === 1 ? '#E9F6F3' : 'white' }}
                     onClick={onClickALL}
                     role="button"
                     className="flex items-center justify-center rounded-lg  border border-[#20A58A] h-[67px] mr-1 px-3 w-[180px] ]"
                   >
                     <div>
                       <div className="text-sm font-semibold">All Classrooms</div>
                       <div
                         role="button"
                         onClick={(e) => {
                           e.preventDefault();
                           e.stopPropagation();
                           setSelection('lkfjilerf');
                           allStudents();
                         }}
                         className="text-[#20A58A] underline text-[12px] font-normal"
                       >
                         All Students
                       </div>
                     </div>
                   </div>
                 </div>:
                 <div>
                 <div
                   style={{ backgroundColor: selected === 1 ? '#E9F6F3' : 'white' }}
                  // onClick={onClickALL}
                   role="button"
                   className="flex items-center justify-center rounded-lg  border border-[#20A58A] h-[67px] mr-1 px-3 w-[180px] ]"
                 >
                   <div>
                     <div className="text-sm font-semibold">{allHeadingText} Classrooms</div>
                      <div role='button' onClick={(e)=>{
                         e.preventDefault();
                         e.stopPropagation();
                         setSelected(2)
                     setSelection("lkfjilerf");
                    dispatch(getStudentsOfDepartment(ids))
                     }} className='text-[#20A58A] underline text-[12px] font-normal'>All Students</div>
                   </div>
                 </div>
               </div>
                 }
          <div
            className="flex items-center"
            style={{
              alignItems: 'center',
              // width: '100%',
              overflowX: 'scroll',
              padding: 0,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <List
              dense
              sx={{
                //width: '100%',
                //maxWidth: 360,
                // bgcolor: 'red',
                borderRadius: 10,
              }}
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {classRoom &&
                classRoom?.length > 0 &&
                classRoom?.map((value: any) => {
                  return (
                    <div className="flex">
                      <div
                      //className="flex"
                      >
                        <ListItem
                          key={value?.classroom?.id}
                          style={{
                            padding: 0,
                            //height: '50px',
                            // width: '200px',
                            //backgroundColor: 'red',
                          }}
                          disablePadding
                        >
                          <ListItemButton
                            style={{
                              padding: 0,
                              paddingLeft: 10,
                              //paddingTop: 10,
                            }}
                          >
                            <StudentInformation
                              role={'Edu'}
                              clickText="View Students"
                              onClickView={() => {
                                setSelection('lkfjilerf');
                                handleClassroomClick(value?.classroom?.id);
                              }}
                              // studentImg={value.studentImg}
                              studentName={value?.classroom?.name}
                              grade={value?.classroom?.grade?.name}
                              //schoolName={data?.data?.schoolName}
                              selected={
                                selected === value?.classroom?.id ? true : false
                              }
                              onClick={() =>
                                handleClassroomClick(value?.classroom?.id)
                              }
                              clickDelete={() => {
                                openModal('WarningModal', {
                                  title: 'Are you sure?',
                                  description: `Are you sure you want to delete ${value?.classroom?.name}? This action cannot be undone.`,
                                  onContinue: () =>
                                    handleDeleteClass(value?.classroom?.id),
                                });
                              }}
                            />
                          </ListItemButton>
                        </ListItem>
                      </div>
                    </div>
                  );
                })}
              {classRoom?.classrooms &&
                classRoom?.classrooms?.length > 0 &&
                classRoom?.classrooms?.map((value: any) => {
                  return (
                    <div className="flex">
                      <div
                      //className="flex"
                      >
                        <ListItem
                          key={value?.classroom?.id}
                          style={{
                            padding: 0,
                            //height: '50px',
                            // width: '200px',
                            //backgroundColor: 'red',
                          }}
                          disablePadding
                        >
                          <ListItemButton
                            style={{
                              padding: 0,
                              paddingLeft: 10,
                              //paddingTop: 10,
                            }}
                          >
                            <StudentInformation
                              role={'Edu'}
                              // studentImg={value.studentImg}
                              clickText="View Students"
                              onClickView={(e: any) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setSelection('lkfjilerf');
                                handleClassroomClick(value?.classroom?.id);
                              }}
                              studentName={value?.classroom?.name}
                              grade={value?.classroom?.grade?.name}
                              // schoolName={classRoom?.school?.name}
                              selected={
                                selected === value?.classroom?.id ? true : false
                              }
                              onClick={() =>
                                handleClassroomClick(value?.classroom?.id)
                              }
                              clickDelete={() => {
                                openModal('WarningModal', {
                                  title: 'Are you sure?',
                                  description: `Are you sure you want to delete ${value?.classroom?.name}? This action cannot be undone.`,
                                  onContinue: () =>
                                    handleDeleteClass(value?.classroom?.id),
                                });
                              }}
                            />
                          </ListItemButton>
                        </ListItem>
                      </div>
                    </div>
                  );
                })}
              {/* {depSelect
                ? classRoom?.map((value: any) => {
                    return (
                      <div className="flex">
                        <div
                        //className="flex"
                        >
                          <ListItem
                            key={value?.classroom?.id}
                            style={{
                              padding: 0,
                              //height: '50px',
                              // width: '200px',
                              //backgroundColor: 'red',
                            }}
                            disablePadding
                          >
                            <ListItemButton
                              style={{
                                padding: 0,
                                paddingLeft: 10,
                                //paddingTop: 10,
                              }}
                            >
                              <StudentInformation
                                role={'Edu'}
                                clickText='View Students'
                                onClickView={()=>{setSelection("lkfjilerf");
                                  handleClassroomClick(value?.classroom?.id)
                                }}
                                // studentImg={value.studentImg}
                                studentName={value?.classroom?.name}
                                grade={value?.classroom?.grade?.name}
                                //schoolName={data?.data?.schoolName}
                                selected={
                                  selected === value?.classroom?.id
                                    ? true
                                    : false
                                }
                                onClick={() =>
                                  handleClassroomClick(value?.classroom?.id)
                                }
                                clickDelete={() => {
                                  openModal('WarningModal', {
                                    title: 'Are you sure?',
                                    description: `Are you sure you want to delete ${value?.classroom?.name}? This action cannot be undone.`,
                                    onContinue: () =>
                                      handleDeleteClass(value?.classroom?.id),
                                  });
                                }}
                              />
                            </ListItemButton>
                          </ListItem>
                        </div>
                      </div>
                    );
                  })
                : classRoom?.classrooms?.map((value: any) => {
                    return (
                      <div className="flex">
                        <div
                        //className="flex"
                        >
                          <ListItem
                            key={value?.classroom?.id}
                            style={{
                              padding: 0,
                              //height: '50px',
                              // width: '200px',
                              //backgroundColor: 'red',
                            }}
                            disablePadding
                          >
                            <ListItemButton
                              style={{
                                padding: 0,
                                paddingLeft: 10,
                                //paddingTop: 10,
                              }}
                            >
                              <StudentInformation
                                role={'Edu'}
                                // studentImg={value.studentImg}
                                clickText='View Students'
                                onClickView={()=>{setSelection("lkfjilerf");
                                  handleClassroomClick(value?.classroom?.id)
                                }}
                                studentName={value?.classroom?.name}
                                grade={value?.classroom?.grade?.name}
                               // schoolName={classRoom?.school?.name}
                                selected={
                                  selected === value?.classroom?.id
                                    ? true
                                    : false
                                }
                                onClick={() =>
                                  handleClassroomClick(value?.classroom?.id)
                                }
                                clickDelete={() => {
                                  openModal('WarningModal', {
                                    title: 'Are you sure?',
                                    description: `Are you sure you want to delete ${value?.classroom?.name}? This action cannot be undone.`,
                                    onContinue: () =>
                                      handleDeleteClass(value?.classroom?.id),
                                  });
                                }}
                              />
                            </ListItemButton>
                          </ListItem>
                        </div>
                      </div>
                    );
                  })} */}
            </List>
          </div>
        </div>
      ) : selection === 'lkfjilerf' ? (
        <div className="flex items-center mt-2 ml-2">
         {!allHeading?
          <div>
          <div
            style={{ backgroundColor: selected === 2 ? '#E9F6F3' : 'white' }}
            onClick={allStudents}
            role="button"
            className="flex items-center justify-center rounded-lg  border border-[#20A58A] h-[60px] mr-1 px-3 w-[180px]"
          >
            <div className="text-sm font-semibold">All Students</div>
          </div>
        </div>:
        <div>
        <div
          style={{ backgroundColor: selected === 2 ? '#E9F6F3' : 'white' }}
         // onClick={allStudents}
          role="button"
          className="flex items-center justify-center rounded-lg  border border-[#20A58A] h-[60px] mr-1 px-3 w-[180px]"
        >
          <div className="text-sm font-semibold">{allHeadingText} Students</div>
         
        </div>
      </div>
          }
          <div
            className="flex items-center"
            style={{
              alignItems: 'center',
              // width: '100%',
              overflowX: 'scroll',
              padding: 0,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <List
              dense
              sx={{
                //width: '100%',
                //maxWidth: 360,
                // bgcolor: 'red',
                borderRadius: 10,
              }}
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {students?.map((value: any) => {
                return (
                  <div className="flex">
                    <div
                    //className="flex"
                    >
                      <ListItem
                        key={value?.user?.id}
                        style={{
                          padding: 0,
                          //height: '50px',
                          // width: '200px',
                          //backgroundColor: 'red',
                        }}
                        disablePadding
                      >
                        <ListItemButton
                          style={{
                            padding: 0,
                            paddingLeft: 10,
                            // paddingTop: 10,
                          }}
                        >
                          <StudentInformation
                            gemsToken="0"
                            role={'student'}
                            studentImg={value?.user?.profileImage || student}
                            studentName={value?.user?.name}
                            grade={value?.user?.username}
                            schoolName={''}
                            selected={
                              selected === value?.user?.id ? true : false
                            }
                            onClick={() => handleStudentClick(value?.user?.id)}
                            clickDelete={() => {
                              openModal('WarningModal', {
                                title: 'Are you sure?',
                                description: `Are you sure you want to delete ${value?.user?.username}? This action cannot be undone.`,
                                onContinue: () =>
                                  handleDeleteStudent(value?.user?.id),
                              });
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </div>
                  </div>
                );
              })}
            </List>
          </div>
        </div>
      ) : selection === 'ghefcdyegdyw' ? (
        <div className="flex items-center mt-2 ml-2">
          <div>
            <div
              style={{ backgroundColor: selected === 7 ? '#E9F6F3' : 'white' }}
              onClick={allDepartment}
              role="button"
              className="flex items-center justify-center rounded-lg  border border-[#20A58A] h-[67px] mr-1 px-3 w-[180px]"
            >
              <div>
                <div className="text-sm font-semibold">All Departments</div>
                <div
                  role="button"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setSelection('jhgkedbj');
                    onClickALL();
                  }}
                  className="text-[#20A58A] underline text-[12px] font-normal"
                >
                  All Classes
                </div>
              </div>
            </div>
          </div>
          <div
            className="flex items-center"
            style={{
              alignItems: 'center',
              // width: '100%',
              overflowX: 'scroll',
              padding: 0,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <List
              dense
              sx={{
                //width: '100%',
                //maxWidth: 360,
                // bgcolor: 'red',
                borderRadius: 10,
              }}
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {departmentList?.map((value: any) => {
                return (
                  <div className="flex">
                    <div
                    //className="flex"
                    >
                      <ListItem
                        key={value?.id}
                        style={{
                          padding: 0,
                          //height: '50px',
                          // width: '200px',
                          //backgroundColor: 'red',
                        }}
                        disablePadding
                      >
                        <ListItemButton
                          style={{
                            padding: 0,
                            paddingLeft: 10,
                            //paddingTop: 10,
                          }}
                        >
                          <StudentInformation
                            role={'Dep'}
                            clickText="View Classroom"
                            onClickView={(e:any) => {
                              e.preventDefault();
                              e.stopPropagation()
                              setAllHeading(true);
                              setAllHeadingText(value?.name);
                              setSelection('jhgkedbj');
                              setSelected(1);
                              setIds(value?.id)
                              dispatch(getCurrentSelectedUser('dep'));
                              dispatch(
                                getDepartmentClassesSchoolLeader(value?.id),
                              );
                              dispatch(
                                getEngagements({
                                  id: value?.id,
                                  type: 'DEPARTMENT',
                                }),
                              );
                              dispatch(
                                getReactions({
                                  id: value?.id,
                                  type: 'DEPARTMENT',
                                }),
                              );
                              dispatch(
                                getTotals({
                                  id: value?.id,
                                  type: 'DEPARTMENT',
                                }),
                              );
                            }}
                            studentName={value?.name}
                            grade={value?.id}
                            // schoolName={data?.data?.school?.name}
                            selected={selected === value?.id ? true : false}
                            onClick={() => handleDepClick(value?.id)}
                            // clickDelete={() => {

                            //   openModal('WarningModal', {
                            //     title: 'Are you sure?',
                            //     description:
                            //       `Are you sure you want to delete ${value?.user?.username}? This action cannot be undone.`,
                            //     onContinue:()=>handleDeleteStudent(value?.user?.id)
                            //   });
                            // }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </div>
                  </div>
                );
              })}
            </List>
          </div>
        </div>
      ) : (
        <div>departmenst</div>
      )}
    </Box>
  );
}
export default ClassRoomComponentSchoolLeader;
