import AddAnoterParentWarningModal from './AddAnoterParentWarningModal';
import AddAnotherClassRoomWarningModal from './AddAnotherClassRoomWarningModal';
import AddAnotherStudentWarningModal from './AddAnotherStudentWarningModal';
import AddAnotherUserModal from './AddAnotherUserModal';
import AddClassRoomModal from './AddClassRoomModal';
import AddHomeSchoolClassRoomModal from './AddHomeSchoolClassRoomModal';
import AddParentModal from './AddParentModal';
import AddStudent from './AddStudent';
import AddStudentModal from './AddStudentModal';
import ApplyPromoModal from './ApplyPromoModal';
import EducatorPremiumPlan from './EducatorPremiumPlan';
import EducatorUpgradeSuccess from './EducatorUpgradeSuccess';
import EducatorWelcome from './EducatorWelcome';
import ParentFreePlanStep1 from './ParentFreePlanStep1';
import ParentPremiumPlan from './ParentPremiumPlan';
import ParentSchoolSettingsModal from './ParentSchoolSettingsModal';
import ParentSelectAcademic from './ParentSelectAcademic';
import ParentSelectSocialEmotion from './ParentSelectSocialEmotion';
import ParentTrial from './ParentTrial';
import ParentUpgradeToPlanWarning from './ParentUpgradeToPlanWarning';
import PaymentDetails from './PaymentDetails';
import QrCodeErrorModal from './QrCodeErrorModal';
import QrCodeSuccessModal from './QrCodeSuccessModal';
import SchoolSettingsModal from './SchoolSettingsModal';
import UpgradeToAddMoreStudentModal from './UpgradeToAddMoreStudentModal';
import WarningModal from './WarningModal';

const modals = {
  PaymentDetails,
  WarningModal,
  ApplyPromoModal,
  QrCodeErrorModal,
  QrCodeSuccessModal,
  ParentFreePlanStep1,
  AddStudentModal,
  AddAnotherStudentWarningModal,
  UpgradeToAddMoreStudentModal,
  AddParentModal,
  ParentUpgradeToPlanWarning,
  AddAnoterParentWarningModal,
  AddStudent,
  ParentTrial,
  ParentSelectAcademic,
  ParentSelectSocialEmotion,
  ParentPremiumPlan,
  AddHomeSchoolClassRoomModal,
  AddAnotherClassRoomWarningModal,
  SchoolSettingsModal,
  EducatorPremiumPlan,
  EducatorWelcome,
  EducatorUpgradeSuccess,
  AddAnotherUserModal,
  ParentSchoolSettingsModal,
  AddClassRoomModal,
};

export default modals;
