import React, { useRef } from 'react';
import { styled } from '@mui/material/styles';
import { Loader, Radio, Select } from '@mantine/core';
import { Typography } from '@mui/material';
import { ChevronDown } from 'mdi-material-ui';
function CustomizedSelectComponent({
  value,
  onChange,
  data, 
  heading,
  error,
  gradeLoading,
  fullWidth,
  placeholder,
  onClick,
  onkeydown,
  ref,
  dropdownOpened,
  onDropdownOpen,
  onDropdownClose
}: {
  value: any;
  onChange: any;
  data: any;
  heading: string;
  error?: any;
  gradeLoading?: any;
  fullWidth?:boolean;
  placeholder?:any;
  onClick?:any
  onkeydown?:any;
  ref?: any;
  dropdownOpened?:boolean,
  onDropdownOpen?:any,
  onDropdownClose?:any
}) {
  const nextInputRef = useRef<HTMLInputElement | null>(null);
  return (
    <div className={fullWidth?"w-[100%] m-1":"w-[96%] m-1"}>
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: 500,
          color: '#484848',
         // marginTop: 3,
 
        }}
      >
        {heading}
      </Typography>
      <Select
      ref={ref}
      dropdownOpened={dropdownOpened}
      onDropdownOpen={onDropdownOpen}
      onDropdownClose={onDropdownClose}
      onKeyDown={onkeydown}
      onClick={onClick}
        classNames={{
          input:
            'block text-[7px] border focus:border  border-[#DCDCDC] !rounded-lg shadow-sm focus:outline-none appearance-none justify-center focus:border-[#20A58A] focus:ring-1 focus:ring-[#20A58A] transition-all duration-200 ease-in-out',
          error: '!border-[#F41F52] focus:border-[#F41F52]',
          section: '!space-y-2',
          label: 'block !text-[14px] !text-[#484848] ',
         // root: '!space-y-2',
        }}
        size="md"
        placeholder={placeholder}
        error={error}
        value={value}
        data={data}
        onChange={onChange}
        rightSection={<ChevronDown />}
        disabled={gradeLoading}
        comboboxProps={{ withinPortal: false }}
       // style={{marginTop:1}}
      />
    </div>
  );
}

export default CustomizedSelectComponent;
