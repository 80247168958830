import { apiSlice } from '..';
import {
  GetSessionDetailsBody,
  PlanResponse,
  StripePaymentSessionResponse,
  TLineItems,
} from './types';

export const paymentApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSubscriptions: builder.query<PlanResponse, void>({
      query: () => {
        return {
          url: 'payment-subscription/plans?planCategory=B2B',
          method: 'GET',
        };
      },
    }),
    approveOrRejectChild: builder.mutation({
      query: ({ confirmationId, confirmationStatus }) => {
        return {
          url: '/auth/update-parent-child-confirmation-status',
          method: 'POST',
          body: {
            confirmationId,
            confirmationStatus,
          },
        };
      },
    }),
    iniTiatePayment: builder.mutation({
      query: ({ items, userId }: { items: TLineItems; userId: string }) => {
        return {
          url: '/payment-subscription/create-checkout-session',
          method: 'POST',
          body: {
            lineItems: items || [],
            userId: userId,
            clientCancelUrl: `${window.location.href}`,
          },
        };
      },
    }),
    getSessionDetails: builder.query<
      StripePaymentSessionResponse,
      GetSessionDetailsBody
    >({
      query: (sessionId) => {
        return {
          url: `payment-subscription/get-checkout-session/${sessionId}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useGetSubscriptionsQuery,
  useApproveOrRejectChildMutation,
  useIniTiatePaymentMutation,
  useGetSessionDetailsQuery,
} = paymentApi;
