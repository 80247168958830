import { Button, Modal, rem } from '@mantine/core';
import useModal from 'components/Modal/hook/useModal';
import useAuth from 'hooks/useAuth';
import { useGetMyProfileQuery } from 'store/apis/profile';
import cn from 'utils/cn';

export default function UpgradeToAddMoreStudentModal() {
  const { closeModal, isOpen, modal, openModal } = useModal<{
    onContinue?: () => void;
    educatorPlan?: 'free' | 'premium';
  }>();

  const { loginUser, redirectToLogin } = useAuth();
  const { data } = useGetMyProfileQuery();

  return (
    // <Modal isOpen={isOpen('AskAGrownupModal')} onClose={closeModal}>
    //   <Modal.Content className="[--max-width:_45rem] [--top:_2rem]  md:[--top:_10%]">
    //     <Modal.CloseButton />
    <Modal
      opened={isOpen('UpgradeToAddMoreStudentModal')}
      onClose={closeModal}
      closeOnClickOutside={false}
      closeOnEscape={false}
      size={'xl'}
      centered
      shadow="sm"
      radius={'lg'}
      classNames={{
        title:
          '!text-lg !font-semibold lg:!text-xl 2xl:!text-2xl !text-[#2C3E50]',
        close:
          '!text-[#2C3E50] !text-lg !font-semibold lg:!text-xl 2xl:!text-2xl',
      }}
      padding={rem(24)}
      zIndex={999999999}
      withCloseButton={false}
    >
      <div className="grid gap-4 lg:gap-6">
        <div className="grid gap-2 items-start justify-start align-top max-sm:justify-center">
          <h1
            className={cn(
              '!font-regular !text-lg lg:!text-xl 2xl:!text-2xl text-[#131515] text-center mt-2 max-sm:text-center',
            )}
          >
            The <span className="font-bold text-black">FREE Plan</span> is only{' '}
            <span className="font-bold text-black"> include 1 student</span>,
            Please upgrade your plan to add more students.
          </h1>
        </div>

        <div className="grid gap-4 lg:grid-cols-2">
          <Button
            onClick={() => {
              const token = localStorage.getItem('token');
              if (data?.data && token) {
                loginUser({
                  token,
                  redirectTo: 'plans',
                });
              } else {
                redirectToLogin();
              }
            }}
            className="max-w-full !rounded-xl"
            color="#20A58A"
            size="xl"
            fw={500}
          >
            Upgrade Plan
          </Button>
          <Button
            onClick={() => {
              if (modal?.props?.onContinue) {
                modal?.props?.onContinue();
                return;
              }

              if (data?.data?.accountSetup?.accountPaymentStatus === 'FREE' && data?.data?.type !== 'parent') {
                openModal('ParentSchoolSettingsModal', {
                  educatorPlan: 'free',
                  cache: true,
                });
                return;
              }

              openModal('AddParentModal', { cache: true });
            }}
            className="max-w-full !rounded-xl"
            color="#999999"
            size="xl"
            fw={500}
          >
            Skip, For now
          </Button>
        </div>
      </div>
    </Modal>

    // </Modal.Content>
    // </Modal>
  );
}
