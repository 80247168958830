// *** React Imports
import React, { useEffect, useState } from 'react';
// *** Material UI Imports
import {
  Box,
  Grid,
  TextField,
  Autocomplete,
  InputLabel,
  CircularProgress,
  InputAdornment,
  Checkbox,
  Avatar,
  Typography,
  Chip,
  List,
  ListItem,
  Paper,
  FormControlLabel,
} from '@mui/material';

import MDDialog from '@core/components/MDDialog';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { profile } from 'assets';

/* mui-color-input' */
import { useAppDispatch, useAppSelector } from 'store/store';
import Validations from 'helper/validations';
import {
  setBtnLoading,
  startLoading,
  stopLoading,
} from 'store/Slice/loadingSlice';

import AddSkill from 'components/dialog/skill/addSkill';
import AddSubTopic from 'components/dialog/subtopic/addSubTopic';
import {
  getDistrictByID,
  addUpdateDistrict,
  getDistrictList,
  getAllSchoolOfDistrict,
  getStates,
  getCities,
} from 'store/Thunk/districtThunk';
import { getGradeList } from 'store/Thunk/videoThunk';
import { addUpdateSchool, getAllSchoolList } from 'store/Thunk/schoolThunk';
import { getSchoolLeaderList } from 'store/Thunk/schoolLeaderThunk';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function Index({ open, onClose, title, saveTbtText, editId }: any) {
  const dispatch: any = useAppDispatch();
  const { btnLoading, screenLoading } = useAppSelector(
    (state: any) => state.loadingReducer,
  );
  const { districtID } = useAppSelector((state: any) => state.districtReducer);

  const { districtList } = useAppSelector(
    (state: any) => state.districtReducer,
  );
  const { schoolLeaderList } = useAppSelector(
    (state: any) => state.schoolLeaderReducer,
  );

  const [formData, setFormData] = useState<any>({
    grade: [],
  });
  const [formError, setFormError] = useState<any>({});

  const [screenLoader, setScreenLoader] = React.useState(false);
  const [loadingDistrict, setLoadingDistrict] = React.useState(false);
  const [loadingSchoolLeader, setLoadingSchoolLeader] = React.useState(false);
  const [openDistrict, setOpenDistrict] = React.useState<boolean>(false);
  const [openSchoolType, setOpenSchoolType] = React.useState(false);
  const [openSpecificSchoolType, setOpenSpecificSchoolType] =
    React.useState(false);
  const [openSchoolGrade, setOpenSchoolGrade] = React.useState(false);
  const [openSchoolLeader, setOpenSchoolLeader] = React.useState(false);
  const [openCities, setOpenCities] = useState(false);

  const [openEndGrade, setOpenEndGrade] = React.useState(false);
  const [grade, setGrade] = React.useState<any[]>([]);
  const [openState, setOpenState] = React.useState(false);
  const [openSubTopicDialog, setOpenSubTopicDialog] = React.useState(false);
  const [newSubTopicValue, setNewSubTopicValue] = React.useState({});
  const [openSkillDialog, setOpenSkillDialog] = React.useState(false);
  const [newSkillValue, setNewSkillValue] = React.useState({});
  const { stateList } = useAppSelector((state: any) => state.districtReducer);
  const { cityList } = useAppSelector((state: any) => state.districtReducer);
  console.log('cityList', cityList);
  useEffect(() => {
    dispatch(getCities(formData.state?.id));
    // setLoadingState(openState);
  }, [formData.state]);
  useEffect(() => {
    dispatch(getStates({ pageIndex: 0, pageSize: 7000 }));
    // setLoadingState(openState);
  }, []);
  const SchoolTypesList = [
    { name: 'Regular' },
    { name: 'Special' },
    { name: 'Education' },
    { name: 'Career' },
    { name: 'Technical' },
    { name: 'Alternative Education' },
  ];

  const SpecificSchoolType = [
    { name: 'All' },
    { name: 'Charter' },
    { name: 'Magnet' },
  ];

  const SchoolGrade = [
    { name: 'Elementary' },
    { name: 'Middle' },
    { name: 'High School' },
  ];
  const { schoolByID } = useAppSelector((state) => state.schoolReducer);
  console.log('editId', editId);
  useEffect(() => {
    setFormData({
      name: (schoolByID && schoolByID[0]?.name) || '',
      schoolType: (schoolByID && { name: schoolByID[0]?.schoolType }) || '',
      specificSchoolType:
        (schoolByID && { name: schoolByID[0]?.specificSchoolType }) || '',
      schoolGrades: (schoolByID && { name: schoolByID[0]?.schoolGrades }) || '',
      streetAddress: (schoolByID && schoolByID[0]?.streetAddress) || '',
      city: (schoolByID && schoolByID[0]?.city) || '',
      state: (schoolByID && schoolByID[0]?.state) || '',
      zipCode: (schoolByID && schoolByID[0]?.zipCode) || '',
      county: (schoolByID && schoolByID[0]?.county) || '',
      phone: (schoolByID && schoolByID[0]?.phone) || '',
      districtId: (schoolByID && schoolByID[0]?.ncesDistrictId) || '',
      ncesSchoolId: (schoolByID && schoolByID[0]?.ncesSchoolId) || '',
      schoolLeaderIds: (schoolByID && schoolByID[0]?.user) || '',
    });
  }, [schoolByID]);

  // useEffect(() => {
  //   if (districtID?.length > 0) {
  //     setFormData({
  //       ...formData,
  //       district: districtID[0],
  //       districtId: districtID[0]?.id,
  //     });
  //   }
  // }, [districtID]);

  useEffect(() => {
    dispatch(getDistrictList({ pageSize: 500, pageIndex: 0 }));
  }, []);
  console.log('setLoadingDistrict', loadingDistrict);
  console.log('setLoadingDistrict', schoolByID);
  console.log('setLoadingDistrict', formData);
  useEffect(() => {
    dispatch(getSchoolLeaderList());
  }, []);

  const handleChangeInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleChangeAutocomplete =
    (name: string) =>
    (
      event: React.SyntheticEvent<Element, Event>,
      newValue: string | null | any,
    ) => {
      let param = {
        [name]: newValue,
      };
      setFormData({ ...formData, ...param });
    };

  const handleSkillDialog = (name: string = '') => {
    setNewSkillValue({
      ...(!openSkillDialog
        ? {
            topic: formData.topic,
            name: name,
            subTopic: formData.sub_topic,
          }
        : {}),
    });
    setOpenSkillDialog(!openSkillDialog);
  };

  const handleSubTopicDialog = (name: string = '') => {
    setNewSubTopicValue({
      ...(!openSubTopicDialog
        ? {
            topic: formData.topic,
            name: name,
          }
        : {}),
    });
    setOpenSubTopicDialog(!openSubTopicDialog);
  };

  const onSave = (event: any) => {
    event.preventDefault();
    let _request: any = {
      id: editId || '',
      name: formData?.name || '',
      schoolType: formData?.schoolType?.name || '',
      specificSchoolType: formData?.specificSchoolType?.name || '',
      schoolGrades: formData?.schoolGrades?.name || '',
      streetAddress: formData?.streetAddress || '',
      city: formData?.city.id || '',
      state: formData?.state?.id || '',
      zipCode: formData?.zipCode || '',
      county: formData?.county || '',
      phone: formData?.phone || '',
      districtId: formData?.districtId?.id || formData?.districtId || '',
      ncesSchoolId: formData?.ncesSchoolId || '',
      schoolLeaderIds: formData?.schoolLeaderIds
        ? formData?.schoolLeaderIds?.map((item: any) => {
            return item?.id;
          })
        : '',
    };

    let allError = Validations.ValidateSchoolDataFrom(_request);
    console.log('_request', _request);
    console.log('_request', allError);
    if (
      Object.entries(allError).length === 0 &&
      allError.constructor === Object
    ) {
      console.log('_request', _request);
      dispatch(setBtnLoading(true));
      dispatch(addUpdateSchool(_request)).then(() => {
        if (districtID?.length && districtID[0]?.ncesDistrictId) {
          dispatch(startLoading());
          dispatch(
            getAllSchoolOfDistrict({
              pageSize: 8,
              pageIndex: 1,
              id: districtID[0]?.ncesDistrictId,
            }),
          ).then(() => dispatch(stopLoading()));
        } else {
          dispatch(startLoading());
          dispatch(getAllSchoolList({ pageSize: 8, pageIndex: 1 })).then(() =>
            dispatch(stopLoading()),
          );
        }
      });
      onClose();
    } else {
      setFormError(allError);
    }
  };

  const dialogProps = {
    open,
    onClose,
    onSave,
    title,
    size: 'md',
    saveTbtText,
    closeBtnText: 'Cancel',
    loading: btnLoading,
    screenLoader,
  };

  console.log('districtList', districtList);
  const items = ['Apple', 'Banana', 'Cherry', 'Durian', 'Elderberry'];

  return (
    <MDDialog {...dialogProps}>
      <Box sx={{ p: 3 }}>
        <Grid container spacing={6}>
          {districtList?.items?.length ? (
            <Grid item md={4} sm={6} xs={12}>
              <InputLabel
                error={formError && formError?.districtId}
                sx={{ color: '#484848', pb: 2 }}
              >
                District
              </InputLabel>
              <Autocomplete
                id="districtId"
                open={openDistrict}
                onOpen={() => setOpenDistrict(true)}
                onClose={() => setOpenDistrict(false)}
                isOptionEqualToValue={(option, value) =>
                  option === value ||
                  option?.ncesDistrictId === value?.ncesDistrictId
                }
                getOptionLabel={(option) =>
                  option?.ncesDistrictId || option || ''
                }
                options={districtList?.items}
                loading={loadingDistrict}
                ListboxComponent={(props) => {
                  return <List {...props}>{props.children}</List>;
                }}
                // filterSelectedOptions
                value={formData?.districtId || []}
                onChange={handleChangeAutocomplete('districtId')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select district"
                    error={formError && formError?.districtId}
                    helperText={
                      formError && formError?.districtId
                        ? formError.districtId
                        : ''
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingDistrict ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          ) : null}
          <Grid item md={4} sm={6} xs={12}>
            <InputLabel
              error={formError && formError?.name}
              sx={{ color: '#484848', pb: 2 }}
            >
              School Name
            </InputLabel>
            <TextField
              required
              fullWidth
              id="name"
              value={formData?.name || ''}
              onChange={handleChangeInput}
              placeholder="Enter school name"
              name="name"
              error={formError && formError?.name}
              helperText={formError && formError?.name ? formError.name : ''}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <InputLabel
              error={formError && formError?.ncesSchoolId}
              sx={{ color: '#484848', pb: 2 }}
            >
              School ID
            </InputLabel>
            <TextField
              required
              fullWidth
              id="ncesSchoolId"
              value={formData?.ncesSchoolId || ''}
              onChange={handleChangeInput}
              placeholder="Enter school ID"
              name="ncesSchoolId"
              error={formError && formError?.ncesSchoolId}
              helperText={
                formError && formError?.ncesSchoolId
                  ? formError.ncesSchoolId
                  : ''
              }
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <InputLabel
              error={formError && formError?.schoolType}
              sx={{ color: '#484848', pb: 2 }}
            >
              School type
            </InputLabel>
            <Autocomplete
              id="schoolType"
              open={openSchoolType}
              onOpen={() => setOpenSchoolType(true)}
              onClose={() => setOpenSchoolType(false)}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              getOptionLabel={(option) => option?.name || ''}
              options={SchoolTypesList}
              filterSelectedOptions
              value={formData?.schoolType || {}}
              onChange={handleChangeAutocomplete('schoolType')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select school type"
                  error={formError && formError?.schoolType}
                  helperText={
                    formError && formError?.schoolType
                      ? formError.schoolType
                      : ''
                  }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <InputLabel
              error={formError && formError?.specificSchoolType}
              sx={{ color: '#484848', pb: 2 }}
            >
              Specific School
            </InputLabel>
            <Autocomplete
              id="specificSchoolType"
              open={openSpecificSchoolType}
              onOpen={() => setOpenSpecificSchoolType(true)}
              onClose={() => setOpenSpecificSchoolType(false)}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              getOptionLabel={(option) => option?.name || ''}
              options={SpecificSchoolType}
              filterSelectedOptions
              value={formData?.specificSchoolType || {}}
              onChange={handleChangeAutocomplete('specificSchoolType')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select specific school"
                  error={formError && formError?.specificSchoolType}
                  helperText={
                    formError && formError?.specificSchoolType
                      ? formError.specificSchoolType
                      : ''
                  }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <InputLabel
              error={formError && formError?.schoolGrades}
              sx={{ color: '#484848', pb: 2 }}
            >
              School Grades
            </InputLabel>
            <Autocomplete
              id="districtType"
              open={openSchoolGrade}
              onOpen={() => setOpenSchoolGrade(true)}
              onClose={() => setOpenSchoolGrade(false)}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              getOptionLabel={(option) => option?.name || ''}
              options={SchoolGrade}
              filterSelectedOptions
              value={formData?.schoolGrades || {}}
              onChange={handleChangeAutocomplete('schoolGrades')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select school grades"
                  error={formError && formError?.schoolGrades}
                  helperText={
                    formError && formError?.schoolGrades
                      ? formError.schoolGrades
                      : ''
                  }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <InputLabel
              error={formError && formError?.streetAddress}
              sx={{ color: '#484848', pb: 2 }}
            >
              Street Address
            </InputLabel>
            <TextField
              required
              fullWidth
              id="streetAddress"
              value={formData?.streetAddress || ''}
              onChange={handleChangeInput}
              placeholder="Enter street address"
              name="streetAddress"
              error={formError && formError?.streetAddress}
              helperText={
                formError && formError?.streetAddress
                  ? formError.streetAddress
                  : ''
              }
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <InputLabel
              error={formError && formError?.county}
              sx={{ color: '#484848', pb: 2 }}
            >
              County
            </InputLabel>
            <TextField
              required
              fullWidth
              id="county"
              value={formData?.county || ''}
              onChange={handleChangeInput}
              placeholder="Enter County"
              name="county"
              error={formError && formError?.county}
              helperText={
                formError && formError?.county ? formError.county : ''
              }
            />
          </Grid>

          <Grid item md={4} sm={6} xs={12}>
            <InputLabel
              error={formError && formError?.state}
              sx={{ color: '#484848', pb: 2 }}
            >
              State
            </InputLabel>

            <Autocomplete
              id="states"
              open={openState}
              onOpen={() => setOpenState(true)}
              onClose={() => setOpenState(false)}
              getOptionLabel={(option) => option.name}
              options={stateList}
              value={formData?.state ? formData?.state : { name: '', id: '' }}
              isOptionEqualToValue={(option, value) => option.id === value}
              filterSelectedOptions
              onChange={handleChangeAutocomplete('state')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="What state is your school in?"
                  error={formError && formError?.state}
                  helperText={formError && formError?.state}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <InputLabel
              error={formError && formError?.city}
              sx={{ color: '#484848', pb: 2 }}
            >
              City
            </InputLabel>

            <Autocomplete
              id="cities"
              open={openCities}
              onOpen={() => setOpenCities(true)}
              onClose={() => setOpenCities(false)}
              getOptionLabel={(option) => option.name || ''}
              options={cityList}
              isOptionEqualToValue={(option, value) => option.id === value}
              value={formData?.city || {}}
              sx={{ width: '100%' }}
              filterSelectedOptions
              onChange={(event, value) => {
                handleChangeAutocomplete('city')(event, value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="What city is your school in?"
                  error={formError && formError?.city}
                  helperText={formError && formError?.city}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <InputLabel
              error={formError && formError?.zipCode}
              sx={{ color: '#484848', pb: 2 }}
            >
              Zip code
            </InputLabel>
            <TextField
              required
              fullWidth
              id="zipCode"
              value={formData?.zipCode || ''}
              onChange={handleChangeInput}
              placeholder="Enter zip code"
              name="zipCode"
              error={formError && formError?.zipCode}
              helperText={
                formError && formError?.zipCode ? formError.zipCode : ''
              }
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <InputLabel
              error={formError && formError?.phone}
              sx={{ color: '#484848', pb: 2 }}
            >
              Phone
            </InputLabel>
            <TextField
              required
              fullWidth
              id="phone"
              value={formData?.phone || ''}
              onChange={handleChangeInput}
              placeholder="Enter phone number"
              name="phone"
              error={formError && formError?.phone}
              helperText={formError && formError?.phone ? formError.phone : ''}
            />
          </Grid>
          {schoolLeaderList?.items ? (
            <Grid item md={4} sm={12} xs={12}>
              <InputLabel
                error={formError && formError?.schoolLeaderIds}
                sx={{ color: '#484848', pb: 2 }}
              >
                School Leader
              </InputLabel>
              <Autocomplete
                multiple
                disableCloseOnSelect
                id="schoolLeaderIds"
                open={openSchoolLeader}
                onOpen={() => setOpenSchoolLeader(true)}
                onClose={() => setOpenSchoolLeader(false)}
                isOptionEqualToValue={(option, value) =>
                  option?.name === value?.name
                }
                getOptionLabel={(option) => option?.name || option?.email || ''}
                renderOption={(props, option, { selected }) => (
                  <li
                    {...props}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Grid
                      container
                      spacing={1}
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Grid item xs={2}>
                        <Avatar
                          alt={'avatar_profile'}
                          sx={{ height: '20px', width: '20px', mr: 2 }}
                          src={profile}
                        />
                      </Grid>
                      <Grid item xs={9} sx={{ overflow: 'hidden' }}>
                        <Typography variant="body1">
                          {option?.name || option?.email || ''}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                      </Grid>
                    </Grid>
                  </li>
                )}
                options={schoolLeaderList?.items}
                loading={loadingSchoolLeader}
                filterSelectedOptions
                value={
                  formData?.schoolLeaderIds?.length > 0
                    ? formData?.schoolLeaderIds
                    : []
                }
                onChange={handleChangeAutocomplete('schoolLeaderIds')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select School leader"
                    error={formError && formError?.schoolLeaderIds}
                    helperText={
                      formError && formError?.schoolLeaderIds
                        ? formError.schoolLeaderIds
                        : ''
                    }
                    style={{ minWidth: 250 }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingDistrict ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          ) : null}
        </Grid>
        {openSkillDialog && (
          <AddSkill
            open={openSkillDialog}
            onClose={handleSkillDialog}
            skill={newSkillValue}
          />
        )}
        {openSubTopicDialog && (
          <AddSubTopic
            open={openSubTopicDialog}
            onClose={handleSubTopicDialog}
            subTopic={newSubTopicValue}
          />
        )}
      </Box>
    </MDDialog>
  );
}

export default Index;
