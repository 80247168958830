import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import DistrictLeader from 'pages/districtLeader';
import SchoolLeader from 'pages/school_leader';
import Student from 'pages/student';
import DailyChallenges from 'pages/super_admin/content_management/daily_challenges';
import StudentReplies from 'pages/super_admin/content_management/daily_challenges/StudentReplies';
import Integrations from 'pages/super_admin/content_management/integrations';
import QuizManagement from 'pages/super_admin/content_management/quiz_template';
import ManageQuiz from 'pages/super_admin/content_management/quiz_template/manage_quiz';
import AddQuiz from 'pages/super_admin/content_management/quiz_template/question';
import VideoManagement from 'pages/super_admin/content_management/videos';
import VideoDetail from 'pages/super_admin/content_management/videos/detail';
import VideoEdit from 'pages/super_admin/content_management/videos/edit';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import './styles/common.scss';

import {
  SettingsConsumer,
  SettingsProvider,
} from '@core/context/settingsContext';
import ThemeComponent from '@core/theme/ThemeComponent';
import AppLoader from 'components/appLoader';
import Notification from 'components/notification/notification';
import AuthRoute from 'components/screenLayout/auth-route';
import ProtectedRoute from 'components/screenLayout/protected-route';
import themeConfig from 'config/themeConfig';
import UserLayout from 'layouts/UserLayout';
import { route_info } from 'navigation/route';
import CreateUser from 'pages/global/createUser';
import ViewUser from 'pages/global/viewUser';
import ReportInsight from 'pages/parent/report/ReportInsight';
import StudentDetail from 'pages/student/detail/Index';
import AssignContent from 'pages/super_admin/content_management/assign_content/new';
import DistrictManagement from 'pages/super_admin/district_management';
import DistrictManagementEdit from 'pages/super_admin/district_management/edit';
import SchoolManagement from 'pages/super_admin/school_management/';
import ClassRoomManagement from 'pages/super_admin/school_management/classroom';
import ClassRoomView from 'pages/super_admin/school_management/ClassroomView';
import ParentManagement from 'pages/super_admin/school_management/parent';
import SchoolManagementCrud from 'pages/super_admin/school_management/school';
import SchoolManagementView from 'pages/super_admin/school_management/Schoolview';
import TeacherManagement from 'pages/super_admin/school_management/teacher';
import Settings from 'pages/super_admin/settings';
import UserManagement from 'pages/super_admin/user_management';
import UserRole from 'pages/super_admin/user_role';
import UserSession from 'pages/super_admin/user_session';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';

import Intercom, { update } from '@intercom/messenger-js-sdk';
import FloatingActionBar from 'components/DraggableButton';
import useModal from 'components/Modal/hook/useModal';
import config from 'config/config';
import HowToUse from 'pages/GEMSLarningHub/HowToUse';
import NewLogin from 'pages/login/NewLogin';
import PaymentPlanList from 'pages/PaymentPlanList';
import QrScanner from 'pages/QrScanner';
import NotificationManagement from 'pages/super_admin/notification_management';
import TokenLogin from 'pages/TokenLogin';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/Slice/authSlice';
import { notificationClear } from 'store/Slice/notificationSlice';
import analytics from 'utils/analytics';
import { getDeviceType } from 'utils/lib';
import { Roles } from './constants/consts';
import ParentDash from './pages/parent/info/dash';
import Soon from './pages/super_admin/dashboard/soon';
import Dashboard from './pages/teacher/dashboard';
import MyAssessments from './pages/teacher/my_assesment/my_assesments';
import { useAppDispatch, useAppSelector } from './store/store';

interface NotificationType {
  message: string;
  status: boolean;
  type: string;
}

const initialState: NotificationType = {
  message: '',
  status: false,
  type: '',
};

function App(props: any) {
  const { Component } = props;
  const setConfig = Component?.setConfig ?? undefined;
  const dispatch: any = useAppDispatch();

  const { openModal } = useModal();

  let notificationInfo = useAppSelector(
    (state: any) => state.notificationReducer,
  );
  let data = useSelector((state: any) => state.loadingReducer);
  const [notificationData, setNotificationData] =
    useState<NotificationType>(initialState);

  const user = useSelector(selectUser);


  useEffect(() => {
    if (user) {
      Intercom({
        app_id: config.INTERCOM_APP_ID,
        email: user.email,
        user_id: user.id,
        name: user.name + ' ' + user.lastname,
        created_at: user.createdAt,
        avatar: user?.profilePicture,
        customAttributes: {
          platform: 'admin-web',
          deviceType: getDeviceType(),
        },
      });

      update({
        email: user.email,
        user_id: user.id,
        name: user.name + ' ' + user.lastname,
        created_at: user.createdAt,
        hide_default_launcher: true,
        customAttributes: {
          platform: 'admin-web',
          deviceType: getDeviceType(),
        },
      });
    }

    if (!user) {
      Intercom({
        app_id: config.INTERCOM_APP_ID,
        email: user?.email,
        user_id: user?.id,
        name: undefined,
        created_at: user?.createdAt,
        hide_default_launcher: true,
        customAttributes: {
          platform: 'admin-web',
          deviceType: getDeviceType(),
        },
      });
    }
  }, [user]);

  const [loading, setLoading] = useState<any>(null);

  const clearNotificationMsg = () => {
    setTimeout(() => {
      dispatch(notificationClear());
    }, 3000);
    return true;
  };

  useEffect(() => {
    if (notificationInfo) {
      setNotificationData(notificationInfo);
    }
  }, [notificationInfo]);

  useEffect(() => {
    if (data) {
      setLoading(data.screenLoading);
    }
  }, [data]);

  const { navItems } = useAppSelector((state: any) => state.navItemReducer);

  useEffect(() => {
    const handlePathChangeAnalytics = () => {
      const path = window.location.pathname;
      analytics.logEvent('page_view', { path });
    };

    handlePathChangeAnalytics();

    window.addEventListener('popstate', handlePathChangeAnalytics);

    return () => {
      window.removeEventListener('popstate', handlePathChangeAnalytics);
    };
  }, []);

  return (
    <>
      {loading && <AppLoader />}
      <SettingsProvider {...(setConfig ? { pageSettings: setConfig() } : {})}>
        {/* @ts-ignore */}
        <Helmet>
          <title>{`${themeConfig.templateName} | Admin Panel`}</title>
          <meta
            name="description"
            content="Let us manage your school admin data"
          />
          <meta
            name="keywords"
            content="Material Design, MUI, Admin Template, React Admin Template"
          />
          <meta name="viewport" content="initial-scale=1, width=device-width" />
        </Helmet>
        <SettingsConsumer>
          {({ settings }) => {
            return (
              <ThemeComponent settings={settings}>
                <ToastContainer
                  style={{
                    zIndex: 9999999999999,
                  }}
                />
                {loading && <AppLoader />}
                {notificationData?.message && (
                  <>
                    <Notification
                      msg={notificationData?.message}
                      status={notificationData?.status ? 'success' : 'error'}
                    />
                    {clearNotificationMsg()}
                  </>
                )}

                <Routes>
                  <Route
                    path="/"
                    element={
                      <AuthRoute>
                        {/* <Login /> */}
                        <NewLogin />
                      </AuthRoute>
                    }
                  />
                  <Route path="/token-login" element={<TokenLogin />} />
                  <Route
                    path="/login"
                    element={
                      <AuthRoute>
                        <NewLogin />
                      </AuthRoute>
                    }
                  />

                  {/*============     Teacher Dshboard ============*/}
                  <Route
                    path="/teacher/dashboard"
                    element={
                      <ProtectedRoute
                        allowedRoles={[
                          Roles.TEACHER || Roles.HOME_SCHOOL_PARENT,
                        ]}
                      >
                        {/*<UserLayout children={<Teacher />} />*/}
                        <UserLayout
                          children={<Dashboard />}
                          role={Roles.TEACHER || Roles.HOME_SCHOOL_PARENT}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/my-assignments"
                    element={
                      <ProtectedRoute>
                        <UserLayout children={<MyAssessments />} />
                      </ProtectedRoute>
                    }
                  />
                   <Route
                    path="/admin/gemslearninghub"
                    element={
                      <ProtectedRoute>
                        <UserLayout children={<HowToUse/>} />
                      </ProtectedRoute>
                    }
                  />
                  {/*==============================================  Parent Dash Board  ========*/}
                  <Route
                    path="/parent/dashboard"
                    element={
                      <ProtectedRoute
                        allowedRoles={[Roles.PARENT, Roles.ADMIN]}
                      >
                        <UserLayout
                          children={<ParentDash />}
                          role={Roles.PARENT}
                        />
                        {/*<UserLayout children={<Parent />} role={Roles.PARENT} />*/}
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/parent/insight"
                    element={
                      <ProtectedRoute
                        allowedRoles={[Roles.PARENT, Roles.ADMIN]}
                      >
                        <UserLayout
                          children={<ReportInsight />}
                          role={Roles.PARENT}
                        />
                        {/*<UserLayout children={<Parent />} role={Roles.PARENT} />*/}
                      </ProtectedRoute>
                    }
                  />
                  {/**/}
                  <Route
                    path="/student/dashboard"
                    element={
                      <ProtectedRoute>
                        <UserLayout
                          children={<Student />}
                          role={Roles.PARENT}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.student_details}
                    element={
                      <ProtectedRoute>
                        <UserLayout
                          role={
                            Roles.ADMIN ||
                            Roles.SCHOOL_LEADER ||
                            Roles.TEACHER ||
                            Roles.HOME_SCHOOL_PARENT
                          }
                          children={<StudentDetail />}
                          admin
                        />
                      </ProtectedRoute>
                    }
                  />
                  {/*================================  School Leader Dashboard   =============  */}
                  <Route
                    path="/school-leader/dashboard"
                    element={
                      <ProtectedRoute
                        allowedRoles={[
                          Roles.SCHOOL_LEADER,
                          Roles.ADMIN,
                          Roles.LEARNING_COACH,
                        ]}
                      >
                        <UserLayout
                          children={<SchoolLeader />}
                          role={Roles.SCHOOL_LEADER}
                        />
                      </ProtectedRoute>
                    }
                  />
                  {/*================================  District Leader Dashboard   =============  */}
                  <Route
                    path="/district-leader/dashboard"
                    element={
                      <ProtectedRoute
                        allowedRoles={[Roles.DISTRICT_LEADER, Roles.ADMIN]}
                      >
                        <UserLayout
                          children={<DistrictLeader />}
                          role={Roles.DISTRICT_LEADER}
                        />
                      </ProtectedRoute>
                    }
                  />
                  {/*================================  Admin Dashboard   =============  */}
                  <Route
                    path="/super-admin/dashboard"
                    element={
                      <ProtectedRoute allowedRoles={[Roles.ADMIN]}>
                        <UserLayout
                          role={Roles.ADMIN}
                          children={<Soon />}
                          admin
                        />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/school-leader/user-management/view/"
                    element={
                      <ProtectedRoute allowedRoles={[Roles.SCHOOL_LEADER || Roles.DISTRICT_LEADER]}>
                        <UserLayout
                          role={Roles.SCHOOL_LEADER || Roles.DISTRICT_LEADER}
                          children={<ViewUser />}
                        />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/school-leader/user-management/create"
                    element={
                      <ProtectedRoute allowedRoles={[Roles.SCHOOL_LEADER]}>
                        <UserLayout
                          role={Roles.SCHOOL_LEADER}
                          children={<CreateUser />}
                        />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/school-leader/user-management/edit"
                    element={
                      <ProtectedRoute allowedRoles={[Roles.SCHOOL_LEADER]}>
                        <UserLayout
                          role={Roles.SCHOOL_LEADER}
                          children={<CreateUser />}
                        />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/super-admin/user-management/view/"
                    element={
                      <ProtectedRoute allowedRoles={[Roles.ADMIN]}>
                        <UserLayout
                          role={Roles.ADMIN}
                          children={<ViewUser />}
                          admin
                        />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/super-admin/user-management/edit"
                    element={
                      <ProtectedRoute allowedRoles={[Roles.ADMIN]}>
                        <UserLayout
                          role={Roles.ADMIN}
                          children={<CreateUser />}
                          admin
                        />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/super-admin/user-management/create"
                    element={
                      <ProtectedRoute allowedRoles={[Roles.ADMIN]}>
                        <UserLayout
                          role={Roles.ADMIN}
                          children={<CreateUser />}
                          admin
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/super-admin/notification-management"
                    element={
                      <ProtectedRoute allowedRoles={[Roles.ADMIN]}>
                        <UserLayout
                          role={Roles.ADMIN}
                          children={<NotificationManagement />}
                          admin
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.content_management.video_fun}
                    element={
                      <ProtectedRoute
                        allowedRoles={[Roles.ADMIN, Roles.SCHOOL_LEADER]}
                      >
                        <UserLayout
                          role={Roles.ADMIN || Roles.SCHOOL_LEADER}
                          children={<VideoManagement />}
                          
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.content_management.video}
                    element={
                      <ProtectedRoute
                        allowedRoles={[Roles.ADMIN, Roles.SCHOOL_LEADER]}
                      >
                        <UserLayout
                          role={Roles.ADMIN || Roles.SCHOOL_LEADER}
                          children={<VideoManagement />}
                          admin
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.content_management.video_school_fun}
                    element={
                      <ProtectedRoute
                        allowedRoles={[
                          Roles.SCHOOL_LEADER || Roles.SCHOOL_LEADER,
                        ]}
                      >
                        <UserLayout
                          role={Roles.SCHOOL_LEADER || Roles.ADMIN}
                          children={<VideoManagement />}
                          admin
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.content_management.video_school}
                    element={
                      <ProtectedRoute
                        allowedRoles={[
                          Roles.SCHOOL_LEADER || Roles.SCHOOL_LEADER,
                        ]}
                      >
                        <UserLayout
                          role={Roles.SCHOOL_LEADER || Roles.ADMIN}
                          children={<VideoManagement />}
                          admin
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`${route_info.content_management.video_detail_fun}/:id`}
                    element={
                      <ProtectedRoute
                        allowedRoles={[Roles.ADMIN, Roles.SCHOOL_LEADER]}
                      >
                        <UserLayout
                          role={Roles.ADMIN || Roles.SCHOOL_LEADER}
                          children={<VideoDetail />}
                          admin
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`${route_info.content_management.video_detail}/:id`}
                    element={
                      <ProtectedRoute
                        allowedRoles={[Roles.ADMIN, Roles.SCHOOL_LEADER]}
                      >
                        <UserLayout
                          role={Roles.ADMIN || Roles.SCHOOL_LEADER}
                          children={<VideoDetail />}
                          admin
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`${route_info.content_management.video_detail_school}/:id`}
                    element={
                      <ProtectedRoute
                        allowedRoles={[Roles.SCHOOL_LEADER, Roles.ADMIN]}
                      >
                        <UserLayout
                          role={Roles.SCHOOL_LEADER || Roles.ADMIN}
                          children={<VideoDetail />}
                          admin
                        />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    path={route_info.content_management.video_upload}
                    element={
                      <ProtectedRoute>
                        <UserLayout children={<VideoAdd />} admin />
                      </ProtectedRoute>
                    }
                  /> */}
                  <Route
                    path={`${route_info.content_management.video_edit}/:id`}
                    element={
                      <ProtectedRoute
                        allowedRoles={[Roles.ADMIN, Roles.SCHOOL_LEADER]}
                      >
                        <UserLayout
                          role={Roles.ADMIN || Roles.SCHOOL_LEADER}
                          children={<VideoEdit />}
                          admin
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`${route_info.notification_management.create}`}
                    element={
                      <ProtectedRoute allowedRoles={[Roles.ADMIN]}>
                        <UserLayout
                          role={Roles.ADMIN}
                          children={<QuizManagement />}
                          admin
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`${route_info.content_management.video_edit_fun}/:id`}
                    element={
                      <ProtectedRoute
                        allowedRoles={[Roles.ADMIN, Roles.SCHOOL_LEADER]}
                      >
                        <UserLayout
                          role={Roles.ADMIN || Roles.SCHOOL_LEADER}
                          children={<VideoEdit />}
                          admin
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`${route_info.content_management.video_edit_school}/:id`}
                    element={
                      <ProtectedRoute
                        allowedRoles={[Roles.SCHOOL_LEADER, Roles.ADMIN]}
                      >
                        <UserLayout
                          role={Roles.SCHOOL_LEADER || Roles.ADMIN}
                          children={<VideoEdit />}
                          admin
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.content_management.quiz_template}
                    element={
                      <ProtectedRoute
                        allowedRoles={[Roles.ADMIN, Roles.SCHOOL_LEADER]}
                      >
                        <UserLayout
                          role={Roles.ADMIN || Roles.SCHOOL_LEADER}
                          children={<QuizManagement />}
                          admin
                        />
                        {/* <UserLayout children={<QuizManagement />} admin /> */}
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.content_management.quiz_template_school}
                    element={
                      <ProtectedRoute
                        allowedRoles={[Roles.SCHOOL_LEADER, Roles.ADMIN]}
                      >
                        <UserLayout
                          role={Roles.SCHOOL_LEADER || Roles.ADMIN}
                          children={<QuizManagement />}
                          admin
                        />
                        {/* <UserLayout children={<QuizManagement />} admin /> */}
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.content_management.manage_quiz}
                    element={
                      <ProtectedRoute
                        allowedRoles={[Roles.ADMIN, Roles.SCHOOL_LEADER]}
                      >
                        <UserLayout
                          role={Roles.ADMIN || Roles.SCHOOL_LEADER}
                          children={<ManageQuiz />}
                          admin
                        />
                        {/* <UserLayout children={<QuizManagement />} admin /> */}
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.content_management.manage_quiz_school}
                    element={
                      <ProtectedRoute
                        allowedRoles={[Roles.SCHOOL_LEADER, Roles.ADMIN]}
                      >
                        <UserLayout
                          role={Roles.SCHOOL_LEADER || Roles.ADMIN}
                          children={<ManageQuiz />}
                          admin
                        />
                        {/* <UserLayout children={<QuizManagement />} admin /> */}
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.content_management.question}
                    element={
                      <ProtectedRoute
                        allowedRoles={[Roles.ADMIN, Roles.SCHOOL_LEADER]}
                      >
                        <UserLayout
                          noPadding
                          role={Roles.ADMIN || Roles.SCHOOL_LEADER}
                          children={<AddQuiz />}
                          admin
                          hiddenSidebar
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.content_management.question_school}
                    element={
                      <ProtectedRoute
                        allowedRoles={[Roles.SCHOOL_LEADER, Roles.ADMIN]}
                      >
                        <UserLayout
                          noPadding
                          role={Roles.SCHOOL_LEADER || Roles.ADMIN}
                          children={<AddQuiz />}
                          admin
                          hiddenSidebar
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.content_management.daily_challenge}
                    element={
                      <ProtectedRoute>
                        <UserLayout
                          role={Roles.ADMIN || Roles.SCHOOL_LEADER}
                          children={<DailyChallenges />}
                          admin
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.content_management.daily_challenge_school}
                    element={
                      <ProtectedRoute>
                        <UserLayout
                          role={Roles.SCHOOL_LEADER || Roles.ADMIN}
                          children={<DailyChallenges />}
                          admin
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.content_management.student_replies}
                    element={
                      <ProtectedRoute>
                        <UserLayout
                          role={Roles.ADMIN || Roles.SCHOOL_LEADER}
                          children={<StudentReplies />}
                          admin
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.content_management.student_replies_school}
                    element={
                      <ProtectedRoute>
                        <UserLayout
                          role={Roles.SCHOOL_LEADER || Roles.ADMIN}
                          children={<StudentReplies />}
                          admin
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.content_management.integrations}
                    element={
                      <ProtectedRoute>
                        <UserLayout
                          role={Roles.ADMIN || Roles.SCHOOL_LEADER}
                          children={<Integrations />}
                          admin
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.content_management.integrations_school}
                    element={
                      <ProtectedRoute>
                        <UserLayout
                          role={Roles.SCHOOL_LEADER || Roles.ADMIN}
                          children={<Integrations />}
                          admin
                        />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path={`${route_info.school_management.school_manage}`}
                    element={
                      <ProtectedRoute>
                        <UserLayout
                          children={<SchoolManagementView />}
                          role={Roles.SCHOOL_LEADER || Roles.ADMIN}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`${route_info.school_management.school_manageFilter}`}
                    element={
                      <ProtectedRoute>
                        <UserLayout
                          children={<SchoolManagementView />}
                          role={Roles.SCHOOL_LEADER || Roles.ADMIN}
                          admin
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`${route_info.school_management.school_details}`}
                    element={
                      <ProtectedRoute>
                        <UserLayout
                          children={<SchoolManagementView />}
                          role={Roles.TEACHER || Roles.ADMIN}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.school_management.school}
                    element={
                      <ProtectedRoute>
                        <UserLayout
                          role={Roles.ADMIN || Roles.SCHOOL_LEADER}
                          children={<SchoolManagement />}
                          admin
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.school_management.districtCrud}
                    element={
                      <ProtectedRoute>
                        <UserLayout
                          role={Roles.ADMIN}
                          children={<DistrictManagement />}
                          admin
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.school_management.schoolCrud}
                    element={
                      <ProtectedRoute>
                        <UserLayout
                          role={Roles.ADMIN || Roles.SCHOOL_LEADER}
                          children={<SchoolManagementCrud />}
                          admin
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.district_management_school}
                    element={
                      <ProtectedRoute>
                        <UserLayout
                          role={Roles.ADMIN}
                          children={<SchoolManagementCrud />}
                          admin
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`${route_info.school_management.schoolView}/:id`}
                    element={
                      <ProtectedRoute>
                        <UserLayout
                          role={Roles.ADMIN}
                          children={<SchoolManagementView />}
                          admin
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.school_management.classroom}
                    element={
                      <ProtectedRoute>
                        <UserLayout
                          role={Roles.ADMIN || Roles.SCHOOL_LEADER}
                          children={<ClassRoomManagement />}
                          admin
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`${route_info.district_details}/${route_info.school_management.school_details}/${route_info.school_management.classroomView}`}
                    element={
                      <ProtectedRoute>
                        <UserLayout
                          role={Roles.ADMIN}
                          children={<ClassRoomView />}
                          admin
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`${route_info.school_management.school_details}/${route_info.school_management.classroomView}`}
                    element={
                      <ProtectedRoute>
                        <UserLayout
                          children={<ClassRoomView />}
                          role={Roles.TEACHER}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`${route_info.school_management.school_manage}/${route_info.school_management.classroomView}`}
                    element={
                      <ProtectedRoute>
                        <UserLayout
                          children={<ClassRoomView />}
                          role={Roles.SCHOOL_LEADER}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.school_management.teacher}
                    element={
                      <ProtectedRoute>
                        <UserLayout
                          role={Roles.ADMIN || Roles.SCHOOL_LEADER}
                          children={<TeacherManagement />}
                          admin
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.school_management.parent}
                    element={
                      <ProtectedRoute>
                        <UserLayout children={<ParentManagement />} admin />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/assign-content"
                    element={
                      <ProtectedRoute>
                        <UserLayout
                          role={
                            Roles.TEACHER || Roles.ADMIN || Roles.SCHOOL_LEADER
                          }
                          children={<AssignContent />}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/assign-content/:id"
                    element={
                      <ProtectedRoute>
                        <UserLayout
                          role={
                            Roles.TEACHER || Roles.ADMIN || Roles.SCHOOL_LEADER
                          }
                          children={<AssignContent />}
                        />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    path="/super-admin/school-management"
                    element={
                      <ProtectedRoute>
                        <UserLayout children={<SchoolManagement />} admin />
                      </ProtectedRoute>
                    }
                  /> */}
                  {/* <Route
                    path="/super-admin/parent-management"
                    element={
                      <ProtectedRoute>
                        <UserLayout children={<ParentManagement />} admin />
                      </ProtectedRoute>
                    }
                  /> */}
                  <Route
                    path={route_info.district_management}
                    element={
                      <ProtectedRoute>
                        <UserLayout
                          role={Roles.ADMIN}
                          children={<DistrictManagement />}
                          admin
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.district_management_edit}
                    element={
                      <ProtectedRoute>
                        <UserLayout
                          role={Roles.ADMIN}
                          children={<DistrictManagementEdit />}
                          admin
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.user_management}
                    element={
                      <ProtectedRoute>
                        <UserLayout
                          role={Roles.ADMIN || Roles.SCHOOL_LEADER}
                          children={<UserManagement />}
                          admin
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.user_manage}
                    element={
                      <ProtectedRoute>
                        <UserLayout
                          role={Roles.SCHOOL_LEADER}
                          children={<UserManagement />}
                          admin
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.user_role}
                    element={
                      <ProtectedRoute>
                        <UserLayout children={<UserRole />} admin />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.user_session}
                    element={
                      <ProtectedRoute>
                        <UserLayout children={<UserSession />} admin />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.user_settings}
                    element={
                      <ProtectedRoute>
                        <UserLayout children={<Settings />} admin />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.district_details}
                    element={
                      <ProtectedRoute>
                        <UserLayout
                          role={Roles.ADMIN || Roles.SCHOOL_LEADER}
                          children={<SchoolManagementCrud />}
                          admin
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`${route_info.district_details}/${route_info.school_management.school_details}`}
                    element={
                      <ProtectedRoute>
                        <UserLayout
                          role={Roles.ADMIN || Roles.SCHOOL_LEADER}
                          children={<SchoolManagementView />}
                          admin
                        />
                      </ProtectedRoute>
                    }
                  />

                  <Route path="/plans" element={<PaymentPlanList />} />
                  <Route path="/qr" element={<QrScanner />} />

                  {/* <Route
                    path={`${route_info.school_management.schoolView}/:id`}
                    element={
                      <ProtectedRoute>
                        <UserLayout children={<SchoolManagementView />} admin />
                      </ProtectedRoute>
                    }
                  /> */}
                  <Route
                    path="*"
                    element={
                      <div className="flex flex-col items-center justify-center h-screen gap-4">
                        <h2 className="text-4xl font-bold text-gray-500 text-center">
                          404
                        </h2>
                        <p>
                          Page not found. Go back to{' '}
                          <a href="/" className="text-blue-500 hover:underline">
                            Home
                          </a>
                        </p>
                      </div>
                    }
                  />
                </Routes>
              </ThemeComponent>
            );
          }}
        </SettingsConsumer>
      </SettingsProvider>
      <FloatingActionBar />
    </>
  );
}

export default App;
