import { Box, Typography } from '@mui/material';
//import { student } from 'assets';
import * as React from 'react';
//import { setStudent } from 'store/Slice/studentSlice';
import AcademicUnlock from './components/AcademicUnlock';
import EngagementbyProducts from './components/EngagementbyProducts';
import EngagementbyTopics from './components/EngagementbyTopics';
import Header from './components/Header';
import LeaderRewards from './components/LeaderRewards';
import ProductsEngagement from './components/ProductsEngagement';
//import SkillBox from './components/SkillBox';
import SkillsMain from './components/SkillsMain';
//import SocialMatrics from './components/SocialMatrics';
import SocialMatricsList from './components/SocialMatricsList';
import Strengths from './components/Strengths';
import StudentList from './components/StudentList';
import SubjectSelection from './components/SubjectSelection';
import ToDoList from './components/ToDoList';
import TopicsEngamement from './components/TopicsEngamement';
import { useDispatch } from 'react-redux';
import Modal from '@mui/material/Modal';
import { ThunkDispatch } from '@reduxjs/toolkit';
import {
  getChildList,
  getChildTotals,
  getParentSubTopicList,
} from 'store/Thunk/ParentDashboardThunk';
import { useAppSelector } from 'store/store';
import DefaultCell from 'components/DefaultCell';
import CustomTable from 'components/customTable';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ToDo from './components/ToDo';
import { useGetMyProfileQuery } from 'store/apis/profile';
import AddStudent from '../components/Modals/AddStudent';
import useModal from 'components/Modal/hook/useModal';
import { useGetUserStudentsQuery } from 'store/apis/user';

function ParentOnboarded({ onClick, view }: { onClick: any; view: string }) {
  const [selected, setSelected] = React.useState('MATH01');
  const {
    childList,
    childTotals,
    childReactions,
    childEngagements,
    parentSubtopicList,
    studentInfo,
    studentQRdata,
  } = useAppSelector((state: any) => state.parentDashboardReducer);

  //const [studentData, setStudentData]=React.useState({})
  const { openModal, closeModal } = useModal();
  const [student, setStudent] = React.useState([]);

  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const { data } = useGetMyProfileQuery();

  const { data: childData, isLoading } = useGetUserStudentsQuery(
    data?.data?.id || '',
    {
      skip: !data?.data?.id,
    },
  );

  React.useEffect(() => {
    dispatch(getChildList());
  }, []);
  React.useEffect(() => {
    console.log('called');
    setStudent(childList);
  }, [childList]);

  //const [role, setRole] = React.useState('upgradedPrent');
  const data1 = [
    {
      id: 1,
      img: require('../../../assets/Parent/Vector.png'),
      text: 'Start the scheduled Bellwork/DoNOW.',
    },
    {
      id: 2,
      img: require('../../../assets/Parent/Vector2.png'),
      text: 'Start the scheduled Bellwork/DoNOW.',
    },
    {
      id: 3,
      img: require('../../../assets/Parent/Vector3.png'),
      text: 'Assign your students first tasks.',
    },
  ];

  const totalAssesments = [
    {
      name: 'Mobile',
      value: childEngagements?.mobileView,
    },
    {
      name: 'Classroom',
      value: childEngagements?.webView,
    },
  ];
  React.useEffect(() => {
    dispatch(getChildTotals(selected));
  }, [selected]);
  return (
    <div>
      {view == 'parentOnboarded' ? (
        <div className="block lg:flex lg:mx-0 justify-between mt-2 mx-3">
          {/** Left view */}
          <div className="w-full lg:w-[20%]">
            {childData?.data?.map((item) => (
              <StudentList
                role={'student'}
                list={item?.dependentOfUser}
                addNew={() => openModal('AddStudent')}
              />
            ))}

            {/* <ToDo data={data} />
            <LeaderRewards role={view} /> */}
          </div>
          {/** right view */}
          <div className="w-full lg:w-[80%] ">
            <Box className="border border-[#EAECF0] lg:mx-2 rounded-lg p-2">
              <div className="flex justify-between">
                <Header text={'Academic Metrics:'} />
                <SubjectSelection
                  selected={selected}
                  onClickMath={() => setSelected('MATH01')}
                  onClickELA={() => setSelected('ela')}
                  onClickScience={() => setSelected('science')}
                  onClickTest={() => setSelected('test')}
                />
              </div>
              <SkillsMain data={childTotals} />
              <Box className="border border-[#EAECF0] mt-2 rounded-lg p-2">
                {childTotals?.userStrenths?.length > 0 ||
                childTotals?.userGrowth?.length > 0 ? (
                  <div className="block lg:flex justify-between lg:space-x-3">
                    <div className="lg:w-1/2">
                      {childTotals?.userStrenths?.length > 0 ? (
                        <Header text="Strengths" />
                      ) : null}

                      <div className="mt-2">
                        {childTotals?.userStrenths?.map((item: any) => (
                          <Strengths
                            color={''}
                            Bcolor={''}
                            strength={true}
                            count={1}
                            text={item?.skill?.template[0]?.template?.title}
                            value={item?.score}
                          />
                        ))}
                      </div>
                    </div>
                    <div className="lg:w-1/2 ">
                      {childTotals?.userGrowth?.length > 0 ? (
                        <Header text="Opportunities for Growth" />
                      ) : null}

                      <div className="mt-2">
                        {childTotals?.userGrowth?.map((item: any) => (
                          <Strengths
                            color={item?.score <= 68 ? '#FFF0F0' : '#FEDF89'}
                            Bcolor={item?.score <= 68 ? '#FF6B6B' : '#FFFAEB'}
                            strength={false}
                            count={1}
                            text={item?.skill?.template[0]?.template?.title}
                            value={item?.score}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  <AcademicUnlock />
                )}
              </Box>
              <SocialMatricsList data={childReactions} role={view} />
            </Box>

            {childEngagements?.byTopics?.length > 0 ? (
              <div className="lg:flex block lg:mx-2 mb-2">
                <EngagementbyTopics data={childEngagements} />
                <EngagementbyProducts data={totalAssesments} />
              </div>
            ) : (
              <div className="lg:flex block lg:mx-2 mb-2">
                <TopicsEngamement
                  studentQR={studentQRdata?.qr}
                  studentData={studentInfo || null}
                  parent={true}
                  onClick={onClick}
                />
                <ProductsEngagement />
              </div>
            )}
          </div>
        </div>
      ) : //  view == 'parentUpgraded' ? (
      //   <div className="block lg:flex justify-between mt-2 ">
      //     <div className="w-full lg:w-[30%]">
      //       <StudentList
      //         list={students}
      //         addNew={() => console.log('add new')}
      //       />
      //       <ToDoList list={data} />
      //       <LeaderRewards role={role} />
      //     </div>
      //     <div className="w-full lg:w-[70%] ">
      //       <Box className="border border-[#EAECF0] lg:mx-2 rounded-lg p-2">
      //         <div className="flex justify-between">
      //           <Header text={'Academic Metrics:'} />
      //           <SubjectSelection
      //             selected={selected}
      //             onClickMath={() => setSelected('math')}
      //             onClickELA={() => setSelected('ela')}
      //             onClickScience={() => setSelected('science')}
      //             onClickTest={() => setSelected('test')}
      //           />
      //         </div>
      //         <SkillsMain role={role} />
      //         <Box className="border border-[#EAECF0] mt-2 rounded-lg p-2">
      //           <div className="flex items-center justify-between space-x-3">
      //             <div className="w-1/2">
      //               <Header text="Strengths" />
      //               <div className="mt-2">
      //                 <Strengths
      //                   color={''}
      //                   Bcolor={''}
      //                   strength={true}
      //                   count={1}
      //                   text={'State-aligned standard or Test Prep skill'}
      //                 />
      //                 <Strengths
      //                   color={''}
      //                   Bcolor={''}
      //                   strength={true}
      //                   count={2}
      //                   text={'State-aligned standard or Test Prep skill'}
      //                 />
      //                 <Strengths
      //                   color={''}
      //                   Bcolor={''}
      //                   strength={true}
      //                   count={3}
      //                   text={'State-aligned standard or Test Prep skill'}
      //                 />
      //                 <Strengths
      //                   color={''}
      //                   Bcolor={''}
      //                   strength={true}
      //                   count={4}
      //                   text={'State-aligned standard or Test Prep skill'}
      //                 />
      //               </div>
      //             </div>
      //             <div className="w-1/2">
      //               <Header text="Opportunities for Growth" />
      //               <div className="mt-2">
      //                 <Strengths
      //                   color={'#FFF0F0'}
      //                   Bcolor={'#FF6B6B'}
      //                   strength={false}
      //                   count={1}
      //                   text={'State-aligned standard or Test Prep skill'}
      //                 />
      //                 <Strengths
      //                   color={'#FFF0F0'}
      //                   Bcolor={'#FF6B6B'}
      //                   strength={false}
      //                   count={2}
      //                   text={'State-aligned standard or Test Prep skill'}
      //                 />
      //                 <Strengths
      //                   color={'#FFFAEB'}
      //                   Bcolor={'#FEDF89'}
      //                   strength={false}
      //                   count={3}
      //                   text={'State-aligned standard or Test Prep skill'}
      //                 />
      //                 <Strengths
      //                   color={'#FFFAEB'}
      //                   Bcolor={'#FEDF89'}
      //                   strength={false}
      //                   count={4}
      //                   text={'State-aligned standard or Test Prep skill'}
      //                 />
      //               </div>
      //             </div>
      //           </div>
      //         </Box>
      //         <SocialMatricsList role={role} />
      //       </Box>
      //       <div className="flex mt-3 mb-2 ml-2">
      //         <EngagementbyTopics />
      //         <EngagementbyProducts />
      //       </div>
      //     </div>
      //   </div>
      // ) :
      null}
    </div>
  );
}

export default ParentOnboarded;
