import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Header from './Header';
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Line,
  Label,
  Legend,
} from 'recharts';
import { ReactI18NextChild } from 'react-i18next';
// const data = [
//   { name: 'Mobile', value: 70 },
//   { name: 'ClassRoom', value: 20 },
// ];

const COLORS = [ '#C44297', '#1D957C'];
const style = {
  top: '30%',
  right: 0,
  transform: 'translate(-10%, -50%)',
  lineHeight: '16px',
  color: 'black',
  fontSize: '11px',
};

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, midAngle } =
    props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius - 60) * cos;
  const sy = cy + (outerRadius - 60) * sin;
  return (
    <Sector
      cx={sx}
      cy={sy}
      innerRadius={2}
      outerRadius={outerRadius}
      startAngle={startAngle}
      endAngle={endAngle}
      cornerRadius={7}
      fill="#1D957C"
    />
  );
};
const renderColorfulLegendText = (value: string, entry: any) => {
  const { color } = { color: 'black' };

  return <span style={{ color }}>{value}</span>;
};
function CustomLabel(value1: any, value2: any) {
  return (
    <text
      x="40%"
      y="40%"
      fill="#3d405c"
      className="recharts-text recharts-label"
      textAnchor="middle"
      dominantBaseline="central"
    >
      <tspan alignmentBaseline="middle" fontSize="26">
        {value1}
      </tspan>
      <tspan fontSize="14">{value2}</tspan>
    </text>
  );
}
function EngagementbyProducts({data}:{data?:any}) {

  return (
    <Box className="border border-[#EAECF0] mt-2 rounded-lg ml-2 py-2 px-3">
      <Header text="Proficiency Levels " />
      <div>
        <PieChart width={300} height={200}>
          <Pie
            data={data}
            dataKey="value"
            nameKey="name"
            outerRadius={70}
            cornerRadius={7}
            cx="40%"
            cy="40%"
            fill="#8884d8"
            activeIndex={1}
            activeShape={renderActiveShape}
          >
            {data?.map((entry:any, index:any) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Legend
            verticalAlign="middle"
            iconType="circle"
            align="right"
            layout={'vertical'}
            height={12}
            iconSize={10}
            wrapperStyle={style}
            formatter={renderColorfulLegendText}
          />
        </PieChart>
      </div>
    </Box>
  );
}

export default EngagementbyProducts;