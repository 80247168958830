import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

export interface ResponseData {
  status: String;
  messagecode: String;
  message: String;
  data: any;
}
 
interface IntialState {
  districtList: any;
  districtID: any;
  stateList: any;
  cityList: any;
  districtState: any;
}

const intialState: IntialState = {
  districtList: [],
  districtID: [],
  stateList: [],
  cityList: [],
  districtState: [],
};

const DistrictSlice = createSlice({
  name: 'District_slice',
  initialState: intialState,
  reducers: {
    setDistrictList: (state: Draft<IntialState>, action: PayloadAction<[]>) => {
      state.districtList = action.payload;
    },
    setStateList: (state: Draft<IntialState>, action: PayloadAction<[]>) => {
      state.stateList = action.payload;
    },
    setCityList: (state: Draft<IntialState>, action: PayloadAction<[]>) => {
      state.cityList = action.payload;
    },
    setDistrictByID: (state: Draft<IntialState>, action: PayloadAction<[]>) => {
      state.districtID = action.payload;
    },
    setDistrictByState: (
      state: Draft<IntialState>,
      action: PayloadAction<[]>,
    ) => {
      state.districtState = action.payload;
    },
  },
});

export const {
  setDistrictList,
  setDistrictByState,
  setStateList,
  setCityList,
  setDistrictByID,
} = DistrictSlice.actions;

export default DistrictSlice.reducer;
