import { CircularProgress } from '@mui/material';
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
interface Props {
  children: JSX.Element;
  path?: string;
}

const AuthRoute = ({ children, path }: Props) => {
  const { loginUser, isLoading } = useAuth();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function login() {
      try {
        setLoading(true);
        await loginUser({});
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log('error', error);
      }
    }
    login();
  }, []);

  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isLoading]);

  if (loading || isLoading) {
    return (
      <div className="fixed inset-0 bg-white bg-opacity-50 z-[9999999999999] flex justify-center items-center">
        <CircularProgress color="inherit" size={20} />
      </div>
    );
  }

  return (
    <>
      {children}

      {isLoading && (
        <div className="fixed inset-0 bg-white bg-opacity-50 z-[9999999999999] flex justify-center items-center">
          <CircularProgress color="inherit" size={20} />
        </div>
      )}
    </>
  );
};

export default AuthRoute;
