import { CircularProgress } from '@mui/material';
import useModal, { ModalName } from 'components/Modal/hook/useModal';
import VerifyEmail from 'components/VerifyEmail';
import useAuth from 'hooks/useAuth';
import localforage from 'localforage';
import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useGetMyProfileQuery } from 'store/apis/profile';

interface Props {
  children: JSX.Element;
  allowedRoles?: string[];
}

export type TModalType =
  | 'parent-free'
  | 'parent-no-addon'
  | 'parent-addon'
  | 'educator-free'
  | 'educator-premium';

const ProtectedRoute = ({ children, allowedRoles }: Props) => {
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();

  const queryParams = Object.fromEntries(searchParams.entries());
  const { isLoading, loginUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const { openModal } = useModal();
  const { data: userData } = useGetMyProfileQuery();

  useEffect(() => {
    async function login() {
      try {
        setLoading(true);
        await loginUser(queryParams, true);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log('error', error);
      }
    }
    login();
  }, []);

  useEffect(() => {
    async function handleCachedModal() {
      const modal = await localforage.getItem('modal');
      localforage.keys().then((keys) => {
        console.log('keys', keys);
      });
      const isPlanRoute = pathname.includes('/plans');
      if (modal && !isPlanRoute && !userData?.data?.accountSetup?.isAccountSetupCompleted) {
        const modaldata = typeof modal === 'string' ? JSON.parse(modal) : {};
        const modalName = modaldata?.name as ModalName;
        const modalProps = modaldata?.props;
        openModal(modalName, modalProps);
      }
    }

    handleCachedModal();
  }, []);

  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isLoading]);

  if (isLoading || loading) {
    return (
      <div className="fixed inset-0 bg-white bg-opacity-50 z-50 flex justify-center items-center">
        <CircularProgress color="inherit" size={20} />
      </div>
    );
  }

  return (
    <>
      <VerifyEmail />
      {children}
      {isLoading && (
        <div className="fixed inset-0 bg-white bg-opacity-50 z-50 flex justify-center items-center">
          <CircularProgress color="inherit" size={20} />
        </div>
      )}
    </>
  );
};

export default ProtectedRoute;
