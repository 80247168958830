import { Typography } from '@mui/material';
import React from 'react';

function HeaderM({ text }: { text: any }) {
  return (
    <Typography sx={{ fontWeight: 600, fontSize: 14 }} color="#101828">
      {text}
    </Typography>
  );
}

export default HeaderM;
