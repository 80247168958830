import { useFormik } from 'formik';

import { Button, LoadingOverlay, Modal, rem } from '@mantine/core';
import useModal from 'components/Modal/hook/useModal';
import cn from 'utils/cn';

import SelectInputField from 'components/SelectInputField';
import TextInputField from 'components/TextInputField';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useAddClassRoomMutation } from 'store/apis/account-setup';
import { useGetGradeListQuery } from 'store/apis/hometab';
import { useGetMyProfileQuery } from 'store/apis/profile';
import { useGetStatesQuery, useGetTopicsQuery } from 'store/apis/states-cities';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';

const validationSchema = z.object({
  name: z.string().min(2, 'Too Short!'),
  grade: z.string({ message: 'Required' }),
  period: z.string({ message: 'Required' }),
  topic: z.string({ message: 'Required' }),
});

export type AddClassRoomModalProps = z.infer<typeof validationSchema>;

export default function AddClassRoomModal() {
  const { closeModal, isOpen, modal, openModal } = useModal<{
    onContinue?: () => void;
    isFirstClassRoom?: boolean;
    withUpgradeSuccess?: boolean;
    withAddons?: boolean;
    educatorPlan?: 'free' | 'premium';
  }>();

  const dispatch = useDispatch();

  const { data: gradeList, isLoading } = useGetGradeListQuery();
  const [createHomeSchoolClassRoom, createHomeSchoolClassRoomResponse] =
    useAddClassRoomMutation();

  const { data: userData, refetch } = useGetMyProfileQuery();

  const formik = useFormik<AddClassRoomModalProps>({
    initialValues: {
      name: '',
      grade: '',
      period: '',
      topic: '',
    },
    validationSchema: toFormikValidationSchema(validationSchema),
    onSubmit: async (values, { setFieldError }) => {
      try {
        if (!userData?.data?.id || !userData?.data?.schoolId) return;
        await createHomeSchoolClassRoom({
          name: values.name,
          gradeId: values.grade,
          period: values.period,
          topicId: values.topic,
          userIds: [userData?.data?.id],
          schoolIds: [userData?.data?.schoolId],
        }).unwrap();

        // dispatch(profileApi.util.invalidateTags(['MyProfile']));

        await refetch();

        /*   if (modal?.props?.withUpgradeSuccess) {
           openModal('ParentSelectAcademic', formik.values);
           return;
         } */

        openModal('AddStudentModal', {
          ...formik.values,
          withAddons: modal?.props?.withAddons,
          educatorPlan: modal?.props?.educatorPlan,
          cache: true,
          withUpgradeSuccess: modal?.props?.withUpgradeSuccess,
        });
      } catch (error) {
        console.log(error);
      }
      try {
        if (!userData?.data?.id || !userData?.data?.schoolId) return;
        await createHomeSchoolClassRoom({
          name: values.name,
          gradeId: values.grade,
          period: values.period,
          topicId: values.topic,
          teacherIds: [userData?.data?.id],
          schoolIds: [userData?.data?.schoolId],
        }).unwrap();

        /*   if (modal?.props?.withUpgradeSuccess) {
          openModal('ParentSelectAcademic', formik.values);
          return;
        } */

        if (
          userData?.data?.accountSetup?.students &&
          userData?.data?.accountSetup?.students?.length <= 0
        ) {
          openModal('AddStudentModal', {
            educatorPlan: userData?.data?.isUserUpgradedPlan
              ? 'premium'
              : 'free',
            cache: true,
            withUpgradeSuccess: modal?.props?.withUpgradeSuccess,
          });
          return;
        }

        if (
          userData?.data?.accountSetup?.parents &&
          userData?.data?.accountSetup?.parents?.length < 2
        ) {
          openModal('AddParentModal', {
            educatorPlan: userData?.data?.isUserUpgradedPlan
              ? 'premium'
              : 'free',
            cache: true,
            withUpgradeSuccess: modal?.props?.withUpgradeSuccess,
          });
          return;
        }

        if (modal?.props?.withUpgradeSuccess) {
          if (userData?.data?.type !== 'parent') {
            openModal('ParentSchoolSettingsModal', {
              ...formik.values,
              cache: true,
              withUpgradeSuccess: modal?.props?.withUpgradeSuccess,
            });
          }
          if (!userData?.data?.accountSetup?.isAcademicContentsSelected) {
            openModal('ParentSelectAcademic', {
              ...formik.values,
              cache: true,
              withUpgradeSuccess: modal?.props?.withUpgradeSuccess,
            });
          }
          if (!userData?.data?.accountSetup?.isSocialContentsSelected) {
            openModal('ParentSelectSocialEmotion', {
              ...formik.values,
              cache: true,
              withUpgradeSuccess: modal?.props?.withUpgradeSuccess,
            });
          }
          return;
        }

        openModal('ParentUpgradeToPlanWarning', {
          cache: true,
          educatorPlan: userData?.data?.isUserUpgradedPlan ? 'premium' : 'free',
          withUpgradeSuccess: modal?.props?.withUpgradeSuccess,
        });
        /* 
        openModal('AddStudentModal', {
          ...formik.values,
          withAddons: data?.data?.accountSetup?.isEducatorAddOnIncluded,
          educatorPlan: modal?.props?.educatorPlan,
          cache: true,
        }); */
      } catch (error) {
        console.log(error);
      }
    },
  });

  const {
    data: statesData,
    isLoading: statesLoading,
    error: statesError,
    isFetching: statesFetching,
  } = useGetStatesQuery({
    search: '',
  });

  const gradedata = React.useMemo(() => {
    if (!gradeList) return [];
    return gradeList.data.map((grade) => ({
      value: grade.id,
      label: grade.name,
    }));
  }, [gradeList]);

  const { data: topicsData, isLoading: topicsLoading } = useGetTopicsQuery();

  const allTopics = React.useMemo(() => {
    if (!topicsData) return [];

    const topicsToReturn = topicsData.data.map((topic) => ({
      value: topic.id,
      label: topic.name,
    }));
    if (!userData?.data?.isUserUpgradedPlan) {
      return topicsToReturn.filter((topic) => topic.value === 'MATH01');
    }
    return topicsToReturn;
  }, [topicsData, userData?.data?.isUserUpgradedPlan]);

  return (
    // <Modal isOpen={isOpen('AskAGrownupModal')} onClose={closeModal}>
    //   <Modal.Content className="[--max-width:_45rem] [--top:_2rem]  md:[--top:_10%]">
    //     <Modal.CloseButton />
    <Modal
      opened={isOpen('AddClassRoomModal')}
      onClose={closeModal}
      closeOnClickOutside={false}
      closeOnEscape={false}
      size={'xl'}
      centered
      shadow="sm"
      radius={'lg'}
      classNames={{
        title:
          '!text-lg !font-semibold lg:!text-xl 2xl:!text-2xl !text-[#2C3E50]',
        close:
          '!text-[#2C3E50] !text-lg !font-semibold lg:!text-xl 2xl:!text-2xl',
      }}
      padding={rem(24)}
      zIndex={999999999}
      withCloseButton={false}
    >
      <div className="grid gap-4 lg:gap-6">
        <div className="space-y-2">
          <h1
            className={cn(
              '!font-semibold text-center !text-lg lg:!text-xl 2xl:!text-2xl text-[#131515]',
            )}
          >
            Add Classroom
          </h1>
        </div>

        <form
          onSubmit={formik.handleSubmit}
          className="flex flex-col lg:grid gap-4 lg:!grid-cols-2"
        >
          <div className="col-span-2">
            <TextInputField
              label="Classroom Name"
              placeholder="Enter Classroom Name"
              {...formik.getFieldProps('name')}
              error={formik.touched.name && formik.errors.name}
            />
          </div>

          <SelectInputField
            label="Grade/Subject"
            placeholder="Select Grade/Subject"
            data={gradedata}
            disabled={isLoading}
            onChange={(value) => formik.setFieldValue('grade', value)}
            value={formik.values.grade}
            error={formik.touched.grade && formik.errors.grade}
            onBlur={() => formik.setFieldTouched('grade', true)}
          />

          <TextInputField
            label="Classroom Period"
            placeholder="Enter Classroom Period"
            {...formik.getFieldProps('period')}
            error={formik.touched.period && formik.errors.period}
          />

          <div className="col-span-2">
            <SelectInputField
              label="Department"
              placeholder="Select Department"
              data={allTopics}
              disabled={topicsLoading}
              onChange={(value) => formik.setFieldValue('topic', value)}
              value={formik.values.topic}
              error={formik.touched.topic && formik.errors.topic}
              onBlur={() => formik.setFieldTouched('topic', true)}
            />
          </div>

          <Button
            className="max-w-full !rounded-xl col-span-2"
            color="#20A58A"
            size="xl"
            fw={500}
            type="submit"
          >
            Add Classroom
          </Button>
        </form>
      </div>
      <LoadingOverlay visible={createHomeSchoolClassRoomResponse.isLoading} />
    </Modal>

    // </Modal.Content>
    // </Modal>
  );
}

// {
//   modal?.props?.withUpgradeSuccess && (
//     <div className="space-y-4 mb-6">
//       <h1
//         className={cn(
//           '!font-semibold text-center !text-lg lg:!text-xl 2xl:!text-2xl text-[#131515]',
//         )}
//       >
//         🎉 Upgrade Successful!
//       </h1>
//       <p
//         className={cn(
//           '!text-sm lg:!text-base xl:!text-lg 2xl:!text-xl text-[#131515] text-center',
//         )}
//       >
//         You've unlocked more learning opportunities for as an Homeschool Parent!
//         Now, personalize G3MS as an educator to maximize their success.
//       </p>
//     </div>
//   );
// }
