import { useFormik } from 'formik';

import { Button, LoadingOverlay, Modal, rem } from '@mantine/core';
import useModal from 'components/Modal/hook/useModal';
import cn from 'utils/cn';

import DateInputField from 'components/DateInputField';
import TelephoneInput from 'components/TelephoneInput';
import TextInputField from 'components/TextInputField';
import { useGetMyProfileQuery } from 'store/apis/profile';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { useAddUserMutation } from 'store/apis/user';
import { toast } from 'react-toastify';

const validationSchema = z.object({
  fistName: z.string().min(2, 'Too Short!'),
  lastName: z.string().min(2, 'Too Short!'),
  dob: z.date({ message: 'Invalid Date' }),
  email: z.string().email('Invalid Email'),
  dialCode: z.string().optional(),
  phoneNumber: z.string().min(6, 'Too Short!'),
});

export type AddParentModalProps = z.infer<typeof validationSchema>;

export default function AddParentModal() {
  const { closeModal, isOpen, modal, openModal } = useModal<{
    onContinue?: () => void;
    isFirstStudent?: boolean;
    withAddons?: boolean;
  }>();

  const { data, isLoading } = useGetMyProfileQuery();
  const [addUserRequest, addUserResponse] = useAddUserMutation();

  const formik = useFormik<AddParentModalProps>({
    initialValues: {
      fistName: '',
      lastName: '',
      dob: new Date(),
      email: '',
      dialCode: '',
      phoneNumber: '',
    },
    validationSchema: toFormikValidationSchema(validationSchema),
    onSubmit: async (values, { setFieldError }) => {
      try {
        const res = await addUserRequest({
          email: values.email,
          name: values.fistName,
          lastname: values.lastName,
          dateOfBirth: values.dob,
          type: 'parent',
          phone: `${values.phoneNumber}`,
        }).unwrap();

        console.log('add parent response', res);

        /*     if (data?.data?.isUserUpgradedPlan && data?.data?.accountSetup?.isEducatorAddOnIncluded) {
           openModal('ParentPremiumPlan', { cache: true });
           return;
         } */
        if (data?.data?.isUserUpgradedPlan) {
          if (data?.data?.isUserUpgradedPlan && data?.data?.type === 'parent') {
            openModal('ParentPremiumPlan', { cache: true });
          }

          if (data?.data?.isUserUpgradedPlan && data?.data?.type !== 'parent') {
            openModal('EducatorPremiumPlan', { cache: true });
          }

          return;
        }

        openModal('ParentUpgradeToPlanWarning', { cache: true });
      } catch (error: any) {
        console.log(error);
        toast.error(error?.data?.message || 'Failed to add parent');
        console.log(error);
      }
    },
  });

  console.log('error add parent', formik.errors);

  return (
    // <Modal isOpen={isOpen('AskAGrownupModal')} onClose={closeModal}>
    //   <Modal.Content className="[--max-width:_45rem] [--top:_2rem]  md:[--top:_10%]">
    //     <Modal.CloseButton />
    <Modal
      opened={isOpen('AddParentModal')}
      onClose={closeModal}
      closeOnClickOutside={false}
      closeOnEscape={false}
      size={'xl'}
      centered
      shadow="sm"
      radius={'lg'}
      classNames={{
        title:
          '!text-lg !font-semibold lg:!text-xl 2xl:!text-2xl !text-[#2C3E50]',
        close:
          '!text-[#2C3E50] !text-lg !font-semibold lg:!text-xl 2xl:!text-2xl',
      }}
      padding={rem(24)}
      zIndex={999999999}
      withCloseButton={false}
    >
      <div className="grid gap-4 lg:gap-6">
        <div className="grid gap-2 items-start justify-start align-top">
          <h1
            className={cn(
              '!font-semibold !text-lg lg:!text-xl 2xl:!text-2xl text-[#131515] max-sm:text-center',
            )}
          >
            Add Parent
          </h1>
          <p
            className={cn(
              '!text-sm lg:!text-base xl:!text-lg 2xl:!text-xl text-[#131515]',
            )}
          >
            Would you like to add another parent to manage account?
          </p>
        </div>

        <form
          onSubmit={formik.handleSubmit}
          className="flex flex-col lg:grid gap-4 lg:grid-cols-2"
        >
          <TextInputField
            label="First Name"
            placeholder="Enter First Name"
            {...formik.getFieldProps('fistName')}
            error={formik.touched.fistName && formik.errors.fistName}
          />
          <TextInputField
            label="Last Name"
            placeholder="Enter Last Name"
            {...formik.getFieldProps('lastName')}
            error={formik.touched.lastName && formik.errors.lastName}
          />

          <DateInputField
            label="Date of Birth"
            placeholder="Enter Date of Birth"
            value={formik.values.dob as Date}
            onChange={(value: any) =>
              formik.setFieldValue('dob', value as Date)
            }
            onBlur={() => formik.setFieldTouched('dob', true)}
            error={
              formik.touched.dob && typeof formik.errors.dob === 'string'
                ? formik.errors.dob
                : undefined
            }
          />

          <TextInputField
            label="Email"
            placeholder="Enter Email"
            {...formik.getFieldProps('email')}
            error={formik.touched.email && formik.errors.email}
          />

          <div className="col-span-2">
            <TelephoneInput
              label="Phone Number"
              onChange={(value) => {
                formik.setFieldValue('phoneNumber', value);
              }}
              onCountryChange={(value) => {
                formik.setFieldValue('dialCode', value.callingCode);
              }}
              value={formik.values.phoneNumber}
              error={
                formik.touched.phoneNumber && formik.errors.phoneNumber
                  ? formik.errors.phoneNumber
                  : undefined
              }
              onBlur={() => formik.setFieldTouched('phoneNumber', true)}
            />
          </div>

          <div className="grid gap-4 lg:grid-cols-2 col-span-2 mt-4">
            <Button
              className="max-w-full !rounded-xl"
              color="#20A58A"
              size="xl"
              fw={500}
              type="submit"
              loading={addUserResponse.isLoading}
            >
              Add Parent
            </Button>
            <Button
              onClick={() => {
                if (
                  data?.data?.isUserUpgradedPlan &&
                  data?.data?.type === 'parent'
                ) {
                  openModal('ParentPremiumPlan', { cache: true });
                  return;
                }

                if (
                  data?.data?.isUserUpgradedPlan &&
                  data?.data?.type !== 'parent'
                ) {
                  openModal('EducatorPremiumPlan', { cache: true });
                  return;
                }

                openModal('ParentUpgradeToPlanWarning', { cache: true });
              }}
              className="max-w-full !rounded-xl"
              color="#999999"
              size="xl"
              fw={500}
              loading={addUserResponse.isLoading}
            >
              Skip
            </Button>
          </div>
        </form>
      </div>
      <LoadingOverlay visible={addUserResponse.isLoading} />
    </Modal>

    // </Modal.Content>
    // </Modal>
  );
}
