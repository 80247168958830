import { apiSlice } from '..';
import { TVerifyTokenResponse } from './types';

export const homeTabApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    verifyToken: builder.query<TVerifyTokenResponse, string>({
      query: (string) => 'verifyToken/' + string,
    }),
    loginAdmin: builder.mutation<
      TVerifyTokenResponse['data'],
      {
        email: string;
        password: string;
      }
    >({
      query: ({ email, password }) => ({
        url: 'admin/login',
        method: 'POST',
        body: { email, password },
      }),
    }),
  }),
});

export const {
  useVerifyTokenQuery,
  useLazyVerifyTokenQuery,
  useLoginAdminMutation,
} = homeTabApiSlice;
