import { Button, Modal, rem } from '@mantine/core';
import useModal from 'components/Modal/hook/useModal';
import { useGetMyProfileQuery } from 'store/apis/profile';
import cn from 'utils/cn';

export default function EducatorWelcome() {
  const { closeModal, isOpen, modal, openModal } = useModal<{
    onContinue?: () => void;
    withUpgradeSuccess?: boolean;
  }>();

  const { data, isLoading } = useGetMyProfileQuery();

  return (
    // <Modal isOpen={isOpen('AskAGrownupModal')} onClose={closeModal}>
    //   <Modal.Content className="[--max-width:_45rem] [--top:_2rem]  md:[--top:_10%]">
    //     <Modal.CloseButton />
    <Modal
      opened={isOpen('EducatorWelcome')}
      onClose={closeModal}
      closeOnClickOutside={false}
      closeOnEscape={false}
      size={'lg'}
      centered
      shadow="sm"
      radius={'lg'}
      classNames={{
        title:
          '!text-lg !font-semibold lg:!text-xl 2xl:!text-2xl !text-[#2C3E50]',
        close:
          '!text-[#2C3E50] !text-lg !font-semibold lg:!text-xl 2xl:!text-2xl',
      }}
      padding={rem(24)}
      zIndex={999999999}
      withCloseButton={false}
    >
      <div className="flex flex-col gap-4 xl:gap-8">
        <div className="grid gap-2 lg:gap-4 justify-center align-top items-center place-items-center">
          <h1
            className={cn(
              '!font-semibold !text-lg xl:!text-xl 2xl:!text-2xl text-[#131515] text-center',
            )}
          >
            🎉 Welcome to G3MS, {data?.data?.name} {data?.data?.lastname}!
          </h1>
        </div>

        <p
          className={cn(
            '!text-sm lg:!text-base xl:!text-lg 2xl:!text-xl text-black',
          )}
        >
          Thank you for joining G3MS to support your students’ learning journey.
          Here’s what you can do with your account:
        </p>

        <ul
          className={cn(
            '!text-sm lg:!text-base xl:!text-lg 2xl:!text-xl text-[#131515] space-y-1',
          )}
        >
          <li>✔️ Assign state-aligned lessons and track student progress.</li>
          <li>✔️ Access the Admin Dashboard to personalize instruction.</li>
          <li>️✔️ Monitor trends and progress across classrooms or groups.</li>
          <li>
            ✔️ Engage students with gamified rewards and interactive challenges.
          </li>
        </ul>

        <p
          className={cn(
            '!text-sm lg:!text-base xl:!text-lg 2xl:!text-xl text-black font-bold',
          )}
        >
          ✨ Let’s work together to make learning personalized, fun, and
          impactful!
        </p>

        <div className="w-full grid">
          <Button
            loading={isLoading}
            onClick={() => {
              if (!data?.data?.accountSetup?.educatorClassrooms?.length) {
                if (data?.data?.type === 'homeschool_parent_esa') {
                  openModal('AddHomeSchoolClassRoomModal', {
                    educatorPlan: 'premium',
                    cache: true,
                    withUpgradeSuccess: modal?.props?.withUpgradeSuccess,
                  });
                  return;
                }
                openModal('AddClassRoomModal', {
                  educatorPlan: data?.data?.isUserUpgradedPlan
                    ? 'premium'
                    : 'free',
                  cache: true,
                  withUpgradeSuccess: modal?.props?.withUpgradeSuccess,
                });
                return;
              }
              if (
                data?.data?.accountSetup?.students &&
                data?.data?.accountSetup?.students?.length <= 0
              ) {
                openModal('AddStudentModal', {
                  educatorPlan: data?.data?.isUserUpgradedPlan
                    ? 'premium'
                    : 'free',
                  cache: true,
                  withUpgradeSuccess: modal?.props?.withUpgradeSuccess,
                });
                return;
              }

              if (
                !data?.data?.accountSetup?.schoolDetails?.school_settings.length
              ) {
                openModal('ParentSchoolSettingsModal', {
                  educatorPlan: data?.data?.isUserUpgradedPlan
                    ? 'premium'
                    : 'free',
                  cache: true,
                  withUpgradeSuccess: modal?.props?.withUpgradeSuccess,
                });
                return;
              }

              if (data?.data?.isUserUpgradedPlan) {
                if (!data?.data?.accountSetup?.isAcademicContentsSelected) {
                  openModal('ParentSelectAcademic', {
                    educatorPlan: 'premium',
                    cache: true,
                    withUpgradeSuccess: modal?.props?.withUpgradeSuccess,
                  });
                  return;
                }

                if (!data?.data?.accountSetup?.isSocialContentsSelected) {
                  openModal('ParentSelectSocialEmotion', {
                    educatorPlan: 'premium',
                    cache: true,
                    withUpgradeSuccess: modal?.props?.withUpgradeSuccess,
                  });
                  return;
                }

                openModal('EducatorPremiumPlan', {
                  educatorPlan: 'premium',
                  cache: true,
                  withUpgradeSuccess: modal?.props?.withUpgradeSuccess,
                });
                return;
              }

              /*               if (
                data?.data?.accountSetup?.parents &&
                data?.data?.accountSetup?.parents?.length < 2
              ) {
                openModal('AddParentModal', {
                  educatorPlan: data?.data?.isUserUpgradedPlan
                    ? 'premium'
                    : 'free',
                  cache: true,
                });
                return;
              } */

              openModal('ParentUpgradeToPlanWarning', {
                cache: true,
                educatorPlan: data?.data?.isUserUpgradedPlan
                  ? 'premium'
                  : 'free',
                withUpgradeSuccess: modal?.props?.withUpgradeSuccess,
              });
            }}
            className="max-w-full !rounded-xl w-full"
            color="#20A58A"
            size="xl"
            fw={500}
          >
            Let’s begin your setup
          </Button>
        </div>
      </div>
    </Modal>

    // </Modal.Content>
    // </Modal>
  );
}
