import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { _LessonTypes_2, assignmentTypes } from 'helper/types';
import useSchoolClassroomInfo from 'hooks/useSchoolClassroomInfo';
import API from 'store/api';
import { setButtonLoading } from 'store/Slice/assignContentSlice';
import { startLoading, stopLoading } from 'store/Slice/loadingSlice';

import {
  notificationFail,
  notificationSuccess,
} from 'store/Slice/notificationSlice';
import { RootState } from 'store/store';

interface Types {
  userIds?: any;
  assignmentType: string;
  startDate: string;
  endDate: string;
  type?: string;
  groupId?: string;
  assignClassroomId?: string;
}

export const assignContent = createAsyncThunk(
  'assign/content',
  async (data: any, { dispatch, getState }) => {
    const teacherSchool: any = localStorage.getItem('teacherSchool');
    const current_classroomId: any = localStorage.getItem(
      'current_classroomId',
    );

    console.log(data);
    try {
      if (teacherSchool) {
        console.log(data);
        // Continue with your code that uses the userId variable
        let url = data?.contentSelection
          ? `/api/v1/assignment/classroom/${current_classroomId}`
          : `/api/v1/assignment/school-assignment/${teacherSchool}`;
        dispatch(setButtonLoading(true));
        console.log(data);
        const response = await API.post(url, data);
        if (response.data.data.error) {
          dispatch(notificationFail('Duplicates found'));
        } else {
          dispatch(notificationSuccess('Content Successfully Assigned'));
        }
        dispatch(setButtonLoading(false));
        console.log('resres', response);
        return response; // Return the response from the async thunk
      }
    } catch (error: any) {
      dispatch(notificationFail(error?.response?.message || ''));
      console.log(error);
      throw error; // Throw the error to be caught in the catch block
    }
  },
);

export const deleteSchoolAssignment = createAsyncThunk(
  'assign/delete',
  async ({ schoolAssignmentId, templateId }: any, { dispatch, getState }) => {
    try {
      dispatch(setButtonLoading(true));
      await API.delete(
        `/api/v1/assignment/classroom/school-assignment/${schoolAssignmentId}/template/${encodeURIComponent(
          templateId,
        )}`,
      );
      dispatch(notificationSuccess('Assignment Deleted Successfully'));
    } catch (err: any) {
      dispatch(notificationFail(err.response.data.message));
      dispatch(setButtonLoading(false));
      return err;
    }
  },
);
export const deleteAssignment = createAsyncThunk(
  'assign/delete',
  async (
    { dailyAssignmentId, contentId, contentType }: any,
    { dispatch, getState },
  ) => {
    try {
      dispatch(setButtonLoading(true));
      await API.delete(
        `/api/v1/assignment/classroom/assignment/${dailyAssignmentId}/content/${encodeURIComponent(
          contentId,
        )}?type=${contentType}`,
      );
      dispatch(notificationSuccess('Assignment Deleted Successfully'));
    } catch (err: any) {
      dispatch(notificationFail(err.response.data.message));
      dispatch(setButtonLoading(false));
      return err;
    }
  },
);

export const updateAssignment = createAsyncThunk(
  'assign/delete',
  async ({ assignmentId, data }: any, { dispatch, getState }) => {
    try {
      const storedInfo = localStorage.getItem('selectiveInfo');
      if (storedInfo) {
        const selectiveData = JSON.parse(storedInfo) || '';
        dispatch(setButtonLoading(true));
        const updateAssignment: AxiosResponse<any> = await API.post(
          `/api/v1/assignment/classroom/assignment/${assignmentId}`,
          {
            ...data,
            startDate: selectiveData.startDate,
            endDate: selectiveData.endDate,
            assignmentType: assignmentTypes[selectiveData.assignmentType],
          },
        );
        dispatch(setButtonLoading(false));
        dispatch(notificationSuccess('Assignment Successfully Updated'));
      }
    } catch (err: any) {
      dispatch(notificationFail(err.response.data.message));
      dispatch(setButtonLoading(false));
      return err;
    }
  },
);

interface Assignment {
  assignmentType: string;
  startDate: string;
  endDate: string;
  assignmentId: string;
  userId: string | null;
  groupId: string | null;
  assignClassroomId: string;
  content: any;
}
export const getAllAssignments = createAsyncThunk(
  'assign/content',
  async (_request: any, { dispatch, getState }) => {
    try {
      dispatch(startLoading());
      const page = _request.pageIndex || 0;
      const size = _request.pageSize || 10;
      const search = _request?.search || '';
      let url = `/api/v1/assignment/school-daily-practice/${_request.id}?page=${
        page + 1
      }&limit=${size}`;

      if (search) {
        url += `&query=${search}`;
      }
      const response: AxiosResponse<any> = await API.get(url);

      dispatch(stopLoading());

      return response.data.data;
    } catch (err: any) {
      dispatch(stopLoading());
      dispatch(notificationFail(err.response.data.message));
      dispatch(setButtonLoading(false));
      return err;
    }
  },
);
export const getImportedAssignments = createAsyncThunk(
  'assign/content',
  async (_request: any, { dispatch, getState }) => {
    try {
      dispatch(startLoading());
      const page = _request.pageIndex || 0;
      const size = _request.pageSize || 10;
      const search = _request?.search || '';
      let url = `/api/v1/assignment/imported-assessments/${_request.id}?page=${
        page + 1
      }&limit=${size}`;

      if (search) {
        url += `&query=${search}`;
      }
      const response: AxiosResponse<any> = await API.get(url);

      dispatch(stopLoading());

      return response.data.data;
    } catch (err: any) {
      dispatch(stopLoading());
      dispatch(notificationFail(err.response.data.message));
      dispatch(setButtonLoading(false));
      return err;
    }
  },
);
export const getAssignments = createAsyncThunk(
  'assign/content',
  async (id: any, { dispatch, getState }) => {
    try {
      dispatch(startLoading());
      const assignmentRecord: { student: any; assignments: any }[] = [];
      const studentsResponse: AxiosResponse<any> = await API.get(
        `/api/v1/classroom/${id}/student?limit=10000`,
      );

      const studentsList: any[] = studentsResponse.data.data.items;

      const response: AxiosResponse<any> = await API.get(
        `/api/v1/assignment/all/classroom/${id}?limit=10000`,
      );

      const assignmentList: any[] = response.data.data;
      const studentAssignments = assignmentList.filter(
        (assignment) => assignment.userId !== null && assignment.userId !== '',
      );

      studentsList.forEach((student) => {
        const matchingAssignments = studentAssignments.filter(
          (assignment) => student?.user.id === assignment.userId,
        );

        const studentRecord = {
          student: student,
          assignments: matchingAssignments
            .filter((assignment) => assignment.content.length > 0)
            .map((assignment) => ({ ...assignment })),
        };

        if (studentRecord.assignments.length > 0) {
          assignmentRecord.push(studentRecord);
        }
      });

      const mergedData = assignmentRecord.reduce((result: any, item: any) => {
        const studentId =
          item.student.id || (item?.student?.user && item.student.user.id);
        if (!result[studentId]) {
          result[studentId] = {
            student: item.student,
            assignments: item.assignments,
          };
        } else {
          result[studentId].assignments.push(...item.assignments);
        }

        return result;
      }, {});
      for (const studentId in mergedData) {
        if (mergedData[studentId].assignments) {
          mergedData[studentId].assignments.sort((a: any, b: any) => {
            if (a.startDate && b.startDate) {
              return (
                new Date(a?.startDate as Date)?.getTime() -
                new Date(b?.startDate as Date)?.getTime()
              );
            }
            return 0;
          });
        }
      }
      dispatch(stopLoading());

      return mergedData;
    } catch (err: any) {
      dispatch(stopLoading());
      dispatch(notificationFail(err.response.data.message));
      dispatch(setButtonLoading(false));
      return err;
    }
  },
);
export const checkExistence = createAsyncThunk(
  'checkExistence',
  async (content: any, { dispatch, getState }) => {
    try {
      const storedInfo = localStorage.getItem('selectiveInfo');
      const data: any = { userIds: [], contentSelection: [content] };

      if (storedInfo) {
        const selectiveData = JSON.parse(storedInfo);
        if (selectiveData.assignTo === 'Individual') {
          data.userIds = [selectiveData.userId];
        } else if (selectiveData.assignTo === 'Group') {
          const getClassGroupResponse: AxiosResponse<any> = await API.get(
            `/api/v1/classroom/${selectiveData.assignClassroomId}/group/${selectiveData.groupId}`,
          );
          const groupStudentIds =
            getClassGroupResponse?.data?.data &&
            getClassGroupResponse?.data?.data?.GroupStudent.map(
              (item: any) => item.userId,
            );
          const uniqueStudents = [...new Set(groupStudentIds)];
          data.userIds = uniqueStudents;
        }
        if (selectiveData.assignTo === 'Whole class') {
          const classStudents = await API.get(
            `/api/v1/classroom/${selectiveData?.assignClassroomId}/student`,
          );
          const studentIds =
            classStudents.data?.data?.items &&
            classStudents.data.data?.items.map((item: any) => item.userId);
          const uniqueStudents = [...new Set(studentIds)];
          data.userIds = uniqueStudents;
        }
      }
      const response = await API.post(
        `/api/v1/assignment/checkexistence`,
        data,
      );
      return response?.data?.data;
    } catch (err: any) {
      dispatch(notificationFail(err.response.data.message));
      dispatch(setButtonLoading(false));
      return err;
    }
  },
);
const allAssessments = [
  {
    userId: 123,
    assessments: [
      {
        skill: {
          id: '1234',
        },
      },
      {
        skill: {
          id: '1235',
        },
      },
      {
        skill: {
          id: '1236',
        },
      },
    ],
  },
  {
    userId: 12,
    assessments: [
      {
        skill: {
          id: '123',
        },
      },
      {
        skill: {
          id: '122',
        },
      },
      {
        skill: {
          id: '121',
        },
      },
      {
        skill: {
          id: '120',
        },
      },
    ],
  },
];
