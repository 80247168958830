import { apiSlice } from '..';
import { TUpdateParentSchoolSettings } from './types';

export const onBoardingApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    updateParentSchoolSettings: builder.mutation<
      any,
      TUpdateParentSchoolSettings
    >({
      query: ({ schoolId, ...body }) => ({
        url: '/account/setup/create-school-settings/' + schoolId,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['MyProfile'],
    }),
  }),
});

export const { useUpdateParentSchoolSettingsMutation } = onBoardingApi;
