// ** React Imports
import { useState,useEffect } from 'react';

// ** MUI Imports
// import Fab from '@mui/material/Fab'
import { styled, useTheme } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';

// ** Icons Imports
// import ArrowUp from 'mdi-material-ui/ArrowUp'

// ** Theme Config Import

import themeConfig from '../../config/themeConfig';

// ** Type Import
import { LayoutProps } from '../../@core/layouts/types';

// ** Components
import AppBar from './components/vertical/appBar';
// import Customizer from '../../@core/components/customizer'
import Navigation from './components/vertical/navigation';
import { route_info } from 'navigation/route';
import { useLocation } from 'react-router-dom';
import useShowEmailOtp from 'icons/useShowEmailOtp';
import cn from 'utils/cn';

// import ScrollToTop from '../../@core/components/scroll-to-top'

const VerticalLayoutWrapper = styled('div')({
  height: '100%',
  display: 'flex',
 // backgroundColor:"royalblue"
});

const MainContentWrapper = styled(Box)<BoxProps>({
  flexGrow: 1,
  minWidth: 0,
  display: 'flex',
  minHeight: '100dvh',
  flexDirection: 'column',
  
});

const ContentWrapper = styled('main')(({ theme }) => ({
  flexGrow: 1,
  width: '100%',

  transition: 'padding .25s ease-in-out',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));
  let role=""
const VerticalLayout = (props: LayoutProps) => {
  const showOtpModal = useShowEmailOtp();
  const theme = useTheme();
  // ** Props
  const { noPadding, settings, children, hiddenSidebar } = props;
  useEffect(()=>{

    const user: any = localStorage.getItem('userData') ;

    if(user){
       role= JSON.parse(user).type
    }
    },[])
  // ** Vars
  const { skin, contentWidth } = settings;
  const { navigationSize, collapsedNavigationSize } = themeConfig;
  const navWidth = navigationSize;
  const navigationBorderWidth = skin === 'bordered' ? 1 : 0;
  const collapsedNavWidth = collapsedNavigationSize;

  // ** States
  const [navHover, setNavHover] = useState<boolean>(false);
  const [navVisible, setNavVisible] = useState<boolean>(false);

  // ** Toggle Functions
  const toggleNavVisibility = () => setNavVisible(!navVisible);

  return (
    <>
      <VerticalLayoutWrapper className={cn((role=="parent"?' layout-wrapper-parent':" layout-wrapper"))}   >
     
        {/* Navigation Menu */}
        {
          // navHidden &&
          // themeConfig.layout === 'vertical' &&
          // !(navHidden && settings.lastLayout === 'horizontal') ? null : (
          !hiddenSidebar && (
            <Navigation
              navWidth={navWidth}
              navHover={navHover}
              navVisible={navVisible}
              setNavHover={setNavHover}
              setNavVisible={setNavVisible}
              collapsedNavWidth={collapsedNavWidth}
              toggleNavVisibility={toggleNavVisibility}
              navigationBorderWidth={navigationBorderWidth}
              {...props}
            />
          )
          // )
        }
        <MainContentWrapper className="layout-content-wrapper">
          {/* AppBar Component */}
          <AppBar toggleNavVisibility={toggleNavVisibility} {...props} />

          {/* Content */}
          <ContentWrapper
            className="layout-page-content"
            sx={{
              ...(contentWidth === 'boxed' && {
                mx: 'auto',
                ...(!noPadding && {
                  '@media (min-width:1440px)': { maxWidth: 1440 },
                }),
                '@media (min-width:1200px)': { maxWidth: '100%' },
              }),
              ...(!noPadding && {
                padding: theme.spacing(6),
              }),
            }}
          >
            {children}
          </ContentWrapper>
        </MainContentWrapper>
      </VerticalLayoutWrapper>

      {/* Customizer */}
      {/* {disableCustomizer || hidden ? null : <Customizer />} */}

      {/* Scroll to top button */}
      {/* {scrollToTop ? (
        scrollToTop(props)
      ) : (
        <ScrollToTop className='mui-fixed'>
          <Fab color='primary' size='small' aria-label='scroll back to top'>
            <ArrowUp />
          </Fab>
        </ScrollToTop>
      )} */}
    </>
  );
};

export default VerticalLayout;
