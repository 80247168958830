import { Button, Checkbox, LoadingOverlay, Modal, rem } from '@mantine/core';
import useModal from 'components/Modal/hook/useModal';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  useGetAcademicContentQuery,
  useUpdateAccountContentMutation,
} from 'store/apis/account-setup';
import { Topic } from 'store/apis/account-setup/types';
import { useGetMyProfileQuery } from 'store/apis/profile';
import cn from 'utils/cn';

const options = [
  'Math',
  'ISEE Lower',
  'SSAT Lower',
  'SHAT',
  'ELA',
  'ISEE Middle',
  'SSAT Middle',
  'PSAT',
  'Science',
  'ISEE Upper',
  'SSAT Upper',
  'Digital SAT',
  'Social Studies',
];

export default function ParentSelectAcademic() {
  const { closeModal, isOpen, modal, openModal } = useModal<{
    onContinue?: () => void;
    withAddons?: boolean;
    educatorPlan?: 'free' | 'premium';
  }>();

  const { data, isLoading } = useGetAcademicContentQuery();
  const [updateAccountContent, updateAccountContentRes] =
    useUpdateAccountContentMutation();

  const [selectedItems, setSelectedItems] = useState<Topic[]>([]);
  const { data: userData } = useGetMyProfileQuery();

  useEffect(() => {
    if (data) {
      const items = data?.data
        ?.map((item) => ({
          topicId: item.id,
          enabled: item.enabled,
        }))
        .filter((item) => item.topicId === 'MATH01');
      setSelectedItems(items);
    }
  }, [data]);

  return (
    // <Modal isOpen={isOpen('AskAGrownupModal')} onClose={closeModal}>
    //   <Modal.Content className="[--max-width:_45rem] [--top:_2rem]  md:[--top:_10%]">
    //     <Modal.CloseButton />
    <Modal
      opened={isOpen('ParentSelectAcademic')}
      onClose={closeModal}
      closeOnClickOutside={false}
      closeOnEscape={false}
      size={'xl'}
      centered
      shadow="sm"
      radius={'lg'}
      classNames={{
        title:
          '!text-lg !font-semibold lg:!text-xl 2xl:!text-2xl !text-[#2C3E50]',
        close:
          '!text-[#2C3E50] !text-lg !font-semibold lg:!text-xl 2xl:!text-2xl',
      }}
      padding={rem(24)}
      zIndex={999999999}
      withCloseButton={false}
    >
      <div className="space-y-4 lg:space-y-10">
        <div className="space-y-2">
          <h1
            className={cn(
              '!font-semibold text-center !text-lg lg:!text-xl 2xl:!text-2xl text-[#131515]',
            )}
          >
            Select Academic & Test Prep Content
          </h1>
          <p
            className={cn(
              '!text-sm lg:!text-base xl:!text-lg 2xl:!text-xl text-[#131515]',
            )}
          >
            Select the subjects and test prep areas to personalize G3MS learning
            experience and help them reach their full potential!
          </p>
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-3 justify-between  gap-y-4">
          {data?.data
            ?.filter((item) => item.enabled)
            .map((option) => (
              <Checkbox
                key={option.id}
                label={option.name}
                checked={selectedItems.some(
                  (item) => item.topicId === option.id,
                )}
                onChange={() => {
                  if (
                    selectedItems.some((item) => item.topicId === option.id)
                  ) {
                    setSelectedItems((prev) =>
                      prev.filter((item) => item.topicId !== option.id),
                    );
                  } else {
                    setSelectedItems((prev) => [
                      ...prev,
                      { topicId: option.id, enabled: true },
                    ]);
                  }
                }}
                classNames={{
                  label:
                    'text-[#131515] !text-sm lg:!text-base xl:!text-lg 2xl:!text-xl font-semibold sm:-mt-1',
                  root: '!min-w-[48%] sm:!min-w-[30%] !flex !items-center !justify-start',
                }}
                color="#20A58A"
              />
            ))}
        </div>

        <div className="grid">
          <Button
            className="w-full !rounded-xl col-span-2 "
            color="#20A58A"
            size="xl"
            fw={500}
            type="submit"
            onClick={async () => {
              try {
                await updateAccountContent({
                  topics: selectedItems,
                }).unwrap();
                openModal('ParentSelectSocialEmotion', {
                  withAddons:
                    userData?.data?.accountSetup?.isEducatorAddOnIncluded,
                  educatorPlan: modal?.props?.educatorPlan,
                  cache: true,
                });
              } catch (error) {
                toast.error('Failed to update account content');
              }
            }}
          >
            Next
          </Button>
        </div>
      </div>

      <LoadingOverlay
        visible={isLoading || updateAccountContentRes.isLoading}
      />
    </Modal>

    // </Modal.Content>
    // </Modal>
  );
}
