// ** React Imports
import { useState, SyntheticEvent, Fragment, useEffect } from 'react';

// ** MUI Imports
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import MenuIcon from 'mdi-material-ui/Menu';

// ** Icons Imports
import CogOutline from 'mdi-material-ui/CogOutline';
import LogoutVariant from 'mdi-material-ui/LogoutVariant';

// ** Type Imports
import { Settings } from '../../../../@core/context/settingsContext';
import { useNavigate } from 'react-router-dom';
import { setLoginUser } from 'store/Slice/authSlice';
import { useAppDispatch } from 'store/store';
import { profileApi, useGetMyProfileQuery } from 'store/apis/profile';
import localforage from 'localforage';
import useAuth from 'hooks/useAuth';
import { update } from '@intercom/messenger-js-sdk';

interface Props {
  settings: Settings;
  hidden?: boolean;
  onClick?: any;
}

// ** Styled Components
// const BadgeContentSpan = styled('span')(({ theme }) => ({
//   width: 8,
//   height: 8,
//   borderRadius: '50%',
//   backgroundColor: theme.palette.success.main,
//   boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
// }))

const UserDropdown = (props: Props) => {
  const dispatch = useAppDispatch();

  // ** Props
  const { settings, hidden, onClick } = props;

  const { data } = useGetMyProfileQuery();

  const name: any = data?.data?.name;
  const profileImage = data?.data?.profileImage;
  const type: any = data?.data?.type;
  const id: string = data?.data?.id || '';
  const lastName: any = data?.data.lastname;
  const school: any = data?.data.schoolName;
  const token: any = localStorage.getItem('token');
  // const firstName: any = JSON.parse(header)?.first_name || 'Eureka'
  // const lastName: any = JSON.parse(header)?.last_name || ''
  const email: any = data?.data?.email || '';
  const { redirectToLogin, loginUser } = useAuth();

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const navigate = useNavigate();
  // const [userName, setUserName] = useState('')

  // ** Hooks
  // const router = useRouter()
  // const { logout } = useAuth()

  // useEffect(() => {
  //   setUserName(firstName + ' ' + lastName)
  // }, [firstName, lastName])

  // ** Vars
  const { direction } = settings;

  const handleDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = (url?: string) => {
    if (url) {
      navigate(url);
    }
    setAnchorEl(null);
  };

  const styles = {
    py: 2,
    px: 4,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    color: 'text.primary',
    textDecoration: 'none',
    '& svg': {
      fontSize: '1.375rem',
      color: 'text.secondary',
    },
  };

  const handleLogout = async () => {
    localStorage.removeItem('userData');
    localStorage.removeItem('uuid');
    localStorage.removeItem('header');
    localStorage.removeItem('selectedStudentId');
    dispatch(setLoginUser({}));

    update({
      name: '',
    });

    dispatch(profileApi.util.resetApiState());
    localStorage.clear();

    await localforage.clear();
    if (type === 'super_admin') {
      return navigate('/login');
    }
    redirectToLogin();
  };

  const types: any = {
    super_admin: 'Super Admin',
    teacher: 'Teacher',
    parent: 'Parent',
    school_leader: 'School Leader',
    district_leader: 'District Leader',
    private_tutor: 'Private Tutor',
    tutor_company: 'Tutor Company',
    tutor: 'Tutor',
    other: 'Other',
  };

  return (
    <Fragment>
      <div className='flex items-center'>
      {hidden && (
           <div role="button" className=" mr-2 w-3" onClick={onClick}>
           <MenuIcon />
         </div>
        )}
      <div role="button" onClick={(e) => handleDropdownOpen(e)}>
        
        <Avatar
          alt={name}
          src={profileImage}
          sx={{
            ml:hidden?3: 6,
            width: 25,
            height: 25,
            mr : hidden? -3:0,
          }}
        />
      </div>

      </div>
      
      {!hidden ? (
        <Box
          sx={{
            display: 'flex',
            ml: 3,
            cursor: 'pinter',
            alignItems: 'flex-start',
            flexDirection: 'column',
          }}
          onClick={(e) => handleDropdownOpen(e)}
        >
          <Tooltip title={id} style={{ zIndex: 100 }}>
            <Box sx={{ display: 'flex' }}>
              <Typography
                sx={{ fontWeight: 300, fontSize: hidden ? 9 : 13 }}
                color="#484848"
              >
                {name || email} {lastName}
              </Typography>
            </Box>
          </Tooltip>

          <Box sx={{ display: 'flex', gap: '3px' }}>
            <Typography
              variant="body2"
              sx={{ fontSize: hidden ? '0.6rem' : '0.8rem' }}
              color="#858585"
            >
              {type ? `${types[type]}` : ''}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: '3px' }}>
            <Typography
              variant="body2"
              sx={{ fontSize: hidden ? '0.6rem' : '0.8rem' }}
              color="#858585"
            >
              {school || ''}
            </Typography>
          </Box>
        </Box>
      ) : null}

      {!hidden && (
        <IconButton sx={{ ml: 2 }} onClick={(e) => handleDropdownOpen(e)}>
          <KeyboardArrowDownIcon />
        </IconButton>
      )}

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleDropdownClose()}
        sx={{ '& .MuiMenu-paper': { width: 300, mt: 4, borderRadius: 1 } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: direction === 'ltr' ? 'right' : 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: direction === 'ltr' ? 'right' : 'left',
        }}
      >
        <div className="flex items-center justify-center">
          <MenuItem
            sx={{
              py: 2,
              background: '#C44297',
              width: '90%',
              alignSelf: 'center',
              borderRadius: '6px',
              color: 'white',
              fontSize: '15px',
              fontWeight: 500,
            }}
            onClick={handleLogout}
          >
            Logout
          </MenuItem>
        </div>
      </Menu>
    </Fragment>
  );
};

export default UserDropdown;
