import { Box, Card, Typography } from '@mui/material';
import * as React from 'react';
import Header from './components/Header';
import EducatorFeature from './components/EducatorFeature';
import ToDo from './components/ToDo';
import CarouselView from './components/CarouselView';
import SkillsMain from './components/SkillsMain';
import ClassRoomComponent from './components/ClassRoomComponent';
import Strengths from './components/Strengths';
import AcademicUnlock from './components/AcademicUnlock';
import SocialMatricsList from './components/SocialMatricsList';
import ProficiencyLevels from './components/ProficiencyLevels';
import TierSupport from './components/TierSupport';
import EngagementbyTopics from './components/EngagementbyTopics';
import EngagementbyProducts from './components/EngagementbyProducts';
import TopicsEngamement from './components/TopicsEngamement';
import { useAppSelector } from 'store/store';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { getAllSchools, getAllStudent, getAllStudentDistrictLeader, getAllStudentSchoolLeader, getClassList, getDepartments, getDepartmentsSchoolLeader } from 'store/Thunk/ParentEducatorDashboardThunk';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ClassRoomComponentSchoolLeader from './components/ClassRoomComponentSchoolLeader';
import ClassRoomComponentDistrictLeader from './components/ClassRoomComponentDistrictLeader';
import { useGetMyProfileQuery } from 'store/apis/profile';

function DistrictLeader({
  role,
  onPress,
  onClick,
}: {
  role?: any;
  onPress?: any;
  onClick?: any;
}) {
  //EduNoOnbrded //EduOnboarded
  //const [role, setRole] = React.useState('EduOnboarded');
  const [classRooms, setClassRooms] = React.useState([]);
  const [engagement, setEngagements] = React.useState<any>([]);
  const [reaction, setReactions] = React.useState({});
  const [total, setTotals] = React.useState<any>(null);
  const { classRoomList, engagements, reactions, totals, currentSelectedUser } = useAppSelector(
    (state: any) => state.ParentEducatorReducer,
  );
  const { childTotals, childReactions, childEngagements, studentQRdata } = useAppSelector(
    (state: any) => state.parentDashboardReducer,
  );
  const{data}=useGetMyProfileQuery()
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  React.useEffect(() => {
    dispatch(getClassList());
    dispatch(getAllStudentDistrictLeader(data?.data?.school?.ncesDistrictId));
    dispatch(getDepartmentsSchoolLeader())
    dispatch(getAllSchools(data?.data?.school?.ncesDistrictId))
  }, [data]);
   const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  React.useEffect(() => {
    if(currentSelectedUser === "class" || currentSelectedUser === "dep"||currentSelectedUser === "all" || currentSelectedUser === "school"){
      setEngagements(engagements);     
      setReactions(reactions);
      setTotals(totals);
    }else if(currentSelectedUser === "student"){
      setEngagements(childEngagements);
      setReactions(childReactions);
      setTotals(childTotals);
    }
    
  }, [ engagements, reactions, totals, childEngagements, childReactions, childTotals, currentSelectedUser]);
  React.useEffect(() => {
    setClassRooms(classRoomList);
  }, [classRoomList]);

  return (
    <>
      {role == 'EduNoOnbrded' ? (
        <div>
          <>
            {/** 1st div */}
            <div className=" block lg:flex w-full justify-between">
              <div className="w-full lg:w-[65%]">
                <EducatorFeature onPress={onPress} />
                {/* <ToDo /> */}
                <CarouselView />
                <Box className="border border-[#EAECF0] lg:ml-3 lg:mr-2 rounded-lg my-2 px-2 pb-2 ">
                  <div className="mt-2 ml-2">
                    <Header text="Academic Metrics" />
                  </div>
                  <SkillsMain data={0} />
                </Box>
              </div>
              <div className="w-full lg:w-[35%]">
                <ProficiencyLevels />
                <TierSupport />
                <TopicsEngamement parent={false} onClick={onClick} />
              </div>
            </div>
          </>
        </div>
      ) : role === 'EduOnboarded' ? (
        <div className=" block lg:flex w-full justify-between bg-white">
          <div className="w-full lg:w-[70%]">
            <ClassRoomComponentDistrictLeader classRooms={classRooms} role={role} />
            {/* <ToDo edu={true} data={data} /> */}
            <Box className="border border-[#EAECF0] lg:ml-3 lg:mr-2 rounded-lg mt-2 p-2">
              <div className='mb-3'>
                <Header text="Academic Metrics" />
              </div>
              <SkillsMain data={total} />
            </Box>
            <Box className="border border-[#EAECF0] mt-2 rounded-lg p-2 mx-2 ml-3">
                {total?.userStrenths?.length > 0 ||
                total?.userGrowth?.length > 0 ? (
                  <div className="block lg:flex justify-between lg:space-x-3">
                    {total?.userStrenths?.length > 0 ?
                    <div className="w-full">
                  
                    <Header text="Strengths" />
                

                  <div className="mt-3">
                    {total?.userStrenths?.map((item: any) => (
                      <Strengths
                        color={''}
                        Bcolor={''}
                        strength={true}
                        count={1}
                        text={item?.skill?.template[0]?.template?.title}
                        value={item?.score}
                      />
                    ))}
                  </div>
                </div>:
                null
                    }
                    {total?.userGrowth?.length > 0 ?
                     <div className="w-full">
  
                     <Header text="Opportunities for Growth" />
   

                   <div className="mt-3">
                     {total?.userGrowth?.map((item: any) => (
                       <Strengths
                         color={item?.score <= 68 ? '#FFF0F0' : '#FEDF89'}
                         Bcolor={
                           item?.score <= 68 ? '#FF6B6B' : '#FFFAEB'
                         }
                         strength={false}
                         count={1}
                         text={item?.skill?.template[0]?.template?.title}
                         value={item?.score}
                       />
                     ))}
                   </div>
                 </div>:null  
                  }
                   
                  </div>
                ) : (
                  <AcademicUnlock 
                  onClick={handleOpen}
                  />
                )}
              </Box>
            <Box className="border border-[#EAECF0] lg:ml-3 lg:mr-2 rounded-lg mt-2 p-2">
              <SocialMatricsList data={reaction} role={role} />
            </Box>
          </div>
          <div className="w-full lg:w-[30%]">
            <ProficiencyLevels 
             //options={engagement?.topics}
            dataKey={total?.GrothLevels}
            />
            <TierSupport
            //options={engagement?.topics}
             tierData={total?.rtiTier}/>
            {engagement?.byTopics?.length>0 ? (
              <div className="lg:flex block lg:mx-2 mb-2">
                <EngagementbyTopics
                isEdu={true}
                  data={engagement}
                />
              </div>
            ) : ( 
              <TopicsEngamement
              studentQR={studentQRdata?.qr}
              parent={false} onClick={onClick} />
            )}
          </div>
        </div>
      ) : null}
    </>
  );
}
export default DistrictLeader;
