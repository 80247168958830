import { Roles } from '@core/utils/global-mock';
import {
  Grid,
  TextField,
  InputLabel,
  Box,
  Typography,
  Button,
  Stack,
  Autocomplete,
  Tooltip,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DynamicAutocomplete from 'components/AutoComplete/DynamicAutocomplete';
import React, { useState, useEffect } from 'react';
import { getClassroomBySchoolID } from 'store/Thunk/classroomThunk';
import { getAllParents } from 'store/Thunk/parentThunk';
import { getAllSchoolListIds, getSchoolByCity } from 'store/Thunk/schoolThunk';
import { getGradeList } from 'store/Thunk/videoThunk';
import { useAppDispatch, useAppSelector } from 'store/store';
import SelectAvatar from './selectAvatar';
import Validations from 'helper/validations';
import { updateStudent } from 'store/Thunk/studentThunk';
import ViewLoaderBox from 'components/viewUser/loader';
import { createUserAtFirebaseAndSetAtBackend } from 'store/Thunk/authThunk';
import { LoadingButton } from '@mui/lab';
import { useLocation, useNavigate } from 'react-router-dom';
import { getUserData, getUserQR } from 'store/Thunk/user';
import { setUser } from 'store/Slice/userSlice';
import ClearIcon from '@mui/icons-material/Clear';
import { setBtnLoading } from 'store/Slice/loadingSlice';
import {
  notificationFail,
  notificationSuccess,
} from 'store/Slice/notificationSlice';
import { getLinkPartFromRole, getLoggedInUser } from 'helper/functions';
import { Trumpet } from 'mdi-material-ui';
import ImageViewPopup from 'components/popUp/imageViewPopup';
import { studentQR } from 'assets';
import { getCities, getStates } from 'store/Thunk/districtThunk';
import { setSchoolByCity } from 'store/Slice/schoolSlice';
import { AvatarsList } from 'components/Dashboard/students.mock';
import { updateSchoolLeader } from 'store/Thunk/schoolLeaderThunk';

function SchoolLeader() {
  const teacherSchool: any = localStorage.getItem('teacherSchool');
  // State variables
  const [formError, setFormError] = useState<any>({});
  const [formData, setFormData] = useState<any>({
    school: { id: teacherSchool },
  });
  const [age, setAge] = useState<any>([
    { value: true, label: 'Over 13' },
    { value: false, label: 'Under 13' },
  ]);
  const [paidStatus, setPaidStatus] = useState<any>([
    { value: 'Paid_by_District', label: 'Paid by District' },
    { value: 'Paid_by_School', label: 'Paid by School' },
    { value: 'Paid_by_Parent', label: 'Paid by Parent' },
    { value: 'Paid_by_Self', label: 'Paid by Self' },
    { value: 'Paid_by_Demo', label: 'Paid by Demo' },
    { value: 'Free', label: 'Free' },
  ]);
  const findMatchingObject = (data: any, value: any) => {
    return data.find((item: any) => item.value === value);
  };
  const [openGrade, setOpenGrade] = useState<boolean>(false);
  const [loadingGrade, setLoadingGrade] = useState<boolean>(false);
  const [grade, setGrade] = useState([]);
  const [parentOpen, setParentOpen] = useState<boolean>(false);
  const [schoolOpen, setSchoolOpen] = useState<boolean>(false);
  const [openCities, setOpenCities] = useState(false);
  const [openState, setOpenState] = React.useState(false);
  const [imageFile, setImageFile] = useState<any>(null);

  const [classroomOpen, setClassRoomOpen] = useState<boolean>(false);
  const [openAvatarModal, setOpenAvatarModal] = useState<boolean>(false);
  const [imagePreview, setImagePreview] = useState<any>(null);
  const [imageView, setImageView] = useState(false);
  const [defaultImage, setDefaultImage] = useState<any>(null);
  useEffect(() => {
    const fetchPicture = async (index: number) => {
      const response = await fetch(AvatarsList[11].img);
      const blob = await response.blob();
      const file = new File([blob], 'userprofile');
      setDefaultImage(file);
    };
    fetchPicture(1);
  }, []);
  // Redux state and dispatch
  const dispatch = useAppDispatch();
  const { gradeList } = useAppSelector((state: any) => state.videoReducer);
  const { btnLoading } = useAppSelector((state: any) => state.loadingReducer);
  const {
    user,
    userQR,
    loading: userLoading,
  } = useAppSelector((state) => state.userReducer);

  // Other variables
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [schoolLeaderId] = useState(queryParams.get('id'));
  const navigate = useNavigate();
  const initial = getLinkPartFromRole();
  const currentLoggedInUserSchool =
    localStorage.getItem('current_schoolId') || '';
  const loggedInUser = getLoggedInUser();
  const { stateList } = useAppSelector((state: any) => state.districtReducer);
  const { cityList } = useAppSelector((state: any) => state.districtReducer);
  const { schoolByCity } = useAppSelector((state: any) => state.schoolReducer);

  const { schoolList, loading: schoolLoading } = useAppSelector(
    (state) => state.schoolReducer,
  );
  const { parentsList, loading } = useAppSelector(
    (state) => state.parentReducer,
  );
  const { classroomList } = useAppSelector((state) => state.classroomReducer);
  console.log('classroomList', stateList);
  console.log('classroomList', formData);
  console.log('classroomList', formError);
  console.log('classroomList', schoolList);
  console.log('classroomList', schoolByCity);
  console.log('classroomList', user);
  console.log('classroomList', schoolLeaderId);

  useEffect(() => {
    dispatch(getAllParents({ _request: { allowPagination: false } }));
  }, []);
  useEffect(() => {
    formData.state?.id && dispatch(getCities(formData.state?.id));
    // setLoadingState(openState);
  }, [formData.state]);
  useEffect(() => {
    dispatch(getStates({ pageIndex: 0, pageSize: 7000 }));
    // setLoadingState(openState);
  }, []);
  useEffect(() => {
    formData.city?.id && dispatch(getSchoolByCity({ id: formData.city?.id }));
    // setLoadingState(openState);
  }, [formData.city]);
  useEffect(() => {
    schoolLeaderId && dispatch(getUserQR(schoolLeaderId || ''));
    // setLoadingState(openState);
  }, [schoolLeaderId]);
  useEffect(() => {
    dispatch(
      getClassroomBySchoolID({
        id: formData?.school?.id || teacherSchool,
      }),
    );
  }, [formData?.school]);

  useEffect(() => {
    if (schoolLeaderId) {
      dispatch(getUserData(schoolLeaderId));
    }
    return () => {
      dispatch(setUser([]));
      dispatch(setBtnLoading(false));
    };
  }, []);

  useEffect(() => {
    if (openGrade) {
      dispatch(getGradeList()).then(() => {
        setLoadingGrade(false);
      });
      setLoadingGrade(true);
    } else {
      setGrade([]);
    }
  }, [openGrade]);

  useEffect(() => {
    setLoadingGrade(false);
    setGrade(gradeList || []);
  }, [gradeList]);
  const handleImageChange = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      setImageFile(file);
      reader.onload = () => {
        setImagePreview(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (user) {
      const school =
        user?.school?.length > 0 ? user?.school[0]?.school : user?.school;
      setFormData({
        salutation: user?.salutation || '',
        isUnder13: findMatchingObject(age, user?.isUnder13) || '',
        paidStatus: findMatchingObject(paidStatus, user?.paidStatus) || '',
        username: user?.username || '',
        name: user?.name,
        role: { label: Roles.leader },
        email: user?.email || '',
        school: {
          id: user?.school?.id || teacherSchool,
          name: user?.school?.name,
        },
        city: { name: user?.school?.city || '' },
        state: { name: user?.school?.state || '' },
        grade:
          user?.grade?.map((item: any) => {
            return {
              id: item?.grade?.id,
              name: item?.grade?.name,
            };
          }) || [],
        parent: user?.parent ? user?.parent : { name: '', email: '' },
        classroom:
          user?.classroom?.map((item: any) => {
            return {
              classroom: {
                id: item?.classroom?.id,
                name: item?.classroom?.name,
              },
            };
          }) || [],
      });
      if (user?.profileImage) {
        setImagePreview(user?.profileImage);
      }
    }
  }, [user]);

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDownlaodQR = () => {
    const downloadLink = document.createElement('a');
    downloadLink.href = userQR?.qr || '';
    downloadLink.download = `QR-${schoolLeaderId}.jpg`;
    downloadLink.click();
  };

  const handleChangeAutocomplete =
    (name: string) => (value: string | null | any) => {
      let param = {
        [name]: value,
        ...(name === 'state' && {
          school: {},
        }),
      };
      setFormData({ ...formData, ...param });
    };

  const onSubmit = (event: any) => {
    event.preventDefault();

    let _request = {
      username: formData?.username || '',
      name: formData?.name || '',
      role: Roles.leader,
      email: formData?.email || '',
      districtId: formData?.school?.district || '',
      totalNoOfTeacher: 2,
      city: formData?.city.id || '',
      school: formData?.school?.id || '',
      state:
        formData?.state?.value ||
        formData?.state?.id ||
        formData?.state?.name ||
        '',
      file: imageFile,
    };
    console.log('first', formData);
    console.log('first', formError);
    console.log('first', _request);
    console.log('first', loggedInUser?.type);
    let allError = Validations.validateSchoolLeaderForm(
      _request,
      loggedInUser?.type,
    );
    setFormError(allError);

    if (
      Object.entries(allError).length === 0 &&
      allError.constructor === Object
    ) {
      if (schoolLeaderId) {
        dispatch(setBtnLoading(true));
        dispatch(updateSchoolLeader({ ..._request, uuid: schoolLeaderId }))
          .unwrap()
          .then(() => {
            dispatch(notificationSuccess('School Updated Successfully'));
            setTimeout(() => {
              navigate(`/${initial}/user-management?tab=3`);
            }, 100);
          })
          .catch(() => {
            dispatch(setBtnLoading(false));
          });
      } else {
        dispatch(
          createUserAtFirebaseAndSetAtBackend({
            userInfo: { ..._request },
          }),
        );
      }
    } else {
      setFormError(allError);
    }
  };

  if (userLoading) {
    return <ViewLoaderBox />;
  }

  const handleCancel = () => {
    navigate(`/${initial}/user-management?tab=2`);
  };

  return (
    <>
      <Grid sx={{ p: 4 }} container className="relative" spacing={6}>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between">
            <Typography color="#000" fontWeight={700} variant="body1">
              User Information
            </Typography>
            <input
              accept="image/*"
              id="contained-button-file"
              multiple
              type="file"
              hidden
              onChange={handleImageChange}
            />
            {imagePreview ? (
              <span
                style={{
                  background: '#a1f2cb',
                  padding: '10px 15px',
                  borderRadius: '6px',
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'center',
                  width: '168px',
                  justifyContent: 'space-between',
                  color: '#000',
                }}
              >
                <span onClick={() => setImageView(true)}>View Image</span>
                <span
                  onClick={() => {
                    setImagePreview(null);
                    setImageFile(null);
                  }}
                  style={{ marginBottom: '-5px', cursor: 'pointer' }}
                >
                  <ClearIcon />
                </span>
              </span>
            ) : (
              <label htmlFor="contained-button-file">
                <Button
                  sx={{ borderStyle: 'dashed' }}
                  variant="outlined"
                  component="span"
                >
                  Upload Image
                </Button>
              </label>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InputLabel
            error={Boolean(formError?.name?.usernam)}
            sx={{ color: '#484848', pb: 2 }}
          >
            Username
          </InputLabel>
          <TextField
            required
            fullWidth
            id="Username"
            value={formData?.username || ''}
            onChange={handleChangeInput}
            name="username"
            error={formError && formError?.username}
            helperText={
              formError && formError?.username ? formError.username : ''
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InputLabel
            error={Boolean(formError?.name?.message)}
            sx={{ color: '#484848', pb: 2 }}
          >
            Full Name
          </InputLabel>
          <TextField
            required
            fullWidth
            id="name"
            value={formData?.name || ''}
            onChange={handleChangeInput}
            name="name"
            error={formError && formError?.name}
            helperText={formError && formError?.name ? formError.name : ''}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InputLabel
            error={Boolean(formError?.email?.message)}
            sx={{ color: '#484848', pb: 2 }}
          >
            Email
          </InputLabel>
          <TextField
            required
            fullWidth
            id="email"
            type="email"
            disabled={Boolean(schoolLeaderId)}
            value={formData?.email || ''}
            onChange={handleChangeInput}
            name="email"
            error={formError && formError?.email}
            helperText={formError && formError?.email ? formError.email : ''}
          />
        </Grid>

        {loggedInUser?.type === 'super_admin' && (
          <Grid item md={4} sm={6} xs={12}>
            <InputLabel
              error={formError && formError?.state}
              sx={{ color: '#484848', pb: 2 }}
            >
              State
            </InputLabel>

            <Autocomplete
              id="states"
              open={openState}
              onOpen={() => setOpenState(true)}
              onClose={() => setOpenState(false)}
              getOptionLabel={(option) => option.name}
              options={stateList}
              value={formData?.state ? formData?.state : { name: '', id: '' }}
              isOptionEqualToValue={(option, value) => option.id === value}
              filterSelectedOptions
              onChange={(event, value) =>
                handleChangeAutocomplete('state')(value)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="What state is your school in?"
                  error={formError && formError?.state}
                  helperText={formError && formError?.state}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        )}
        {loggedInUser?.type === 'super_admin' && (
          <Grid item md={4} sm={6} xs={12}>
            <InputLabel
              error={formError && formError?.city}
              sx={{ color: '#484848', pb: 2 }}
            >
              City
            </InputLabel>

            <Autocomplete
              id="cities"
              open={openCities}
              onOpen={() => setOpenCities(true)}
              onClose={() => setOpenCities(false)}
              getOptionLabel={(option) => option.name || ''}
              options={cityList}
              isOptionEqualToValue={(option, value) => option.id === value}
              value={formData?.city || {}}
              sx={{ width: '100%' }}
              filterSelectedOptions
              onChange={(event, value) =>
                handleChangeAutocomplete('city')(value)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="What city is your school in?"
                  error={formError && formError?.city}
                  helperText={formError && formError?.city}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        )}

        {loggedInUser?.type === 'super_admin' && (
          <Grid item xs={12} sm={6} md={4}>
            <DynamicAutocomplete
              id="School"
              label="School"
              multiple={false}
              getOptionLabel={(option) => option?.name || ''}
              options={schoolByCity || []}
              loading={schoolLoading}
              onOpen={() => setSchoolOpen(true)}
              onClose={() => setSchoolOpen(false)}
              open={schoolOpen}
              filterSelectedOptions
              value={formData?.school || {}}
              onChange={(event, value) =>
                handleChangeAutocomplete('school')(value)
              }
              placeholder="Select School"
              error={formError && formError?.school}
              helperText={
                formError && formError?.school ? formError.school : ''
              }
            />
          </Grid>
        )}

        <Grid item xs={12} sm={6} md={4}>
          <DynamicAutocomplete
            id="role"
            label="Role"
            getOptionLabel={(option) => option?.label || ''}
            disable
            options={[{ label: Roles.leader }]}
            filterSelectedOptions
            multiple={false}
            value={
              formData?.role?.length > 0
                ? formData?.role[0]
                : formData?.role || { label: Roles.leader }
            }
            onChange={(event, value) => handleChangeAutocomplete('role')(value)}
            placeholder="Select Role"
            error={formError && formError?.role}
            helperText={formError && formError?.role ? formError.role : ''}
          />
        </Grid>

        <Grid
          item
          xs={12}
          justifyContent="flex-end"
          sx={{ mt: 5, display: 'flex', gap: '20px' }}
        >
          <Button
            className="gray_bg_btn"
            sx={{ width: '163px', height: '44px' }}
            variant="contained"
            disabled={btnLoading}
            onClick={() => handleCancel()}
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={onSubmit}
            loading={btnLoading}
            sx={{
              width: '163px',
              height: '44px',
              '& .MuiLoadingButton-loadingIndicator': {
                color: 'white',
              },
            }}
            className="primary_bg_btn"
          >
            Apply
          </LoadingButton>
        </Grid>
        <React.Fragment>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              fontWeight={600}
              sx={{
                textAlign: 'center',
                fontSize: '28px !important',
                textDecoration: 'underline',
              }}
              id="alert-dialog-title"
            >
              {'Download and log in using this QR code'}
            </DialogTitle>
            <DialogContent>
              <img
                onClick={handleClickOpen}
                style={{ width: '100%' }}
                src={userLoading ? '' : userQR?.qr || ''}
                alt=""
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button variant="contained" onClick={handleDownlaodQR} autoFocus>
                Download
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      </Grid>

      <SelectAvatar
        setFormData={setFormData}
        open={openAvatarModal}
        formData={formData}
        setOpen={setOpenAvatarModal}
        setImagePreview={setImagePreview}
      />
      <ImageViewPopup
        imageUrl={imagePreview}
        open={imageView}
        onClose={() => setImageView(false)}
      />
    </>
  );
}

export default SchoolLeader;
