import { Box, Card, Modal } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player/lazy';
function HowToUse() {
  const [play, setPlay] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const handleClose = () => {
    setPlay(false);
  };
  const onStartPlay = (e: any) => {
    setVideoUrl(e)
    setPlay(true);
  };
 const videos=[
  {
    title:"How to Assign Content to Students",
    url:"https://www.youtube.com/watch?v=WEPD5O8TNxU"
  },
  {
    title:"How to launch PIN Assessment in G3MSs",
    url:"https://www.youtube.com/watch?v=tpTxpWH-m0A"
  },
  {
    title:"How to Log Into G3MS",
    url:"https://www.youtube.com/watch?v=y-3brwfJ6dI"
  },
  {
    title:"Welcome Video Teachers",
    url:"https://www.youtube.com/watch?v=lbpDvQquDDA"
  },
  {
    title:"Welcome Video School Leaders",
    url:"https://www.youtube.com/watch?v=e_LaAjYw7Ho"
  },
  {
    title:"User Management Tour",
    url:"https://www.youtube.com/watch?v=b5z4vRYeTGg"
  },
  {
    title:"Content Management Tour",
    url:"https://www.youtube.com/watch?v=X-qM2LeB0Ls"
  },
  {
    title:"Hey School Leaders, Let's Tour Our G3MS Dashboard",
    url:"https://www.youtube.com/watch?v=aOfIGq4nuNU"
  },
  {
    title:"Assigning Content Using Imported Assessments",
    url:"https://www.youtube.com/watch?v=V-z9XdRw6UM"
  },
  {
    title:"Hey Teachers, Let's Tour Our G3MS Dashboard",
    url:"https://www.youtube.com/watch?v=cLolCn8GExw"
  },
 ]
  return (
    <Card sx={{ px: 7 }}>
      <div className="mt-3 font-semibold text-md">G3MS Learning Hub</div>
      <div className="mt-4 font-base text-sm text-[#414651]">
        Discover how G3MS empowers students with AI-driven learning, engaging
        challenges, and personalized support to achieve academic success.
      </div>
      <Box
        sx={{
          position: 'relative',
          marginTop: 4,
        }}
      > <div className="block lg:grid grid-cols-3 lg:space-x-4 w-full">
        {videos?.map((item)=>(
         <>
          <div className='block md:block lg:hidden'
            onClick={() => onStartPlay(item.url)}
            style={{
              cursor: 'pointer',
              position: 'relative',
              display: 'inline-block',
            
            }}
          >
            <ReactPlayer
              url={item.url}
              light={true} // Shows a thumbnail
              playing={false} // Prevent autoplay
              controls={false} // Hide controls
              width="360px"
              height="225px"
            />
            {/* Click to Open Modal */}
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                 height: '100%',
                //backgroundColor: 'rgba(0, 0, 0, 0.4)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                fontSize: '20px',
              }}
            ></div>
               <div className="text-md font-semibold mt-4 mb-4">
              {item.title}
            </div>
          </div>
       
       
          {/* <div>
            <div onClick={(e)=>onStartPlay(e)}
               style={{ cursor: "pointer", position: "relative", display: "inline-block" }}
              >
            <ReactPlayer
            ref={playerRef}
            playing={false}
            controls={false}
              width={'360px'}
              height={'200px'}
              url="https://www.youtube.com/watch?v=WEPD5O8TNxU"
            />
            </div>
            <div className="text-md font-semibold mt-2">
              How to Assign Content to Students
            </div>
          </div> */}
          {/* <div>
            <ReactPlayer
              width={'360px'}
              height={'200px'}
              url="https://www.youtube.com/watch?v=tpTxpWH-m0A"
            />
            <div className="text-md font-semibold mt-2">
              How to launch PIN Assessment in G3MSs
            </div>
          </div>
          <div>
            <ReactPlayer
              width={'360px'}
              height={'200px'}
              url="https://www.youtube.com/watch?v=y-3brwfJ6dI"
            />
            <div className="text-md font-semibold mt-2">
              How to Log Into G3MS
            </div>
          </div>
          <div className="mt-3">
            <ReactPlayer
              width={'360px'}
              height={'200px'}
              url="https://www.youtube.com/watch?v=lbpDvQquDDA"
            />
            <div className="text-md font-semibold mt-2">
              Welcome Video Teachers
            </div>
          </div>
          <div className="mt-3">
            <ReactPlayer
              width={'360px'}
              height={'200px'}
              url="https://www.youtube.com/watch?v=e_LaAjYw7Ho"
            />
            <div className="text-md font-semibold mt-2">
              Welcome Video School Leaders
            </div>
          </div>
          <div className="mt-3">
            <ReactPlayer
              width={'360px'}
              height={'200px'}
              url="https://www.youtube.com/watch?v=b5z4vRYeTGg"
            />
            <div className="text-md font-semibold mt-2">
              User Management Tour
            </div>
          </div>
          <div className="mt-3">
            <ReactPlayer
              width={'360px'}
              height={'200px'}
              url="https://www.youtube.com/watch?v=X-qM2LeB0Ls"
            />
            <div className="text-md font-semibold mt-2">
              Content Management Tour
            </div>
          </div>
          <div className="mt-3">
            <ReactPlayer
              width={'360px'}
              height={'200px'}
              url="https://www.youtube.com/watch?v=aOfIGq4nuNU"
            />
            <div className="text-md font-semibold mt-2">
              Hey School Leaders, Let's Tour Our G3MS Dashboard
            </div>
          </div>
          <div className="mt-3">
            <ReactPlayer
              width={'360px'}
              height={'200px'}
              url="https://www.youtube.com/watch?v=V-z9XdRw6UM"
            />
            <div className="text-md font-semibold mt-2">
              Assigning Content Using Imported Assessments
            </div>
          </div>
          <div className="mt-3">
            <ReactPlayer
              width={'360px'}
              height={'200px'}
              url="https://www.youtube.com/watch?v=cLolCn8GExw"
            />
            <div className="text-md font-semibold mt-2">
              Hey Teachers, Let's Tour Our G3MS Dashboard
            </div>
          </div> */}
    </>
        ))}
        </div>
        

        <div className="flex items-center justify-center m-4 w-full">
          <div className="w-[100%] flex items-center justify-center border-t-[1px] p-3">
            <div>
              <div className="flex items-center justify-center">
                <img
                  src={require('assets/HowToUse.png')}
                  alt="users"
                  className="w-24 mt-4"
                />
              </div>
              <div className="flex items-center justify-center">
                <div className="text-md font-semibold mt-2">
                  Still have questions?
                </div>
              </div>
              <div className="flex items-center justify-center">
                <div className="mt-4 font-base text-sm text-[#414651]">
                  Can’t find the answer you’re looking for? Please chat to our
                  friendly team.
                </div>
              </div>
              <div className="flex items-center justify-center mt-2">
                <div
                  role="button"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open('mailto:Help@getg3ms.com', '_blank');
                  }}
                  className="py-3 px-4 mr-3 flex items-center justify-center bg-[#20A58A] text-xs font-semibold text-[white] rounded-lg"
                >
                  Report an issue
                </div>
                <div className="py-[11px] px-4 flex items-center justify-center border border-[#20A58A] text-xs font-semibold text-[#20A58A] rounded-lg">
                  Recommend a feature
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
      <Modal
        open={play}
        onClose={handleClose}
       hideBackdrop
        style={{
          width: '50%',
          height: '60%',
          margin: 'auto',
          background: '#E9F6F3',
          padding: '20px',
          borderRadius: '10px',
          alignItems:"center",
          justifyContent:"center",

        }}
      >
        <div>
          <div className='flex justify-end'>
            <div
            role='button'
            onClick={()=>setPlay(false)}>X</div>
          </div>
          <div className='flex justify-center'>
          <ReactPlayer
            //ref={playerRef}
            playing={true}
            controls={true}            
              url={videoUrl}
            />
          </div>
  
        </div>
      </Modal>
    </Card>
  );
}

export default HowToUse;
