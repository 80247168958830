import React, { useEffect, useState } from 'react';
import { Grid, Typography, Card, Box } from '@mui/material';
import { parents, school, student, teacher2 } from 'assets';
import { TableList } from '../../../components/Dashboard/table';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { getDashboardInfo } from '../../../store/Thunk/dashboardThunk';
import { CardInfo } from '../../../components/Dashboard/cardInfo';

import Students from '../../../components/Dashboard/students';
// import {studentsList} from '../../../components/Dashboard/students.mock'
import { StudInfo } from '../../../components/Dashboard/student';
import { ITeacher } from '../../../components/Dashboard/table';
import FreePlanBenifits from '../components/FreePlan/FreePlanBenifits';
import Header from '../components/Header/Header';
import Progresss from '../components/Progress/Progresss';
import ButtonMain from '../components/ButtonMain';
import BenifitsText from '../components/FreePlan/BenifitsText';
import UpgradeCard from '../components/UpgradePlan/UpgradeCard';
import GiftsCard from '../components/Gifts/GiftsCard';
import GradientView from '../components/Leaders/GradientView';
import Bottom from '../components/BottomBar/Bottom';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Steps from '../components/Onboarding/Steps';
import CustomizedSteppers from '../components/Onboarding/Steppers';
import ModalMain from '../components/Modals/ModalUpgradedParents';
import ModalFreeParents from '../components/Modals/AddStudent';
import EducatorSettings from '../components/Modals/EducatorSettings';
import { ElevatorPassengerOffOutline } from 'mdi-material-ui';
import ParentOnboarded from '../Dashboards/ParentOnboarded';
import EducatorDashboard from '../Dashboards/EducatorDashboard';
import { useGetMyProfileQuery } from 'store/apis/profile';
import config from 'config/config';

function Index() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openUpgrade, setOpenUpgrade] = React.useState(false);
  const handleOpenUpgrade = () => setOpenUpgrade(true);
  const handleCloseUpgrade = () => setOpenUpgrade(false);
  const [openEducator, setOpenEducator] = React.useState(false);
  const handleOpenEducator = () => setOpenEducator(true);
  const handleCloseEducator = () => setOpenEducator(false);

  // set status 2 to show the parent
  const [status, setStatus] = useState('2');
  const [roles, setRoles] = useState('');
  const [view, setView] = useState('');
  const dispatch: any = useAppDispatch();
  const { data } = useGetMyProfileQuery();
  // const { teachers  } = useAppSelector((state: any) => state.dashboardReducer);
  // const studentsList: StudInfo[] =[]
  // const teachers:ITeacher =[]
  const teacherSchool: any = localStorage.getItem('teacherSchool');
  const header: any = localStorage.getItem('userData');
  const token: any = JSON.parse(header).token;
  console.log("user data", data?.data)
  useEffect(() => {
    dispatch(getDashboardInfo({ id: teacherSchool }));
    checkLevel();
  }, [roles, status]);
  const upgradePlan = () => {};

  const checkLevel = () => {
    if (status === '1') {
      setView('parentNotOnboarded');
      setOpen(true);
    } else if (status === '2') {
      setView('parentOnboarded');
      setOpen(false);
    } else if (status === '3') {
      setView('parentUpgraded');
      setOpenUpgrade(true);
    } else if (status === '4') {
      setView('EduNoOnbrded');
      setOpenEducator(true);
    } else if (status === '5') {
      setView('EduOnboarded');
      //setOpenEducator(true);
    } else if (status === '6') {
      setView('eduWithData');
      //setOpenEducator(true);
    }
  };

  const {
    totalStudentsCount,
    totalTeachersCount,
    totalParentsCount,
    totalClassroomsCount,
  } = useAppSelector((state: any) => state.dashboardReducer);
  return (
    <>
      <Card>
        <Header
          role={view}
          name={data?.data?.name}
          onPress={() =>
            window.open(
              `${config.STUDENT_BASE_URL}/auth/token-login?token=${token}?&redirectTo=plans`,
              'blank',
            )
          }
        />
        {view === 'parentNotOnboarded' ? (
          /** Parent Dashboard not oboarded*/
          <>
            {/** 1st div */}
            <div className=" block lg:flex w-full justify-between">
              <Box className="w-full lg:w-9/12 border border-[#EAECF0] lg:ml-3 lg:mr-2 rounded-lg">
                <Box className="mx-2 mt-2  border-[1.5px] border-[#E8E8E8] rounded-lg">
                  <CustomizedSteppers />
                </Box>
                <FreePlanBenifits
                  name={'[Parent Name]!'}
                  childName={"[Child's Name]"}
                />
                <ButtonMain
                  color={false}
                  font={'500'}
                  onPress={() => console.log('')}
                  text={'Next'}
                />
              </Box>
              <div className="w-full lg:w-3/12 ">
                <UpgradeCard
                  onPress={handleOpenUpgrade}
                  from={''}
                  fw={'400'}
                  styl={{
                    fontWeight: 700,
                    fontSize: 12,
                    marginLeft: 3,
                    marginRight: 2,
                  }}
                  stroke={'#20A58A'}
                />
              </div>
            </div>

            {/** 2nd div */}
            <div className="block lg:flex w-full justify-between mt-2 mb-4">
              <Box className="w-full lg:w-9/12 border border-[#EAECF0] ml-3 mr-2 rounded-lg">
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: 13,
                    marginLeft: 2,
                    marginTop: 2,
                  }}
                  fontFamily="Inter"
                  color="#101828"
                >
                  Explore Features
                </Typography>
                <div>
                  <Carousel
                    className="px-5 py-5 "
                    showArrows={false}
                    showIndicators={false}
                    showThumbs={false}
                    centerMode={true}
                    autoPlay={true}
                    swipeable={true}
                    infiniteLoop={true}
                    autoFocus={false}
                    centerSlidePercentage={92}
                  >
                    <img
                      className=""
                      src={require('../../../assets/Parent/Credit.png')}
                    />
                    <img src={require('../../../assets/Parent/Credit.png')} />
                  </Carousel>
                </div>
              </Box>
              <div className="block md:flex lg:block w-full  lg:w-3/12 ">
                <Box className="mr-3 w-full md:1/2 lg:w-full border border-[#EAECF0]  rounded-lg items-center mb-2">
                  <GiftsCard />
                </Box>
                <Box className="w-full md:1/2 lg:w-full border border-[#EAECF0]  rounded-lg items-center">
                  <>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: 13,
                        marginLeft: 2,
                        marginTop: 2,
                      }}
                      fontFamily="Inter"
                      color="#101828"
                    >
                      G3MS Leader Rewards
                    </Typography>
                    <div className="flex  justify-around px-2 py-1">
                      <GradientView
                        src={require('../../../assets/Parent/Asset2.png')}
                        img={require('../../../assets/Parent/img1.png')}
                        name={'Jane'}
                        grade={'5th'}
                        marks={'48/50'}
                        classGradient={'abdd'}
                      />
                      <GradientView
                        src={require('../../../assets/Parent/Asset3.png')}
                        img={require('../../../assets/Parent/img3.png')}
                        name={'Ronald'}
                        grade={'4th'}
                        marks={'50/50'}
                        classGradient={'ab'}
                      />
                      <GradientView
                        src={require('../../../assets/Parent/Asset1.png')}
                        img={require('../../../assets/Parent/img2.png')}
                        name={'Ronald'}
                        grade={'4th'}
                        marks={'47/50'}
                        classGradient={'ad'}
                      />
                    </div>
                  </>
                </Box>
              </div>
            </div>
          </>
        ) : view == 'parentOnboarded' || view == 'parentUpgraded' ? (
          /** Parent Dashboard oboarded*/

          <ParentOnboarded
            view={view}
            onClick={() => {
              setStatus('3');
              checkLevel();
            }}
          />
        ) : view == 'EduNoOnbrded' ||
          view == 'EduOnboarded' ||
          view == 'eduWithData' ? (
          /** Parent Dashboard oboarded*/
          <EducatorDashboard
            onClick={() => {
              setStatus('6');
              checkLevel();
            }}
            onPress={() => setOpenEducator(true)}
            role={view}
          />
        ) : null}
      </Card>

      {/** bottom div */}
      <Card sx={{ marginTop: 3 }}>
        <Bottom />
      </Card>
      {/** <ModalMain onClick={handleOpen} open={open} onClose={handleClose} /> */}
      <ModalMain
        keepOldPlan={() => {
          // setOnBoarded(true);
          // localStorage.setItem('onBoarded', JSON.stringify(onBoarded));
        }}
        onPressUpgrade={() => {
          handleCloseUpgrade();
          handleOpenEducator();
        }}
        open={openUpgrade}
        onClose={handleCloseUpgrade}
      />
      <EducatorSettings
        onPress={() => {
          setOpenEducator(false);
          setStatus('5');
        }}
        open={openEducator}
        onClose={handleCloseEducator}
      />
    </>
  );
}

export default Index;
