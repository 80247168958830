import { Box, IconButton, Typography } from '@mui/material';
import DefaultCell from 'components/DefaultCell';
import CustomTable from 'components/customTable';
import '../../user_management/index.scss';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { useOnClickOutside } from 'hooks/useOnClickOutside';

import { setTeacherList } from 'store/Slice/videoSlice';
import { useAppDispatch, useAppSelector } from 'store/store';
import { getUserListAccordingToType } from 'store/Thunk/userThunk';
import CrudPopUp from 'components/popUp/crudPopup';
import AvatartWithText from 'components/AvatarWithText';
import { formatDate, getLocalDateTime } from 'helper/service';
import ReadMore from 'components/ReadMore/ReadMore';
import { useNavigate } from 'react-router-dom';
import { getLinkPartFromRole } from 'helper/functions';
import { deleteStudent } from 'store/Thunk/studentThunk';
import DeleteUserDialog from 'components/dialog/users/Delete/deleteUser';
import { getDistrictByID } from 'store/Thunk/districtThunk';
import { getAllSchoolList, getSchoolLeader } from 'store/Thunk/schoolThunk';
import useSchoolClassroomInfo from 'hooks/useSchoolClassroomInfo';

const ClassItem = ({
  title,
  count,
  itemKey,
}: {
  title: string;
  count: number;
  itemKey: number;
}) => {
  return (
    <Box>
      {itemKey < 2 && (
        <Box
          sx={{
            background: '#F8F8F8',
            borderRadius: '4px',

            fontWeight: 400,
            p: 1,
          }}
        >
          <span>{title}</span>
        </Box>
      )}
    </Box>
  );
};

function SchoolLeader({ userList }: any) {
  const current_schoolId = localStorage.getItem('current_schoolId');

  const { schoolId } = useSchoolClassroomInfo();
  const { districtID } = useAppSelector((state: any) => state.districtReducer);

  const { schoolLeader } = useAppSelector((state) => state.schoolReducer);
  const navigate = useNavigate();
  const [activeButtonRow, setActiveButtonRow] = useState<any>(null);
  const dropDownRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(dropDownRef, () => setActiveButtonRow(null));
  const [pageNo, setPageNo] = useState(0);
  const [perPage, setPageSize] = useState(10);
  const [search, setSearch] = useState<any>(null);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  // useEffect(() => {
  //   dispatch(getSchoolLeader({ id: current_schoolId }));
  // }, [current_schoolId]);
  const [selectedUserToDelete, setSelectedUserToDelete] = useState({
    dependentOfUser: {
      name: '',
      profileImage: '',
    },
    id: '',
  });
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const initial = getLinkPartFromRole();

  const { schoolLeaderList } = useAppSelector((state) => state.userReducer);
  const dispatch = useAppDispatch();
  console.log('schoolLeader', schoolLeaderList);

  useEffect(() => {
    let schoolId = '';
    const intial = getLinkPartFromRole();
    if (intial === 'school-leader') {
      schoolId =
        localStorage.getItem('teacherSchool') ||
        localStorage.getItem('current_schoolId') ||
        '';
    }
    dispatch(
      getUserListAccordingToType({ userType: 'school_leader', schoolId }),
    );
  }, []);

  useEffect(() => {
    if (schoolLeaderList?.items && schoolLeaderList?.meta) {
      setPageCount(schoolLeaderList?.meta?.totalPages);
      setTotalCount(schoolLeaderList?.meta?.totalCount);
    }
  }, [schoolLeaderList]);

  const handleEventClick = (rowIndex: number) => {
    setActiveButtonRow(rowIndex);
  };

  useEffect(() => {
    if (schoolLeaderList?.items?.length > 0) {
      setPageCount(schoolLeaderList?.meta?.totalPages);
      setTotalCount(schoolLeaderList?.meta?.totalCount);
    }
  }, [schoolLeaderList]);

  const defaultCellStyle = {};

  const handleSubmit = () => {
    setDeleteLoading(true);
    dispatch(deleteStudent(selectedUserToDelete.id))
      .unwrap()
      .then(() => {
        setOpenDeleteModal(false);
        dispatch(
          getUserListAccordingToType({
            pageSize: perPage,
            pageIndex: pageNo,
            search,
            userType: 'school_leader',
          }),
        );
      })
      .catch(() => {})
      .finally(() => {
        setDeleteLoading(false);
        setSelectedUserToDelete({
          dependentOfUser: {
            name: '',
            profileImage: '',
          },
          id: '',
        });
      });
  };

  const columns = [
    {
      Header: 'District',
      accessor: 'ncesDistrictId',
      Cell: ({ value }: any) => (
        <DefaultCell sx={defaultCellStyle} value={value} />
      ),
    },
    {
      Header: 'SchoolId',
      accessor: 'schoolId',
      Cell: ({ value }: any) => (
        <DefaultCell sx={defaultCellStyle} value={value} />
      ),
    },
    {
      Header: 'State',
      accessor: 'state',
      Cell: ({ value }: any) => (
        <DefaultCell sx={defaultCellStyle} minWidth="50px" value={value} />
      ),
    },
    {
      Header: 'Name',
      accessor: 'studentInfo',
      Cell: ({ value }: any) => <AvatartWithText value={value} />,
    },
    {
      Header: 'Email',
      accessor: 'email',
      Cell: ({ value }: any) => (
        <ReadMore
          maxLength={20}
          sx={{ ...defaultCellStyle, minWidth: '150px' }}
          text={value}
        />
      ),
    },

    {
      Header: 'Created Date',
      accessor: 'createdDate',
      Cell: ({ value }: any) => (
        <DefaultCell sx={defaultCellStyle} minWidth="145px" value={value} />
      ),
    },
    {
      Header: 'Action',
      accessor: '',
      Cell: ({ row }: any) => (
        <>
          <IconButton onClick={() => handleEventClick(row.index)}>
            <MoreVertIcon />
          </IconButton>
          {activeButtonRow === row.index && (
            <CrudPopUp
              showEdit
              showDelete
              showView
              onDeleteClick={() => {
                setOpenDeleteModal(true);
                setSelectedUserToDelete({
                  id: row.original.schoolId || '',
                  dependentOfUser: row?.original?.parentInfo,
                });
              }}
              onEditClick={() => {
                navigate(
                  `/school-leader/user-management/edit?mode=leader&id=${row?.original?.schoolId}`,
                );
              }}
              onViewClick={() =>
                navigate(
                  `/school-leader/user-management/view?mode=leader&id=${row?.original?.schoolId}`,
                )
              }
              setClose={() => setActiveButtonRow(null)}
            />
          )}
        </>
      ),
    },
  ];

  const fetchData = useCallback(({ pageSize, pageIndex, search }: any) => {
    setPageNo(pageIndex);
    setPageSize(pageSize);
    dispatch(
      getUserListAccordingToType({
        pageSize,
        pageIndex,
        search,
        userType: 'school_leader',
      }),
    );
  }, []);

  const rows = useMemo(() => {
    if (schoolLeaderList?.items?.length > 0) {
      const data = schoolLeaderList?.items?.map((item: any) => ({
        schoolId: item.id,
        state: item?.school?.state || '',
        studentInfo: {
          name: item?.name,
          image: item?.profileImage,
        },

        ncesDistrictId: item?.school?.ncesDistrictId || '',
        email: item.email,

        createdDate: formatDate(item?.createdAt),
      }));
      return data;
    } else return [];
  }, [schoolLeaderList]);

  return (
    <>
      <div style={{ position: 'relative' }}>
        <CustomTable
          isSorted={false}
          fetchData={fetchData}
          pageCount={pageCount}
          canSearch
          pageNo={pageNo}
          totalCount={totalCount}
          table={{
            columns: columns,
            rows: rows,
          }}
        />
      </div>
      <DeleteUserDialog
        loading={deleteLoading}
        open={openDeleteModal}
        title={'Are you sure you want to delete the user from G3MS?'}
        description={
          selectedUserToDelete?.dependentOfUser?.name
            ? 'Are you sure want to remove parent children relation too?'
            : ''
        }
        onOk={handleSubmit}
        onClose={() => {
          setOpenDeleteModal(false);
          setSelectedUserToDelete({
            dependentOfUser: {
              name: '',
              profileImage: '',
            },
            id: '',
          });
        }}
      />
    </>
  );
}

export default SchoolLeader;
