import { useFormik } from 'formik';

import { Button, Modal, rem } from '@mantine/core';
import useModal from 'components/Modal/hook/useModal';
import cn from 'utils/cn';

import DateInputField from 'components/DateInputField';
import SelectInputField from 'components/SelectInputField';
import TelephoneInput from 'components/TelephoneInput';
import TextInputField from 'components/TextInputField';
import { Calendar } from 'iconsax-react';
import React from 'react';
import { useGetGradeListQuery } from 'store/apis/hometab';
import { useGetUserTypesQuery } from 'store/apis/school';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { useAddUserMutation, useAddUserToClassMutation } from 'store/apis/user';
import { UserType } from 'store/apis/profile/types';
import { useGetMyProfileQuery } from 'store/apis/profile';

const validationSchema = z.object({
  fistName: z.string().min(2, 'Too Short!'),
  lastName: z.string().min(2, 'Too Short!'),
  dob: z.date({ message: 'Invalid Date' }),
  email: z.string().email('Invalid Email'),
  dialCode: z.string().min(1, 'Too Short!'),
  phoneNumber: z.string().min(6, 'Too Short!'),
  role: z.string({ message: 'Required' }),
  classroom: z.string({ message: 'Required' }),
});

export type AddAnotherUserModalProps = z.infer<typeof validationSchema>;

export default function AddAnotherUserModal() {
  const { closeModal, isOpen, modal, openModal } = useModal<{
    onContinue?: () => void;
    isFirstStudent?: boolean;
    withUpgradeSuccess?: boolean;
    withAddons?: boolean;
  }>();

  const { data: gradeList, isLoading } = useGetGradeListQuery();
  const {
    data: roleData,
    isLoading: roleLoading,
    error: roleError,
  } = useGetUserTypesQuery(undefined);

  const { data: userData } = useGetMyProfileQuery();

  const [addUserRequest, addUserResponse] = useAddUserMutation();
  const [addUserToClassRequest, addUserToClassResponse] =
    useAddUserToClassMutation();

  const classRooms = React.useMemo(() => {
    if (!userData?.data?.classrooms) return [];
    return userData?.data?.classrooms.map((cl) => ({
      value: cl.classroom.id,
      label: cl.classroom.name,
    }));
  }, [userData]);

  const formik = useFormik<AddAnotherUserModalProps>({
    initialValues: {
      fistName: '',
      lastName: '',
      dob: new Date(),
      role: '',
      email: '',
      dialCode: '',
      phoneNumber: '',
      classroom: '',
    },
    validationSchema: toFormikValidationSchema(validationSchema),
    onSubmit: async (values, { setFieldError }) => {
      try {
        const user = await addUserRequest({
          name: values.fistName,
          lastname: values.lastName,
          email: values.email,
          phone: values.phoneNumber,
          dateOfBirth: values.dob,
          type: values.role as UserType,
        }).unwrap();

        if (!user) {
          return;
        }

        await addUserToClassRequest({
          userIds: [user.data.id],
          classId: values.classroom,
        }).unwrap();

        openModal('AddAnotherClassRoomWarningModal', {
          ...formik.values,
          educatorPlan: 'premium',
          cache: true,
        });
        return;
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    // <Modal isOpen={isOpen('AskAGrownupModal')} onClose={closeModal}>
    //   <Modal.Content className="[--max-width:_45rem] [--top:_2rem]  md:[--top:_10%]">
    //     <Modal.CloseButton />
    <Modal
      opened={isOpen('AddAnotherUserModal')}
      onClose={closeModal}
      closeOnClickOutside={false}
      closeOnEscape={false}
      size={'xl'}
      centered
      shadow="sm"
      radius={'lg'}
      classNames={{
        title:
          '!text-lg !font-semibold lg:!text-xl 2xl:!text-2xl !text-[#2C3E50]',
        close:
          '!text-[#2C3E50] !text-lg !font-semibold lg:!text-xl 2xl:!text-2xl',
      }}
      padding={rem(24)}
      zIndex={999999999}
      withCloseButton={false}
    >
      <div className="grid gap-4 lg:gap-6">
        <div className="space-y-2">
          <h1
            className={cn(
              '!font-semibold !text-lg lg:!text-xl 2xl:!text-2xl text-[#131515]',
            )}
          >
            Add Another User to Classroom
          </h1>
          <p
            className={cn(
              '!text-sm lg:!text-base xl:!text-lg 2xl:!text-xl text-[#131515]',
            )}
          >
            Would you like to add another user to to the classroom? It could be
            another student, another teacher, a parent, or leader.
          </p>
        </div>

        <form
          onSubmit={formik.handleSubmit}
          className="flex flex-col lg:grid gap-4 lg:!grid-cols-2"
        >
          <TextInputField
            label="First Name"
            placeholder="Enter First Name"
            {...formik.getFieldProps('fistName')}
            error={formik.touched.fistName && formik.errors.fistName}
          />
          <TextInputField
            label="Last Name"
            placeholder="Enter Last Name"
            {...formik.getFieldProps('lastName')}
            error={formik.touched.lastName && formik.errors.lastName}
          />

          <DateInputField
            label="Date of Birth"
            placeholder="Enter Date of Birth"
            value={formik.values.dob as Date}
            onChange={(value: any) =>
              formik.setFieldValue('dob', value as Date)
            }
            onBlur={() => formik.setFieldTouched('dob', true)}
            error={
              formik.touched.dob && typeof formik.errors.dob === 'string'
                ? formik.errors.dob
                : undefined
            }
            rightSection={<Calendar />}
          />

          <TextInputField
            label="Email"
            placeholder="Enter Email"
            {...formik.getFieldProps('email')}
            error={formik.touched.email && formik.errors.email}
          />

          <div className="col-span-2 flex flex-col lg:grid gap-4 ">
            <SelectInputField
              label="User Role"
              placeholder="Select User Role"
              data={roleData?.data.map((role) => ({
                value: role,
                label: role?.replace(/_/g, ' '),
              }))}
              disabled={isLoading}
              onChange={(value) => formik.setFieldValue('role', value)}
              value={formik.values.role}
              error={formik.touched.role && formik.errors.role}
              onBlur={() => formik.setFieldTouched('role', true)}
            />
            <TelephoneInput
              label="Phone Number"
              onChange={(value) => {
                formik.setFieldValue('phoneNumber', value);
              }}
              onCountryChange={(value) => {
                formik.setFieldValue('dialCode', value.callingCode);
              }}
              value={formik.values.phoneNumber}
              error={
                formik.touched.phoneNumber && formik.errors.phoneNumber
                  ? formik.errors.phoneNumber
                  : undefined
              }
              onBlur={() => formik.setFieldTouched('phoneNumber', true)}
            />

            <SelectInputField
              label="Classroom"
              placeholder="Select Classroom"
              data={classRooms}
              disabled={isLoading}
              onChange={(value) => formik.setFieldValue('classroom', value)}
              value={formik.values.classroom}
              error={formik.touched.classroom && formik.errors.classroom}
              onBlur={() => formik.setFieldTouched('classroom', true)}
            />
          </div>

          <div className="grid gap-4 lg:grid-cols-2 col-span-2">
            <Button
              className="max-w-full !rounded-xl col-span-2"
              color="#20A58A"
              size="xl"
              fw={500}
              type="submit"
            >
              Add User
            </Button>
            <Button
              className="max-w-full !rounded-xl col-span-2"
              color="#999999"
              size="xl"
              fw={500}
              type="button"
              onClick={() => {
                openModal('ParentSelectAcademic', {
                  ...modal?.props,
                  educatorPlan: 'premium',
                  cache: true,
                });
              }}
            >
              Skip
            </Button>
          </div>
        </form>
      </div>
    </Modal>

    // </Modal.Content>
    // </Modal>
  );
}
