import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Link,
  Breadcrumbs,
  Stack,
  Button,
} from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Tooltip from '@mui/material/Tooltip';
import CustomTable from 'components/customTable';
import DefaultCell from 'components/DefaultCell';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNavigate, useParams } from 'react-router-dom';
import AddUpdate from 'components/dialog/classroom/AddUpdate';

import { getClassroomList } from 'store/Thunk/classroomThunk';
import DeleteClassroom from 'components/dialog/classroom/Delete';
import EditBorderIcon from 'icons/editBorderIcon';
import DeleteBorderIcon from 'icons/deleteBorderIcon';
import { setClassroomByID } from 'store/Slice/classroomSlice';
import EyeBorderIcon from 'icons/eyeBorderIcon';
import AddFirstClassroom from './AddFirstClassroom';
import AddStudentToClassRoom from '../modals/AddStudentToClassRoom';
import { useAppDispatch, useAppSelector } from 'store/store';
import API from 'store/api';
import AddTeacherToClassRoom from '../modals/AddTeacherToClassRoom';
import { Roles } from 'constants/consts';
function handleBreadCrumbClick(
  event: React.MouseEvent<HTMLDivElement, MouseEvent>,
) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}
interface propsType {
  setTabValue?: any;
}

function Index({ setTabValue }: propsType) {
  const { id } = useParams();

  if (!id) {
    throw new Error('No school id was provided');
  }

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { classroomList } = useAppSelector(
    (state: any) => state.classroomReducer,
  );
  const { screenLoading } = useAppSelector(
    (state: any) => state.loadingReducer,
  );

  const [isOpen, setIsOpen] = useState(false);
  const [classroom, setClassroom] = useState<any[]>([]);
  const [classroomFiltered, setClassroomFiltered] = useState<any[]>([]);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState<any>(null);
  const [totalCount, setTotalCount] = useState(0);
  const [editId, setEditId] = useState('');
  const params = useParams();

  const urlParams = new URLSearchParams(window.location.search);
  const grade = urlParams.get('grade');
  const topic = urlParams.get('topic');
  const studentDetail = urlParams.get('studentDetail');
  useEffect(() => {
    const filteredClassrooms = classroom.filter(
      (item) =>
        item.classroom.topic.id === topic && item.classroom.grade.id === grade,
    );

    setClassroomFiltered(filteredClassrooms);
  }, [topic, grade]);
  useEffect(() => {
    setClassroomFiltered(classroom);
  }, [classroom]);
  const [isAddStudentDialogOpen, setIsAddStudentDialogOpen] =
    useState<boolean>(false);

  const [isAddTeacherDialogOpen, setIsTeacherDialogOpen] =
    useState<boolean>(false);

  const classRef = useRef<string | null>(null);

  const openAddStudentDialog = useCallback(
    (id: string) => () => {
      if (!id) {
        throw new Error('No class id was provided');
      }
      classRef.current = id;
      setIsAddStudentDialogOpen(true);
    },
    [],
  ) as (id: string) => () => void;

  const openAddTeacherDialog = useCallback(
    (id: string) => () => {
      if (!id) {
        throw new Error('No class id was provided');
      }
      classRef.current = id;
      console.log(id);
      setIsTeacherDialogOpen(true);
    },
    [],
  ) as (id: string) => () => void;

  const closeAddStudentDialog = useCallback(() => {
    classRef.current = null;
    setIsAddStudentDialogOpen(false);
  }, []);

  const handleEditClick = (id: string) => {
    dispatch(setClassroomByID({}));
    setEditId(id);
    setIsOpen(!isOpen);
  };
  const userData: any = localStorage.getItem('userData');

  const isAuthenticated = JSON.parse(userData);
  const userRole = isAuthenticated['type'];
  const userId = userRole === Roles.TEACHER ? isAuthenticated.id : '';
  const fetchIdRef = useRef(0);
  useEffect(() => {
    //if (!id) {
    dispatch(
      getClassroomList({
        pageCount: 1,
        perPage: 10,
        id,
        grade: params.gradeId,
        topic: params.topicId,
        userId: userId,
      }),
    );
    //}
  }, []);

  useEffect(() => {
    if (classroomList && classroomList?.items) {
      setPageCount(classroomList?.meta?.totalPages);
      setTotalCount(classroomList?.meta?.totalCount);

      const fetchStudentCount = async (classRoom: any) => {
        const res = await API.get(
          `api/v1/classroom/${classRoom.classroom.id}/student`,
        );
        return {
          ...classRoom,
          studentsCount: res.data.data.items.length,
        };
      };

      const promises = classroomList.items.map((classRoom: any) =>
        fetchStudentCount(classRoom),
      );

      Promise.all(promises)
        .then((updatedClassrooms) => setClassroom(updatedClassrooms))
        .catch((error) => console.error(error));
    }
  }, [classroomList]);

  const handleFetchClassRoom = (setPageOne: boolean) => {
    if (setPageOne) {
      setPageNo(0);
      dispatch(
        getClassroomList({
          pageCount: 1,
          perPage: 10,
          id,
          grade: params.gradeId,
          topic: params.topicId,
          userId: userId,
        }),
      );
    } else {
      dispatch(
        getClassroomList({
          pageSize,
          pageIndex: pageNo,
          id,
          search,
          grade: params.gradeId,
          topic: params.topicId,
          userId: userId,
        }),
      );
    }
  };

  const handleDeleteDialog = (id: string) => {
    setDeleteId(id);
    setOpenDelete(!openDelete);
  };

  const columns = [
    {
      Header: 'Department',
      accessor: 'topic',
      Cell: ({ value }: any) => (
        <Tooltip title={value?.id} arrow>
          {/* <DefaultCell value={value?.name} />, */}
          <Typography
            variant="inherit"
            fontWeight={400}
            sx={{
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-words',
              width: '100%',
              minWidth: '150px',
              color: '#84818a',
              fontSize: '14px',
              fontWeight: 500,
            }}
          >
            {value?.name || '-'}
          </Typography>
        </Tooltip>
      ),
    },
    {
      Header: 'Classroom Name',
      accessor: 'classroom_name',
      Cell: ({ value, row }: any) => (
        <Tooltip title={row.original?.classroomId || ''} arrow>
          <Typography
            variant="inherit"
            fontWeight={400}
            sx={{
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-words',
              width: '100%',
              minWidth: '150px',
              color: '#84818a',
              fontSize: '14px',
              fontWeight: 500,
            }}
          >
            {value || '-'}
          </Typography>
        </Tooltip>
      ),
    },
    {
      Header: 'Classroom Period',
      accessor: 'classroom_period',
      Cell: ({ value }: any) => (
        <DefaultCell
          sx={{ color: '#84818a', fontWeight: 500, fontSize: '14px' }}
          value={value}
        />
      ),
    },
    {
      Header: 'Grade',
      accessor: 'grade',
      Cell: ({ value }: any) => (
        <Tooltip title={value?.id} arrow>
          {/* <DefaultCell value={value?.name} />, */}
          <Typography
            variant="inherit"
            fontWeight={400}
            sx={{
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-words',
              width: '100%',
              minWidth: '150px',
              color: '#84818a',
              fontSize: '14px',
              fontWeight: 500,
            }}
          >
            {value?.name || '-'}
          </Typography>
        </Tooltip>
      ),
    },
    {
      Header: 'Teacher',
      accessor: 'teacher',
      Cell: ({ value, row }: any) => (
        <Stack direction="row" spacing={0} alignItems="center">
          {value?.ClassroomTeacher?.length === 0 ? (
            <Box>
              <Button
                onClick={openAddTeacherDialog(row.original?.classroomId)}
                variant="text"
                sx={{
                  textDecoration: 'underline',
                  textTransform: 'none !important',
                  color: '#84818a',
                  fontSize: '14px',
                  fontWeight: 500,
                }}
                style={{ color: '#84818a', fontSize: '14px', fontWeight: 500 }}
              >
                Add Teacher
              </Button>
            </Box>
          ) : (
            <Box
              sx={{
                borderBottom: '1px solid #979C9E',
                px: 2,
                cursor: 'pointer',
                color: '#84818a',
              }}
              onClick={() => {
                window.location.href = `/school/${id}/classroom/${
                  row.original?.action?.id
                }?topic=${row.original?.action?.topic?.name}&topicId=${
                  row.original.action?.topic?.id
                }&grade=${row.original.action?.grade?.name}&gradeId=${
                  row.original.action?.grade?.id
                }&name=${row.original.action.name}&period=${
                  row.original.action?.period
                }&type=${row.original?.action?.type || '-'}&studentsCount=${
                  row.original?.student
                }&schoolId=${id}&classroomId=${
                  row.original?.action?.id
                }&section=teacher`;
              }}
            >
              {value?.ClassroomTeacher?.length}
            </Box>
          )}
        </Stack>
      ),
    },
    {
      Header: 'Student',
      accessor: 'student',
      Cell: ({ value, row }: any) => {
        return (
          <Stack direction="row" spacing={0} alignItems="center">
            {value === 0 ? (
              <Box>
                <Button
                  variant="text"
                  onClick={openAddStudentDialog(row.original?.classroomId)}
                  sx={{
                    textDecoration: 'underline',
                    textTransform: 'none !important',
                    color: '#84818a',
                    fontSize: '14px',
                    fontWeight: 500,
                  }}
                  style={{
                    color: '#84818a',
                    fontSize: '14px',
                    fontWeight: 500,
                  }}
                >
                  Add Student
                </Button>
              </Box>
            ) : (
              <Box
                sx={{
                  borderBottom: '1px solid #979C9E',
                  px: 2,
                  cursor: 'pointer',
                  color: '#84818a',
                }}
                onClick={() => {
                  window.location.href = `/school/${id}/classroom/${
                    row.original?.action?.id
                  }?topic=${row.original?.action?.topic?.name}&topicId=${
                    row.original.action?.topic?.id
                  }&grade=${row.original.action?.grade?.name}&gradeId=${
                    row.original.action?.grade?.id
                  }&name=${row.original.action.name}&period=${
                    row.original.action?.period
                  }&type=${row.original?.action?.type || '-'}&studentsCount=${
                    row.original?.student
                  }&schoolId=${id}&classroomId=${
                    row.original?.action?.id
                  }&section=student`;
                }}
                // onClick={() => {
                //   console.log(row.original.action.topic.name);
                // }}
              >
                {/* <Link
                  href={`${window.location.href}/classroom/${row.original?.classroomId}?section=student`}
                  underline="none"
                  key="1"
                  color="#84818a"
                  fontSize="14px"
                  fontWeight="500"
                >
                </Link> */}
                {value}
              </Box>
            )}
          </Stack>
        );
      },
    },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: ({ row, value }: any) => (
        <Box className="flex w-full flex-row justify-center items-center">
          <IconButton onClick={() => handleEditClick(value?.id)}>
            <EditBorderIcon />
          </IconButton>
          <IconButton
            sx={{
              color:
                row.original?.student > 0
                  ? 'rgba(76, 78, 100, 0.26)'
                  : '#F41F52',
              border:
                row.original?.student > 0
                  ? '1px solid rgba(76, 78, 100, 0.26)'
                  : '1px solid #F41F52',
              borderRadius: '4px',
              height: '30px',
              width: '31px',
            }}
            disabled={row.original?.student > 0}
            onClick={() => handleDeleteDialog(value?.id)}
          >
            {/* <IconButton onClick={() => console.log(value, row.original)}> */}
            <DeleteOutlinedIcon
              sx={{
                color:
                  row.original?.student > 0
                    ? 'rgba(76, 78, 100, 0.26)'
                    : '#F41F52',
              }}
            />
          </IconButton>
          <IconButton
            onClick={() => {
              
              window.location.href = `/school/${id}/classroom/${
                value?.id
              }?topic=${value.topic.name}&topicId=${value.topic.id}&grade=${
                value.grade.name
              }&gradeId=${value.grade.id}&name=${value.name}&period=${
                value.period
              }&type=${value.type || '-'}&studentsCount=${
                row.original.student
              }&schoolId=${id}&classroomId=${value.id}`;
                  
            }}
          >
            <div style={{ color: '#20A58A' }}>
              <EyeBorderIcon />
            </div>
          </IconButton>
        </Box>
      ),
    },
  ];

  const getRows = () => {
    const data: any = (grade && topic ? classroomFiltered : classroom)?.map(
      (obj: any) => ({
        topic: obj?.classroom?.topic,
        classroomId: obj?.classroom?.id,
        classroom_name: obj?.classroom?.name,
        classroom_period: obj?.classroom?.period,
        grade: obj?.classroom?.grade,
        teacher: obj?.classroom,
        student: obj?.studentsCount,
        action: obj?.classroom,
      }),
    );
    return data;
  };

  console.log('fff', classroomFiltered);
  console.log('fff', classroom);
  console.log('page', pageCount, pageNo, totalCount);
  const tableData = {
    columns: columns,
    rows: getRows(),
  };

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="/"
      onClick={() => handleBreadCrumbClick}
    >
      School Management
    </Link>,
    <Typography key="2" color="#C73E9D">
      Classroom
    </Typography>,
  ];

  const handleAddUpdateClassroom = () => {
    setEditId('');
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    dispatch(
      getClassroomList({
        pageSize,
        id,
        search,
        grade: params.gradeId,
        topic: params.topicId,
        userId: userId,
      }),
    );
  }, [params.gradeId, params.topicId]);
  const fetchData = useCallback(
    ({ pageSize, pageIndex, search }: any) => {
      setPageNo(pageIndex);
      setSearch(search);
      dispatch(
        getClassroomList({
          pageSize,
          pageIndex,
          id,
          search,
          grade: params.gradeId,
          topic: params.topicId,
          userId: userId,
        }),
      );
    },
    [params.gradeId, params.topicId],
  );

  return (
    <>
      {!id ? (
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          style={{ marginBottom: 20 }}
        >
          {breadcrumbs}
        </Breadcrumbs>
      ) : null}
      {getRows().length > 0 ? (
        <CustomTable
          table={tableData}
          fetchData={fetchData}
          title={id ? '' : 'Classroom'}
          canSearch
          buttonTitle="Add Classroom"
          pageCount={pageCount}
          pageNo={pageNo}
          totalCount={totalCount}
          handleOnChange={handleAddUpdateClassroom}
        />
      ) : (
        <AddFirstClassroom />
      )}
      {isOpen && (
        <AddUpdate
          open={isOpen}
          editId={editId}
          schoolId={id}
          onClose={handleAddUpdateClassroom}
          fetch={handleFetchClassRoom}
          userId={userId}
          id={id}
        />
      )}

      {openDelete && (
        <DeleteClassroom
          open={openDelete}
          deleteId={deleteId}
          schoolId={id || ''}
          onClose={handleDeleteDialog}
          fetch={handleFetchClassRoom}
        />
      )}

      {isAddStudentDialogOpen && classRef.current && (
        <AddStudentToClassRoom
          isAddStudentDialogOpen={isAddStudentDialogOpen}
          setIsAddStudentDialogOpen={setIsAddStudentDialogOpen}
          onClose={closeAddStudentDialog}
          classRoomId={classRef.current}
          schoolId={id}
          fetchData={handleFetchClassRoom}
        />
      )}
      {isAddTeacherDialogOpen && classRef.current && (
        <AddTeacherToClassRoom
          isAddTeacherDialogOpen={isAddTeacherDialogOpen}
          setIsTeacherDialogOpen={setIsTeacherDialogOpen}
          onClose={closeAddStudentDialog}
          classRoomId={classRef.current}
          fetchClassRooms={handleFetchClassRoom}
        />
      )}
    </>
  );
}

export default Index;
