import DistrictLeader from 'pages/parent/Dashboards/DistrictLeaderDashboard';
import React from 'react';
import { Card } from '@mui/material';
import Header from 'pages/parent/components/Header/Header';

function index() {
    return (
        <Card>
          <Header role={'EduOnboarded'}  />
          <DistrictLeader role={'EduOnboarded'} />
        </Card>
      );
}

export default index; 