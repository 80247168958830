import {
  Box,
  Typography,
  Card,
  Avatar,
  IconButton,
  Divider,
  Tooltip,
  Link,
} from '@mui/material';
import style from './view.module.scss';
import React, { useEffect, useState } from 'react';
import { ClassItem, TypoItem } from './macros';
import { useAppDispatch, useAppSelector } from 'store/store';
import { useLocation, useNavigate } from 'react-router-dom';
import { getUserData } from 'store/Thunk/user';
import { DeleteBorderIcon, EditBorderIcon } from 'icons';
import SimpleTable from 'components/simpleTable/simpleTable';
import ViewLoaderBox from './loader';
import { getLinkPartFromRole, getUser } from 'helper/functions';
import { setUser } from 'store/Slice/userSlice';
import DeleteUserDialog from 'components/dialog/users/Delete/deleteUser';
import { deleteTeacher } from 'store/Thunk/teacherThunk';
import { getUserListAccordingToType } from 'store/Thunk/userThunk';
import { Roles } from 'constants/consts';
import { route_info } from 'navigation/route';
import { setBreadCrumbsData } from 'store/Thunk/ParentEducatorDashboardThunk';
import { useGetMyProfileQuery } from 'store/apis/profile';

function Parent() {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const [teacherId] = useState(queryParams.get('id'));
  const { user, loading } = useAppSelector((state) => state.userReducer);
  const { selectedRoute } = useAppSelector(
    (state: any) => state.ParentEducatorReducer,
  );
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedUserToDelete, setSelectedUserToDelete] = useState({
    id: user.id,
    name: user.name,
  });
  const { data } = useGetMyProfileQuery();
  const navigate = useNavigate();
  const initial = getLinkPartFromRole();

  useEffect(() => {
    if (teacherId) {
      dispatch(getUserData(teacherId));
    }
    return () => {
      dispatch(setUser([]));
    };
  }, []);
  console.log('selectedRoute))))', selectedRoute);

  const handleEditClick = () => {
    const url = `/school-leader/user-management/edit?mode=teacher&id=${teacherId}`;
    navigate(url);
  };

  useEffect(() => {
    if (user) {
      dispatch(setBreadCrumbsData(breadcrumbs));
    }
  }, [user]);
  function handleBreadCrumbClick(
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
  }
  const { type } = getUser();
  const breadcrumbs =
    type === Roles.DISTRICT_LEADER && selectedRoute === 'User Management'
      ? [
          <Link
            underline="hover"
            key="1"
            color="inherit"
            href={route_info.user_manage}
            onClick={() => handleBreadCrumbClick}
          >
            User Management
          </Link>,
          <Typography key="3" color="black">
            Teachers
          </Typography>,
          // <Link  underline="hover"
          // key="1"
          // color="inherit"
          // href={route_info?.district_management_school.replace(
          //   ':id',
          //   data?.data?.school?.ncesDistrictId || ""
          // )}
          // onClick={() => handleBreadCrumbClick}>
          //   {districtID.length > 0
          //     ? districtID[0]?.name || districtID[0]?.ncesDistrictId
          //     : 'District'}
          // </Link>,
          <Box
          className="actions-left"
          sx={{
            ml: 2,
            alignItems: 'center',
            background: '#4CBFA71A',
            justifyContent: 'center',
            paddingX: 3,
            paddingY: 1,
            borderRadius: 0.6,
          }}
        >
          <Typography
            className="title_text"
            style={{ fontSize: '0.8rem', fontWeight: '600' }}
            color={'#20A58A'}
          >
            {user?.name}
          </Typography>
        </Box>
        ]
      : type === Roles.DISTRICT_LEADER
      ? [
          <Link
            underline="hover"
            key="1"
            color="inherit"
            href={route_info?.district_management_school.replace(
              ':id',
              data?.data?.school?.ncesDistrictId || '',
            )}
            onClick={() => handleBreadCrumbClick}
          >
            District Management
          </Link>,
          <Link  underline="hover"
          key="1"
          color="inherit"
          href={route_info?.district_management_school.replace(
            ':id',
            data?.data?.school?.ncesDistrictId || ""
          )}
         >
            {user?.school?.state}
          </Link>,
          <Link
            underline="hover"
            key="1"
            color="inherit"
            //href={`district/${data?.data?.school?.ncesDistrictId}/school/${user?.school?.ncesSchoolId}`}
            onClick={() => 
              navigate(route_info?.school_management.school_details.replace(
                                                ':id',
                                                user?.school?.ncesSchoolId,                                 
                                              ))
            }
          >
            {user?.school?.name}
          </Link>,
          <Link
            underline="hover"
            key="1"
            color="inherit"
            onClick={() => navigate(-1)}
          >
            {user?.classrooms?.length > 0 &&
              user?.classrooms[0]?.classroom?.name}
          </Link>,
           <Box
           className="actions-left"
           sx={{
             ml: 2,
             alignItems: 'center',
             background: '#4CBFA71A',
             justifyContent: 'center',
             paddingX: 3,
             paddingY: 1,
             borderRadius: 0.6,
           }}
         >
           <Typography
             className="title_text"
             style={{ fontSize: '0.8rem', fontWeight: '600' }}
             color={'#20A58A'}
           >
               {user?.classrooms?.length > 0 && user?.name}
           </Typography>
         </Box>
        ]
        :type === Roles.SCHOOL_LEADER && selectedRoute === 'User Management'
        ? [
            <Link
              underline="hover"
              key="1"
              color="inherit"
              href={route_info.user_manage}
              onClick={() => handleBreadCrumbClick}
            >
              User Management
            </Link>,
            <Typography key="3" color="black">
              Teachers
            </Typography>,
            // <Link  underline="hover"
            // key="1"
            // color="inherit"
            // href={route_info?.district_management_school.replace(
            //   ':id',
            //   data?.data?.school?.ncesDistrictId || ""
            // )}
            // onClick={() => handleBreadCrumbClick}>
            //   {districtID.length > 0
            //     ? districtID[0]?.name || districtID[0]?.ncesDistrictId
            //     : 'District'}
            // </Link>,
            <Box
             className="actions-left"
             sx={{
               ml: 2,
               alignItems: 'center',
               background: '#4CBFA71A',
               justifyContent: 'center',
               paddingX: 3,
               paddingY: 1,
               borderRadius: 0.6,
             }}
           >
             <Typography
               className="title_text"
               style={{ fontSize: '0.8rem', fontWeight: '600' }}
               color={'#20A58A'}
             >
                 {user?.name}
             </Typography>
           </Box>
           
          ]
        : type === Roles.SCHOOL_LEADER
        ? [
            <Link
              underline="hover"
              key="1"
              color="inherit"
              //href={`district/${data?.data?.school?.ncesDistrictId}/school/${user?.school?.ncesSchoolId}`}
              onClick={() => 
                navigate(route_info?.school_management.school_details.replace(
                                                  ':id',
                                                  user?.school?.ncesSchoolId,                                 
                                                ))
              }
            >
              {user?.school?.name}
            </Link>,
            <Link
              underline="hover"
              key="1"
              color="inherit"
              onClick={() => navigate(-1)}
            >
              {user?.classrooms?.length > 0 &&
                user?.classrooms[0]?.classroom?.name}
            </Link>,
             <Box
             className="actions-left"
             sx={{
               ml: 2,
               alignItems: 'center',
               background: '#4CBFA71A',
               justifyContent: 'center',
               paddingX: 3,
               paddingY: 1,
               borderRadius: 0.6,
             }}
           >
             <Typography
               className="title_text"
               style={{ fontSize: '0.8rem', fontWeight: '600' }}
               color={'#20A58A'}
             >
             {user?.classrooms?.length > 0 && user?.name}
             </Typography>
           </Box>
          ]
      : [
      <Link
        underline="hover"
        key="1"
        color="inherit"
        onClick={() => navigate(-1)}
      >
        All Classes
        {/* {user?.classrooms?.length > 0 &&
          user?.classrooms[0]?.classroom?.name} */}
      </Link>,
      <Box
      className="actions-left"
      sx={{
        ml: 2,
        alignItems: 'center',
        background: '#4CBFA71A',
        justifyContent: 'center',
        paddingX: 3,
        paddingY: 1,
        borderRadius: 0.6,
      }}
    >
      <Typography
        className="title_text"
        style={{ fontSize: '0.8rem', fontWeight: '600' }}
        color={'#20A58A'}
      >
      {user?.classrooms?.length > 0 && user?.name}
      </Typography>
    </Box>
    ];

  if (loading) {
    return <ViewLoaderBox allowShadow />;
  }
  const fetchTableData = () => {
    dispatch(
      getUserListAccordingToType({
        pageSize: 10,
        pageIndex: 0,
        search: '',
        userType: 'teacher',
      }),
    );
  };
  const handleDeleteUser = () => {
    setDeleteLoading(true);
    dispatch(deleteTeacher(selectedUserToDelete.id))
      .then(() => {
        fetchTableData();
        navigate(`/super-admin/user-management?tab=0`);
      })
      .finally(() => {
        setOpenDeleteModal(false);
        setDeleteLoading(false);
        setSelectedUserToDelete({
          id: '',
          name: '',
        });
      });
  };
  console.log('user%%%%%', user);
  return (
    <Card sx={{ p: 6, position: 'relative' }}>
      <Box className={style.content_wrapper}>
        {user?.profileImage ? (
          <img
            className={style.profile_img}
            alt={user?.name}
            src={user?.profileImage}
          />
        ) : (
          <Avatar sx={{ width: 100, height: 100, alignitems: 'center' }} />
        )}
        <div className={style.profile_detail_wrapper}>
          <Box className={style.profile_detail}>
            <Typography
              sx={{ color: '#20A58A', ntWeight: 700, fontSize: '23px' }}
            >
              {user?.name}
            </Typography>
            <Typography sx={{ display: '13.48px' }}>
              (ID: {user?.id})
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: '50px',
              flexDirection: 'column',
              mt: '50px',
            }}
          >
            <Box className={style.info}>
              <TypoItem title="Email" detail={user?.email} />
              <TypoItem title="Phone" detail={user?.phone || '-'} />
              <TypoItem title="School" detail={user?.school?.name || '-'} />
            </Box>
            <Box className={style.info}>
              <TypoItem
                title="State"
                detail={user?.school?.state || '-' || '-'}
              />
              <TypoItem title="Zip Code" detail={user?.zipCode || '-'} />
              <TypoItem
                title="Teaching Preference"
                detail={user?.type || '-'}
              />
              <TypoItem
                title="Grade"
                detail={
                  user?.grade?.length > 0 ? user?.grade[0]?.grade?.name : '-'
                }
              />
            </Box>
          </Box>
        </div>

        <Box sx={{ display: 'flex', position: 'absolute', top: 30, right: 30 }}>
          <IconButton onClick={() => handleEditClick()}>
            <EditBorderIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setOpenDeleteModal(true);
              setSelectedUserToDelete({
                id: user.id,
                name: user.name,
              });
            }}
          >
            <DeleteBorderIcon />
          </IconButton>
        </Box>
      </Box>
      <Divider variant="inset" />
      <Box>
        <Typography sx={{ color: '#929292' }}>Classrooms</Typography>
        <Box className={style.classroom_list}>
          {user.classrooms?.length > 0 &&
            user.classrooms?.map((item: any, index: any) => {
              return (
                <ClassItem
                  sx={{ p: 1, color: '#000' }}
                  key={index}
                  title={item?.classroom?.name}
                />
              );
            })}
        </Box>
      </Box>
      <DeleteUserDialog
        loading={deleteLoading}
        open={openDeleteModal}
        title={`Are you sure you want to delete the user "${selectedUserToDelete?.name}" from G3MS?`}
        onOk={handleDeleteUser}
        onClose={() => {
          setOpenDeleteModal(false);
          setSelectedUserToDelete({
            id: '',
            name: '',
          });
        }}
      />
    </Card>
  );
}

export default Parent;
