import {
  BaseQueryApi,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

import { store } from '../store';

import { redirect } from 'react-router-dom';

import { auth } from 'store/firebase';
import { setLoginUser } from 'store/Slice/authSlice';
import analytics from 'utils/analytics';
import config from 'config/config';
import localforage from 'localforage';
import { update } from '@intercom/messenger-js-sdk';
import { getDeviceType } from 'utils/lib';

console.log('config.BASE_URL', config.BASE_URL);

const baseQuery = fetchBaseQuery({
  baseUrl: config.BASE_URL
    ? `${config.BASE_URL}api/v1/`
    : 'https://dev.g3ms.co/api/v1/',
  prepareHeaders: (headers, api) => {
    const userData: any = localStorage.getItem('userData');

    const token: any = userData ? JSON.parse(userData)?.token : undefined;

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const handleLogout = async () => {
  const header: any = localStorage.getItem('userData');
  const type: any = JSON.parse(header)?.type || '';
  localStorage.removeItem('userData');
  localStorage.removeItem('uuid');
  localStorage.removeItem('header');
  if (type === 'super_admin') {
    return redirect('/login');
  }
  analytics.logoutUser();
  localStorage.clear();
  await localforage.clear();
  window.location.href = `${config.STUDENT_BASE_URL}/auth/logout`;
};

async function baseQueryWithReAuth(
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: {},
) {
  const result = await baseQuery(args, api, extraOptions);

  if (result.error) {
    analytics.apiError({ error: result.error, api, args });
  } else {
    analytics.logEvent('api_response', { response: result, api, args });
  }

  // console.log(result, 'result');
  const isInvalidToken =
    (result?.error?.data as { code?: string })?.code === 'INVALID_TOKEN';
  const isUnAuthorized =
    (result?.error?.data as { code?: string })?.code === 'UNAUTHORIZED';
  if (isInvalidToken || isUnAuthorized) {
    const accessToken = await auth.currentUser?.getIdToken();

    localStorage.setItem('accessToken', accessToken || '');

    const newResult: any = await baseQuery(args, api, extraOptions);

    if (
      newResult?.meta?.response?.status === 401 ||
      newResult.error?.data?.code === 'INVALID_TOKEN' ||
      newResult.error?.data?.code === 'UNAUTHORIZED'
    ) {
      console.log('Invalid Token');
      handleLogout();
      update({
        name: '',
        customAttributes: {
          platform: 'admin-web',
          deviceType: getDeviceType(),
        },
      });
      await auth.signOut();
    }
    return newResult;
  }
  return result;
}

export const apiSlice = createApi({
  reducerPath: 'defaultApiSlice',
  baseQuery: baseQueryWithReAuth,
  tagTypes: [
    'MyProfile',
    'Search',
    'SingleProfile',
    'getVideos',
    'gradeInfo',
    'MyVideos',
    'CartItems',
    'WeeklyChallenge',
    'Notification',
    'UserStudents',
  ],
  endpoints: (builder) => ({}),
});

export default apiSlice;
