
import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import React from 'react';
export interface ResponseData {
    status: String;
    messagecode: String;
    message: String;
    data: any;
  }
  
  interface IntialState {
   classRoomList: any;
    classroomStudentsList:any;
   engagements:any;
   reactions:any;
   totals:any;
   currentSelectedUser:any;
   departmentList:any;
   schoolList:any;
   breadcrumbs:any;
   selectedRoute:any;
  }
  const intialState: IntialState = {
    classRoomList:[],
    classroomStudentsList:[],
    engagements:[],
    reactions:{},
   totals:{},
   currentSelectedUser:"",
   departmentList:[],
   schoolList:[],
   breadcrumbs:React.Component,
   selectedRoute:"",
  };
  const ParentEducatorDashboardSlice = createSlice({
    name: 'ParentEducatorDashboardSlice',
    initialState: intialState,
    reducers: {
      setClassRoomList: (
        state: Draft<IntialState>,
        action: PayloadAction<boolean>,
      ) => {
        state.classRoomList = action.payload;
      },    
      setClassroomStudentList: (
        state: Draft<IntialState>,
        action: PayloadAction<boolean>,
      ) => {
        state.classroomStudentsList = action.payload;
      },  
      setEngagementList: (
        state: Draft<IntialState>,
        action: PayloadAction<boolean>,
      ) => {
        state.engagements = action.payload;
      },  
      setReactionList: (
        state: Draft<IntialState>,
        action: PayloadAction<boolean>,
      ) => {
        state.reactions = action.payload;
      },  
      setTotals: (
        state: Draft<IntialState>,
        action: PayloadAction<boolean>,
      ) => {
        state.totals = action.payload;
      },  
      setCurrentSelectedUser: (
        state: Draft<IntialState>,
        action: PayloadAction<boolean>,
      ) => {
        state.currentSelectedUser = action.payload;
      },  
      setDepartmentList: (
        state: Draft<IntialState>,
        action: PayloadAction<boolean>,
      ) => {
        state.departmentList = action.payload;
      }, 
      setSchools: (
        state: Draft<IntialState>,
        action: PayloadAction<boolean>,
      ) => {
        state.schoolList = action.payload;
      }, 
      setbreadcrumbs: (
        state: Draft<IntialState>,
        action: PayloadAction<boolean>,
      ) => {
        state.breadcrumbs = action.payload;
      }, 
      setRoute: (
        state: Draft<IntialState>,
        action: PayloadAction<boolean>,
      ) => {
        state.selectedRoute = action.payload;
      }, 
    },
  });
  export const {
    setClassRoomList,
   setClassroomStudentList,
    setEngagementList,
    setReactionList,
    setTotals,
    setCurrentSelectedUser,
    setDepartmentList,
    setSchools,
    setbreadcrumbs,
    setRoute
  } = ParentEducatorDashboardSlice.actions;
  
  export default ParentEducatorDashboardSlice.reducer;
