import { Avatar, Box, Button, IconButton, Typography } from '@mui/material';
import DefaultCell from 'components/DefaultCell';
import CustomTable from 'components/customTable';

import '../../user_management/index.scss';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CrudPopUp from 'components/popUp/crudPopup';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useAppDispatch, useAppSelector } from 'store/store';
import { getUserListAccordingToType } from 'store/Thunk/userThunk';

import AvatartWithText from 'components/AvatarWithText';
import { formatDate } from 'helper/service';
import { useNavigate } from 'react-router-dom';
import StatusPopup from 'components/popUp/StatusPopup';
import { deleteParent, updateUserStatus } from 'store/Thunk/parentThunk';
import { getLinkPartFromRole } from 'helper/functions';
import DeleteParentDialog from 'components/dialog/users/Delete/deleteParent';
import { addParentToClassRoom } from 'store/Thunk/classroomThunk';
import API from 'store/api';
import { SearchOff } from '@mui/icons-material';

const ClassItem = ({
  title,
  itemKey,
}: {
  title: string;
  count: number;
  itemKey: number;
}) => {
  return (
    <Box>
      {itemKey < 2 && (
        <Box
          sx={{
            background: '#F8F8F8',
            borderRadius: '4px',
            p: 1,
          }}
        >
          <span>{title}</span>
        </Box>
      )}
    </Box>
  );
};

function Parent() {
  const navigate = useNavigate();
  const [activeButtonRow, setActiveButtonRow] = useState<any>(null);
  const [pageNo, setPageNo] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [search, setSearch] = useState('');

  const [isOpen, setIsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState('');
  const [status, setStatus] = useState('');
  const initial = getLinkPartFromRole();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [newSelectedParent, setNewSelectedParent] = useState<any>();
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [selectedUserToDelete, setSelectedUserToDelete] = useState({
    dependentOfUser: [],
    id: '',
  });
  const handleEventClick = (rowIndex: number) => {
    setActiveButtonRow(rowIndex);
  };

  const handleOk = async () => {
    setLoadingDelete(true);
    if (newSelectedParent?.id) {
      const childrenIds = selectedUserToDelete.dependentOfUser.map(
        (item: any) => item.id,
      );

      await API.post(
        `/api/v1/parents/${selectedUserToDelete.id}/remove/children`,
        {
          userIds: [...childrenIds],
        },
      );

      await dispatch(
        addParentToClassRoom({
          userIds: [...childrenIds],
          parentId: newSelectedParent?.id,
        }),
      );
    }
    await dispatch(deleteParent(selectedUserToDelete.id));

    dispatch(
      getUserListAccordingToType({
        pageSize: pageCount,
        pageIndex: pageNo,
        search: search,
        userType: 'parent',
      }),
    )
      .unwrap()
      .then(() => {
        setOpenDeleteModal(false);
        setSelectedUserToDelete({
          dependentOfUser: [],
          id: '',
        });
      })
      .finally(() => {
        setLoadingDelete(false);
      });
  };

  const { parentList } = useAppSelector((state) => state.userReducer);
  const dispatch = useAppDispatch();
  console.log('parentList', parentList);
  useEffect(() => {
    let schoolId = '';
    const intial = getLinkPartFromRole();
    if (intial === 'school-leader') {
      schoolId =
        localStorage.getItem('current_schoolId') ||
        localStorage.getItem('teacherSchool') ||
        '';
    }
    dispatch(getUserListAccordingToType({ userType: 'parent', schoolId }));
  }, []);

  useEffect(() => {
    if (parentList?.items?.length > 0) {
      setPageCount(parentList?.meta?.totalPages);
      setTotalCount(parentList?.meta?.totalCount);
    }
  }, [parentList]);

  const columns = [
    {
      Header: 'Name',
      accessor: 'nameAndPhoto',
      Cell: ({ value }: any) => <AvatartWithText value={value} />,
    },
    {
      Header: 'Email',
      accessor: 'email',
      Cell: ({ value }: any) => <DefaultCell minWidth="0px" value={value} />,
    },
    {
      Header: 'School',
      accessor: 'school',
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: 'Children',
      accessor: 'children',
      Cell: ({ value }: any) => (
        <Box
          sx={{ gap: value?.length > 100 ? '50px' : '30px' }}
          className="class_list"
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            {value?.map((item: any, index: number) => (
              <ClassItem
                itemKey={index}
                title={item.name}
                count={value?.length}
              />
            ))}
          </Box>
          <Box className="badge_head">
            {value?.length > 2 && (
              <span style={{ marginBottom: '25px' }} className="count_badge">
                {'+' + (value.length - 2)}
              </span>
            )}
          </Box>
        </Box>
      ),
    },
    {
      Header: 'State',
      accessor: 'state',
      Cell: ({ value }: any) => <DefaultCell minWidth="0px" value={value} />,
    },
    {
      Header: 'Created Date',
      accessor: 'createdDate',
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ row, value }: any) => (
        <Button
          variant="contained"
          className={value === 'active' ? 'green_bg_btn' : 'red_bg_btn'}
          onClick={() => {
            setStatus(value);
            setIsOpen(!isOpen);
            setSelectedUser(row?.original?.parentId);
          }}
          endIcon={<KeyboardArrowDownIcon />}
        >
          {value?.charAt(0).toUpperCase() + value?.slice(1)}
        </Button>
      ),
    },
    {
      Header: 'Action',
      accessor: '',
      Cell: ({ row }: any) => (
        <>
          <IconButton onClick={() => handleEventClick(row.index)}>
            <MoreVertIcon />
          </IconButton>
          {activeButtonRow === row.index && (
            <CrudPopUp
              showEdit
              showView
              showDelete
              onDeleteClick={() => {
                setOpenDeleteModal(true);
                setSelectedUserToDelete({
                  id: row.original.parentId || '',
                  dependentOfUser: row?.original?.children,
                });
              }}
              onEditClick={() => {
                navigate(
                  `/school-leader/user-management/edit?mode=parent&id=${row.original.parentId}`,
                );
              }}
              onViewClick={() =>
                navigate(
                  `/school-leader/user-management/view?mode=parent&id=${row.original.parentId}`,
                )
              }
              setClose={() => setActiveButtonRow(null)}
            />
          )}
        </>
      ),
    },
  ];

  const fetchData = useCallback(({ pageSize, pageIndex, search }: any) => {
    setPageNo(pageIndex);
    setPageCount(pageSize);
    setSearch(search);
    dispatch(
      getUserListAccordingToType({
        pageSize,
        pageIndex,
        search,
        userType: 'parent',
      }),
    );
  }, []);

  const updateStatus = (updatedstatus: string) => {
    dispatch(
      updateUserStatus({ status: updatedstatus, uuid: selectedUser }),
    ).then(() => {
      fetchData({
        pageIndex: 0,
        pageSize: totalCount,
        search: '',
      });
      closeModal();
    });
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedUser('');
  };

  const rows = useMemo(() => {
    if (parentList?.items?.length > 0) {
      const data = parentList?.items?.map((item: any) => ({
        parentId: item?.id,
        nameAndPhoto: {
          name: item?.name,
          image: item?.profileImage,
        },
        email: item.email,
        school: item?.school?.name,
        classroom: item?.classroom?.map(
          (item: any) =>
            item?.clasroom?.name.split(':')[1]?.trim() || item?.clasroom?.name,
        ),
        children: item?.dependentOfUser?.map((item: any) => {
          return {
            name: item?.user?.name,
            id: item?.user?.id,
          };
        }),

        state: item?.school?.state || '',
        teachingPreference: item?.teachingPreference,
        status: item?.status || 'active',
        createdDate: formatDate(item.createdAt),
      }));
      return data;
    } else return [];
  }, [parentList]);

  return (
    <>
      <div style={{ position: 'relative' }}>
        <CustomTable
          isSorted={false}
          fetchData={fetchData}
          pageCount={pageCount}
          canSearch
          pageNo={pageNo}
          totalCount={totalCount}
          table={{
            columns: columns,
            rows: rows,
          }}
        />
        {isOpen && (
          <StatusPopup
            open={isOpen}
            status={status}
            onClose={closeModal}
            onSave={updateStatus}
          />
        )}
      </div>
      <DeleteParentDialog
        loading={loadingDelete}
        title="Are you sure you want to delete the parent from G3MS?"
        haveChildren={selectedUserToDelete?.dependentOfUser?.length > 0 && true}
        description={
          selectedUserToDelete?.dependentOfUser?.length > 0
            ? `${
                selectedUserToDelete.id
              } You cannot delete this Parent as they are related to ${selectedUserToDelete.dependentOfUser
                .map((item: any) => item?.name)
                .join(
                  ', ',
                )}. To delete this parent, the students must be assigned a different parent first.`
            : ''
        }
        onOk={() => handleOk()}
        newSelectedParent={newSelectedParent}
        setNewSelectedParent={setNewSelectedParent}
        onClose={() => {
          setOpenDeleteModal(false);
          setSelectedUserToDelete({
            dependentOfUser: [],
            id: '',
          });
        }}
        open={openDeleteModal}
      />
    </>
  );
}

export default Parent;
