import { Button, Modal, rem, RingProgress } from '@mantine/core';
import useModal from 'components/Modal/hook/useModal';
import localforage from 'localforage';
import { useFinishAccountSetupMutation } from 'store/apis/account-setup';
import cn from 'utils/cn';

export default function ParentTrial() {
  const { closeModal, isOpen, modal, openModal } = useModal<{
    onContinue?: () => void;
  }>();

  
    const [finishAccountSetupRequest, finishAccountSetupResponse] =
      useFinishAccountSetupMutation();

  return (
    // <Modal isOpen={isOpen('AskAGrownupModal')} onClose={closeModal}>
    //   <Modal.Content className="[--max-width:_45rem] [--top:_2rem]  md:[--top:_10%]">
    //     <Modal.CloseButton />
    <Modal
      opened={isOpen('ParentTrial')}
      onClose={closeModal}
      closeOnClickOutside={false}
      closeOnEscape={false}
      size={'lg'}
      centered
      shadow="sm"
      radius={'lg'}
      classNames={{
        title:
          '!text-lg !font-semibold lg:!text-xl 2xl:!text-2xl !text-[#2C3E50]',
        close:
          '!text-[#2C3E50] !text-lg !font-semibold lg:!text-xl 2xl:!text-2xl',
      }}
      padding={rem(24)}
      zIndex={999999999}
      withCloseButton={false}
    >
      <div className="flex flex-col gap-4 xl:gap-8">
        <div className="grid gap-2 lg:gap-4 sm:grid-cols-[auto,1fr] justify-start align-top items-center max-sm:place-items-center">
          <RingProgress
            sections={[{ value: 80, color: '#20A58A' }]}
            transitionDuration={250}
            label={
              <p className="!text-[#344054] !text-sm  xl:!text-base text-center mx-auto !font-medium">
                7 days
              </p>
            }
            roundCaps={true}
            rootColor="#EAECF0"
            size={110}
          />

          <h1
            className={cn(
              '!font-semibold !text-lg xl:!text-xl 2xl:!text-2xl text-[#131515] max-sm:text-center',
            )}
          >
            🎉 Welcome to G3MS! Your 7-Day Premium Free Trial Has Begun!
          </h1>
        </div>

        <p
          className={cn(
            '!text-sm lg:!text-base xl:!text-lg 2xl:!text-xl text-black font-bold',
          )}
        >
          Explore premium features designed to support your child’s learning:
        </p>

        <ul
          className={cn(
            '!text-sm lg:!text-base xl:!text-lg 2xl:!text-xl text-[#131515] space-y-1',
          )}
        >
          <li>
            <span className="text-black font-bold">🧠 AI Tutor:</span>{' '}
            Personalized recommendations to close learning gaps and build
            future-ready skills.
          </li>
          <li>
            <span className="text-black font-bold">🎁 Earn Real Rewards:</span>{' '}
            Redeem tokens for gift cards and other prizes your child loves.
          </li>
          <li>
            <span className="text-black font-bold">
              📚 Access All Subjects:
            </span>{' '}
            ELA, Science, Social Studies, Math, Test Prep, and more
          </li>
        </ul>

        <p
          className={cn(
            '!text-sm lg:!text-base xl:!text-lg 2xl:!text-xl text-black font-bold my-4',
          )}
        >
          Take full advantage of these features during your free trial to see
          how G3MS can transform learning for your family!
        </p>

        <div className="w-full grid">
          <Button
            onClick={async () => {
              await finishAccountSetupRequest();
              await localforage.removeItem('modal');
              closeModal();
            }}
            className="max-w-full !rounded-xl w-full"
            color="#20A58A"
            size="xl"
            fw={500}
          >
            Get Started
          </Button>
        </div>
      </div>
    </Modal>

    // </Modal.Content>
    // </Modal>
  );
}
