import { apiSlice } from '..';
import {
  CitiesParams,
  IStatesCitiesResponse,
  StatesParams,
  TDistrictResponse,
  TTopicResponse,
} from './types';

export const schoolApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStates: builder.query<IStatesCitiesResponse, StatesParams>({
      query: (body) => {
        return {
          url: 'csc/states',
          method: 'GET',
          params: body,
        };
      },
    }),
    getCities: builder.query<IStatesCitiesResponse, CitiesParams>({
      query: (body) => {
        return {
          url: 'csc/cities',
          method: 'GET',
          params: body,
        };
      },
    }),
    getDistricts: builder.query<TDistrictResponse, string | void>({
      query: (city) => {
        return {
          url: city ? '/districts?query=' + city : '/districts',
          method: 'GET',
        };
      },
    }),
    getTopics: builder.query<TTopicResponse, void>({
      query: () => {
        return {
          url: 'topics',
          method: 'GET',
        };
      },
    }),
  }),
});

export const { useGetStatesQuery, useGetCitiesQuery, useGetDistrictsQuery,useGetTopicsQuery } =
  schoolApi;
