import { Button, Checkbox, LoadingOverlay, Modal, rem } from '@mantine/core';
import useModal from 'components/Modal/hook/useModal';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  useGetFunContentQuery,
  useUpdateAccountContentMutation,
} from 'store/apis/account-setup';
import { Topic } from 'store/apis/account-setup/types';
import { useGetMyProfileQuery } from 'store/apis/profile';
import cn from 'utils/cn';

const options = [
  'Investing',
  'Building Relationships',
  'Social Fun!',
  'Money Management ',
  'Web3',
  'AI workforce',
];

export default function ParentSelectSocialEmotion() {
  const { closeModal, isOpen, modal, openModal } = useModal<{
    onContinue?: () => void;
    withAddons?: boolean;
    educatorPlan?: 'free' | 'premium';
  }>();

  const { data, isLoading: profileLoading } = useGetMyProfileQuery();

  const { data: contents, isLoading } = useGetFunContentQuery();
  const [updateAccountContent, updateAccountContentRes] =
    useUpdateAccountContentMutation();

  const [selectedItems, setSelectedItems] = useState<Topic[]>([]);

  useEffect(() => {
    if (contents) {
      const items = contents?.data?.map((item) => ({
        topicId: item.id,
        enabled: item.enabled,
      }));
      setSelectedItems(items);
    }
  }, [contents]);

  return (
    // <Modal isOpen={isOpen('AskAGrownupModal')} onClose={closeModal}>
    //   <Modal.Content className="[--max-width:_45rem] [--top:_2rem]  md:[--top:_10%]">
    //     <Modal.CloseButton />
    <Modal
      opened={isOpen('ParentSelectSocialEmotion')}
      onClose={closeModal}
      closeOnClickOutside={false}
      closeOnEscape={false}
      size={'lg'}
      centered
      shadow="sm"
      radius={'lg'}
      classNames={{
        title:
          '!text-lg !font-semibold lg:!text-xl 2xl:!text-2xl !text-[#2C3E50]',
        close:
          '!text-[#2C3E50] !text-lg !font-semibold lg:!text-xl 2xl:!text-2xl',
      }}
      padding={rem(24)}
      zIndex={999999999}
      withCloseButton={false}
    >
      <div className="space-y-4 lg:space-y-10">
        <div className="space-y-2">
          <h1
            className={cn(
              '!font-semibold text-center !text-lg lg:!text-xl 2xl:!text-2xl text-[#131515]',
            )}
          >
            Social Emotional & Life skills Content
          </h1>
          <p
            className={cn(
              '!text-sm lg:!text-base xl:!text-lg 2xl:!text-xl text-[#131515]',
            )}
          >
            Now let's customize the personal growth with essential life skills.
          </p>
        </div>

        <div className="flex justify-between flex-wrap gap-y-4">
          {contents?.data?.map((option) => (
            <Checkbox
              key={option.id}
              label={option.name}
              checked={selectedItems.some((item) => item.topicId === option.id)}
              onChange={() => {
                if (selectedItems.some((item) => item.topicId === option.id)) {
                  setSelectedItems((prev) =>
                    prev.filter((item) => item.topicId !== option.id),
                  );
                } else {
                  setSelectedItems((prev) => [
                    ...prev,
                    { topicId: option.id, enabled: true },
                  ]);
                }
              }}
              classNames={{
                label:
                  'text-[#131515] !text-sm lg:!text-base xl:!text-lg 2xl:!text-xl font-semibold sm:-mt-1',
                root: '!min-w-[48%] !flex !items-center !justify-start',
              }}
              color="#20A58A"
            />
          ))}
        </div>

        <div className="grid">
          <Button
            className="w-full !rounded-xl col-span-2 "
            color="#20A58A"
            size="xl"
            fw={500}
            type="submit"
            onClick={async () => {
              try {
                await updateAccountContent({
                  topics: selectedItems,
                }).unwrap();

                if (modal?.props?.onContinue) {
                  modal?.props?.onContinue();
                  return;
                }

                /* if (!data?.data?.isUserUpgradedPlan) {
                  openModal('UpgradeToAddMoreStudentModal', { cache: true });
                  return;
                } */

              /*   if (
                  data?.data?.accountSetup?.isEducatorAddOnIncluded &&
                  data?.data?.type === 'parent' && data?.data?.accountSetup?.parents?.length < 2
                ) {
                  openModal('AddParentModal', {
                    withAddons:
                      data?.data?.accountSetup?.isEducatorAddOnIncluded,
                    cache: true,
                  });
                  return;
                } */
               
                if (
                  data?.data?.type === 'parent' &&
                  data?.data?.isUserUpgradedPlan &&
                  data?.data?.accountSetup?.parents?.length < 2
                ) {
                  openModal('AddParentModal', { cache: true });
                  return;
                }

                if (!data?.data?.isUserUpgradedPlan) {
                  if (
                    data?.data?.accountSetup?.schoolDetails?.school_settings
                      ?.length ||
                    data?.data?.type === 'homeschool_parent_esa'
                  ) {
                    openModal('ParentTrial', { cache: true });
                    return;
                  }

                  openModal('ParentSchoolSettingsModal', {
                    educatorPlan: 'free',
                    cache: true,
                  });
                  return;
                }
                if (
                  data?.data?.isUserUpgradedPlan &&
                  data?.data?.type === 'parent'
                ) {
                  openModal('EducatorPremiumPlan', {
                    educatorPlan: 'free',
                    cache: true,
                  });
                  return;
                }

                if (
                  !data?.data?.isUserUpgradedPlan &&
                  data?.data?.type === 'parent'
                ) {
                  openModal('ParentUpgradeToPlanWarning', { cache: true });
                  return;
                }

                openModal('ParentTrial', {
                  educatorPlan: 'free',
                  cache: true,
                });
              } catch (error) {
                toast.error('Failed to update account content');
              }
            }}
          >
            Next
          </Button>
        </div>
      </div>

      <LoadingOverlay
        visible={
          isLoading || updateAccountContentRes.isLoading || profileLoading
        }
      />
    </Modal>

    // </Modal.Content>
    // </Modal>
  );
}
