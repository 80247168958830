import { Button, Modal, rem } from '@mantine/core';
import useModal from 'components/Modal/hook/useModal';
import { AddStudentModalProps } from 'modals/AddStudentModal';

import { useGetMyProfileQuery } from 'store/apis/profile';
import cn from 'utils/cn';

export default function AddAnotherStudentWarningModal() {
  const { closeModal, isOpen, modal, openModal } = useModal<
    {
      onContinue?: () => void;
    } & AddStudentModalProps
  >();

  const { data } = useGetMyProfileQuery();

  console.log('modal?.props', modal?.props);

  return (
    // <Modal isOpen={isOpen('AskAGrownupModal')} onClose={closeModal}>
    //   <Modal.Content className="[--max-width:_45rem] [--top:_2rem]  md:[--top:_10%]">
    //     <Modal.CloseButton />
    <Modal
      opened={isOpen('AddAnotherStudentWarningModal')}
      onClose={closeModal}
      closeOnClickOutside={false}
      closeOnEscape={false}
      size={'xl'}
      centered
      shadow="sm"
      radius={'lg'}
      classNames={{
        title:
          '!text-lg !font-semibold lg:!text-xl 2xl:!text-2xl !text-[#2C3E50]',
        close:
          '!text-[#2C3E50] !text-lg !font-semibold lg:!text-xl 2xl:!text-2xl',
      }}
      padding={rem(24)}
      zIndex={999999999}
      withCloseButton={false}
    >
      <div className="grid gap-4 lg:gap-6">
        <div className="space-y-2 items-start">
          <h1
            className={cn(
              '!font-semibold !text-lg lg:!text-xl 2xl:!text-2xl text-[#131515] max-sm:text-center w-full mx-auto self-center',
            )}
          >
            🎉 {modal?.props?.fistName} {modal?.props?.lastName} was added to
            G3MS.
          </h1>
          <p
            className={cn(
              '!text-sm lg:!text-base xl:!text-lg 2xl:!text-xl text-[#131515]',
            )}
          >
            Would you like to add another student?
          </p>
        </div>

        <div className="grid gap-4 lg:grid-cols-2">
          <Button
            onClick={() => {
              if (data?.data?.type === 'parent') {
                if (
                  !data?.data?.isUserUpgradedPlan &&
                  data?.data?.parents?.length > 1
                ) {
                  openModal('ParentUpgradeToPlanWarning', { cache: true });
                  return;
                }

                if (data?.data?.isUserUpgradedPlan) {
                  if (!data?.data?.accountSetup?.isAcademicContentsSelected) {
                    openModal('ParentSelectAcademic', {
                      withUpgradeSuccess: true,
                      cache: true,
                    });
                    return;
                  }

                  if (!data?.data?.accountSetup?.isSocialContentsSelected) {
                    openModal('ParentSelectSocialEmotion', {
                      withUpgradeSuccess: true,
                      cache: true,
                    });
                    return;
                  }

                  if (
                    data?.data?.accountSetup?.schoolDetails?.school_settings
                      ?.length === 0 &&
                    data?.data?.accountSetup.isEducatorAddOnIncluded
                  ) {
                    openModal('ParentSchoolSettingsModal', {
                      withUpgradeSuccess: true,
                      cache: true,
                    });
                    return;
                  }

                  openModal('ParentPremiumPlan', {
                    withUpgradeSuccess: true,
                    cache: true,
                  });
                  return;
                }

                openModal('AddParentModal', { cache: true, ...modal?.props });
              } else {
                openModal('ParentSchoolSettingsModal', {
                  cache: true,
                  ...modal?.props,
                });
              }
            }}
            className="max-w-full !rounded-xl"
            color="#999999"
            size="xl"
            fw={500}
          >
            No
          </Button>
          <Button
            onClick={() => {
              if (data?.data?.isUserUpgradedPlan) {
                openModal('AddStudentModal', { cache: true });
              } else {
                openModal('UpgradeToAddMoreStudentModal', { cache: true });
              }
            }}
            className="max-w-full !rounded-xl"
            color="#20A58A"
            size="xl"
            fw={500}
          >
            Yes
          </Button>
        </div>
      </div>
    </Modal>

    // </Modal.Content>
    // </Modal>
  );
}
