import { Button, Modal, rem } from '@mantine/core';
import useModal from 'components/Modal/hook/useModal';
import localforage from 'localforage';
import { useFinishAccountSetupMutation } from 'store/apis/account-setup';
import cn from 'utils/cn';

export default function EducatorPremiumPlan() {
  const { closeModal, isOpen, modal, openModal } = useModal<{
    onContinue?: () => void;
  }>();

  const [finishAccountSetupRequest, finishAccountSetupResponse] =
    useFinishAccountSetupMutation();

  return (
    // <Modal isOpen={isOpen('AskAGrownupModal')} onClose={closeModal}>
    //   <Modal.Content className="[--max-width:_45rem] [--top:_2rem]  md:[--top:_10%]">
    //     <Modal.CloseButton />
    <Modal
      opened={isOpen('EducatorPremiumPlan')}
      onClose={closeModal}
      closeOnClickOutside={false}
      closeOnEscape={false}
      size={'lg'}
      centered
      shadow="sm"
      radius={'lg'}
      classNames={{
        title:
          '!text-lg !font-semibold lg:!text-xl 2xl:!text-2xl !text-[#2C3E50]',
        close:
          '!text-[#2C3E50] !text-lg !font-semibold lg:!text-xl 2xl:!text-2xl',
      }}
      padding={rem(24)}
      zIndex={999999999}
      withCloseButton={false}
    >
      <div className="flex flex-col gap-4 xl:gap-8">
        <div className="grid gap-2 lg:gap-4 justify-center align-top items-center place-items-center">
          <h1
            className={cn(
              '!font-semibold !text-lg xl:!text-xl 2xl:!text-2xl text-[#131515] text-center',
            )}
          >
            🎉 Welcome to Premium! 🎉
          </h1>
        </div>

        <p
          className={cn(
            '!text-sm lg:!text-base xl:!text-lg 2xl:!text-xl text-black',
          )}
        >
          Thank you for joining G3MS Premium to support your child’s learning
          journey! Here’s what you can do with Premium:
        </p>

        <ul
          className={cn(
            '!text-sm lg:!text-base xl:!text-lg 2xl:!text-xl text-[#131515] space-y-1 list-disc pl-8',
          )}
        >
          <li className="font-bold">
            Redeem tokens for exciting gift cards and rewards.{' '}
          </li>
          <li>
            <span className="text-black font-bold">
              Access unlimited AI Tutor support
            </span>{' '}
            for homework and learning success.
          </li>
          <li>
            <span className="text-black font-bold">
              Stay ahead with full access
            </span>{' '}
            to Math, ELA, Science, Social Studies, test prep, and coding
            challenges.
          </li>
        </ul>

        <p
          className={cn(
            '!text-sm lg:!text-base xl:!text-lg 2xl:!text-xl text-black',
          )}
        >
          ✨ We're excited to be part of your family's success! Keep exploring
          and watch your child thrive!
        </p>

        <div className="w-full grid">
          <Button
            onClick={async () => {
              await finishAccountSetupRequest();
              await localforage.removeItem('modal');
              closeModal();
            }}
            className="max-w-full !rounded-xl w-full"
            color="#20A58A"
            size="xl"
            fw={500}
            loading={finishAccountSetupResponse.isLoading}
          >
            Get Started
          </Button>
        </div>
      </div>
    </Modal>

    // </Modal.Content>
    // </Modal>
  );
}
