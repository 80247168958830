import { createAsyncThunk } from '@reduxjs/toolkit';
import API from 'store/api';

import {
  setChildList,
  setChildTotals,
  setChildReactions,
  setChildEngagements,
  setParentSubTopicList,
  setStudentQR,
  setStudentData,
} from 'store/Slice/ParentDashboardSlice';
import {
  notificationFail,
  notificationSuccess,
} from 'store/Slice/notificationSlice';
import { stopLoading, startLoading } from 'store/Slice/loadingSlice';

export const getChildList = createAsyncThunk(
  'getchildlist',

  async (_, { dispatch }) => {
    const userData: any = localStorage.getItem('userData');
    const id: any = JSON.parse(userData).id;
    console.log('id%%%%%%%%%%', id);
    let url = `api/v1/parents/${id}/children`;
    // let url = `api/v1/parents/1cbfdfd9-949d-4436-bf93-105e3eb7ce1c/children`;
    try {
      const response: any = await API.get(url);
      dispatch(setChildList(response.data.data));
      // console.log("children",response.data.data)
      return response;
    } catch (err) {
      return err;
    }
  },
);
export const getChildQR = createAsyncThunk(
  'getchildqr',

  async (_, { dispatch }) => {
    const userData: any = localStorage.getItem('selectedStudentId');
    let url = `api/v1/students/get-qr/${userData}`;
    try {
      const response: any = await API.get(url);
      dispatch(setStudentQR(response.data.data));
      // console.log("setStudentQR",response.data.data)
      return response;
    } catch (err) {
      return err;
    }
  },
);
export const getChildTotals = createAsyncThunk(
  'getchildtotals',

  async (topicId: any, { dispatch }) => {
    const userData: any = localStorage.getItem('selectedStudentId');
    let url = `/api/v1/dashboard/parent/totals/${userData}/${topicId}`;

    try {
      const response: any = await API.get(url);
      dispatch(setChildTotals(response.data.data));
      // console.log("child totals",  response.data.data)
      return response;
    } catch (err) {
      return err;
    }
  },
);
export const getChildReactions = createAsyncThunk(
  'getchildreactions',

  async (_, { dispatch }) => {
    const userData: any = localStorage.getItem('selectedStudentId');

    let url = `/api/v1/dashboard/parent/reactions/${userData}`;

    try {
      const response: any = await API.get(url);
      dispatch(setChildReactions(response.data.data));
      // console.log("child reactions",  response.data.data)
      return response;
    } catch (err) {
      return err;
    }
  },
);
export const getChildEngagements = createAsyncThunk(
  'getchildengagements',

  async (_, { dispatch }) => {
    const userData: any = localStorage.getItem('selectedStudentId');
    let url = `/api/v1/dashboard/parent/engagements/${userData}`;

    try {
      const response: any = await API.get(url);
      dispatch(setChildEngagements(response.data.data));
      // console.log("child engagements",  response.data.data)
      return response;
    } catch (err) {
      return err;
    }
  },
);
export const getParentSubTopicList = createAsyncThunk(
  'getparentsubtopiclist',

  async (_data: any, { dispatch }) => {
    //  console.log("subtopic data",  _data)
    let url = `/api/v1/dashboard/parent/subtopics?topicId=${_data.topicId}&limit=${_data.limit}&page=${_data.page}`;

    try {
      const response: any = await API.get(url);
      dispatch(setParentSubTopicList(response.data.data));
      // console.log("subtopic data",  response)
      return response;
    } catch (err) {
      return err;
    }
  },
);
export const getStudentData = createAsyncThunk(
  'getstudentdata',

  async (_data: any, { dispatch }) => {
    //  console.log("subtopic data",  _data)
    dispatch(setStudentData(_data));
  },
);
export const addStudent = createAsyncThunk(
  'addStudent',

  async (_data: any, { dispatch }) => {
    //console.log("Add student",  _data)
    let url = `/api/v1/users`;
    dispatch(startLoading());
    try {
      const response: any = await API.post(url, _data);
      dispatch(notificationSuccess('Student Added Successfully!!'));
      dispatch(stopLoading());
      dispatch(getChildList());
      return response;
    } catch (err: any) {
      dispatch(notificationFail(err.response.data.message));
      dispatch(stopLoading());
      return err;
    }
  },
);
export const deleteStudent = createAsyncThunk(
  'deleteStudent',

  async (id: any, { dispatch }) => {
    // console.log("delete student",  id)
    let url = `/api/v1/users/${id}`;
    dispatch(startLoading());

    try {
      const response: any = await API.delete(url);
      dispatch(notificationSuccess('Student Deleted Successfully!!'));
      dispatch(stopLoading());
      dispatch(getChildList());
      let tempArr;

      return response;
    } catch (err: any) {
      dispatch(notificationFail(err.response.data.message));
      dispatch(stopLoading());
      return err;
    }
  },
);

