// ** React Imports
import { ElementType, ReactNode, useEffect } from 'react';

// ** Next Imports
// import Link from 'next/link'
// import { useRouter } from 'next/router'

// ** MUI Imports
import Chip from '@mui/material/Chip';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import Box, { BoxProps } from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import { styled, useTheme } from '@mui/material/styles';
import ListItemButton, {
  ListItemButtonProps,
} from '@mui/material/ListItemButton';

// ** Configs Import
import themeConfig from 'config/themeConfig';

// ** Types
import { NavLink, NavGroup } from '@core/layouts/types';
import { Settings } from '@core/context/settingsContext';

// ** Custom Components Imports
import UserIcon from 'layouts/components/UserIcon';
// import Translations from 'layouts/components/Translations'
// import CanViewNavLink from 'layouts/components/acl/CanViewNavLink'

// ** Utils
// import { handleURLQueries } from '@core/layouts/utils'
import { useLocation, useNavigate } from 'react-router-dom';
import Translations from 'layouts/components/Translations';
import { fontSize } from '@mui/system';

interface Props {
  parent?: boolean;
  item: NavLink;
  admin: boolean;
  navHover?: boolean;
  settings: Settings;
  navVisible?: boolean;
  collapsedNavWidth: number;
  navigationBorderWidth: number;
  toggleNavVisibility: () => void;
  isSubToSub?: NavGroup | undefined;
}

// ** Styled Components
const MenuNavLink = styled(ListItemButton)<
  ListItemButtonProps & {
    component?: ElementType;
    target?: '_blank' | undefined;
  }
>(({ theme }) => ({
  width: '100%',
  height: role === 'parent' ? 40 : 56,
  borderRadius: 12,
  marginBottom: 10,
  color: 'red',
  fontWeight: 600,
  fontSize: role === 'parent' ? 12 : 12,
  lineHeight: role === 'parent' ? 16 : 16,
  fontFamily: 'inter',
  transition: 'padding-left .25s ease-in-out',
  '&.active': {
    '&, &:hover': {
      backgroundColor: '#4CBFA7',
    },
    '& .MuiTypography-root': {
      fontWeight: 500,
      fontSize:14,
      color: `${theme.palette.common.black} !important`,
    },
    '& .MuiListItemIcon-root': {
      color: `${theme.palette.common.black} !important`,
    },
  },
}));
let role = '';

const MenuItemTextMetaWrapper = styled(Box)<BoxProps>({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  transition: 'opacity .25s ease-in-out',
  ...(themeConfig.menuTextTruncate && { overflow: 'hidden' }),
});

const VerticalNavLink = ({
  admin,
  item,
  parent,
  navHover,
  settings,
  navVisible,
  isSubToSub,
  collapsedNavWidth,
  toggleNavVisibility,
  navigationBorderWidth,
}: Props) => {
  // ** Hooks
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const user: any = localStorage.getItem('userData');
    console.log('asfdlkja;sdlkfja;sldkfj;asdlkf', user);
    if (user) {
      role = JSON.parse(user).type;
    }
  }, []);
  // const router = useRouter()
  // ** Vars
  const { skin, navCollapsed } = settings;

  const IconTag: ReactNode =
    parent && !item.icon ? themeConfig.navSubItemIcon : item.icon;

  const conditionalIconColor = () => {
    if (skin === 'semi-dark' && theme.palette.mode === 'light') {
      return {
        color: `rgba(${theme.palette.customColors.dark}, ${
          parent ? 0.68 : 0.87
        })`,
      };
    } else if (skin === 'semi-dark' && theme.palette.mode === 'dark') {
      return {
        color: `rgba(${theme.palette.customColors.light}, ${
          parent ? 0.68 : 0.87
        })`,
      };
    } else
      return {
        color: parent
          ? theme.palette.text.secondary
          : theme.palette.text.primary,
      };
  };

  const conditionalBgColor = () => {
    if (skin === 'semi-dark' && theme.palette.mode === 'light') {
      return {
        '&:hover': {
          backgroundColor: `#4CBFA7`,
         // backgroundColor: "red"
        },
      };
    } else if (skin === 'semi-dark' && theme.palette.mode === 'dark') {
      return {
        '&:hover': {
         backgroundColor: `#4CBFA7`,
         //backgroundColor: "red"
        },
      };
    } else return {};
  };

  const isNavLinkActive = () => {
    if (location.pathname === item.path) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <ListItem
      disablePadding
      className="nav-link"
      disabled={item.disabled || false}
      sx={{
        transition: 'padding .25s ease-in-out',
        py: '0px',
        px: parent
          ? '0 !important'
          : `${theme.spacing(navCollapsed && !navHover ? 2 : 3)} !important`,
      }}
    >
      <MenuNavLink
        component={'a'}
        className={item?.icon && isNavLinkActive() ? 'active' : ''}
        {...(item.openInNewTab ? { target: '_blank' } : null)}
        onClick={(e) => {
          if (item.path === undefined) {
            e.preventDefault();
            e.stopPropagation();
          }
          if (navVisible) {
            toggleNavVisibility();
          }
          if (item.path) {
            navigate(`${item.path}`);
          }
        }}
        sx={{
          backgroundColor:
            isNavLinkActive() && role === 'parent'
              ? '#20A58A !important'
              : isNavLinkActive() && !item?.icon
              ? '#4CBFA7  !important'
              : 'inherit',
          color:
            !item?.icon && isNavLinkActive() ? '#4CBFA7 !important' : 'inherit',
          maxHeight: !item?.icon ? '44px' : 'unset',
          py: !item?.icon ? 0 : 2.25,
          mb: !item?.icon ? '0px' : '0px',
          ...conditionalBgColor(),
          ...(item.disabled
            ? { pointerEvents: 'none' }
            : { cursor: 'pointer' }),
          pr:
            navCollapsed && !navHover
              ? (collapsedNavWidth - navigationBorderWidth - 24 - 16) / 8
              : 3,
          pl:
            navCollapsed && !navHover
              ? (collapsedNavWidth - navigationBorderWidth - 24 - 16) / 8
              : 4,
        }}
      >
        {/* {isSubToSub ? null : (
          <ListItemIcon
            sx={{
              ...conditionalIconColor(),
              transition: 'margin .25s ease-in-out',
              ...(navCollapsed && !navHover ? { mr: 0 } : { mr: 2 }),
              ...(parent ? { ml: 2, mr: 4 } : {}), // This line should be after (navCollapsed && !navHover) condition for proper styling
              ...(isNavLinkActive() && { filter: 'brightness(0) invert(1)' }),
            }}
          >
            <UserIcon
              icon={IconTag}
              componentType="vertical-menu"
              iconProps={{
                sx: {
                  ...(!parent
                    ? { fontSize: '1.5rem' }
                    : { fontSize: '0.5rem' }),
                  ...(parent && item.icon ? { fontSize: '0.875rem' } : {}),
                },
              }}
            />
          </ListItemIcon>
        )} */}

        <MenuItemTextMetaWrapper
          sx={{
            ...(isSubToSub ? { ml: 4 } : {}),
            ...(navCollapsed && !navHover ? { opacity: 0 } : { opacity: 1 }),
          }}
        >
          <Typography
            marginLeft={item?.icon ? 0 : 8}
            {...((themeConfig.menuTextTruncate ||
              (!themeConfig.menuTextTruncate && navCollapsed && !navHover)) && {
              noWrap: true,
            })}
          >
            <Translations
              active={!item.icon && isNavLinkActive()}
              text={item.title}
              admin
            />
          </Typography>
          {/* {item.badgeContent && role !== 'parent' ? (
            <Chip
              size="small"
              label={item.badgeContent}
              color={item.badgeColor || 'primary'}
              sx={{
                ml: 1.5,
                '& .MuiChip-label': {
                  px: 2.5,
                  lineHeight: 1.385,
                  textTransform: 'capitalize',
                },
              }}
            />
          ) : item.badgeContent && role == 'parent' ? (
            <div className="h-fit  px-3 bg-[#f5ebf4] flex items-center justify-evenly rounded-full ml-2">
              <div className=" rounded-full bg-[#C44297] h-1.5 w-1.5" />
              <div className="text-[8px] font-normal ml-1 leading-5 text-[#C44297]">
                Free Trial
              </div>
            </div>
          ) : null} */}
        </MenuItemTextMetaWrapper>
      </MenuNavLink>
    </ListItem>
    // </CanViewNavLink>
  );
};

export default VerticalNavLink;
