// eslint-disable-next-line import/no-anonymous-default-export

import { env } from "config";

const prodConfig = {
  BASE_URL: 'https://live.g3ms.co/',
  WS: 'wss://live.g3ms.co/',
  INTERCOM_APP_ID: 'sd2zegi7',
  STUDENT_BASE_URL:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:3001'
      : process.env.REACT_APP_STUDENT_BASE_URL! || 'https://webapp.g3ms.co',
};

const devConfig = {
  BASE_URL: 'https://dev.g3ms.co/',
  WS: 'wss://dev.g3ms.co/',
  INTERCOM_APP_ID: 'sd2zegi7',
  STUDENT_BASE_URL:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:3001'
      : process.env.REACT_APP_STUDENT_BASE_URL! ||
        'https://dev-webapp.g3ms.co',
};

const config = env === 'dev' ? devConfig : prodConfig;

export default config;
