import { apiSlice } from '..';
import {
  IAddTemporarySchoolBody,
  ISchoolBody,
  ISchoolResponse,
  TGetUserTypeResponse,
} from './types';

export const schoolApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSchools: builder.query<ISchoolResponse, ISchoolBody>({
      query: (body) => {
        return {
          url: 'schools',
          method: 'GET',
          params: body,
        };
      },
    }),
    addTemporarySchool: builder.mutation<any, IAddTemporarySchoolBody>({
      query: (body) => {
        return {
          url: 'user/add-school',
          method: 'POST',
          body,
        };
      },
    }),
    getUserTypes: builder.query<TGetUserTypeResponse, void>({
      query: () => {
        return {
          url: '/auth/get-user-types',
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useGetSchoolsQuery,
  useAddTemporarySchoolMutation,
  useGetUserTypesQuery,
} = schoolApi;
